import Repository from './repository'

const resource = '/body-composition'

export default {
  ComposicaoCorporalGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  ComposicaoGetByAssessmentId (id) {
    return Repository.get(`${resource}/assessment/${id}`)
  },
  ComposicaoCorporalGet () {
    return Repository.get(`${resource}`)
  },
  ComposicaoCorporalGetLatest () {
    return Repository.get(`${resource}/latest`)
  },
  ComposicaoCorporalGetFirst () {
    return Repository.get(`${resource}/first`)
  },
  ComposicaoCorporalPost (item) {
    return Repository.post(`${resource}`, item)
  },
  ComposicaoCorporalPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  ComposicaoCorporalDelete (planoId) {
    return Repository.delete(`${resource}/${planoId}`)
  }
}
