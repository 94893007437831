export default class CircunferenceModel {
    id = 0
    circ_shoulders = 0
    circ_chest = 0
    circ_right_arm = 0
    circ_right_forearm = 0
    circ_left_arm = 0
    circ_left_forearm = 0
    circ_waist = 0
    circ_abdomen = 0
    circ_hip = 0
    circ_right_calf = 0
    circ_right_thigh = 0
    circ_left_thigh = 0
    circ_left_calf = 0
    student_physical_assessment_id = 0
}