<template>
	<div class="p-3 bg-white card border-radius-xl mt-3 me-3" data-animation="FadeIn" v-if="state.planoTreino">
		<div class="row">

			<div class="col-12 text-center">
        <div class="row">
          <div class="col-md-4 col-12">
            <div class="my-auto ms-auto">
                <button
                  type="button"
                  class="mb-0 btn btn-sm bg-gradient-success "
                  @click="elaborarTreino()"
                >
                  Config. semanas
                </button>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <span class="h5">Elaborador de treino</span>
          </div>
          <div class="col-md-4 col-12 text-end">
            <div v-if="state.trainingNumber !== ''">
              <span class="font-weight-bold">
                Treino
                <span class="text-danger">{{ state.trainingNumber }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
			<div class="col-12 text-center">
				<!-- <h6 class="mt-3">
					{{ state.planoTreino.students[0].name }} / {{ state.planoTreino.name }}
				</h6> -->
				<div class="text-sm my-3">
					<span class="font-weight-bold">
						{{ state.planoTreino.students[0].name }} / {{ state.planoTreino.name }}
					</span> 
					<br/>
					<!-- DADOS DO PLANO DE TREINO -->
					<span class="font-weight-bold text-danger">{{ (state.planoTreino.training_cycle.name.split(' '))[0] }}: </span>
					{{ (state.planoTreino.training_cycle.name.split(' '))[1] }} - 
					<span class="font-weight-bold text-danger">OBJ: </span> {{ state.planoTreino.objective }} - 
					<span class="font-weight-bold text-danger">Tipo:</span> {{ state.planoTreino.plan_group == 0 ? 'Individual' : 'Grupo' }} - 
					<span class="font-weight-bold text-danger">Início:</span> {{ $filters.datePtbr(state.planoTreino.initial_date) }} -
					<span class="font-weight-bold text-danger">Fim:</span> {{ $filters.datePtbr(state.planoTreino.final_date) }} - 
					<span class="font-weight-bold text-danger">Nº dias:</span> {{ $filters.countDayAndWeek(state.planoTreino.initial_date, state.planoTreino.final_date, 'D') }} - 
					<span class="font-weight-bold text-danger">Semanas:</span> {{ state.semanas.length }}
					<br/>
					<!-- DADOS DE PERIODIZAÇÃO DA SEMANA SELECIONADA -->
					<div v-if="state.selectedWeekData">
						<span class="font-weight-bold text-danger">DATA: </span>
						{{ $filters.datePtbr(state.selectedWeekData.initial_date) }} à  {{ $filters.datePtbr(state.selectedWeekData.final_date) }} |
						<span class="font-weight-bold text-danger">PERÍODO: </span> {{ state.selectedWeekData.period?.name }} | 
						<span class="font-weight-bold text-danger">ETAPA:</span> {{ state.selectedWeekData.step?.name }} | 
						<span class="font-weight-bold text-danger">MESO:</span> {{ state.selectedWeekData.mesocycle?.name }} | 
						<span class="font-weight-bold text-danger">MICRO:</span> {{ state.selectedWeekData.microcycle?.name }} | 
						<span class="font-weight-bold text-danger">MÉTODO:</span> {{ state.selectedWeekData.method?.name }} | 
						<span class="font-weight-bold text-danger">% TEC:</span> {{ state.selectedWeekData.percent_technique }}% | 
						<span class="font-weight-bold text-danger">% FOR:</span> {{ state.selectedWeekData.percent_force }}% | 
						<br/>
					</div>
				</div>
			</div>
		</div>

		<div class="row justify-content-between">
			<div class="col-12 col-md-4">
				<div class="form-group">
					<label class="required">Semanas de treino</label>
					<select class="form-control custom-select form-control-sm" name="tested_exercises" id="tested_exercises"
						@change="buscarTreinoSemana"
						v-model="state.selectedWeek">
						<option value="">Selecione a semana de treino</option>
						<option v-for="(item, index) in state.semanas" :key="index" :value="item.id">
							Semana {{ index + 1 }} | {{ $filters.datePtbr(item.initial_date) }} à
							{{ $filters.datePtbr(item.final_date) }}
						</option>
					</select>
				</div>
			</div>
      <div class="col-12 col-md-8">
        <div class="float-end" v-if="state.daysOfWeek.length > 0">
          <table class="table-bordered" v-if="state.totals != null">
            <thead>
            <tr>
              <th colspan="2" class="pe-3 text-end">S</th>
              <th class="text-center">REP</th>
              <th class="text-center">TON</th>
              <th class="text-center">PM</th>
              <th class="text-center">IMR</th>
            </tr>
            </thead>
            <tbody>
            <tr class="">
              <td class=" px-3">Total da Semana</td>
              <td class="text-center px-3">{{ state.totals.week.series }}</td>
              <td class="text-center px-3">{{ state.totals.week.repetitions }}</td>
              <td class="text-center px-3">{{ state.totals.week.tonnage }}</td>
              <td class="text-center px-3">{{ state.totals.week.middleWeight }}</td>
              <td class="text-center px-3">{{ state.totals.week.imr }}</td>
            </tr>
            <tr class="">
              <td class="px-3">Total do Plano</td>
              <td class="text-center px-3">{{ state.totals.plan.series }}</td>
              <td class="text-center px-3">{{ state.totals.plan.repetitions }}</td>
              <td class="text-center px-3">{{ state.totals.plan.tonnage }}</td>
              <td class="text-center px-3">{{ state.totals.plan.middleWeight }}</td>
              <td class="text-center px-3">{{ state.totals.plan.imr }}</td>
            </tr>
            <tr class="">
              <td class=" px-3">Total Acumulado</td>
              <td class="text-center px-3">{{ state.totals.accumulated.series }}</td>
              <td class="text-center px-3">{{ state.totals.accumulated.repetitions }}</td>
              <td class="text-center px-3">{{ state.totals.accumulated.tonnage }}</td>
              <td class="text-center px-3">{{ state.totals.accumulated.middleWeight }}</td>
              <td class="text-center px-3">{{ state.totals.accumulated.imr }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
		</div>

    <div class="d-flex justify-content-end">


    </div>

    <!--    ABAS DE DIAS DA SEMANA-->
		<div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs" id="myTab-elaborador-individual" role="tablist">
          <li class="nav-item" role="presentation" >
            <button class="nav-link active" id="elaborador-tab" data-bs-toggle="tab" data-bs-target="#elaborador" type="button"
                    role="tab" aria-controls="elaborador" aria-selected="true" @click="state.selectedTab='elaborador'">
              Elaborador
              <br/>
              <span style="font-size: 0.7em" class="text-white">
                segredo
                </span>
            </button>
          </li>
          <li class="nav-item" role="presentation" v-for="(item, index) in state.daysOfWeek" :key="index" @click.passive="state.trainingNumber = item.training_number">
            <button class="nav-link" :id="item.day+'-tab'" data-bs-toggle="tab" :data-bs-target="'#'+item.day" type="button"
                    role="tab" :aria-controls="item.day" aria-selected="true" @click="state.selectedTab=item.day">
              <span class="font-weight-bold">
                {{ $filters.nameDayPortuguese(item.day) }}
              </span>
              <span class="small">
                - {{item.base_group_name}}

              </span>
              <i class="fas fa-trash text-secondary ms-2" style="font-size:.9rem;" @click="removerDiaSemana($filters.nameDayPortuguese(item.day), item, index)"></i>
              <br/>
              <span style="font-size: 0.7em">
                  {{item.day_date.split('-').reverse().join('/')}}
                </span>

            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div  class="tab-pane fade active show" id="elaborador" role="tabpanel" aria-labelledby="elaborador-tab">
            <!-- EXERCICIOS TESTADOS -->
            <div class="row mt-3">
              <div class="col-12 form-group col-md-3">
                <label for="">Selecione o grupo</label>
                <select
                    class="form-control  form-control-sm custom-select"
                    name="student-manager"
                    required
                    id="select-student-manager"
                    @change="buscaExercicioGrupoTreino"
                    v-model="state.trainingPlanGroupId"
                >
                  <option value="">Selecione um grupo</option>
                  <option value="">CRIAR CARDIO</option>
                  <option v-for="(item, index) in state.planoTreino.groups" :key="index" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
              <div class="form-group col-12 col-md-3">
                <label class="required">Exercícios testados</label>
                <select class="form-control custom-select form-control-sm" name="tested_exercises" id="tested_exercises"
                        v-model="state.testedExerciseSelected" @change="adicionarExercicio('T')">
                  <option value="">Selecione um exercício</option>
                  <option value="0">Adicionar todos</option>
                  <option v-for="(item, index) in state.exerciciosTestados" :key="index" :value="item.id">
                    {{ item.exercise.name }}
                  </option>
                </select>
              </div>
              <!-- MARCAR SEMANAS E DIAS -->
              <div class="col-12 form-group col-md-6"
                   v-if="state.semanas.length > 0 && state.planoTreino.groups.length > 0"
              >
                <label for="">Selecione as semanas</label>
                <select-box-dia-treino
                    :ItemsProp="state.semanasSelect"
                    :FieldProp="'name'"
                    :GruposProp="state.planoTreino.groups"
                    :PlaceholderTextProp="'Selecione'"
                    @salvarDias="adicionarGruposDias"
                ></select-box-dia-treino>
              </div>
            </div>


            <hr>

            <TabelaExercicios
                v-if="state.exerciciosTestados.length > 0 || state.treinoElaborado.length > 0"
                :exerciciosProp="state.treinoElaborado"
                :exerciciosTestadosProp="state.exerciciosTestados"
            ></TabelaExercicios>

            <div class="row" v-show="state.treinoElaborado.length > 0" >
              <div class="col-12 col-md-6">
                <div class="form-group d-flex">
                  <label for="" class="me-3">Observações: </label>
                  <textarea rows="3" class="form-control form-control-sm" style="resize: none" v-model="state.observation" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-end">
                <button type="button" id="btnSalvarExerciciosGrupo" :disabled="state.btnSalvarExerciciosDisabled" class="btn bg-gradient-success btn-sm" @click="adicionarExerciciosGrupo">
                  Salvar
                </button>
                <button type="button" class="ms-3 btn bg-gradient-primary btn-sm" @click="limparExercicios">
                  Limpar
                </button>
              </div>
            </div>
          </div>
          <!--          LISTAGEM DAS ABAS DE DIAS DA SEMANA SELECIONADA-->
          <div class="tab-pane fade" v-for="(item, index) in state.daysOfWeek" :key="index" :id="item.day" role="tabpanel" :aria-labelledby="item.day+'-tab'">

            <tabela-exercicios-elaborados
                v-if="item.exercises.length > 0"
                :exerciciosTestadosProp="state.exerciciosTestados"
                :exerciciosProp="item.exercises"
                @refresh-week="buscarTreinoSemana"
            ></tabela-exercicios-elaborados>
          </div>
        </div>
      </div>
    </div>

		

		<!-- TODAS MODALIDADES, GRUPOS E EXERCICIOS -->
		<div class="row">
			<div class="col-12 col-md-4 mt-sm-0">
				<label>Modalidade</label>
				<select class="form-control custom-select form-control-sm" name="modalidades" id="select-modalidades"
					@change="buscarGrupoPorModalidade" v-model="state.modalidadeSelecionadaId">
					<option value="0">Selecione uma modalidade</option>
					<option v-for="(modalidade, index) in state.modalidades" :key="index" :value="modalidade.id">
						{{ modalidade.name }}
					</option>
				</select>
			</div>
			<div class="col-12 col-md-4 mt-sm-0">
				<label>Grupo</label>
				<select class="form-control custom-select form-control-sm" name="grupos"  @change="buscarExercicioPorGrupo"
					v-model="state.grupoSelecionadoId">
					<option value="0">Selecione um grupo</option>
					<option v-for="(item, index) in state.grupos" :key="index" :value="item.id">
						{{ item.name }}
					</option>
				</select>
			</div>
			<div class="col-12 col-md-4 mt-sm-0">
				<label>Nome do exercicio</label>
				<select class="form-control custom-select form-control-sm" name="grupos"
					v-model="state.exercicioSelecionadoId" @change="adicionarExercicio()">
					<option value="0">Selecione um grupo</option>
					<option v-for="(item, index) in state.exercicios" :key="index" :value="item.id">
						{{ item.name }}
					</option>
				</select>
			</div>
		</div>

		<div class="row">
			<div class="col-12 text-center">
				<router-link
					:to="{ name: 'PlanoTreinoSemanas' }"
					class=" text-danger text-sm"
				>
					[ VOLTAR - LISTA DE SEMANAS DO TREINO ]
				</router-link>	
			</div>
		</div>
	</div>
</template>
  
<script>
import kpiRepository from "../../../../services/api/kpiRepository"
import planosTreinoRepository from "../../../../services/api/planosTreinoRepository"
import modalidadeRepository from "../../../../services/api/modalidadeRepository"
import grupoRepository from "../../../../services/api/grupoRepository"
import exercicioRepository from "../../../../services/api/exercicioRepository"
import testesCargaRepository from "../../../../services/api/testesCargaRepository"
import {inject, onMounted, reactive} from "vue"
import {useRoute, useRouter} from "vue-router"
import {useStore} from "vuex"
import TabelaExercicios from "./TabelaExercicios.vue"
import SemanaElaborada from "./SemanaElaborada.vue"
import TabelaExerciciosElaborados from "./TabelaExerciciosElaborados.vue"
import SelectBoxDiaTreino from './../../../../components/SelectBoxDiaTreino.vue'

export default {
	components: {
		TabelaExercicios, 
		SemanaElaborada, 
		TabelaExerciciosElaborados,
    SelectBoxDiaTreino
	},
	setup() {
		const router = useRouter()
		const route = useRoute()
		const swal = inject("$swal")
		const store = useStore()
		onMounted(() => {
      //store.commit('SET_COPY_EXERCISES', [])
			store.commit("SET_SPLASH_SCREEN", false)

			state.planoTreinoId = store.getters.TrainingPlanManager
      if(state.planoTreinoId) {
        buscarModalidade()
        buscarDadosPlanoTreino()
        buscarPlanoDetreinoSemanas()
      }


		})

		const state = reactive({
      observation: '',
			selectedTab: 'elaborador',
      trainingPlanGroupId: '',
      totals: null,
			selectedWeek: '',
			semanasSelecionadas: null,
			diasDaSemanaSelecionadas: [],
			planoTreinoId: 0,
			planoTreino: null,
      btnSalvarExerciciosDisabled: false,
      trainingNumber: '',
			grupoSelecionadoId: "0",
			modalidadeSelecionadaId: "0",
			exercicioSelecionadoId: "0",
			modalidades: [],
			grupos: [],
			exercicios: [],
			exerciciosTestados: [],
			testedExerciseSelected: "",
			treinoElaborado: [],
			semanas: [],
			daysOfWeek: [],
			weekTraining: [],
			semanasSelecionadasCheckbox: [],
			semanasSelect: [],
			selectedWeekData: null
		})

		const buscarTreinoSemana = async () => {

      state.trainingNumber = ''
			state.selectedWeekData = state.semanas.find(x => x.id == state.selectedWeek)
      router.push({query: {week: state.selectedWeek}})
			//console.log(state.selectedWeekData)
      try {
        store.commit("SET_SPLASH_SCREEN", true)
        let response = await planosTreinoRepository.PlanoTreinoElaboradoBySemanaIdGet(state.selectedWeek)
        if (response.data.success) {
          state.weekTraining = []
          state.weekTraining = response.data.data
          state.daysOfWeek = []
					if(!response.data.data) {
						swal({
							icon: "info",
							text: "Semana não elaborada!",
						})
						return
					}
          for (const [key, value] of Object.entries(response.data.data)) {
            let exercises = value['exercises'].map(exercise => {
              //let item = state.testedExercises.find(x => x.exercise_id == exercise.exercise_id)
              let item = state.exerciciosTestados.find(x => x.exercise_id == exercise.exercise_id)
              exercise['max_load_test'] = item ? item.max_load_test : 0
              if(exercise.series.length == 0) {
                exercise.series.push({
                  repetitions: '',
                  load: '',
                  perc_load: '',
                  series: '',
                })
              }
              return exercise
            })
            state.daysOfWeek.push(
              {
                day: value['day_name'],
                day_date: value['day_date'],
                training_number: value['training_number'],
                base_group_name: value['base_group_name'],
                exercises: exercises
              }
            )
          }
          buscarTotalizadores()
          
        }
      } catch (error) {
        console.log(error)
        store.commit("SET_SPLASH_SCREEN", false)
      } finally {
        store.commit("SET_SPLASH_SCREEN", false)
      }
    }

    const buscarTotalizadores = async () => {
      let response = await kpiRepository.TrainingAverageTotalsGet(state.planoTreinoId, state.selectedWeek)
      if (response.data.success) {
        state.totals = response.data.data

      }
    }

		const buscarPlanoDetreinoSemanas = async () => {
			try {
				store.commit("SET_SPLASH_SCREEN", true)
				let response = await planosTreinoRepository.SemanasDiasGet()
				state.semanas = []
				if (response.data.success) {
					state.semanas = response.data.data.map((item, index) => {
						//item['copiar'] = false
						item['name'] = `Sem. ${index+1}`
						return item
					})
					state.semanasSelect = [...state.semanas]
					// state.semanasSelect.unshift({id: 0, copiar: false, name: 'Selecionar tudo' })
				}

				store.commit("SET_SPLASH_SCREEN", false)
			} catch (error) {
				store.commit("SET_SPLASH_SCREEN", false)
				swal({
					icon: "error",
					title: "Erro ao listar semanas do plano de treino!",
					text: error.message,
				})
			} finally {
				store.commit("SET_SPLASH_SCREEN", false)
			}
		}

		const buscarDadosPlanoTreino = async () => {
			try {
				store.commit("SET_SPLASH_SCREEN", true)
				let response = await planosTreinoRepository.PlanosTreinoGetById(
					state.planoTreinoId
				)
				store.commit("SET_SPLASH_SCREEN", false)
				if (response.data.success) {
					state.planoTreino = response.data.data

					await buscarExerciciosTestados()
          if (route.query.week && parseInt(route.query.week) > 0) {
            state.selectedWeek = parseInt(route.query.week)
            await buscarTreinoSemana()
          }
				}
			} catch (error) {
				store.commit("SET_SPLASH_SCREEN", false)
				swal({
					icon: "error",
					title: "Erro ao listar dados do plano de treino!",
					text: error.message,
				})
			} finally {
				store.commit("SET_SPLASH_SCREEN", false)
			}
		}

		const buscarExerciciosTestados = async () => {
			try {
				store.commit("SET_SPLASH_SCREEN", true)
				let response = await testesCargaRepository.ExerciciosTestadosPorPlanoTreinoId(
					state.planoTreinoId
				)
				store.commit("SET_SPLASH_SCREEN", false)
				if (response.data.success) {
					state.exerciciosTestados = response.data.data
				}
			} catch (error) {
				store.commit("SET_SPLASH_SCREEN", false)
				swal({
					icon: "error",
					title: "Erro ao listar exercicios testados!",
					text: error.message,
				})
			} finally {
				store.commit("SET_SPLASH_SCREEN", false)
			}
		}

		const buscarModalidade = () => {
			store.commit("SET_SPLASH_SCREEN", true)
			modalidadeRepository
				.ModalidadeGet()
				.then((response) => {
					store.commit("SET_SPLASH_SCREEN", false)
					if (response.data.success) {
						state.modalidades = response.data.data
					}
				})
				.catch((error) => {
					store.commit("SET_SPLASH_SCREEN", false)
					swal({
						icon: "error",
						title: "Erro ao buscar modalidade!",
						text: error.message,
					})
				})
		}

		const buscarGrupoPorModalidade = () => {
			store.commit("SET_SPLASH_SCREEN", true)
			grupoRepository
				.GrupoGetByModalityId(state.modalidadeSelecionadaId)
				.then((response) => {
					store.commit("SET_SPLASH_SCREEN", false)
					if (response.data.success) {
						state.grupos = response.data.data
					}
				})
				.catch((error) => {
					store.commit("SET_SPLASH_SCREEN", false)
					swal({
						icon: "error",
						title: "Erro ao buscar grupos!",
						text: error.message,
					})
				})
		}

		const buscarExercicioPorGrupo = (id = null) => {
			store.commit("SET_SPLASH_SCREEN", true)
			exercicioRepository
				.ExercicioGetByGroupId(state.grupoSelecionadoId)
				.then((response) => {
					store.commit("SET_SPLASH_SCREEN", false)
					if (response.data.success) {
						state.exercicios = response.data.data
					}
				})
				.catch((error) => {
					store.commit("SET_SPLASH_SCREEN", false)
					swal({
						icon: "error",
						title: "Erro ao buscar exercicios!",
						text: error.message,
					})
				})
		}

		function adicionarExercicio(tipoLista = "") {

			if (tipoLista == "T") {
				if(state.testedExerciseSelected == '0') {
					state.exerciciosTestados.forEach(item => {
						let obj = {
							order: state.treinoElaborado.length + 1,
							exercise: Object.assign({}, item.exercise),
							max_load_test: item.max_load_test,
              exercise_id: item.exercise.id,
							series: [
								{
									repetitions: '',
									load: '',
									perc_load: '',
									series: '',
								},
							],
						}

						state.treinoElaborado.push(obj)
					})
				} else {
					let exercise = state.exerciciosTestados.find((x) => x.id == parseInt(state.testedExerciseSelected))
          //if(verificaExericioDuplicado(exercise, exercise.exercise_id)) return
					let obj = {
						order: state.treinoElaborado.length + 1,
						exercise: Object.assign({}, exercise.exercise),
            exercise_id: exercise.exercise.id,
						max_load_test: exercise.max_load_test,
						series: [
							{
								repetitions: '',
								load: '',
								perc_load: '',
								series: '',
							},
						],
					}
          if(verificaExericioDuplicado(state.treinoElaborado, obj, obj.exercise_id)) return
					state.treinoElaborado.push(obj)
				}
			} else {
				let exercise = state.exercicios.find(
					(x) => x.id == parseInt(state.exercicioSelecionadoId)
				)

        //if(verificaExericioDuplicado(exercise, exercise.id)) return
				let obj = {
					order: state.treinoElaborado.length + 1,
					exercise: exercise,
          exercise_id: exercise.id,
					max_load_test: 0,
					series: [
						{
							repetitions: '',
							load: '',
							perc_load: '',
							series: '',
						},
					],
				}

				if(state.selectedTab == 'elaborador') {

          if(verificaExericioDuplicado(state.treinoElaborado, obj, obj.exercise_id)) return
					state.treinoElaborado.push(obj)
				} else {
					let dayIndex = state.daysOfWeek.findIndex(x => x.day == state.selectedTab)

          if(verificaExericioDuplicado(state.daysOfWeek[dayIndex].exercises, obj, obj.exercise_id)) return


					obj.order = state.daysOfWeek[dayIndex].exercises.length + 1
					let exercises = [...state.daysOfWeek[dayIndex].exercises]
					let lastExercise = exercises[exercises.length-1]

					obj['training_day'] = lastExercise.training_day
					obj['training_plan_week_id'] = lastExercise.training_plan_week_id
					obj['training_date'] = lastExercise.training_date
					obj['exercise_id'] = obj.exercise.id
					exercises.push(obj)
					//state.daysOfWeek[dayIndex].exercises.push(obj)
					state.daysOfWeek[dayIndex].exercises = [...exercises]

				}

			}
		}

    function verificaExericioDuplicado(listExercises, exercise, exerciseId) {

      /*let exercicioDuplicado = false
      listExercises.forEach(item => {
        if(item['exercise_id'] == exerciseId) {
          exercicioDuplicado = true
        }
      })

      return exercicioDuplicado*/
      return listExercises.some(item => item['exercise_id'] == exerciseId)
    }



		const adicionarExerciciosGrupo = async () => {

      let exercicios = state.treinoElaborado.map((item, index) => {
        item['order'] = index + 1
        return item
      })

      /*let obj = {
        trainingPlanId: state.planoTreinoId,
        exercises: exercicios,
        training_plan_group_id: state.trainingPlanGroupId,
        observation: state.observation,
        weeks: state.semanasSelect
      }*/
      let obj = {
        trainingPlanId: state.planoTreinoId,
        exercises: exercicios,
        training_plan_group_id: state.trainingPlanGroupId,
        observation: state.observation
      }

			try {
        state.btnSalvarExerciciosDisabled = true
				store.commit("SET_SPLASH_SCREEN", true)

				let response = await planosTreinoRepository.AdicionarExerciciosGrupoPost(obj)

				if(response.data.success) {
					swal({
						icon: "success",
						title: "Exercicíos adicionados com sucesso!",
					}).then(() => {
            buscarTreinoSemana()
					})
				}

			} catch (error) {
        console.log(error)
				store.commit("SET_SPLASH_SCREEN", false)
				swal({
					icon: "error",
					title: "Erro ao salvar exercicios!",
					text: error.message,
				})
			} finally {
        state.btnSalvarExerciciosDisabled = false
        store.commit("SET_SPLASH_SCREEN", false)
      }
		}

    const adicionarGruposDias = async () => {

      let obj = {
        weeks: state.semanasSelect
      }

      try {
        state.btnSalvarExerciciosDisabled = true
        store.commit("SET_SPLASH_SCREEN", true)

        let response = await planosTreinoRepository.AdicionarGruposDiasPost(obj)

        if(response.data.success) {
          swal({
            icon: "success",
            title: "Exercicíos adicionados com sucesso!",
          }).then(() => {
            buscarTreinoSemana()
          })
        }

      } catch (error) {
        console.log(error)
        store.commit("SET_SPLASH_SCREEN", false)
        swal({
          icon: "error",
          title: "Erro ao salvar exercicios!",
          text: error.message,
        })
      } finally {
        state.btnSalvarExerciciosDisabled = false
        store.commit("SET_SPLASH_SCREEN", false)
      }
    }

		const limparExercicios = () => {

      if(state.treinoElaborado.length > 0) {
        const groupId = state.treinoElaborado[0]['training_plan_group_id']
        swal({
          title: "Tem certeza?",
          text: `Deseja deletar todos exercicios do grupo?`,
          showCancelButton: true,
          confirmButtonText: "Sim, quero deletar!",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            deletarExercicioConfirma(groupId)
          }
        })

      }

		}

    function deletarExercicioConfirma(groupId) {
      store.commit('SET_SPLASH_SCREEN', true)
      planosTreinoRepository.PlanoTreinoExercicioByGrupoIdDelete(groupId).then(function (response)  {
        store.commit('SET_SPLASH_SCREEN', false)
        if(response.data.success) {
          swal({
            title: "Removido!",
            text: "Exercicios removidos com sucesso!",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
          state.treinoElaborado = []
        }
      }).catch(error => {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error.message)
        swal({
          icon: 'error',
          title: 'Erro ao deletar exercicios!',
          text: error.message
        })
      })
    }

    function removerDiaSemana(day, item, index) {

      swal({
        title: "Tem certeza?",
        text: `Deseja deletar ${day}`,
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        cancelButtonText: "Não, cancelar!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          let ids = item.exercises.map(exercise => {
            return exercise.id
          })
          removerDiaSemanaConfirma(ids, index)
        }
      })
    }

    function removerDiaSemanaConfirma(ids, index) {
      store.commit('SET_SPLASH_SCREEN', true)
      planosTreinoRepository.SemanaDiaDelete(ids).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if(response.data.success) {
          state.daysOfWeek.splice(index, 1)
          swal({
            title: "Removido!",
            text: "Dia removido com sucesso!",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          }).then(() => {
            // listarMetodos()
          })
        }
      }).catch(error => {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error.message)
        swal({
          icon: 'error',
          title: 'Erro ao deletar dia!',
          text: error.message
        })
      })
    }

    function totalizadoresEstrategicos(tipo) {
      // let totais = {'S': 0, 'REP': 0,	'TON': 0,	'PM': 0,	'IMR': 0}
      let response = 0
      let responseIMR = 0
      let ton = 0
      let rep = 0
      state.daysOfWeek.forEach(day => {
        day.exercises.forEach(exercise => {
          if (tipo == 'S') {
            exercise.series.forEach(load => {
              if(load.series != '' && parseInt(load.series) > 0) {
                response += parseInt(load.series)
              }
            })
          } else if (tipo == 'REP') {

            exercise.series.forEach(load => {
              if(load.series != '' && load.repetitions != '') {
                response += (parseInt(load.series) * parseInt(load.repetitions))
              }

            })
          } else if (tipo == 'TON') {
            exercise.series.forEach(load => {
              if(load.series != '' && load.load != '' && load.repetitions != '') {
                response += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions))
              }

            })
          } else if (tipo == 'PM') {

            exercise.series.forEach(load => {
              if(load.load != '' && load.series != '' && load.repetitions != '') {

                ton += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions))

                rep += (parseInt(load.series) * parseInt(load.repetitions))
                //response += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions)) / (parseInt(load.series) * parseInt(load.repetitions))
              }
            })
            if(rep > 0) {
              response = parseFloat(ton / rep).toFixed(2)
            }
          } else if (tipo == 'IMR') {
            /*let ton = 0
            let rep = 0*/

            exercise.series.forEach(load => {
              if(load.load != '' && load.series != '' && load.repetitions != '') {

                ton += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions))

                rep += (parseInt(load.series) * parseInt(load.repetitions))
                //response += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions)) / (parseInt(load.series) * parseInt(load.repetitions))
              }

              /*if(load.series != '') {
                response += parseInt(load.series)
              }*/

            })
            if(rep > 0) {
              let pm = (parseFloat(ton / rep).toFixed(2))
              if(exercise.max_load_test) {
                responseIMR += ((pm * 100) / exercise.max_load_test)
              }
            }
          }
        })


      })
      if(tipo == 'IMR') {

        //return parseFloat(responseIMR) > 0 ? parseFloat(responseIMR).toFixed(2) : parseFloat(responseIMR)
        return parseInt(responseIMR)
      }
      return response
    }

    // METODO PARA BUSCAR OS EXERCICIOS DO GRUPO DE EXERCICIOS
    function buscaExercicioGrupoTreino() {
      planosTreinoRepository.ExerciciosPorGrupoIdGet(state.trainingPlanGroupId).then(response => {
        state.treinoElaborado = []
        if(response.data.success && response.data.data.length > 0) {
          response.data.data.forEach(item => {
            item['checkCopy'] = false
            state.treinoElaborado.push(item)
          })

        }
      }).catch(error => {
        console.error(error)
      })
    }

    function elaborarTreino() {
      router.push(`/elaborar-treino`)
    }

		return {
			state,
			removerDiaSemana,
			buscarGrupoPorModalidade,
			buscarExercicioPorGrupo,
			adicionarExercicio,
			adicionarExerciciosGrupo,
			limparExercicios,
			buscarTreinoSemana,
      adicionarGruposDias,
      totalizadoresEstrategicos,
      buscaExercicioGrupoTreino,
      elaborarTreino
		}
	},
	
}
</script>
