<template>
  <div class="py-4 container-fluid">
    <ModalidadeFormulario :modalidadeId="state.modalidadeId" @closeModal="displayModal(false, true)" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <MyTable
              :data="state.modalidades"
              :columns="columns"
              :itemsPerPage="12">
              <template v-slot:row="{ row }">
                <td class="text-sm pointer">
                  {{ row.name }} 
                </td>
                <td class=" text-center">
                  {{ $filters.datePtbr(row.created_at) }}
                </td>
                <td  class="text-sm ">
                  <div class="float-end">
                    <div>
                      <span
                        class="pointer mx-3"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Editar modalidade"
                        @click="cadastrarGrupo(row.id)"
                      >
                        <i class="fas fa-layer-group me-2"></i>
                        Cadastrar grupo
                      </span>
                    </div>
                    <div>
                      <span
                        class="pointer mx-3"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Editar modalidade"
                        @click="editarModalidade(row.id)"
                      >
                        <i class="fas fa-pen text-secondary me-2"></i>
                        Editar
                        
                      </span>
                    </div>
                    <div>
                      <span
                        class="pointer mx-3"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Deletar modalidade"
                        @click="deletarModalidade(row)"
                      >
                        <i class="fas fa-trash text-secondary me-2"></i>
                        Deletar
                      </span>
                    </div>
                  </div>
                </td>
              </template>
            </MyTable>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    
  </div>
</template>

<script>
  import { reactive, onMounted, ref, inject } from 'vue'
  import { useStore } from 'vuex'
  import modalidadeRepository from "../../../../../services/api/modalidadeRepository";
  import MyTable from '../../../../../components/MyTable.vue';
  import { useRouter, useRoute } from 'vue-router'
  import ModalidadeFormulario from './ModalidadeFormulario.vue';

  export default {
    components: {
      MyTable,
      ModalidadeFormulario
    },
    setup () {
      const router = useRouter()
      // const route = useRoute()
      const swal = inject('$swal')
      const store = useStore()
      onMounted(() => {
        store.commit('SET_SPLASH_SCREEN', false)
        listarModalidades()
      })

      //const hidePassword = ref(true)
      // const passwordFieldIcon = computed(() => hidePassword.value ? ['fa', 'eye'] : ['fa', 'eye-slash']);
      // const passwordFieldType = computed(() => hidePassword.value ? "password" : "text");

      let modalVisible= ref(false);
      const columns = ref([
        {
          label: 'Nome',
          field: 'nome',
          sortable: 'nome'
        },
        {
          label: 'Data de cadastro',
          field: 'dataCadastro'
        },
        {
          label: 'Ações',
          field: 'actions'
        }
      ])

      const state = reactive({
        modalidadeId: 0,
        modalidades: [],
        modalidadeId: 0
      })

      const listarModalidades = () => {
        store.commit('SET_SPLASH_SCREEN', true)
        modalidadeRepository.ModalidadeGet().then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          state.modalidades = []

          if (response.data.success) {
            state.modalidades = response.data.data

          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao listar modalidades!',
            text: error.message
          })
        })
      }

      function cadastrarGrupo(id) {
        router.push(`/cadastros/grupos/${id}`);
      }

      function editarModalidade(id) {
        state.modalidadeId = id
        displayModal(true)
        //router.push(`/cadastros/modalidade/${id}`);
      }

      function deletarModalidade(item) {
        swal({
          title: "Tem certeza?",
          text: `Deseja deletar ${item.name}`,
          showCancelButton: true,
          confirmButtonText: "Sim, quero deletar!",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            deletarModalidadeConfirma(item.id)
          }
        })
      }

      function deletarModalidadeConfirma(id) {
        store.commit('SET_SPLASH_SCREEN', true)
        modalidadeRepository.ModalidadeDelete(id).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if(response.data.success) {
            swal({
              title: "Removido!",
              text: "Modalidade removida com sucesso!",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            }).then(() => {
              listarModalidades()
            })
          }
        }).catch(error => {
          store.commit('SET_SPLASH_SCREEN', false)
          console.error(error.message)
          swal({
            icon: 'error',
            title: 'Erro ao deletar modalidade!',
            text: error.message
          })
        })
      }

      function displayModal(display = true, refresh = false) {
        if(!display) state.modalidadeId = 0
        modalVisible.value = display
        if(refresh) {
          
          listarModalidades() 
        }
      }

      return {
        state,
        columns,
        editarModalidade,
        deletarModalidade,
        displayModal,
        modalVisible,
        cadastrarGrupo
      }
    }
  }
  
</script>
