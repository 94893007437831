<template>
  <div class="row mt-5">
    <div class="col-12 col-md-4">
      <div class="form-group">
        <label class="required">Semanas de treino</label>
        <select class="form-control custom-select" name="tested_exercises" id="tested_exercises"
          @change="buscarTreinoSemana"
          v-model="state.selectedWeek">
          <option value="">Selecione a semana de treino</option>
          <option v-for="(item, index) in state.semanas" :key="index" :value="item.id">
            Semana {{ index + 1 }} | {{ $filters.datePtbr(item.initial_date) }} à
            {{ $filters.datePtbr(item.final_date) }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12">
      <ul class="nav nav-tabs" id="myTab-semana-elaborada" role="tablist">
        <li class="nav-item" role="presentation" v-for="(item, index) in state.daysOfWeek" :key="index">
          <button class="nav-link" :id="item.day+'-tab'" data-bs-toggle="tab" :data-bs-target="'#'+item.day" type="button"
            role="tab" :aria-controls="item.day" aria-selected="true">
            {{ $filters.nameDayPortuguese(item.day) }}
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade" v-for="(item, index) in state.daysOfWeek" :key="index" :id="item.day" role="tabpanel" :aria-labelledby="item.day+'-tab'">
          <tabela-exercicios-elaborados 
            :exerciciosProp="item.exercises" 
            :exerciciosTestadosProp="state.testedExercises"></tabela-exercicios-elaborados>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import planosTreinoRepository from "../../../../services/api/planosTreinoRepository"
import testesCargaRepository from "../../../../services/api/testesCargaRepository"
import { reactive, onMounted, ref, inject, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import { useStore } from "vuex"
import TabelaExerciciosElaborados from "./TabelaExerciciosElaborados.vue"
export default {
  components: {
    TabelaExerciciosElaborados
  },
  props: {
    testedExercises: {
      type: Array,
      default: new Array
    },
    planoTreinoId: {
				type: Number,
				default: 0,
			},
		},
  setup(props) {
    onMounted(() => {
      state.planoTreinoId = props.planoTreinoId
      //state.testedExercises = props.testedExercises
      if(parseInt(state.planoTreinoId) > 0 ) {
        buscarPlanoDetreinoSemanas()
        buscarExerciciosTestados()
      }
    })

    const router = useRouter()
		const route = useRoute()
		const swal = inject("$swal")
		const store = useStore()

    const state = reactive({
      semanas: [],
      planoTreinoId: 0,
        selectedWeek: '',
      weekTraining: [],
      daysOfWeek: [],
      testedExercises: []
    })

    const buscarExerciciosTestados = async () => {
			try {
				store.commit("SET_SPLASH_SCREEN", true)
				let response = await testesCargaRepository.ExerciciosByTesteCargaIdGet(
					state.planoTreinoId
				)
				store.commit("SET_SPLASH_SCREEN", false)
				if (response.data.success) {
					state.testedExercises = response.data.data
				}
			} catch (error) {
				store.commit("SET_SPLASH_SCREEN", false)
				swal({
					icon: "error",
					title: "Erro ao listar exercicios testados!",
					text: error.message,
				})
			} finally {
				store.commit("SET_SPLASH_SCREEN", false)
			}
		}

    const buscarPlanoDetreinoSemanas = async () => {
			try {
				store.commit("SET_SPLASH_SCREEN", true)
				let response = await planosTreinoRepository.SemanasGetByPlanosTreinoId(
					state.planoTreinoId
				)
				state.semanas = []
				if (response.data.success) {
					state.semanas = response.data.data.map(item => {
						item['copiar'] = false
						return item
					})
				}

				store.commit("SET_SPLASH_SCREEN", false)
			} catch (error) {
				store.commit("SET_SPLASH_SCREEN", false)
				swal({
					icon: "error",
					title: "Erro ao listar semanas do plano de treino!",
					text: error.message,
				})
			} finally {
				store.commit("SET_SPLASH_SCREEN", false)
			}
		}

    // const buscarTreinoSemana = async () => {
    //   try {
    //     store.commit("SET_SPLASH_SCREEN", true)
    //     let response = await planosTreinoRepository.PlanoTreinoElaboradoBySemanaIdGet(state.selectedWeek)
    //     if (response.data.success) {
    //       state.weekTraining = []
    //       state.weekTraining = response.data.data
    //       state.daysOfWeek = []
    //       for (const [key, value] of Object.entries(response.data.data)) {
    //         let exercises = value.map(exercise => {
    //           let item = state.testedExercises.find(x => x.exercise_id == exercise.exercise_id)
    //           exercise['max_load_test'] = item.max_load_test
    //           if(exercise.series.length == 0) {
    //             exercise.series.push({
    //               repetition: '',
    //               load: '',
    //               perc_load: '',
    //               series: '',
    //             })
    //           }
    //           return exercise
    //         })
    //         state.daysOfWeek.push(
    //           {
    //             day: key,
    //             exercises: exercises
    //           }
    //         )
    //       }
    //       console.log(state.daysOfWeek)
    //       // console.log(state.weekTraining)
          
    //     }
    //   } catch (error) {
    //     console.log(error)
    //     store.commit("SET_SPLASH_SCREEN", false)
    //   } finally {
    //     store.commit("SET_SPLASH_SCREEN", false)
    //   }
    // }

    return {
      state,
      buscarTreinoSemana
    }

  }
}
</script>

<style>

</style>