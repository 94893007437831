<template>
    <div
      class="p-3 bg-white card border-top-start-radius-0 border-top-end-radius-0"
      data-animation="FadeIn"
    >
      <div>
        <div class="row mt-3">
          <div class="col-12">
            <h6> Teste de carga</h6>
          </div>
          <div class="form-group col-12 col-md-4">
            <label for="" class="required">Descrição</label>
            <input
              class="form-control"
              type="text"
              v-model="state.testeCarga.description"
            />
          </div>
          <div class="form-group col-12 col-md-2">
            <label for="" class="required">Nº do teste</label>
            <input
                class="form-control"
                type="number"
                disabled
                v-model="state.testeCarga.test_number"
            />
          </div>
        </div>
        <hr>
        <div class="row mt-3">
          <div class="col-12">
            <h5>
              Exercícios
            </h5>
          </div>
          <div class="form-group col-12 col-sm-3 mt-sm-0">
            <label class="required">Modalidade</label>
            <select
              class="form-control custom-select"
              name="modalidade"
              id="select-modalidade"
              v-model="state.modalidadeSelecionadaId"
              @change="buscarGrupos()"
            >
              <option value="">Selecione uma modalidade</option>
              <option v-for="(item, index) in state.modalidades" :key="index" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="form-group col-12 col-sm-3 mt-sm-0">
            <label class="required">Grupo</label>
            <select
              class="form-control custom-select"
              name="grupo"
              required

              v-model="state.grupoSelecionadoId"
              @change="buscarExercicios()"
            >
              <option v-for="(item, index) in state.grupos" :key="index" :value="item.id">{{ item.name }}</option>
            </select>
            <!-- <span class="ms-3 text-error text-danger" v-if="v$.student.sex.$error"> {{ v$.student.sex.$errors[0].$message }} </span> -->
          </div>
          <div class="form-group col-12 col-sm-4 mt-sm-0">
            <label class="required">Exercício</label>
            <select
              class="form-control custom-select"
              name="exercicio"
              required
              id="select-exercicio"
              v-model="state.exercicioSelecionadoId"
            >
              <option v-for="(item, index) in state.exercicios" :key="index" :value="item.id">{{ item.name }}</option>
            </select>
            <!-- <span class="ms-3 text-error text-danger" v-if="v$.student.sex.$error"> {{ v$.student.sex.$errors[0].$message }} </span> -->
          </div>
          <div class="col-12 col-sm-2 align-self-end">
              <button type="button" class="btn bg-gradient-secondary" @click="adicionarExercicio()">Adicionar</button>  
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 table-responsive">
            <table class="table table-bordered table-striped">
              <thead>
                <tr class="font-weight-bold align-middle text-center" style="font-size: 0.80rem; line-height:1.2;">
                  <td>Exercícios</td>
                  <td>Repetições</td>
                  <td>Carga teste</td>
                  <td>Carga máxima <br/> (atual)</td>
                  <td>1º teste</td>
                  <td>Teste anterior</td>
                  <td>Diferencial <br/> Atual / 1º teste</td>
                  <td>Diferencial <br/> Atual / teste anterior</td>
                  <td></td>
                </tr>
              </thead>
              <tbody class="text-center"> 
                <tr v-for="(item, index) in state.exerciciosTesteCarga" :key="index">
                  <td class="text-start">{{ item.exercise.name }}</td>
                  <td>
                    <select
                      class="form-control custom-select"
                      required
                      v-model="item.repetitions"
                    >
                    <option v-for="number in 16" :key="number" :value="number"> {{ number }}</option>
                  </select>
                  </td>
                  <td>
                    <input
                      class="form-control"
                      type="text"
                      v-model="item.load_test"
                    />
                  </td>
                  <td>
                    {{ cargaMaxima(item) }}
                  </td>
                  <td>
                    {{ state.testeCarga.test_number == 1 ? cargaMaxima(item) : cargaMaximaPrimeiroTeste(item.exercise_id, item.load_test) }}
                  </td>
                  <td>
                    {{ cargaMaximaTesteAnterior(item.exercise_id, item.load_test) }}
                  </td>
                  <td>
                    {{ diferencialAtualPrimeiro(item) }}
                  </td>
                  <td>
                    {{ diferencialAtualAnterior(item) }}
                  </td>
                  <td>
                    <span
                      class="pointer"
                      data-bs-toggle="tooltip"
                      data-bs-original-title="Deletar exercicio"
                      title="Deletar exercicio"
                      data-bs-placement="top"
                      v-tooltip
                      @click="deletarExercicio(item)"
                    >
                      <i class="fas fa-trash text-secondary me-2"></i>
                      Deletar
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 text-end">
          <router-link to="/avaliacao-fisica">
            <button type="button" class="btn bg-gradient-secondary">
              Voltar
            </button>
          </router-link>
          <button type="button" class="ms-3 btn bg-gradient-primary" :disabled="salvarDisabled" @click.prevent="salvar">Salvar</button>
        </div>
      </div>
      
    </div>
  </template>
  
  <script>
  import { calcularCargaMaxima } from '../../../../../services/cargaMaxima.service'
  import planosTreinoRepository from "../../../../../services/api/planosTreinoRepository"
  import modalidadeRepository from "../../../../../services/api/modalidadeRepository"
  import grupoRepository from "../../../../../services/api/grupoRepository"
  import exercicioRepository from "../../../../../services/api/exercicioRepository"
  import testesCargaRepository from "../../../../../services/api/testesCargaRepository"
  import { reactive, onMounted, ref, inject, watch } from 'vue'
  import useVuelidate from '@vuelidate/core'
  import { required, helpers } from '@vuelidate/validators'
  import { useRouter, useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  
  export default {
    props: {
      avaliacaoIdProp: {
        type: Number,
        default: 0
      },
      localProp: {
        type: String,
        default: ''
      },
      dataProp: {
        type: String,
        default: ''
      }
    },
    setup (props, context) {
      const router = useRouter()
      const route = useRoute()
      const swal = inject('$swal')
      const store = useStore()

      watch(() => props.localProp, (newValue, oldValue) => {
        state.testeCarga.test_local = newValue
      });

      watch(() => props.dataProp, (newValue, oldValue) => {
        state.testeCarga.test_date = newValue

      });

      onMounted(() => {
        store.commit('SET_SPLASH_SCREEN', false)
        buscarModalidades()

        state.avaliacaoId = props.avaliacaoIdProp
        state.testeCarga.test_date = props.dataProp

        buscarDadosTesteDeCarga()
      })

      const mudaNumeroTeste = ref(false)
      
  
      const state = reactive({
        avaliacaoId: 0,
        testeDeCargaNovoId: 0,
        modalidadeSelecionadaId: '',
        grupoSelecionadoId: '',
        exercicioSelecionadoId: '',
        modalidades: [],
        exercicios: [
					{ name: '...', id: '' }
				],
        grupos: [
					{ name: '...', id: '' }
				],
        exerciciosTesteCarga: [],
        testeCarga: {
          description: '',
          test_number: 0,
          test_date: '',
          test_local: ''
        },
        exerciciosTesteCargaPrimeiro: [],
        exerciciosTesteCargaAnterior: [],
      })

      const buscarDadosTesteDeCarga = async () => {
        try {

          store.commit('SET_SPLASH_SCREEN', true)

          if(state.avaliacaoId == 0) {
            await verificaNumeroTeste()
            return
          }
          let response = await testesCargaRepository.TesteCargaGetByAssessmentId(state.avaliacaoId)
          if (response.data.success && response.data.data) {
            state.testeCarga = {
              id: response.data.data.id,
              student_physical_assessment_id: response.data.data.student_physical_assessment_id,
              description: response.data.data.description,
              test_number: response.data.data.test_number,
              test_date: response.data.data.test_date.split('-').reverse().join('/'),
              test_local: response.data.data.test_local
              
            }
            state.exerciciosTesteCarga = response.data.data.exercises
            buscarDadosComparativos(true)
          }
        }catch(error) {
          console.log(error)
        } finally {
          store.commit('SET_SPLASH_SCREEN', false)
        }
      }
  
  


      const buscarModalidades = async () => {
				state.grupos = [ { name: '...', id: '' } ]
				state.exercicios = [ { name: '...', id: '' } ]
        try {
          store.commit('SET_SPLASH_SCREEN', true)
          let response = await modalidadeRepository.ModalidadeGet()
          if (response.data.success) {
            state.modalidades = response.data.data
          }
        }catch(error) {
          console.log(error)
        } finally {
          store.commit('SET_SPLASH_SCREEN', false)
        }
      }

      const buscarGrupos = async () => {
				state.exercicios = [ { name: '...', id: '' } ]
				state.grupos = [ { name: '...', id: '' } ]
        try {
          store.commit('SET_SPLASH_SCREEN', true)
          let response = await grupoRepository.GrupoGetByModalityId(parseInt(state.modalidadeSelecionadaId))
          if (response.data.success) {
            state.grupos = response.data.data
          }
        }catch(error) {
          console.log(error)
        } finally {
          store.commit('SET_SPLASH_SCREEN', false)
        }
      }

      const buscarExercicios = async () => {
        try {
          store.commit('SET_SPLASH_SCREEN', true)
          let response = await exercicioRepository.ExercicioGetByGroupId(parseInt(state.grupoSelecionadoId))

          if (response.data.success) {
            state.exercicios = response.data.data
          }
        }catch(error) {
          console.log(error)
        } finally {
          store.commit('SET_SPLASH_SCREEN', false)
        }
      }

      const adicionarExercicio = () => {
        if(state.testeCarga.test_number == '' || state.testeCarga.test_number == 0) {
          swal({
            title: "Oops",
            text: "Informe o número do treino para cadastrar um exercício!",
            icon: "warning",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
          return
        }

        let exercicio = state.exercicios.find(x => x.id == parseInt(state.exercicioSelecionadoId))
        let exercicioJaCadastrado = state.exerciciosTesteCarga.find(x => x.exercise_id == parseInt(state.exercicioSelecionadoId))

        if (exercicioJaCadastrado) {
          swal({
            title: "Oops",
            text: "Exercício já adicionado!",
            icon: "warning",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
          return
        }
        state.exerciciosTesteCarga.push(
          { 
            id: '', exercise: { name: exercicio.name } , exercise_id: exercicio.id, repetitions: 1, load_test: 1
          }
        )
      }

      async function verificaNumeroTeste () {

        const response = await testesCargaRepository.TesteCargaGetLatest()
        if(response.data.success) {
          if(response.data.data != null) {
            if(Array.isArray(response.data.data) && response.data.data.length > 0) {
              state.testeCarga.test_number = parseInt(response.data.data.test_number) + 1
            } else if(!Array.isArray(response.data.data)) {
              state.testeCarga.test_number = parseInt(response.data.data.test_number) + 1
            }else {
              state.testeCarga.test_number = 1
            }
          } else {
            state.testeCarga.test_number = 1
          }

        }
        buscarDadosComparativos(true);
      }

      const buscarDadosComparativos = async (force = false) => {
        
        if(mudaNumeroTeste.value || force) {
          // VERIFICA SE NAO É O PRIMEIRO TESTE
          if (state.testeCarga.test_number > 1) {
            try {
              let exerciciosPrimeiroTeste = await testesCargaRepository.ExerciciosPrimeiroTesteCargaGet()
              if(exerciciosPrimeiroTeste.data.success) {
                state.exerciciosTesteCargaPrimeiro = exerciciosPrimeiroTeste.data.data
              }

              let exerciciosTesteAnterior = await testesCargaRepository.ExerciciosByTesteCargaNumeroGet(state.testeCarga.test_number-1)
              if(exerciciosTesteAnterior.data.success) {
                state.exerciciosTesteCargaAnterior = exerciciosTesteAnterior.data.data
              }

            } catch (error) {
              console.error(error)
            } finally {
              
            }
          }
        }
      }


      async function salvar() {
        if(!validate()) return
        salvarDisabled.value = true

        let testeDeCarga = Object.assign({}, state.testeCarga)
        testeDeCarga.test_date = testeDeCarga.test_date != '' ? testeDeCarga.test_date.split('/').reverse().join('-') : null
        // student.phone  = this.$filters.justNumbers(state.student.phone)
        // student.cpf  = this.$filters.justNumbers(state.student.cpf)


        if(state.avaliacaoId == 0 && route.params.id && parseInt(route.params.id) > 0) {
          state.avaliacaoId = parseInt(route.params.id)
        }

        if(state.avaliacaoId == 0) {
          let exerciciosTesteCarga = state.exerciciosTesteCarga.map(item => {
            return {
              'max_load_test': cargaMaxima(item),
              'load_test_id': 0,
              'exercise_id': item.exercise_id,
              'repetitions': item.repetitions,
              'load_test': item.load_test,
            }
          })
          testeDeCarga['exercises'] = exerciciosTesteCarga
          context.emit('salvar', testeDeCarga, 'carga')
          salvarDisabled.value = false
          //window.location.reload()
          console.log('c')
        } else {
          console.log('f')
          store.commit('SET_SPLASH_SCREEN', true)
          testeDeCarga['student_physical_assessment_id'] = state.avaliacaoId
          if(testeDeCarga.id == undefined || testeDeCarga.id == 0) {
            testesCargaRepository.TesteCargaPost(testeDeCarga).then(response => {
              store.commit('SET_SPLASH_SCREEN', false)
              if (response.data.success) {
                console.log('q')
                let testeCargaId = response.data.data.id
                let exerciciosTesteCarga = state.exerciciosTesteCarga.map(item => {
                  return {
                    'max_load_test': cargaMaxima(item),
                    'load_test_id': testeCargaId,
                    'exercise_id': item.exercise_id,
                    'repetitions': item.repetitions,
                    'load_test': item.load_test,
                  }
                  // item['max_load_test'] = cargaMaxima(item)
                  // item.training_plan_id = testeCargaId
                  // return item
                })
                store.commit('SET_SPLASH_SCREEN', true)
                testesCargaRepository.TesteCargaExerciciosPost(exerciciosTesteCarga).then(responseExercicios => {
                  console.log('w')
                  store.commit('SET_SPLASH_SCREEN', false)
                  if (responseExercicios.data.success) {
                    console.log('e')
                    swal({
                      title: "Teste de carga",
                      text: "Teste de carga atualizado com sucesso!",
                      icon: "success",
                      customClass: {
                        confirmButton: "btn bg-gradient-success",
                      },
                      buttonsStyling: false,
                    }).then(() => {
                      salvarDisabled.value = false
                      //router.back()
                    })
                  }
                })
              }
            }).catch((error) => {
              store.commit('SET_SPLASH_SCREEN', false)
              swal({
                icon: 'error',
                title: 'Erro ao salvar teste de carga',
                text: error.message
              })
            })
          } else {
            try {
              let response = await testesCargaRepository.TesteCargaPut(testeDeCarga.id, testeDeCarga)
              store.commit('SET_SPLASH_SCREEN', false)
              if (response.data.success) {
                let testeCargaId = testeDeCarga.id
                let exerciciosTesteCarga = state.exerciciosTesteCarga.map(item => {
                  item['load_test_id'] = testeCargaId
                  item['max_load_test'] = cargaMaxima(item)
                  return item
                })
                store.commit('SET_SPLASH_SCREEN', true)

                let responseExercicios = await testesCargaRepository.TesteCargaExerciciosPut(exerciciosTesteCarga)
                store.commit('SET_SPLASH_SCREEN', false)
                if (responseExercicios.data.success) {
                  swal({
                    title: "Teste de carga",
                    text: "Teste de carga atualizado com sucesso!",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                  })
                  salvarDisabled.value = false
                }
              }
            } catch (error) {
              console.error(error)
              swal({
                title: "Teste de carga",
                text: "Não foi possível atualizar os registros. Contate o administrador!",
                icon: "error",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              })
            } finally {
              salvarDisabled.value = false
              store.commit('SET_SPLASH_SCREEN', false)
            }
          }

        }
      }

      function deletarExercicio(item) {
          swal({
            title: "Tem certeza?",
            text: `Deseja deletar ${item.exercise.name}`,
            showCancelButton: true,
            confirmButtonText: "Sim, quero deletar!",
            cancelButtonText: "Não, cancelar!",
            reverseButtons: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              deletarExercicioConfirma(item.id)
            }
          })
        }
  
        function deletarExercicioConfirma(id) {
          store.commit('SET_SPLASH_SCREEN', true)
          testesCargaRepository.TesteCargaExercicioDelete(id).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)
            if(response.data.success) {
              swal({
                title: "Removido!",
                text: "Exercício removido com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                let exercicioIndex = state.exerciciosTesteCarga.findIndex(x => x.exercise_id == id)

                state.exerciciosTesteCarga.splice(exercicioIndex, 1)
              })
            }
          }).catch(error => {
            store.commit('SET_SPLASH_SCREEN', false)
            console.error(error.message)
            swal({
              icon: 'error',
              title: 'Erro ao deletar teste de carga!',
              text: error.message
            })
          })
        }
  

      // #region validators

      const validate = async () => {
        const result = await v$.value.$validate()
        return result
      }
  
      
  
      const rulesMessages = {
        required: helpers.withMessage('Campo obrigatório', required)
      }
      const rules = {
        modalidadeSelecionadaId: { required: rulesMessages.required },
        grupoSelecionadoId: { required: rulesMessages.required },
        exercicioSelecionadoId: { required: rulesMessages.required }
      }
  
      const v$ = useVuelidate(rules, state)
      // #endregion
  
      // function calcularValoresCarga(index) {
      //   let item = state.exerciciosTesteCarga[index]
      //   item.max_load_test = cargaMaxima(item)
      //   item.diff_current_first = diferencialAtualPrimeiro(item)
      //   item.diff_current_before = diferencialAtualAnterior(item)
      // }

      function filtraExercicioCalculos(exercicioId, primeiro = true) {
        let listaExercicios = []
        listaExercicios = primeiro ? state.exerciciosTesteCargaPrimeiro : state.exerciciosTesteCargaAnterior
        if (listaExercicios.length == 0) return null
        let exercicio = listaExercicios.find(x => x.exercise_id == exercicioId)
        return exercicio
      }

      function cargaMaxima(item) {

        return Math.round(calcularCargaMaxima(parseInt(item.repetitions), parseInt(item.load_test)))
        // if (state.testeCarga.test_number == 1) return Math.round(calcularCargaMaxima(item.repetitions, item.load_test))
        // let cargaMaximaAtual = calcularCargaMaxima(item.repetitions, item.load_test)
        // if (cargaMaximaAtual > item.max_load_test) return Math.round(cargaMaximaAtual)
        // return Math.round(item.max_load_test)
      }

      function cargaMaximaTesteAnterior(exercise_id, load_test) {
        if (state.testeCarga.test_number == 1) return 0
        let exercicio = filtraExercicioCalculos(exercise_id, false)
        // return exercicio.max_load_test
        return exercicio ? exercicio.max_load_test : 0
        // if (state.testeCarga.test_number == 1) return Math.round(calcularCargaMaxima(item.repetitions, item.load_test))
        // let cargaMaximaAtual = calcularCargaMaxima(item.repetitions, item.load_test)
        // if (cargaMaximaAtual > item.max_load_test) return Math.round(cargaMaximaAtual)
        // return Math.round(item.max_load_test)
      }

      function cargaMaximaPrimeiroTeste(exercise_id, load_test) {
        if (state.testeCarga.test_number == 1) return load_test
        let exercicio = filtraExercicioCalculos(exercise_id, true)

        return exercicio ? exercicio.max_load_test : 0
        // return exercicio.max_load_test

      }

      function diferencialAtualPrimeiro(item) {
        // if (item.id == '') return Math.round(item.load_test)
        // return Math.round(item.load_test - item.first_test)
        let exercicio = filtraExercicioCalculos(item.exercise_id, true)

        let cargaMaximaAtual = cargaMaxima(item)
        // return cargaMaximaAtual - exercicio.max_load_test
        if(exercicio) {
          const valor = cargaMaximaAtual - exercicio.max_load_test
          return parseFloat(valor) > 0 ? `+ ${valor}` : valor
        }
        return 0
      }

      function diferencialAtualAnterior(item) {
        // if (item.id == '') return Math.round(item.load_test)
        // return Math.round(item.load_test - item.before_load_test)
        let exercicio = filtraExercicioCalculos(item.exercise_id, false)

        let cargaMaximaAtual = cargaMaxima(item)

        //let res = exercicio ? cargaMaximaAtual - exercicio.max_load_test : 0
        if(exercicio) {
          const valor = cargaMaximaAtual - exercicio.max_load_test
          return parseFloat(valor) > 0 ? `+ ${valor}` : valor
        }
        return 0

        return res
      }

      const salvarDisabled = ref(false)

      return {
        state,
        salvar,
        v$,
        buscarGrupos,
				buscarExercicios,
        adicionarExercicio,
        cargaMaxima,
        diferencialAtualPrimeiro,
        diferencialAtualAnterior,
        buscarDadosComparativos,
        mudaNumeroTeste,
        cargaMaximaPrimeiroTeste,
        cargaMaximaTesteAnterior,
        deletarExercicio,
        salvarDisabled
      }
    },
  }
  </script>
  
  <style>
  
  </style>