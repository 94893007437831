<template>
    <div class="card border-top-start-radius-0 border-top-end-radius-0">
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="d-flex justify-content-start align-items-center">
                        <label for="" class="col-form-label">Peso Corporal</label>
                        <div class="ps-3">
                            <input type="text" v-model="state.composicaoAtual.weight" class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="d-flex justify-content-start align-items-center">
                        <label for="" class="col-form-label">Altura</label>
                        <div class="ps-3">
<!--                            <input type="text" v-model="state.composicaoAtual.height" v-maska="'#.##'" class="form-control form-control-sm">-->
                          <money3
                              v-bind="state.config"
                              v-model="state.composicaoAtual.height"
                              class="form-control form-control-sm"></money3>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="d-flex justify-content-start  align-items-center">
                        <label class="col-form-label" for="">% Gord</label>
                        <div class="ps-3">
<!--                            <input type="text" class="form-control form-control-sm" v-model="state.composicaoAtual.fat_percentage">-->
                          <money3
                              v-bind="state.config"
                              v-model="state.composicaoAtual.fat_percentage"
                              @change="state.calcularMedidas = false"
                              class="form-control form-control-sm"></money3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 table-responsive">
                    <table class="table border table-striped text-center text-sm table-sm">
                        <thead>
                            <tr class="font-weight-bold">
                                <td class="text-start w-25">Medidas</td>
                                <td class="text-start w-15">Atual</td>
                                <td class="w-15">Primeira</td>
                                <td class="w-15">Anterior</td>
                                <td class="w-15">Atual - Anterior</td>
                                <td class="w-15">Atual - Primeira</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-start">Sub Escapular</td>
                                <td class="text-start">
                                    <input type="text"  class="form-control form-control-sm"
                                           @keyup="state.calcularMedidas = true"
                                           style="width: 55px" v-model="state.composicaoAtual.sub_scapular">
                                </td>
                                <td> {{ state.composicaoPrimeira.sub_scapular }} </td>
                                <td> {{ state.composicaoAnterior.sub_scapular }} </td>
                                <td> {{ state.composicaoAtual.sub_scapular  - state.composicaoAnterior.sub_scapular }} </td>
                                <td> {{ state.composicaoAtual.sub_scapular  - state.composicaoPrimeira.sub_scapular }} </td>
                            </tr>
                            <tr>
                                <td class="text-start">Triceps</td>
                                <td>
                                    <input type="text"  class="form-control form-control-sm"
                                           @keyup="state.calcularMedidas = true"
                                           style="width: 55px" v-model="state.composicaoAtual.triceps">
                                </td>
                                <td> {{ state.composicaoPrimeira.triceps }} </td>
                                <td> {{ state.composicaoAnterior.triceps }} </td>
                                <td> {{ state.composicaoAtual.triceps  - state.composicaoAnterior.triceps }} </td>
                                <td> {{ state.composicaoAtual.triceps  - state.composicaoPrimeira.triceps }} </td>
                            </tr>
<!--                            <tr>
                                <td class="text-start">Biceps</td>
                                <td>
                                    <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.biceps">
                                </td>
                                <td> {{ state.composicaoPrimeira.biceps }} </td>
                                <td> {{ state.composicaoAnterior.biceps }} </td>
                                <td> {{ state.composicaoAtual.biceps  - state.composicaoAnterior.biceps }} </td>
                                <td> {{ state.composicaoAtual.biceps  - state.composicaoPrimeira.biceps }} </td>
                            </tr>-->
                            <tr>
                                <td class="text-start">Peitoral</td>
                                <td>
                                    <input type="text"  class="form-control form-control-sm"
                                           @keyup="state.calcularMedidas = true"
                                           style="width: 55px" v-model="state.composicaoAtual.chest">
                                </td>
                                <td> {{ state.composicaoPrimeira.chest }} </td>
                                <td> {{ state.composicaoAnterior.chest }} </td>
                                <td> {{ state.composicaoAtual.chest  - state.composicaoAnterior.chest }} </td>
                                <td> {{ state.composicaoAtual.chest  - state.composicaoPrimeira.chest }} </td>
                            </tr>
                            <tr>
                                <td class="text-start">Médio Axilar</td>
                                <td>
                                    <input type="text"  class="form-control form-control-sm"
                                           @keyup="state.calcularMedidas = true"
                                           style="width: 55px" v-model="state.composicaoAtual.mid_axillary">
                                </td>
                                <td> {{ state.composicaoPrimeira.mid_axillary }} </td>
                                <td> {{ state.composicaoAnterior.mid_axillary }} </td>
                                <td> {{ state.composicaoAtual.mid_axillary  - state.composicaoAnterior.mid_axillary }} </td>
                                <td> {{ state.composicaoAtual.mid_axillary  - state.composicaoPrimeira.mid_axillary }} </td>
                            </tr>
                            <tr>
                                <td class="text-start">Supra Ilíaca</td>
                                <td>
                                    <input type="text"  class="form-control form-control-sm"
                                           @keyup="state.calcularMedidas = true"
                                           style="width: 55px" v-model="state.composicaoAtual.suprailiac">
                                </td>
                                <td> {{ state.composicaoPrimeira.suprailiac }} </td>
                                <td> {{ state.composicaoAnterior.suprailiac }} </td>
                                <td> {{ state.composicaoAtual.suprailiac  - state.composicaoAnterior.suprailiac }} </td>
                                <td> {{ state.composicaoAtual.suprailiac  - state.composicaoPrimeira.suprailiac }} </td>
                            </tr>
                            <tr>
                                <td class="text-start">Abdomen</td>
                                <td>
                                    <input type="text"  class="form-control form-control-sm"
                                           @keyup="state.calcularMedidas = true"
                                           style="width: 55px" v-model="state.composicaoAtual.abdomen">
                                </td>
                                <td> {{ state.composicaoPrimeira.abdomen }} </td>
                                <td> {{ state.composicaoAnterior.abdomen }} </td>
                                <td> {{ state.composicaoAtual.abdomen  - state.composicaoAnterior.abdomen }} </td>
                                <td> {{ state.composicaoAtual.abdomen  - state.composicaoPrimeira.abdomen }} </td>
                            </tr>
                            <tr>
                                <td class="text-start">Coxa</td>
                                <td>
                                    <input type="text"  class="form-control form-control-sm"
                                           @keyup="state.calcularMedidas = true"
                                           style="width: 55px" v-model="state.composicaoAtual.thigh">
                                </td>
                                <td> {{ state.composicaoPrimeira.thigh }} </td>
                                <td> {{ state.composicaoAnterior.thigh }} </td>
                                <td> {{ (state.composicaoAtual.thigh  - state.composicaoAnterior.thigh).toFixed(2) }} </td>
                                <td> {{ (state.composicaoAtual.thigh  - state.composicaoPrimeira.thigh).toFixed(2) }} </td>
                            </tr>
<!--                            <tr>
                                <td class="text-start">Perna</td>
                                <td>
                                    <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.leg">
                                </td>
                                <td> {{ state.composicaoPrimeira.leg }} </td>
                                <td> {{ state.composicaoAnterior.leg }} </td>
                                <td> {{ state.composicaoAtual.leg  - state.composicaoAnterior.leg }} </td>
                                <td> {{ state.composicaoAtual.leg  - state.composicaoPrimeira.leg }} </td>
                            </tr>-->
                            <tr>
                                <td class="text-start">Somatório</td>
                                <td class="text-start">{{somaAtual}}</td>
                                <td>{{somaPrimeiro}}</td>
                                <td>{{somaAnterior}}</td>
                                <td>{{somaAtual - somaAnterior}}</td>
                                <td>{{somaAtual - somaPrimeiro}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12">
                    <resultados-component
                        v-if="state.displayResult"
                        :calculaMedidasProp="state.calcularMedidas"
                        :medidaAtualProp="state.composicaoAtual"
                        :medidaAnteriorProp="state.composicaoAnterior"
                        :medidaPrimeiraProp="state.composicaoPrimeira"
                    />
                </div>
                <div class="col-12 text-end">
                  <router-link to="/avaliacao-fisica">
                    <button type="button" class="btn bg-gradient-secondary">
                      Voltar
                    </button>
                  </router-link>
                    <button type="button" class="ms-3 btn bg-gradient-primary" @click.prevent="salvar">Salvar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {reactive, onMounted, ref, inject, computed, watch} from 'vue'
import { useStore } from 'vuex'
import composicaoCorporalRepository from "../../../../../services/api/composicaoCorporalRepository";
import { useRouter, useRoute } from 'vue-router'
import ComposiaoCorporalModel from "@/models/composicaoCorporalModel";
import ResultadosComponent from "./ResultadosComponent.vue";
import { Money3Component } from 'v-money3'
import {
  fatWeight,
  imc,
  mmMetabolicRate,
  percentageBodyFatPollock,
  skinnyWeight
} from "@/services/composicaoCorporal.service";
import * as composicaoCorporal from "@/services/composicaoCorporal.service";

export default {
  components: {
    ResultadosComponent,
    money3: Money3Component
  },
  props: {
    avaliacaoIdProp: {
      type: Number,
      default: 0
    },
  },
  setup(props, context) {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()

    onMounted( () => {
      state.avaliacaoId = props.avaliacaoIdProp
      buscarDados()
    })


    const state = reactive({
      calcularMedidas: false,
      avaliacaoId: 0,
      composicaoAtual: new ComposiaoCorporalModel(),
      composicaoAnterior: new ComposiaoCorporalModel(),
      composicaoPrimeira: new ComposiaoCorporalModel(),
      config: {
        masked: false,
        prefix: '',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: 0,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
        displayResult: false
      }
    })

    async function buscarDados() {
      store.commit('SET_SPLASH_SCREEN', true)
      try {
        if (state.avaliacaoId == 0) {
          let responseFirst = await composicaoCorporalRepository.ComposicaoCorporalGetFirst()
          let responseLast = await composicaoCorporalRepository.ComposicaoCorporalGetLatest()
          store.commit('SET_SPLASH_SCREEN', false)

          if (responseFirst.data.success && responseFirst.data.data != null) {
            state.composicaoPrimeira = responseFirst.data.data
          }
          if (responseLast.data.success && responseLast.data.data != null) {
            state.composicaoAnterior = responseLast.data.data
          }
        } else {
          let response = await composicaoCorporalRepository.ComposicaoGetByAssessmentId(state.avaliacaoId)
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {

            if(response.data.data.item != null) {
              state.composicaoAtual = Object.assign(new ComposiaoCorporalModel(), response.data.data.item)
              if(parseFloat(state.composicaoAtual.sub_scapular) > 0) {
                state.calcularMedidas = true
              }
            }
            if(response.data.data.before != null) {
              state.composicaoAnterior = Object.assign(new ComposiaoCorporalModel(), response.data.data.before)
            }
            if(response.data.data.first != null) {
              state.composicaoPrimeira = Object.assign(new ComposiaoCorporalModel(), response.data.data.first)
            }

          }
        }
      } catch (error) {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error)
      } finally {
        state.displayResult = true
      }
    }

    const atualPreenchido = computed (() => {
      return parseInt(state.composicaoAtual.sub_scapular) > 0 &&
          parseInt(state.composicaoAtual.triceps) > 0 &&
          parseInt(state.composicaoAtual.chest) > 0 &&
          parseInt(state.composicaoAtual.mid_axillary) > 0 &&
          parseInt(state.composicaoAtual.suprailiac) > 0 &&
          parseInt(state.composicaoAtual.abdomen) > 0 &&
          parseInt(state.composicaoAtual.thigh) > 0
    })

    const somaAtual = computed (() => {
      const response = parseInt(state.composicaoAtual.sub_scapular) +
            parseInt(state.composicaoAtual.triceps) +
            parseInt(state.composicaoAtual.biceps) +
            parseInt(state.composicaoAtual.chest) +
            parseInt(state.composicaoAtual.mid_axillary) +
            parseInt(state.composicaoAtual.suprailiac) +
            parseInt(state.composicaoAtual.abdomen) +
            parseInt(state.composicaoAtual.thigh) +
            parseInt(state.composicaoAtual.leg)
      //console.log('soma atual ' + isNaN(response))
      return isNaN(response) ? 0 : response
    })

    const somaPrimeiro = computed (() => {
      const response = parseInt(state.composicaoPrimeira.sub_scapular) +
          parseInt(state.composicaoPrimeira.triceps) +
          parseInt(state.composicaoPrimeira.biceps) +
          parseInt(state.composicaoPrimeira.chest) +
          parseInt(state.composicaoPrimeira.mid_axillary) +
          parseInt(state.composicaoPrimeira.suprailiac) +
          parseInt(state.composicaoPrimeira.abdomen) +
          parseInt(state.composicaoPrimeira.thigh) +
          parseInt(state.composicaoPrimeira.leg)
      return isNaN(response) ? 0 : response
    })

    const somaAnterior = computed (() => {
        return parseInt(state.composicaoAnterior.sub_scapular) +
            parseInt(state.composicaoAnterior.triceps) +
            parseInt(state.composicaoAnterior.biceps) +
            parseInt(state.composicaoAnterior.chest) +
            parseInt(state.composicaoAnterior.mid_axillary) +
            parseInt(state.composicaoAnterior.suprailiac) +
            parseInt(state.composicaoAnterior.abdomen) +
            parseInt(state.composicaoAnterior.thigh) +
            parseInt(state.composicaoAnterior.leg)
    })

    /*watch(state.composicaoAtual, (newValue, oldValue) => {
      console.log(oldValue)
      console.log(newValue)
      console.log(state.composicaoAtual)
      fatPercentageAtual()
    })

    function fatPercentageAtual() {
      state.composicaoAtual.fat_percentage = composicaoCorporal.percentageBodyFatPollock(state.composicaoAtual.age, somaAtual.value)
    }*/



    function salvar() {
        try {
          if(state.avaliacaoId == 0 && route.params.id && parseInt(route.params.id) > 0) {
            state.avaliacaoId = parseInt(route.params.id)
          }
          store.commit('SET_SPLASH_SCREEN', true)
          state.composicaoAtual.imc = composicaoCorporal.imc(state.composicaoAtual.weight, state.composicaoAtual.height)
          console.log(state.composicaoAtual.age)
          console.log(somaAtual.value)

          console.log(state.composicaoAtual.fat_percentage)
          state.composicaoAtual.skinny_weight = composicaoCorporal.skinnyWeight(state.composicaoAtual.weight, composicaoCorporal.fatWeight(state.composicaoAtual.weight, state.composicaoAtual.fat_percentage))

          if(state.avaliacaoId == 0) {
            context.emit('salvar', state.composicaoAtual, 'medidas')
          } else {
            state.composicaoAtual.student_physical_assessment_id = state.avaliacaoId
            if(state.composicaoAtual.id == undefined || state.composicaoAtual.id == 0) {
              composicaoCorporalRepository.ComposicaoCorporalPost(state.composicaoAtual).then(response => {
                store.commit('SET_SPLASH_SCREEN', false)
                console.log(response)
                if (response.data.success) {
                  swal({
                    title: "Composição corporal",
                    text: "Composição corporal cadastrada com sucesso!",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                  })
                }
              })
            } else {
              composicaoCorporalRepository.ComposicaoCorporalPut(state.composicaoAtual.id, state.composicaoAtual).then(response => {
                store.commit('SET_SPLASH_SCREEN', false)
                console.log(response)
                if (response.data.success) {
                  swal({
                    title: "Composição corporal",
                    text: "Composição corporal cadastrada com sucesso!",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                  })
                }
              })
            }

          }

        } catch (error) {
            store.commit('SET_SPLASH_SCREEN', false)
            console.error(error)
        } finally {
            store.commit('SET_SPLASH_SCREEN', false)
        }
    }

    return {
        state,
        salvar,
        atualPreenchido,
        somaAtual,
        somaPrimeiro,
        somaAnterior
    }
  }
}
</script>

<style scoped>

</style>