import Repository from './repository'

const resource = '/avaliacao-postural'

export default {
  PosturalGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  PosturalGetByAssessmentId (id) {
    return Repository.get(`${resource}/assessment/${id}`)
  },
  PosturalGet () {
    return Repository.get(`${resource}`)
  },
  PosturalPost (item) {
    return Repository.post(`${resource}`, item, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  PosturalPut (id, item) {
    return Repository.put(`${resource}/${id}`, item,{ headers: { 'Content-Type': 'multipart/form-data' } })
  },
  PosturalDelete (id) {
    return Repository.delete(`${resource}/${id}`)
  }
}
