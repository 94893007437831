<template>
  <nav
    class="bg-dark  navbar navbar-main navbar-expand-lg border-radius-xl me-3"
    :class="
      this.$store.state.isRTL ? 'top-1 position-sticky z-index-sticky' : ''
    "
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="pe-2 ps-0 py-1 container-fluid">
      <!-- <breadcrumbs :currentPage="currentRouteName" :textWhite="textWhite" /> -->
      <div class="row mb-3 w-100">
        <seleciona-aluno class="col-12 col-md-5" v-if="this.$store.getters.UserType !== 's'" />
        <selecion-plano-treino
            class="col-12"
            :class="this.$store.getters.UserType !== 's' ? 'col-md-6' : ''"
            v-if="this.$store.getters.StudentManager" />
      </div>
      <div
        class="sidenav-toggler sidenav-toggler-inner d-xl-block w-100 w-md-30"
        :class="this.$store.state.isRTL ? 'me-3' : ''"
      >
        <a @click.prevent="minNav" href="#" class="p-0 nav-link text-body float-end">
          <div class="sidenav-toggler-inner">
            <i
              class="sidenav-toggler-line"
              :class="textWhite ? 'bg-white' : ''"
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="textWhite ? 'bg-white' : ''"
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="textWhite ? 'bg-white' : ''"
            ></i>
          </div>
        </a>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "./Breadcrumbs.vue";
import { mapMutations, mapActions, mapGetters } from "vuex";
import SelecionaAluno from "./SelecionaAluno.vue";
import SelecionPlanoTreino from './SelecionaPlanoTreino.vue'

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav
  },
  methods: {
    // ...mapGetters({
    //   StudentManager: 'StudentManager'
    // }),
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
  },
  components: {
    Breadcrumbs,
    SelecionaAluno,
    SelecionPlanoTreino
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
