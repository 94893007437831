<template>
	<div class="row mt-5">
		<div class="col-12 table-responsive">
			<table class="table table-sm">
				<thead>
					<tr>
						<th scope="col"  class="p-0" style="width:5%;"></th>
						<th scope="col" class="p-0"  style="width:10%;">Exercício</th>
						<th scope="col" class="p-0" >Cargas</th>
						<th scope="col" class="p-0 text-center"  style="width:4%;">S</th>
						<th scope="col" class="p-0 text-center"  style="width:4%;">REP</th>
						<th scope="col" class="p-0 text-center"  style="width:4%;">TON</th>
						<th scope="col" class="p-0 text-center"  style="width:4%;">PM</th>
						<th scope="col" class="p-0 text-center"  style="width:4%;">IMR</th>
					</tr>
				</thead>
				<tbody class="text-sm" v-if="state.treinoElaborado.length === 0">
					<tr>
						<td colspan="8" class="font-weight-bold pt-3 text-decoration-underline text-center">
							Nenhum exercício adicionado
						</td>
					</tr>
				</tbody>
				<tbody class="text-sm" v-else>
					<draggable :list="state.treinoElaborado" tag="transition-group"  item-key="order" @start="state.drag=true" @end="state.drag=false">
						<template #item="{element, index}">
								<tr >
									<td class="align-middle">
                    <input
                        v-if="element.series.length > 0"
                        v-model="element['checkCopy']"
                        @click="listaExerciciosParaCopiar(index)"
                        class="mb-1 form-check-input border"
                        type="checkbox"
                        value="1"
                    /><br>
										<span
											class="pointer"
											data-bs-toggle="tooltip"
											data-bs-original-title="Deletar"
										>
											<i class="fas fa-trash text-secondary" style="font-size:1.2rem;" @click="removerExercicio(index)"></i>
										</span>

									</td>
									<td class="align-middle" style="width: 20%">
										<div>
											<template v-if="element.max_load_test == null || element.max_load_test == 0">
												{{ element.exercise.name }}
											</template> 
											<template v-else>
												<select class="form-control custom-select form-control-sm" name="tested_exercises"
													v-model="element.exercise_id" @change="mudaExercicioTestado($event.target.value, element)">
													<option v-for="(item, index) in state.exerciciosTestados" :key="index" :value="item.exercise_id">
														{{ item.exercise.name }}
													</option>
												</select>
											</template>
										</div>
										<div class="mt-2">Carga máxima: {{ element.max_load_test }}</div>
									</td>
									<td class="d-flex">
										<table 
											v-for="(load, loadIndex) in element.series"
											:key="loadIndex"
										>
											<tr>
												<td rowspan="2" style="text-align: center; vertical-align: middle; height: 30px;">
													<input
														class="form-control form-control-sm"
														type="text"
														v-model="load.load"
														v-maska="'###'"
														@change="eventChangeExerciseLoad(index, loadIndex)"
														style="width: 46px; height: 65px;"
													/>
												</td>
												<td>
													<input
														class="form-control form-control-sm"
														type="text"
														v-model="load.series"
														v-maska="'###'"
														@keyup="addCargaExercicio(index, loadIndex)"
														style="width: 50px;"
													/>
												</td>
											</tr>
											<tr>
												<td>
													<input
														class="form-control form-control-sm"
														type="text"
														v-model="load.repetitions"
														v-maska="'###'"
														@keyup="addCargaExercicio(index, loadIndex)"
														style="width: 50px;"
													/>
												</td>
											</tr>
											<tr>
												<td>
													<input
														class="form-control form-control-sm"
														type="text"
														v-maska="'###'"
														:disabled="!parseInt(element.max_load_test) > 0"
														v-model="load.perc_load"
														@keyup="calcularCarga(index, loadIndex, 'perc')"
														style="width: 46px;"
													/>
												</td>
												<td class="text-center"> 
													<span
														class="pointer"
														data-bs-toggle="tooltip"
														data-bs-original-title="Deletar"
													>
														<i class="fas fa-trash text-secondary"
                               title="Excluir carga/serie"
                               style="font-size:1.2rem;" @click="removerSerie(index, loadIndex)"></i>
													</span>
												</td>
											</tr>
										</table>
                    <div class="d-grid">
                      <button type="button" class="ms-3 btn btn-sm font-weight-normal btn-outline-info p-1"
                              @click="copiaZonas(index)"
                              title="Copiar zonas"
                              style="font-size: 0.7rem;">Copiar zonas</button>

                      <button type="button" class="ms-3 btn btn-sm font-weight-normal btn-outline-info p-1"
                              @click="colarZonas(index)"
                              v-if="CanPasteZones"
                              title="Colar zonas"
                              style="font-size: 0.7rem;">Colar zonas</button>
                    </div>
                  </td>
									<td class="text-center">{{ calcularEstrategicos('S', index) }}</td>
									<td class="text-center">{{ calcularEstrategicos('REP', index) }}</td>
									<td class="text-center">{{ calcularEstrategicos('TON', index) }}</td>
									<td class="text-center">{{ calcularEstrategicos('PM', index) }}</td>
									<td class="text-center">{{ calcularEstrategicos('IMR', index) }}</td>
								</tr>
						</template>
					</draggable>
				</tbody>
        <tfoot>
          <tr>
            <td colspan="8">
              <button type="button" class="ms-3 btn btn-sm font-weight-normal btn-outline-info p-1"
                      @click="copiarExerciciosSelecionados"
                      style="font-size: 0.7rem;">Copiar exercícios</button>
              <button type="button" class="ms-3 btn btn-sm font-weight-normal btn-outline-info p-1"
                      v-if="pasteExercises"
                      @click="colarExerciciosSelecionados"
                      style="font-size: 0.7rem;">Colar exercícios selecionados</button>
            </td>
          </tr>
        </tfoot>
			</table>
		</div>
		
	</div>
</template>

<script>
import {reactive, onMounted, computed, watch, inject} from "vue";
	import draggable from 'vuedraggable'
  import { useStore } from "vuex"
import planosTreinoRepository from "../../../../services/api/planosTreinoRepository"
	export default {
		props: {
			exerciciosProp: {
				type: Array,
				default: [],
			},
			exerciciosTestadosProp: {
				type: Array,
				default: [],
			},
		},
		components: {
			draggable
		},
		setup(props) {
      const store = useStore()
      const swal = inject("$swal")

			watch(() => props.exerciciosProp, () => {
        state.treinoElaborado = props.exerciciosProp
      })

			onMounted(() => {
				state.treinoElaborado = props.exerciciosProp
            /*.map(obj => {
              obj['exercise_id'] = obj.exercise.id
              return obj
            })*/
				
				if(props.exerciciosTestadosProp.length > 0) {
					state.exerciciosTestados = [...props.exerciciosTestadosProp]
				}
				
			});

			const state = reactive({
				treinoElaborado: [],
				exerciciosTestados: [],
			});

			function addCargaExercicio(listaIndex, loadIndex) {
        if(loadIndex >= 0 && listaIndex === 0 && state.treinoElaborado.length > 1) {
          copiarSerieRepeticao(loadIndex)
        }

        let countCargas = state.treinoElaborado[listaIndex]["series"].length
        let ultimoItem = state.treinoElaborado[listaIndex]["series"][countCargas - 1]
        if (ultimoItem.load > 0 || ultimoItem.repetitions > 0 || ultimoItem.series > 0) {
          let obj = {
            repetitions: '',
            load: '',
            perc_load: '',
            series: '',
          };
          state.treinoElaborado[listaIndex]["series"].push(obj)
        }
			}

      function copiarSerieRepeticao(loadIndex) {

        let exercicioTestadoBase = state.treinoElaborado[0]['series'][loadIndex]
        state.treinoElaborado.forEach((obj, index) => {
          if(index > 0) {
            //obj['series'][loadIndex]['load'] = exercicioTestadoBase['load'] !== '' ? exercicioTestadoBase['load'] : 0
            obj['series'][loadIndex]['repetitions'] = exercicioTestadoBase['repetitions'] !== '' ? exercicioTestadoBase['repetitions'] : 0
            obj['series'][loadIndex]['series'] = exercicioTestadoBase['series'] !== '' ? exercicioTestadoBase['series'] : 0
            addCargaExercicio(index, loadIndex)
          }
        })
      }

      function copiarCargaExercicioTreinado(loadIndex) {
        let exercicioTestadoBase = state.treinoElaborado[0]
        state.treinoElaborado.forEach((obj, index) => {
          if(index > 0) {
            obj['series'][loadIndex]['perc_load'] = exercicioTestadoBase['series'][loadIndex]['perc_load']
            calcularCarga(index, loadIndex, 'perc')
          }
        })
      }

      function calcularCarga(listaIndex, loadIndex, tipo = '') {
        if(listaIndex == 0 && state.treinoElaborado.length > 1) {

          copiarCargaExercicioTreinado(loadIndex)
        }
        let item = state.treinoElaborado[listaIndex]
        let itemCarga = item['series'][loadIndex]
        if (tipo == 'perc') {
          let result = (item.max_load_test * parseFloat(itemCarga.perc_load))  / 100
          //state.treinoElaborado[listaIndex]['series'][loadIndex]['load'] = parseFloat(result).toFixed(2) % 1 != 0 ? parseFloat(result).toFixed(2) : parseInt(result)
          state.treinoElaborado[listaIndex]['series'][loadIndex]['load'] = Math.ceil(result)
        } else {
					if (item.max_load_test > 0) {
						let result = ((itemCarga.load) * 100 ) / item.max_load_test
          	state.treinoElaborado[listaIndex]['series'][loadIndex]['perc_load'] = parseFloat(result).toFixed(2) % 1 != 0 ? parseFloat(result).toFixed(2) : parseInt(result)
					} else {
						state.treinoElaborado[listaIndex]['series'][loadIndex]['perc_load'] = 0
					}
          
        }
      }

			function removerSerie(listaIndex, loadIndex) {
				if(state.treinoElaborado[listaIndex]['series'].length  < 2) return
				state.treinoElaborado[listaIndex]['series'].splice(loadIndex, 1)
			}

			function removerExercicio(listaIndex) {

        // SE TIVER ID ENTAO PERTENCE EM ALGUM GRUPO E VAI SER REMOVIDO
        swal({
          title: "Tem certeza?",
          text: `Deseja deletar ${state.treinoElaborado[listaIndex].exercise.name}`,
          showCancelButton: true,
          confirmButtonText: "Sim, quero deletar!",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            if('id' in state.treinoElaborado[listaIndex]) {
              deletarExercicioConfirma(state.treinoElaborado[listaIndex]['id'], listaIndex)
            } else {
              state.treinoElaborado.splice(listaIndex, 1)
            }
          }
        })
			}

      function deletarExercicioConfirma(id, listaIndex) {
        store.commit('SET_SPLASH_SCREEN', true)
        planosTreinoRepository.PlanoTreinoExercicioGrupoDelete(id).then(function (response)  {
          store.commit('SET_SPLASH_SCREEN', false)
          if(response.data.success) {
            swal({
              title: "Removido!",
              text: "Exercicio removido com sucesso!",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            })
            state.treinoElaborado.splice(listaIndex, 1)
          }
        }).catch(error => {
          store.commit('SET_SPLASH_SCREEN', false)
          console.error(error.message)
          swal({
            icon: 'error',
            title: 'Erro ao deletar exercicio!',
            text: error.message
          })
        })
      }

			function calcularEstrategicos(tipo, listaIndex) {
				let exercise = state.treinoElaborado[listaIndex]
				let response = 0
        let ton = 0
        let rep = 0
				if (tipo == 'S') {
					exercise.series.forEach(load => {
						if(load.series != '') {
							response += parseInt(load.series)
						}
					})
				} else if (tipo == 'REP') {
					exercise.series.forEach(load => {
						if(load.series != '' && load.repetitions != '') {
							response += (parseInt(load.series) * parseInt(load.repetitions))
						}
						
					})
				} else if (tipo == 'TON') {
					exercise.series.forEach(load => {
						if(load.series != '' && load.load != '' && load.repetitions != '') {
							response += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions))
						}
						
					})
				} else if (tipo == 'PM') {
					exercise.series.forEach(load => {
						if(load.load != '' && load.series != '' && load.repetitions != '') {
							//response += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions)) / (parseInt(load.series) * parseInt(load.repetitions))
              ton += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions))

              rep += (parseInt(load.series) * parseInt(load.repetitions))
						}
						
					})
          if(rep > 0) {
            response = parseFloat(ton / rep).toFixed(2)
          }
				} else if (tipo == 'IMR') {
					let ton = 0
					let rep = 0
					exercise.series.forEach(load => {
						
						if(load.load != '' && load.series != '' && load.repetitions != '') {
							
							ton += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions))
							
							rep += (parseInt(load.series) * parseInt(load.repetitions))
							//response += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions)) / (parseInt(load.series) * parseInt(load.repetitions))
							
						}

						if(load.series != '') {
							response += parseInt(load.series)
						}
						
					})
					let pm = (parseFloat(ton / rep).toFixed(2))
					
					if(!exercise.max_load_test) return 0
					return ((pm * 100) / exercise.max_load_test).toFixed(2)
				}
        if(tipo == 'IMR') {
          return parseInt(response)
        }
				return response
			}

			function mudaExercicioTestado(eventValue, element) {
				const exercicioIndex = state.treinoElaborado.findIndex(x => x.order == element.order)
				const exercicioTestado = state.exerciciosTestados.find(x => x.exercise_id == eventValue)
				state.treinoElaborado[exercicioIndex].exercise = exercicioTestado.exercise
				state.treinoElaborado[exercicioIndex].exercise_id = exercicioTestado.exercise.id
				// state.treinoElaborado[exercicioIndex].exercise_id = exercicioTestado.exercise_id
				state.treinoElaborado[exercicioIndex].max_load_test = exercicioTestado.max_load_test

        // QUANDO TROCA O EXERCICIO, VERIFICA AS CARGAS COM % E RECALCULA calcularCarga

        if(state.treinoElaborado[exercicioIndex]['series'].length > 0) {
          state.treinoElaborado[exercicioIndex]['series'].forEach((item, index) => {
            const perc = parseFloat(item['perc_load'])
            if(perc > 0) {
              calcularCarga(exercicioIndex, index, 'perc')
            }
          })
        }

			}

      function copiaSeries(index) {
        const series = JSON.parse(JSON.stringify(state.treinoElaborado[index]['series']))

        store.commit('SET_SERIES', series)
      }



      function listaExerciciosParaCopiar(index) {
        state.treinoElaborado[index]['checkCopy'] = !state.treinoElaborado[index]['checkCopy']

      }

      function copiarExerciciosSelecionados() {
        let exercises = []
        state.treinoElaborado.forEach(item => {
          if(item['checkCopy']) {
            exercises.push(item)
          }
        })
        store.commit('SET_COPY_EXERCISES', exercises)
      }

      const pasteExercises = computed (() => {
        return store.getters.getCopyExercises.length > 0
      })

      function colarExerciciosSelecionados() {
        const items = store.getters.getCopyExercises


        items.forEach(item => {
          let obj = {
            order: state.treinoElaborado.length + 1,
            exercise: Object.assign({}, item['exercise']),
            max_load_test: item['max_load_test'],
            exercise_id: item['exercise']['id'],
            series: item['series'],
          }


          //verifica se o exercicio ja nao existe na lista para nao duplicar
          let duplicate = state.treinoElaborado.some(item => item.exercise_id == obj.exercise_id)

          if(!duplicate) {
            state.treinoElaborado.push(obj)
          }

        })


        //state.treinoElaborado.push(store.getters.getSeries)
      }

      function copiaZonas(index) {
        /*const exercise = state.treinoElaborado[index]['series']
        console.log('exercise')
        console.log(exercise)
        const finalExercise = {
          exercise: exercise['exercise'],
          exercise_id: exercise['exercise_id'],
          max_load_test: exercise['max_load_test'],
          series: exercise['series'],
        }

        //let series = [...state.treinoElaborado[index]['series']]

        store.commit('SET_SERIES', finalExercise)*/
        const series = state.treinoElaborado[index]['series'].map(item => {
          return {
            load: item.load,
            perc_load: item.perc_load,
            repetitions: item.repetitions,
            series: item.series,
          }
        })


        store.commit('SET_SERIES', series)
      }

      function colarZonas(index) {
        const series = store.getters.getSeries

        /*let obj = {
          order: state.treinoElaborado.length + 1,
          exercise: Object.assign({}, item['exercise']),
          max_load_test: item['max_load_test'],
          exercise_id: item['exercise']['id'],
          series: item['series'],
        }
        console.log(obj)
        state.treinoElaborado.push(obj)
        //state.treinoElaborado.push(store.getters.getSeries)*/
        //state.treinoElaborado[index]['series'].push(item)

        if(parseInt(state.treinoElaborado[index]['max_load_test']) > 0) {
          const maxLoadTest = parseInt(state.treinoElaborado[index]['max_load_test'])
          series.forEach(item => {
            //const result = (maxLoadTest * parseFloat(item.perc_load)) / 100
            let result = Math.ceil((maxLoadTest * parseFloat(item.perc_load))  / 100)
            //state.treinoElaborado[listaIndex]['series'][loadIndex]['load'] = parseFloat(result).toFixed(2) % 1 != 0 ? parseFloat(result).toFixed(2) : parseInt(result)

            const obj = {
              perc_load: item.perc_load,
              repetitions: item.repetitions,
              series: item.series,
              //load: 0
              load: result
            }
            state.treinoElaborado[index]['series'].push(obj)
          })
        } else {
          series.forEach(item => {
            const obj = {
              perc_load: 0,
              repetitions: item.repetitions,
              series: item.series,
              load: item.perc_load,
            }
            state.treinoElaborado[index]['series'].push(obj)
          })
        }
      }

      const CanPasteZones = computed (() => {
        return store.getters.getSeries
      })

      const eventChangeExerciseLoad = ((index, loadIndex) => {
        addCargaExercicio(index, -1)
        calcularCarga(index, loadIndex)
      })

			

			return {
				state,
				mudaExercicioTestado,
        addCargaExercicio,
        calcularCarga,
				removerSerie,
				removerExercicio,
				calcularEstrategicos,
        copiarExerciciosSelecionados,
        copiaZonas,
        listaExerciciosParaCopiar,
        colarExerciciosSelecionados,
        pasteExercises,
        colarZonas,
        CanPasteZones,
        eventChangeExerciseLoad
			};
		},
	};
</script>

<style>
	select.custom-select.form-control-sm {
		height: 32.39px;
	}
</style>