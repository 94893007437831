import Repository from './repository'

const resource = '/methods'

export default {
  MetodoGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  MetodoGet () {
    return Repository.get(`${resource}`)
  },
  MetodoPost (item) {
    return Repository.post(`${resource}`, item)
  },
  MetodoPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  MetodoDelete (planoId) {
    return Repository.delete(`${resource}/${planoId}`)
  }
}
