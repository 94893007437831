<template>
  <div class="bg-white card border-radius-xl me-3"
       data-animation="FadeIn">
    <div class="card-body">
      <div class="row">
        <div class="col-12 mb-2">
          <h4>
            {{ state.studentName }}
          </h4>
        </div>
        <div class="col-12 me-2">
          <h5>
            Registro de novo pagamento
          </h5>
        </div>
        <div class="form-group col-12 col-sm-3">
          <label class="required">Mês/ano referente</label>
          <input class="form-control form-control-sm"
                 type="text"
                 placeholder="MM/AAAA"
                 v-maska="'##/####'"
                 v-model="state.mesReferentePagamento"
                 required>

        </div>
        <div class="form-group col-12 col-sm-3">
          <label class="required">Data do pagamento</label>
          <input class="form-control form-control-sm"
                 type="text"
                 placeholder="DD/MM/AAAA"
                 v-maska="'##/##/####'"
                 v-model="state.dataPagamento"
                 required>

        </div>
        <div class="form-group col-12 col-sm-3">
          <label class="required">Valor</label>
          <money3
              v-model="state.valorPagamento"
              v-bind="state.config"
              class="form-control form-control-sm"></money3>
        </div>
        <div class="form-group col-12 col-sm-3 ">
          <label class="required">Forma de pagamento</label>
          <select class="form-control form-control-sm custom-select" v-model="state.formaPagamento">
            <option v-for="(item, index) in state.listaFormasPagamento" :key="index" :value="item.id" selected>{{ item.name }}</option>
          </select>
        </div>
        <div class="col-12 col-sm-3 align-self-end form-group">
          <label > </label>
          <button type="button" class="mb-0 btn bg-gradient-success btn-sm" @click="salvar">
            Salvar
          </button>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-12">
          <h5>
            Histórico de pagamentos
          </h5>
        </div>
      </div>
      <MyTable
          :searchable="false"
          :data="state.listaHistorico"
          :columns="colunas"
          :itemsPerPage="12">
        <template v-slot:row="{ row }">
          <td class="text-sm text-center">
            {{ row.payment_reference }}
          </td>
          <td class="text-sm text-center">
            {{ $filters.datePtbr(row.payment_date) }}
          </td>
          <td class="text-sm text-center">
            {{ $filters.converteCentavosValor(row.payment_amount) }}
          </td>
          <td class="text-sm text-center">
            {{ row.payment_form.name }}
          </td>
          <td  class="text-sm ">
            <div class="float-end">
              <span
                  class="pointer"
                  @click="editar(row)"
              >
                  <i class="fas fa-pen text-secondary me-2"></i>
                  Editar

                </span>
              <br>
              <span
                  class="pointer"
                  title="Detalhes"
                  @click="deletar(row)"
              >
                <i class="fas fa-trash text-secondary me-2"></i>
                Deletar

              </span>
            </div>
          </td>
        </template>
      </MyTable>
      <div class="row mt-5">
        <div class="col-12 text-end">
          <button type="button" class="btn bg-gradient-secondary" @click.prevent="voltar">
            Voltar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/MyTable.vue";
import { reactive, onMounted, ref, inject, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import coachAdministrationRepository from "@/services/api/coachAdministrationRepository";
import { Money3Component } from 'v-money3'
import formaPagamentoRepository from "@/services/api/formaPagamentoRepository";
import planosTreinoRepository from "@/services/api/planosTreinoRepository";
export default {
  components: {
    MyTable,
    money3: Money3Component
  },
  props: {
    studentIdProp: {
      type: Number,
      default: null
    },
    studentNameProp: {
      type: String,
      default: ''
    }
  },
  setup (props, context) {
    const store = useStore()
    const swal = inject('$swal')

    onMounted(() => {
      console.log(props.studentIdProp)
      if(props.studentIdProp != null) {
        state.studentId = props.studentIdProp
        state.studentName = props.studentNameProp
        buscarDados()

      }

    })

    const colunas = ref([
      {
        label: 'Mês referência',
        field: 'payment_reference',
        sortable: 'payment_reference'
      },
      {
        label: 'Data do pagamento',
        field: 'payment_date',
        sortable: 'payment_date'
      },
      {
        label: 'Valor pago',
        field: 'payment_amount',
        sortable: 'payment_amount'
      },
      {
        label: 'Forma pgto',
        field: 'payment_form',
        sortable: 'payment_form'
      },
      {
        label: 'Ações',
        field: 'actions'
      }
    ])

    const state = reactive({
      studentName: '',
      dataPagamento: '',
      mesReferentePagamento: '',
      valorPagamento: '',
      formaPagamento: '',
      listaFormasPagamento: [],
      listaHistorico: [],
      studentId: 0,
      pagamentoId: 0,
      months: [
        { mes: "Janeiro", numero: '01' },
        { mes: "Fevereiro", numero: '02' },
        { mes: "Março", numero: "03" },
        { mes: "Abril", numero: '04' },
        { mes: "Maio", numero: '05' },
        { mes: "Junho", numero: '06' },
        { mes: "Julho", numero: '07' },
        { mes: "Agosto", numero: '08' },
        { mes: "Setembro", numero: '09' },
        { mes: "Outubro", numero: '10' },
        { mes: "Novembro", numero: '11' },
        { mes: "Dezembro", numero: '12' }
      ],
      config: {
        masked: false,
        prefix: '',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: 0,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
        displayResult: false
      }
    })

    async function buscarDados() {
      limparDados()
      store.commit('SET_SPLASH_SCREEN', true)
      try {
        const responseHistorico = await coachAdministrationRepository.studentPaymentsStudentIdGet(state.studentId)
        if(responseHistorico.data.success) {
          state.listaHistorico = responseHistorico.data.data.filter(obj => {

            if(obj.payment_reference != null) {
              obj.payment_reference = obj.payment_reference.split('-').reverse().join('/')

            }
            return obj
          })
        }

        const responseForma = await formaPagamentoRepository.FormaGet()
        if(responseForma.data.success) {
          state.listaFormasPagamento = responseForma.data.data
        }


      } catch (error) {
        console.error(error)
      } finally {
        store.commit('SET_SPLASH_SCREEN', false)
      }
    }
    
    function salvar() {
      store.commit('SET_SPLASH_SCREEN', true)
      const item = {
        student_id: state.studentId,
        payment_reference: state.mesReferentePagamento.split('/').reverse().join('-'),
        payment_date: state.dataPagamento.split('/').reverse().join('-'),
        payment_amount: state.valorPagamento,
        payment_form_id: state.formaPagamento,
      }
      console.log(state.pagamentoId)
      if(state.pagamentoId === 0) {
        coachAdministrationRepository.studentPaymentPost(item).then(response => {
          console.log(response)
          if(response.data.success) {
            swal({
              icon: 'success',
              text: 'Pagamento registrado com sucesso'
            })
          }
        }).catch(error => {
          console.error(error)
          swal({
            icon: 'error',
            text: 'Erro ao registrar pagamento'
          })
        }).finally(() => {
          buscarDados()
        })
      } else {
        coachAdministrationRepository.studentPaymentPut(state.pagamentoId, item).then(response => {
          console.log(response)
          if(response.data.success) {
            swal({
              icon: 'success',
              text: 'Pagamento alterado com sucesso'
            })
          }
        }).catch(error => {
          console.error(error)
          swal({
            icon: 'error',
            text: 'Erro ao alterar pagamento'
          })
        }).finally(() => {
          buscarDados()
        })
      }
    }

    function deletar(item) {
      swal({
        text: `Deseja deletar o pagamento ${item.payment_reference}`,
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        cancelButtonText: "Não, cancelar!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          deletarConfirma(item.id)
        }
      })
    }

    function deletarConfirma(id) {
      store.commit('SET_SPLASH_SCREEN', true)
      coachAdministrationRepository.studentPaymentDelete(id).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if(response.data.success) {
          swal({
            title: "Removido!",
            text: "Pagamento removido com sucesso!",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          }).then(() => {
            buscarDados()
          })
        }
      }).catch(error => {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error.message)
        swal({
          icon: 'error',
          title: 'Erro ao deletar pagamento!',
          text: error.message
        })
      })
    }

    function voltar() {
      limparDados()
      context.emit('fecharForm')
    }

    function editar(item) {
      console.log(item)
      state.pagamentoId = item.id
      state.mesReferentePagamento = item.payment_reference
      state.valorPagamento = (item.payment_amount / 100).toFixed(2)
      state.formaPagamento = item.payment_form_id
      state.dataPagamento = item.payment_date.slice(0, 10).split('-').reverse().join('/')
    }

    function limparDados() {
      state.pagamentoId = 0
      state.mesReferentePagamento = ''
      state.valorPagamento = ''
      state.formaPagamento = ''
      state.dataPagamento = ''
    }


    return {
      colunas,
      state,
      buscarDados,
      salvar,
      deletar,
      voltar,
      editar
    }
  }
}
</script>