<template>
  <div class=" pt-3 px-3 ">
    <div class="col-12">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" >
          <button class="nav-link active" id="ativos-tab" data-bs-toggle="tab" data-bs-target="#ativos" type="button"
                  role="tab" aria-controls="ativos" aria-selected="true">
            Alunos ativos
          </button>
        </li>
        <li class="nav-item" role="presentation" >
          <button class="nav-link" id="inativos-tab" data-bs-toggle="tab" data-bs-target="#inativos" type="button"
                  role="tab" aria-controls="inativos" aria-selected="true">
            Alunos inativos
          </button>
        </li>

      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade active show" id="ativos" role="tabpanel" aria-labelledby="ativos-tab">
          <div class="card border-top-start-radius-0 border-top-end-radius-0">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <!-- Card header -->
                  <div class="pb-0 card-header">
                    <div class="d-lg-flex">
                      <div>
                        <h5 class="mb-0">Todos alunos</h5>
                        <p class="mb-0 text-sm">
                          Listagem de alunos
                        </p>
                      </div>
                      <div class="my-auto mt-4 ms-auto mt-lg-0">
                        <div class="my-auto ms-auto">
                          <button type="button" class="mb-0 btn bg-gradient-success btn-sm" @click="formularioAluno()">
                            +&nbsp; Novo aluno
                          </button>
                          <!-- <a
                            href="/cadastros/aluno/criar"
                            class="mb-0 btn bg-gradient-success btn-sm"
                          >
                            +&nbsp; Nova aluno
                          </a> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <MyTable
                        v-if="isVisible"
                        :data="state.alunosAtivos"
                        :columns="columns"
                        :itemsPerPage="12">
                      <template v-slot:row="{ row }">
                        <td class="text-sm pointer">
                          {{ row.name }}
                        </td>
                        <td class="text-sm  text-center">
                          <template v-if="row.cep == null">
                            {{ row.cep }}
                          </template>
                          <template v-else>
                            <span class="font-weight-bold">CEP: </span>{{row.cep}}<br>
                            <!-- <span class="font-weight-bold">Endereço: </span> -->
                            {{row.street}}, {{row.number}} <br>
                            {{row.neighborhood}} <br>
                            {{row.city}}/{{row.state}}<br>`
                          </template>
                        </td>
                        <td class="text-sm  text-center">
                          <span class="font-weight-bold">Email: </span>{{row.email}}<br>
                          <span class="font-weight-bold">Telefone: </span>{{row.phone}}
                        </td>
                        <td class="text-sm  text-center">
                          {{ $filters.datePtbr(row.created_at) }}
                        </td>
                        <td class="text-sm ">
                          <div class="float-end">
                            <div>
                          <span
                              class="pointer"
                              title="Editar aluno"
                              @click="formularioAluno(row.id)"
                          >
                            <i class="fas fa-pen text-secondary me-2"></i>
                            Editar

                          </span>
                            </div>
                            <div>
                          <span
                              class="pointer"
                              title="Deletar aluno"
                              @click="deletarAluno(row)"
                          >
                            <i class="fas fa-trash text-secondary me-2"></i>
                            Deletar
                          </span>
                            </div>
                          </div>
                        </td>
                      </template>
                    </MyTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade show" id="inativos" role="tabpanel" aria-labelledby="inativos-tab">
          <div class="card border-top-start-radius-0 border-top-end-radius-0">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <MyTable
                        v-if="isVisible"
                        :data="state.alunosInativos"
                        :columns="columns"
                        :itemsPerPage="12">
                      <template v-slot:row="{ row }">
                        <td class="text-sm pointer">
                          {{ row.name }}
                        </td>
                        <td class="text-sm  text-center">
                          <template v-if="row.cep == null">
                            {{ row.cep }}
                          </template>
                          <template v-else>
                            <span class="font-weight-bold">CEP: </span>{{row.cep}}<br>
                            <!-- <span class="font-weight-bold">Endereço: </span> -->
                            {{row.street}}, {{row.number}} <br>
                            {{row.neighborhood}} <br>
                            {{row.city}}/{{row.state}}<br>`
                          </template>
                        </td>
                        <td class="text-sm  text-center">
                          <span class="font-weight-bold">Email: </span>{{row.email}}<br>
                          <span class="font-weight-bold">Telefone: </span>{{row.phone}}
                        </td>
                        <td class="text-sm  text-center">
                          {{ $filters.datePtbr(row.created_at) }}
                        </td>
                        <td class="text-sm ">
                          <div class="float-end">
                            <div>
                          <span
                              class="pointer"
                              title="Editar aluno"
                              @click="formularioAluno(row.id)"
                          >
                            <i class="fas fa-pen text-secondary me-2"></i>
                            Editar

                          </span>
                            </div>
                            <div>
                          <span
                              class="pointer"
                              title="Deletar aluno"
                              @click="deletarAluno(row)"
                          >
                            <i class="fas fa-trash text-secondary me-2"></i>
                            Deletar
                          </span>
                            </div>
                          </div>
                        </td>
                      </template>
                    </MyTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { reactive, onMounted, ref, inject } from 'vue'
import { useStore } from 'vuex'
import alunoRepository from "../../../../services/api/alunoRepository";
import MyTable from '../../../../components/MyTable.vue';
import { useRouter } from 'vue-router'
import AlunoFormulario from './AlunoCadastro.vue';

export default {
  components: {
    MyTable,
    AlunoFormulario
  },
  setup () {
    const router = useRouter()
    const swal = inject('$swal')
    const store = useStore()
    onMounted(() => {
      store.commit('SET_SPLASH_SCREEN', false)
      listarAlunos()
    })

    //const hidePassword = ref(true)
    // const passwordFieldIcon = computed(() => hidePassword.value ? ['fa', 'eye'] : ['fa', 'eye-slash']);
    // const passwordFieldType = computed(() => hidePassword.value ? "password" : "text");

    let isVisible= ref(false);
    const columns = ref([
      {
        label: 'Nome',
        field: 'name',
        sortable: 'name'
      },
      {
        label: 'Endereço',
        field: 'address',
        sortable: ''
      },
      {
        label: 'Contato',
        field: 'contact',
        sortable: ''
      },
      {
        label: 'Data de cadastro',
        field: 'created_at'
      },
      {
        label: 'Ações',
        field: 'actions'
      }
    ])

    const state = reactive({
      alunoId: 0,
      alunosAtivos: [],
      alunosInativos: [],
    })

    const listarAlunos = () => {
      store.commit('SET_SPLASH_SCREEN', true)
      alunoRepository.AlunoGet().then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        state.alunos = []

        if (response.data.success) {
          console.log(response.data.data)
          response.data.data.forEach(obj => {
            if(obj['deleted_at'] !== null) {
              console.log('INATIVO')
              state.alunosInativos.push(obj)
            } else {
              console.log('ATIVO')
              state.alunosAtivos.push(obj)
            }
          })
        }
        console.log('state.alunosAtivos')
        console.log(state.alunosAtivos)
      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        swal({
          icon: 'error',
          title: 'Erro ao listar alunos!',
          text: error.message
        })
      }).finally(() => {
        isVisible.value = true
      })
    }

    function formularioAluno(id = 0) {
      state.alunoId = id
      parseInt(id) !== 0 ? router.push(`/alunos/cadastro/${id}`) : router.push('/alunos/cadastro')
    }

    function deletarAluno(item) {
      swal({
        title: "Tem certeza?",
        text: `Deseja deletar ${item.name}`,
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        cancelButtonText: "Não, cancelar!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          deletarAlunoConfirma(item.id)
        }
      })
    }

    function deletarAlunoConfirma(id) {
      store.commit('SET_SPLASH_SCREEN', true)
      alunoRepository.AlunoDelete(id).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if(response.data.success) {
          swal({
            title: "Removido!",
            text: "Aluno removida com sucesso!",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          }).then(() => {
            listarAlunos()
          })
        }
      }).catch(error => {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error.message)
        swal({
          icon: 'error',
          title: 'Erro ao deletar aluno!',
          text: error.message
        })
      })
    }


    return {
      state,
      columns,
      deletarAluno,
      formularioAluno,
      isVisible
    }
  }
}

</script>