<template>
    <div class="form-group busca-simples-api" v-click-outside="onClickOutside" @keydown.esc="ShowBox = false">

        <span class="form-control form-control-sm" @click="ShowBox = true">
          {{SearchText}}
        </span>
      <div class="busca-simples-api-lista" :style="{'width': Width}" v-if="ShowBox">
        <div v-if="ItemsProp.length == 0 || (Items.length == 0)" class="text-center py-1">
          <span>Nenhum registro para ser exibido</span>
        </div>
        <div v-if="Items.length > 0">
          <table class="table table-active border table-striped text-center text-sm table-sm  m-0">
            <thead>
              <tr class="font-weight-bold">
                <td></td>
                <td>Seg</td>
                <td>Ter</td>
                <td>Qua</td>
                <td>Qui</td>
                <td>Sex</td>
                <td>Sab</td>
                <td>Dom</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in Items" :key="index">
                <td class="font-weight-bold">
                  {{item['name']}}
                </td>
                <td>
                  <template v-if="item['days'].hasOwnProperty('seg')">
                    <select class="form-control form-control-sm text-center"
                            :disabled="passDay(item['days']['seg']['date'])"
                            v-model="item['days']['seg']['copiar']"
                            @change="item['days']['seg']['block'] = false"
                            >
                      <option value="0"></option>
                      <option v-for="(item, index) in this.grupos" :key="index" :value="item.id">{{ item.name }}</option>
                    </select>
                  </template>
                </td>
                <td>
                  <template v-if="item['days'].hasOwnProperty('ter')">
                    <select class="form-control form-control-sm text-center"
                            :disabled="passDay(item['days']['ter']['date'])"
                            v-model="item['days']['ter']['copiar']"
                            @change="item['days']['ter']['block'] = false"
                            >
                      <option value="0"></option>
                      <option v-for="(item, index) in this.grupos" :key="index" :value="item.id">{{ item.name }}</option>
                    </select>
                  </template>
                </td>
                <td>
                  <template v-if="item['days'].hasOwnProperty('qua')">
                    <select class="form-control form-control-sm text-center"
                            :disabled="passDay(item['days']['qua']['date'])"
                            v-model="item['days']['qua']['copiar']"
                            @change="item['days']['qua']['block'] = false"
                            >
                      <option value="0"></option>
                      <option v-for="(item, index) in this.grupos" :key="index" :value="item.id">{{ item.name }}</option>
                    </select>
                  </template>
                </td>
                <td>
                  <template v-if="item['days'].hasOwnProperty('qui')">
                    <select class="form-control form-control-sm text-center"
                            :disabled="passDay(item['days']['qui']['date'])"
                            v-model="item['days']['qui']['copiar']"
                            @change="item['days']['qui']['block'] = false"
                            >
                      <option value="0"></option>
                      <option v-for="(item, index) in this.grupos" :key="index" :value="item.id">{{ item.name }}</option>
                    </select>
                  </template>
                </td>
                <td>
                  <template v-if="item['days'].hasOwnProperty('sex')">
                    <select class="form-control form-control-sm text-center"
                            :disabled="passDay(item['days']['sex']['date'])"
                            v-model="item['days']['sex']['copiar']"
                            @change="item['days']['sex']['block'] = false"
                             >
                      <option value="0"></option>
                      <option v-for="(item, index) in this.grupos" :key="index" :value="item.id">{{ item.name }}</option>
                    </select>
                  </template>
                </td>
                <td>
                  <template v-if="item['days'].hasOwnProperty('sab')">
                    <select class="form-control form-control-sm text-center"
                            :disabled="passDay(item['days']['sab']['date'])"
                            v-model="item['days']['sab']['copiar']"
                            @change="item['days']['sab']['block'] = false"
                            >
                      <option value="0"></option>
                      <option v-for="(item, index) in this.grupos" :key="index" :value="item.id">{{ item.name }}</option>
                    </select>
                  </template>
                </td>
                <td>
                  <template v-if="item['days'].hasOwnProperty('dom')">
                    <select class="form-control form-control-sm text-center"
                            :disabled="passDay(item['days']['dom']['date'])"
                            v-model="item['days']['dom']['copiar']"
                            @change="item['days']['dom']['block'] = false"
                             >
                      <option value="0"></option>
                      <option v-for="(item, index) in this.grupos" :key="index" :value="item.id">{{ item.name }}</option>
                    </select>
                  </template>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="8">
                  <button type="button" id="btnSalvarGrupoDia"  @click="salvarDias" class="btn bg-gradient-success btn-sm float-end mt-2 mb-0">
                    Salvar
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
<!--          <div class="item span-striped pointer" v-for="(item, key) in Items" :key="key" @click="clickItem(item, key)">
            <slot name="item" :item="item">
              <div class="form-check">
                <input class="mt-1 form-check-input" type="checkbox" v-model="item.copiar" />
                <span class="ms-1"> {{item[Field]}}</span>
              </div>
            </slot>
          </div>-->
        </div>
        <!-- <div v-if="CreateItem && SearchText.length >= 3" class="py-3 text-center" id="divCadastro" style="cursor: pointer;">
          <span @click="registerItem()">Clique aqui para cadastrar o item <span class="font-weight-bold">{{SearchText}}</span></span>
        </div> -->
      </div>
    </div>
  </template>
  
  <script>
  import vClickOutside from 'click-outside-vue3'
  import moment from "moment/moment";
  export default {
    directives: {
      clickOutside: vClickOutside.directive
    },
    name: 'select2',
    props: [
      'ApiUrlProp',
      'ApiDataProp',
      'PlaceholderTextProp',
      'WidthProp',
      'FieldProp',
      'ItemsProp',
      'ClearAfterSelectedProp',
      'SelectedItemProp',
      'CreateItemProp',
        'GruposProp'
    ],
    data () {
      return {
        itemsSelecionados: [],
        ApiUrl: '', 
        ApiData :'', 
        PlaceholderText: '', 
        Field: '',
        Items: [],
        ShowBox: false,
        SearchText: 'Clique aqui para definir semanas e dias',
        ClearAfterSelected: false,
        CreateItem: false,
        grupos: [],
        today: moment(new Date()).format("yyyy-MM-DD")
      }
    },
    created () {
      this.grupos = this.GruposProp
      this.ApiUrl = this.ApiUrlProp
      this.ApiData = this.ApiDataProp
      this.PlaceholderText = this.PlaceholderTextProp
      this.Width = this.WidthProp ? this.WidthProp : '100%'
      this.CreateItem = this.CreateItemProp ? this.CreateItemProp : false
      this.Field = this.FieldProp
      this.Items = this.ItemsProp

      this.ClearAfterSelected = this.ClearAfterSelectedProp ? this.ClearAfterSelectedProp : false
      //this.changeText()
      if(this.SelectedItemProp) {
        this.clickItem(this.SelectedItemProp)
      }
    },
    watch: {
      /*SelectedItemProp: function () {
        this.clickItem(this.SelectedItemProp)
      }*/
      // ItemsProp: function () {
      //   this.changeText()
      // }
    },
    methods: {
      passDay (date) {
        let day = moment(date).format("yyyy-MM-DD")

        return day < this.today
      },
      salvarDias () {
        this.$emit('salvarDias')
      },
      onClickOutside () {
        this.ShowBox = false
      },
      changeText() {
        this.$emit('searchText', this.SearchText)
        if (this.SearchText.length >= 3) {
          // this.ShowBox = false
          //this.Items = this.ItemsProp.length > 10 ? this.ItemsProp.slice(0, 10) : this.ItemsProp
          this.ItemsSearch()
          // this.OutItem.emit(null)
        }
        if (this.SearchText.length == 0) {
          this.clickItem(null)
        }
      },
      ItemsSearch() {
        //let items = this.ItemsProp.filter( x => x[this.FieldProp].toLowerCase().includes(this.SearchText.toLowerCase()))
        let filterKey = this.SearchText.toLowerCase()
        //console.log(filterKey)
        filterKey = filterKey.replace(/[^a-zA-Z0-9]/g, '')
        let data = this.ItemsProp
        let items = []
        
        if(this.Field != '') {
          items = data.filter( (row) => {
            return Object.keys(row).some( (/*key*/) => {
              return String(row[this.Field]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().indexOf(filterKey) > -1
            })
          })
        } else {
          items = data.filter( (row) => {
            return Object.keys(row).some( (key) => {
              return String(row[key]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().indexOf(filterKey) > -1

            })
          })
        }
        this.Items = items
      },
      onSearch(searchText) {
        if (searchText === null || searchText.match(/^ *$/) !== null) {
          this.OutItem.emit(null)
          return
        }
        let data = {parametro: searchText, empresa: this.varsProd.NomeEmpresa}
        this.searchBoxService.PostListItems(this.urlApiService, data).subscribe( response => {
          this.Items = response['dados']
        })
      },
      clickItem(item) {
        console.log(item)

      },
      blurEvent() {
        this.ShowBox = false
      },
      registerItem () {
        this.$emit('CreatingItem', this.SearchText)
      }
    }
  }
  </script>
  
  <style scoped>
  #divCadastro:hover {
    background-color: #efefef;
  }
  .span-striped:nth-child(even) {
      background-color: #f2f2f2;
  }
   .busca-simples-api {
      position: relative;
  }
   .busca-simples-api .busca {
      width: 100%;
      margin-bottom: 0;
  }
   .busca-simples-api .busca-simples-api-lista {
      width: 100%;
      max-height: 300px;
      position: absolute;
      z-index: 2;
      background-color: white;
      box-shadow: 0px 1px 3px 1px #989797;
      overflow: auto;
      margin-top: 2px;
  }
   .busca-simples-api .busca-simples-api-lista .item {
      padding: 7px 16px;
      font-weight: bold;
      font-size: 15px;
      cursor: pointer;
  }
   .busca-simples-api .busca-simples-api-lista .item:hover {
      background-color: #4fc3f7;
      color: white;
  }
   .busca-simples-api .busca-simples-api-lista::-webkit-scrollbar {
      width: 10px;
  }
   .busca-simples-api .busca-simples-api-lista::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #ded9d9;
      border-radius: 20px;
      margin: 2px;
      width: 10px;
  }
   .busca-simples-api .busca-simples-api-lista::-webkit-scrollbar-thumb {
      background-color: #ded9d9;
      border-radius: 20px;
  }
   .busca-simples-api .busca-simples-api-lista::-webkit-scrollbar-thumb:hover {
      background-color: #bfbfbf;
  }
   
  </style>