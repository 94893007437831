<template>
  <div class="py-4 container-fluid">
    <MetodoFormulario :metodoId="state.metodoId" @closeModal="displayModal(false, true)" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <!-- <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Todos métodos</h5>
                <p class="mb-0 text-sm">
                  Listagem de métodos
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <button type="button" class="mb-0 btn bg-gradient-success btn-sm" @click="displayModal(true)">
                    +&nbsp; Nova método
                  </button>
                </div>
              </div>
            </div>
          </div> -->
          <div class="card-body">
            <MyTable
              :data="state.metodos"
              :columns="columns"
              :itemsPerPage="12">
              <template v-slot:row="{ row }">
                <td class="text-sm pointer">
                  {{ row.name }} 
                </td>
                <td class=" text-center">
                  {{ $filters.datePtbr(row.created_at) }}
                </td>
                <td  class="text-sm "> 
                  <div class="float-end">
                    
                    <div>
                      <span
                        class="pointer"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Editar metodo"
                        @click="editarMetodo(row.id)"
                      >
                        <i class="fas fa-pen text-secondary me-2"></i>
                        Editar
                        
                      </span>
                    </div>
                    <div>
                      <span
                        class="pointer"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Deletar metodo"
                        @click="deletarMetodo(row)"
                      >
                        <i class="fas fa-trash text-secondary me-2"></i>
                        Deletar
                      </span>
                    </div>
                  </div>
                  
                </td>
              </template>
            </MyTable>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    
  </div>
</template>

<script>
  import { reactive, onMounted, ref, inject } from 'vue'
  import { useStore } from 'vuex'
  import metodoRepository from "../../../../../services/api/metodoRepository";
  import MyTable from '../../../../../components/MyTable.vue';
  import { useRouter, useRoute } from 'vue-router'
  import MetodoFormulario from './MetodoFormulario.vue';

  export default {
    components: {
      MyTable,
      MetodoFormulario
    },
    setup () {
      // const router = useRouter()
      // const route = useRoute()
      const swal = inject('$swal')
      const store = useStore()
      onMounted(() => {
        store.commit('SET_SPLASH_SCREEN', false)
        listarMetodos()
      })

      //const hidePassword = ref(true)
      // const passwordFieldIcon = computed(() => hidePassword.value ? ['fa', 'eye'] : ['fa', 'eye-slash']);
      // const passwordFieldType = computed(() => hidePassword.value ? "password" : "text");

      let modalVisible= ref(false);
      const columns = ref([
        {
          label: 'Nome',
          field: 'nome',
          sortable: 'nome'
        },
        {
          label: 'Data de cadastro',
          field: 'dataCadastro'
        },
        {
          label: 'Ações',
          field: 'actions'
        }
      ])

      const state = reactive({
        metodoId: 0,
        metodos: [],
        metodoId: 0
      })

      const listarMetodos = () => {
        store.commit('SET_SPLASH_SCREEN', true)
        metodoRepository.MetodoGet().then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          state.metodos = []

          if (response.data.success) {
            state.metodos = response.data.data

          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao listar metodos!',
            text: error.message
          })
        })
      }

      function editarMetodo(id) {
        // console.log(id)
        state.metodoId = id
        // console.log(state.metodoId)
        //router.push(`/cadastros/metodo/${id}`);
      }

      function deletarMetodo(item) {
        swal({
          title: "Tem certeza?",
          text: `Deseja deletar ${item.name}`,
          showCancelButton: true,
          confirmButtonText: "Sim, quero deletar!",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            deletarMetodoConfirma(item.id)
          }
        })
      }

      function deletarMetodoConfirma(id) {
        store.commit('SET_SPLASH_SCREEN', true)
        metodoRepository.MetodoDelete(id).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if(response.data.success) {
            swal({
              title: "Removido!",
              text: "Método removido com sucesso!",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            }).then(() => {
              listarMetodos()
            })
          }
        }).catch(error => {
          store.commit('SET_SPLASH_SCREEN', false)
          console.error(error.message)
          swal({
            icon: 'error',
            title: 'Erro ao deletar método!',
            text: error.message
          })
        })
      }

      function displayModal(display = true, refresh = false) {
        state.metodoId = 0
        modalVisible.value = display
        if(refresh) {
          
          listarMetodos() 
        }
      }

      return {
        state,
        columns,
        editarMetodo,
        deletarMetodo,
        displayModal,
        modalVisible
      }
    }
  }
  
</script>
