<template>
	<div class="row mt-5">
    <div class="col-12">
      <div class="form-group d-flex">
        <label for="" class="me-3">Observações: </label>
        <textarea rows="3" class="w-100" style="resize: none" v-model="state.observation" />
      </div>
    </div>
		<div class="col-12 table-responsive">
			<table class="table table-sm table-striped">
				<thead>
					<tr>
						<th scope="col"  class="p-0 pe-2" style="width:5%;"></th>
						<th scope="col" class="p-0"  style="width:10%;">Exercício</th>
						<th scope="col" class="p-0" >Cargas</th>
						<th scope="col" class="p-0 text-center"  style="width:4%;">S</th>
						<th scope="col" class="p-0 text-center"  style="width:4%;">REP</th>
						<th scope="col" class="p-0 text-center"  style="width:4%;">TON</th>
						<th scope="col" class="p-0 text-center"  style="width:4%;">PM</th>
						<th scope="col" class="p-0 text-center"  style="width:4%;">IMR</th>
					</tr>
				</thead>
				
				<tbody class="text-sm">
					<draggable :list="state.treinoElaborado" tag="transition-group"  item-key="id" @start="state.drag=true" @end="state.drag=false">
						<template #item="{element, index}">
								<tr >
									<td class="align-middle">
										<span
											class="pointer"
											data-bs-toggle="tooltip"
											data-bs-original-title="Deletar"
										>
											<i class="fas fa-trash text-secondary" style="font-size:1.2rem;" @click="deletarExercicio(element, index)"></i>
										</span>
									</td>
									<td class="align-middle" style="width: 20%">
										<div>
											<template v-if="element.max_load_test == null || element.max_load_test == 0">
												{{ element.exercise.name }}
											</template>
											<template v-else>
												<select class="form-control custom-select form-control-sm" name="tested_exercises"
													v-model="element.exercise_id" @change="mudaExercicioTestado($event.target.value, element)">
													<option v-for="(item, index) in state.exerciciosTestados" :key="index" :value="item.exercise_id">
														{{ item.exercise.name }}
													</option>
												</select>
											</template>
											
											
										</div>
										<div class="mt-2">Carga máxima: {{ element.max_load_test }}</div>
									</td>
									<td class="d-flex">
										<table 
											v-for="(load, loadIndex) in element.series"
											:key="loadIndex"
										>
											<tr>
												<td rowspan="2" style="text-align: center; vertical-align: middle; height: 30px;">
													<input
														class="form-control form-control-sm"
														type="text"
														v-model="load.load"
														v-maska="'###'"
														@keyup="addCargaExercicio(index)"
														@change="calcularCarga(index, loadIndex)"
														style="width: 46px; height: 65px;"
													/>
												</td>
												<td>
													<input
														class="form-control form-control-sm"
														type="text"
														v-model="load.series"
														v-maska="'###'"
														@keyup="addCargaExercicio(index)"
														style="width: 50px;"
													/>
												</td>
											</tr>
											<tr>
												<td>
													<input
														class="form-control form-control-sm"
														type="text"
														v-model="load.repetitions"
														v-maska="'###'"
														@keyup="addCargaExercicio(index)"
														style="width: 50px;"
													/>
												</td>
											</tr>
											<tr>
												<td>
													<input
														class="form-control form-control-sm"
														type="text"
														v-maska="'###'"
														:disabled="!parseInt(element.max_load_test) > 0"
														v-model="load.perc_load"
														@change="calcularCarga(index, loadIndex, 'perc')"
														style="width: 46px;"
													/>
												</td>
												<td class="text-center"> 
													<span
														class="pointer"
														data-bs-toggle="tooltip"
														data-bs-original-title="Deletar"
													>
														<i class="fas fa-trash text-secondary" style="font-size:1.2rem;" @click="removerSerie(index, loadIndex)"></i>
													</span>
												</td>
											</tr>
										</table>
                    <div class="d-grid">
                      <button type="button" class="ms-3 btn btn-sm font-weight-normal btn-outline-info p-1"
                              @click="copiaZonas(index)"
                              title="Copiar zonas"
                              style="font-size: 0.7rem;">Copiar zonas</button>

                      <button type="button" class="ms-3 btn btn-sm font-weight-normal btn-outline-info p-1"
                              @click="colarZonas(index)"
                              v-if="CanPasteZones"
                              title="Colar zonas"
                              style="font-size: 0.7rem;">Colar zonas</button>
                    </div>
									</td>
									<td class="text-center">{{ calcularEstrategicos('S', index) }}</td>
									<td class="text-center">{{ calcularEstrategicos('REP', index) }}</td>
									<td class="text-center">{{ calcularEstrategicos('TON', index) }}</td>
									<td class="text-center">{{ calcularEstrategicos('PM', index) }}</td>
									<td class="text-center">{{ calcularEstrategicos('IMR', index) }}</td>
								</tr>
						</template>
					</draggable>
				</tbody>
        <tfoot>
          <tr class="font-weight-bold">
            <td></td>
            <td></td>
            <td class="text-end ">TOTAIS:</td>
            <td class="text-center">{{ totalizadoresEstrategicos('S') }}</td>
            <td class="text-center">{{ totalizadoresEstrategicos('REP') }}</td>
            <td class="text-center">{{ totalizadoresEstrategicos('TON') }}</td>
            <td class="text-center">{{ totalizadoresEstrategicos('PM') }}</td>
            <td class="text-center">{{ totalizadoresEstrategicos('IMR') }}</td>
          </tr>
        </tfoot>
			</table>
      <div>
        <button type="button" class="ms-3 btn btn-sm font-weight-normal btn-outline-info p-1"
                v-if="pasteExercises"
                @click="colarExerciciosSelecionados"
                style="font-size: 0.7rem;">Colar exercícios selecionados</button>
      </div>
		</div>
		<div class="col-12">
			<div class="row mt-5">
				<div class="col-12 text-end">
					<button type="button" class="ms-3 btn bg-gradient-primary" @click.prevent="salvar">
						Salvar
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {computed, inject, onMounted, reactive, watch} from "vue";
import planosTreinoRepository from "../../../../services/api/planosTreinoRepository"
import {useRoute, useRouter} from "vue-router"
import {useStore} from "vuex"
import draggable from 'vuedraggable'
import store from "@/store";

export default {
		props: {
			exerciciosProp: {
				type: Array,
				default: [],
			},
			exerciciosTestadosProp: {
				type: Array,
				default: [],
			},
		},
		components: {
			draggable
		},
    emits: ['refreshWeek'],
		setup(props, {emit}) {
			watch(() => props.exerciciosProp, () => {
				state.treinoElaborado = []
        setarLista()
      })

			const router = useRouter()
			const route = useRoute()
			const swal = inject("$swal")
			const store = useStore()

			onMounted(() => {
				state.treinoElaborado = []
				if(props.exerciciosTestadosProp.length > 0) {
					state.exerciciosTestados = [...props.exerciciosTestadosProp]
          state.observation = state.exerciciosTestados[0]['observation']
          setarLista()
				} else {
          setarLista()
        }

			})
			

			const state = reactive({
				treinoElaborado: [],
				testedExercises: [],
				drag: false,
				exerciciosTestados: [],
        observation: ''
			})

			function setarLista() {
				state.treinoElaborado = props.exerciciosProp.map(item => {
					item.series.forEach(serie => {
						serie['perc_load'] = ''
						if(serie.load != '' && parseInt(serie.load) > 0 && item.max_load_test != '' && parseInt(item.max_load_test) > 0) {
							serie['perc_load'] = parseInt((parseInt(serie.load) * 100 ) / item.max_load_test)
						}
					})
					return item
				})
				
			}

			function addCargaExercicio(listaIndex) {
				//console.log('a')
        let countCargas = state.treinoElaborado[listaIndex]["series"].length
        let ultimoItem = state.treinoElaborado[listaIndex]["series"][countCargas - 1]
				//console.log(ultimoItem)
        if (ultimoItem.load > 0 || ultimoItem.repetitions > 0 || ultimoItem.series > 0) {
          let obj = {
            repetitions: '',
            load: '',
            perc_load: '',
            series: '',
          };
          state.treinoElaborado[listaIndex]["series"].push(obj)
					//console.log(state.treinoElaborado[listaIndex]["series"])
        }
				
			}

      function calcularCarga(listaIndex, loadIndex, tipo = '') {
        let item = state.treinoElaborado[listaIndex]
        let itemCarga = item['series'][loadIndex]
        if (tipo == 'perc') {
          let result = (parseInt(item.max_load_test) * parseFloat(itemCarga.perc_load))  / 100
          state.treinoElaborado[listaIndex]['series'][loadIndex]['load'] = parseInt(result)
        } else {
					if (parseInt(item.max_load_test) > 0) {
						let result = (parseInt(itemCarga.load) * 100 ) / item.max_load_test
          	state.treinoElaborado[listaIndex]['series'][loadIndex]['perc_load'] = parseInt(result)
					} else {
						state.treinoElaborado[listaIndex]['series'][loadIndex]['perc_load'] = 0
					}
          
        }
      }

			function removerSerie(listaIndex, loadIndex) {
				if(state.treinoElaborado[listaIndex]['series'].length  < 2) return
				state.treinoElaborado[listaIndex]['series'].splice(loadIndex, 1)
			}

			function calcularEstrategicos(tipo, listaIndex) {
				let exercise = state.treinoElaborado[listaIndex]
				
				let response = 0
				if (tipo == 'S') {
					exercise.series.forEach(load => {
						if(load.series != '' && parseInt(load.series) > 0) {
							response += parseInt(load.series)
						}
					})
				} else if (tipo == 'REP') {
					
					exercise.series.forEach(load => {
						if(load.series != '' && load.repetitions != '') {
							response += (parseInt(load.series) * parseInt(load.repetitions))
						}
						
					})
				} else if (tipo == 'TON') {
					exercise.series.forEach(load => {
						if(load.series != '' && load.load != '' && load.repetitions != '') {
							response += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions))
						}
						
					})
				} else if (tipo == 'PM') {
					let ton = 0
					let rep = 0
					exercise.series.forEach(load => {

						if(load.load != '' && load.series != '' && load.repetitions != '') {
							
							ton += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions))
							
							rep += (parseInt(load.series) * parseInt(load.repetitions))
							//response += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions)) / (parseInt(load.series) * parseInt(load.repetitions))
						}
					})
          /*if(parseFloat(ton / rep).toFixed(2) == 'NaN') {
            //console.log(exercise.series)
            console.log([ton / rep])
          }*/
          if(rep !== 0) {
            response = parseFloat(ton / rep).toFixed(2)
          }
				} else if (tipo == 'IMR') {
					let ton = 0
					let rep = 0
					exercise.series.forEach(load => {
						if(load.load != '' && load.series != '' && load.repetitions != '') {
							
							ton += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions))
							
							rep += (parseInt(load.series) * parseInt(load.repetitions))
							//response += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions)) / (parseInt(load.series) * parseInt(load.repetitions))
						}

						/*if(load.series != '') {
							response += parseInt(load.series)
						}*/
						
					})
          if(rep === 0) return 0
					let pm = (parseFloat(ton / rep).toFixed(2))
					if(!exercise.max_load_test) return 0
          let responsePM = parseInt((pm * 100) / exercise.max_load_test)
          //console.log(responsePM)
					return responsePM
				}
				return response
			}

      //const totalizadoresEstrategicos = computed(() => {
      function totalizadoresEstrategicos(tipo) {
        // let totais = {'S': 0, 'REP': 0,	'TON': 0,	'PM': 0,	'IMR': 0}
        let response = 0
        let responseIMR = 0
        let ton = 0
        let rep = 0
        let responsePM = 0
        state.treinoElaborado.forEach((exercise, index) => {
          if (tipo == 'S') {
            exercise.series.forEach(load => {
              if(load.series != '' && parseInt(load.series) > 0) {
                response += parseInt(load.series)
              }
            })
          } else if (tipo == 'REP') {

            exercise.series.forEach(load => {
              if(load.series != '' && load.repetitions != '') {
                response += (parseInt(load.series) * parseInt(load.repetitions))
              }

            })
          } else if (tipo == 'TON') {
            exercise.series.forEach(load => {
              if(load.series != '' && load.load != '' && load.repetitions != '') {
                response += (parseInt(load.load) * parseInt(load.series) * parseInt(load.repetitions))
              }

            })
          } else if (tipo == 'PM') {
            responsePM += parseFloat(calcularEstrategicos(tipo, index))

          } else if (tipo == 'IMR') {

            responseIMR += parseFloat(calcularEstrategicos(tipo, index))

          }
        })

        if(tipo == 'IMR') {
          let exerciciosFiltrados = state.treinoElaborado.filter(obj => {
            let serieLength = obj.series.length
            let serieLoad = obj.series[serieLength - 1].perc_load
            if(parseInt(serieLoad) !== '' && !isNaN(parseInt(serieLoad)) && parseInt(serieLoad) > 0) {
              return obj
            }
          })
          let responseIMRFinal = responseIMR / (exerciciosFiltrados).length

          return isNaN(responseIMRFinal) ? 0 : parseInt(responseIMRFinal)
        }
        if(tipo == 'PM') {
          let exerciciosFiltrados = state.treinoElaborado.filter(obj => {
            let serieLength = obj.series.length
            let serieLoad = obj.series[serieLength - 1].load
            if(serieLoad !== '') {
              return obj
            }
          })

          return (responsePM / exerciciosFiltrados.length).toFixed(2)
        }
        return response
      }

			const  salvar = async () => {
        let data = state.treinoElaborado.map((exercise, index) => {
          if(exercise.id) {
            return {
              id: exercise.id,
              series: exercise.series,
              exercise_id: exercise.exercise_id,
              max_load_test: exercise.max_load_test,
              order: index + 1,
              observation: state.observation
            }
          } else {
            const newExercise = exercise
            newExercise['order'] = index + 1
            newExercise['training_day'] = state.treinoElaborado[0]['training_day']
            newExercise['training_plan_week_id'] = state.treinoElaborado[0]['training_plan_week_id']
            newExercise['training_date'] = state.treinoElaborado[0]['training_date']
            return newExercise
          }
        })

				console.log(data)

				try {
					store.commit("SET_SPLASH_SCREEN", true)
					let response = await planosTreinoRepository.PlanoTreinoAtualizarExerciciosDiaPost(data)
					if(response.data.success) {
						swal({
							icon: "success",
							text: "Exercicíos ajustados com sucesso!",
						})
					}
				} catch (error) {
					console.error(error)
					store.commit("SET_SPLASH_SCREEN", false)
					swal({
						icon: "error",
						title: "Erro ao ajustar exercícios!",
						text: error.message,
					})
				} finally {
					store.commit("SET_SPLASH_SCREEN", false)
				}
			}

			function deletarExercicio(item, index) {
        swal({
          title: "Tem certeza?",
          text: `Deseja deletar ${item.exercise.name}`,
          showCancelButton: true,
          confirmButtonText: "Sim, quero deletar!",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            if(item.id) {
              deletarExercicioConfirma(item.id)
            } else {
              state.treinoElaborado.splice(index, 1)
            }
          }
        })
      }

      function deletarExercicioConfirma(id) {
        store.commit('SET_SPLASH_SCREEN', true)
        planosTreinoRepository.PlanoTreinoExerciciosDiaDelete(id).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if(response.data.success) {
            swal({
              title: "Removido!",
              text: "Exercicio removido com sucesso!",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            }).then(() => {
              /*let itemIndex = state.treinoElaborado.findIndex(x => x.id == id)
							state.treinoElaborado.splice(itemIndex, 1)*/
              refresh()
              //router.go(0)

            })
          }
        }).catch(error => {
          store.commit('SET_SPLASH_SCREEN', false)
          console.error(error.message)
          swal({
            icon: 'error',
            title: 'Erro ao deletar exercicio!',
            text: error.message
          })
        })
      }

      function refresh() {
        router.push({
          path: '/elaborar-treino/elaborador',
          query: {week: state.treinoElaborado[0]['training_plan_week_id']},
        })
        console.log('refreshWeek')
        emit('refreshWeek')
        //router.go(0)
      }

			function mudaExercicioTestado(eventValue, element) {
				const exercicioIndex = state.treinoElaborado.findIndex(x => x.id == element.id)
				const exercicioTestado = state.exerciciosTestados.find(x => x.exercise_id == eventValue)
				state.treinoElaborado[exercicioIndex].exercise = exercicioTestado.exercise
				state.treinoElaborado[exercicioIndex].exercise_id = exercicioTestado.exercise_id
				state.treinoElaborado[exercicioIndex].max_load_test = exercicioTestado.max_load_test

        if(state.treinoElaborado[exercicioIndex]['series'].length > 0) {
          state.treinoElaborado[exercicioIndex]['series'].forEach((item, index) => {
            const perc = parseFloat(item['perc_load'])
            if(perc > 0) {
              calcularCarga(exercicioIndex, index, 'perc')
            }
          })
        }

			}

      function colarSeries(index) {
        state.treinoElaborado[index]['series'] = store.getters.getSeries
      }

      function copiaZonas(index) {
        /*const exercise = state.treinoElaborado[index]['series']
        console.log('exercise')
        console.log(exercise)
        const finalExercise = {
          exercise: exercise['exercise'],
          exercise_id: exercise['exercise_id'],
          max_load_test: exercise['max_load_test'],
          series: exercise['series'],
        }

        //let series = [...state.treinoElaborado[index]['series']]

        store.commit('SET_SERIES', finalExercise)*/
        const series = state.treinoElaborado[index]['series'].map(item => {
          return {
            load: item.load,
            perc_load: item.perc_load,
            repetitions: item.repetitions,
            series: item.series,
          }
        })
        console.log(series)

        store.commit('SET_SERIES', series)
      }

      function colarZonas(index) {
        const series = store.getters.getSeries

        /*let obj = {
          order: state.treinoElaborado.length + 1,
          exercise: Object.assign({}, item['exercise']),
          max_load_test: item['max_load_test'],
          exercise_id: item['exercise']['id'],
          series: item['series'],
        }
        console.log(obj)
        state.treinoElaborado.push(obj)
        //state.treinoElaborado.push(store.getters.getSeries)*/
        //state.treinoElaborado[index]['series'].push(item)
        console.log(state.treinoElaborado[index])
        if(parseInt(state.treinoElaborado[index]['max_load_test']) > 0) {
          const maxLoadTest = parseInt(state.treinoElaborado[index]['max_load_test'])
          series.forEach(item => {
            //const result = (maxLoadTest * parseFloat(item.perc_load)) / 100
            let result = (maxLoadTest * parseFloat(item.perc_load))  / 100
            //state.treinoElaborado[listaIndex]['series'][loadIndex]['load'] = parseFloat(result).toFixed(2) % 1 != 0 ? parseFloat(result).toFixed(2) : parseInt(result)
            console.log(result)
            const obj = {
              perc_load: item.perc_load,
              repetitions: item.repetitions,
              series: item.series,
              load: result,
            }
            state.treinoElaborado[index]['series'].push(obj)
          })
        } else {
          series.forEach(item => {
            const obj = {
              perc_load: 0,
              repetitions: item.repetitions,
              series: item.series,
              load: item.perc_load,
            }
            state.treinoElaborado[index]['series'].push(obj)
          })
        }
      }

      const CanPasteZones = computed (() => {
        return store.getters.getSeries
      })

      const pasteExercises = computed (() => {
        return store.getters.getCopyExercises.length > 0
      })

      function colarExerciciosSelecionados() {
        const items = store.getters.getCopyExercises
        console.log(items)

        items.forEach(item => {
          let obj = {
            order: state.treinoElaborado.length + 1,
            exercise: Object.assign({}, item['exercise']),
            max_load_test: item['max_load_test'],
            exercise_id: item['exercise']['id'],
            series: item['series'],
          }
          console.log(obj)

          //verifica se o exercicio ja nao existe na lista para nao duplicar
          let duplicate = state.treinoElaborado.some(item => item.exercise_id == obj.exercise_id)
          if(!duplicate) {
            state.treinoElaborado.push(obj)
          }

        })


        //state.treinoElaborado.push(store.getters.getSeries)
      }




			return {
				state,
        pasteExercises,
        colarExerciciosSelecionados,
        CanPasteZones,
        addCargaExercicio,
        calcularCarga,
				removerSerie,
				calcularEstrategicos,
				salvar,
				deletarExercicio,
				mudaExercicioTestado,
        totalizadoresEstrategicos,
        colarZonas,
        copiaZonas
			};
		},
	};
</script>

<style>
	select.custom-select.form-control-sm {
		height: 32.39px;
	}
</style>