<template>
  <div class="row" v-if="state.perguntas.length > 0">
    <div class="col-12">
      <div class="card border-radius-top-end-0 border-radius-top-start-0 border-top-end-radius-0">
        <div class="card-body">
          <template v-for="item in state.perguntas" :key="item.id">
            <div class="row mb-3">
              <div class="col-12">
                <span> {{ item.question }}</span>
              </div>
              <div class="col-12">
									<textarea
                      v-if="item.type == 'textarea'"
                      class="form-control"
                      rows="5"
                      v-model="state.respostas[item.id]['answer']"
                  ></textarea>

                <input
                    v-if="item.type == 'text'"
                    type="text"
                    class="form-control"
                    v-model="state.respostas[item.id]['answer']"
                />

                <template v-if="item.type == 'checkbox'">
                  <div
                      class="col form-check pointer"
                      v-for="option in item.options"
                      :key="option.id"
                      @click="state.respostas[item.id]['checkbox'][option.id]['checked'] = !state.respostas[item.id]['checkbox'][option.id]['checked']"
                  >
                    <input
                        class="mt-1 form-check-input"
                        type="checkbox"
                        value="1"
                        v-model="state.respostas[item.id]['checkbox'][option.id]['checked']"
                    />
                    <span class="ms-1">
												{{ option.option }}
											</span>
                  </div>
                </template>

                <template v-if="item.type == 'radio'">
                  <div
                      class="form-check"
                      v-for="option in item.options"
                      :key="option.id"
                      @click="state.respostas[item.id]['anamnese_question_option_id'] = option.id, state.respostas[item.id]['answer'] = option.option"
                  >
                    <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        :checked="state.respostas[item.id]['anamnese_question_option_id'] == option.id"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      {{ option.option }}
                    </label>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <div class="row mt-5">
            <div class="col-12 text-end">
              <router-link to="/avaliacao-fisica">
                <button type="button" class="btn bg-gradient-secondary">
                  Voltar
                </button>
              </router-link>
              <button type="button" class="ms-3 btn bg-gradient-primary" @click.prevent="salvar">Salvar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
	import { reactive, onMounted, ref, inject } from "vue";
	import { useStore } from "vuex";
	import anamneseRepository from "@/services/api/anamneseRepository";
	import MyTable from "../../../../../components/MyTable.vue";
	import { useRouter } from "vue-router";

	export default {
		components: {
			MyTable,
		},
    props: {
      avaliacaoIdProp: {
        type: Number,
        default: 0
      },
    },
		setup(props, context) {
			const router = useRouter();
			const swal = inject("$swal");
			const store = useStore();
			onMounted(() => {
				store.commit("SET_SPLASH_SCREEN", false);
				listarPerguntas()
				//listarPlanosTreino()

        state.avaliacaoId = props.avaliacaoIdProp
			});

			// const hidePassword = ref(true)
			// const passwordFieldIcon = computed(() => hidePassword.value ? ['fa', 'eye'] : ['fa', 'eye-slash']);
			// const passwordFieldType = computed(() => hidePassword.value ? "password" : "text");

			const state = reactive({
				perguntas: [],
				respostas: [],
				trainingPlanid: '',
        avaliacaoId: 0,
			})

			const listarPerguntas = () => {
				store.commit("SET_SPLASH_SCREEN", true);
				anamneseRepository.AnamnesePerguntasGet().then((response) => {
					store.commit("SET_SPLASH_SCREEN", false);
					state.perguntas = []
					if (response.data.success) {
						let training_plan_id = store.getters.TrainingPlanManager
						// let studentId = store.getters.StudentManager
						response.data.data.forEach(item => {
							let obj = []
							obj['anamnese_question_id'] = item.id
							obj['anamnese_question_option_id'] = null
							obj['checkbox'] = []
							obj['answer'] = null
							obj['observation'] = null
							obj['training_plan_id'] = training_plan_id

							if(item.type === 'checkbox') {
								item.options.forEach(opt => {
									obj['checkbox'][opt.id] = {name: opt.option, checked: false}
								})
							}

							state.respostas[item.id] = obj
							//state.respostas.push(obj)
						})
						state.perguntas = response.data.data
					}
				})
				.catch((error) => {
					store.commit("SET_SPLASH_SCREEN", false);
					swal({
						icon: "error",
						title: "Erro ao elaborar anamnese!",
						text: error.message,
					});
				});
			}

			function salvar() {
				try {
          /*console.log('state.avaliacaoId anamnese')
          console.log(state.avaliacaoId)
          return*/
          if(state.avaliacaoId == 0) {
            let respostas = []
            state.respostas.forEach(item => {
              let data = Object.assign({}, item)
              respostas.push(data)
            })

            context.emit('salvar', respostas, 'anamnese')
          } else {
            let respostas = []
            state.respostas.forEach(item => {
              item['student_physical_assessment_id'] = state.avaliacaoId
              let data = Object.assign({}, item)
              respostas.push(data)
            })
            store.commit('SET_SPLASH_SCREEN', true)
            anamneseRepository.AnamnesePost(respostas).then(response => {
              store.commit('SET_SPLASH_SCREEN', false)
              if (response.data.success) {
                swal({
                  title: "Anamnese",
                  text: "Anamnese cadastrada com sucesso!",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                  buttonsStyling: false,
                }).then(() => {
                  window.location.reload()
                })
              }
            }).catch((error) => {
              store.commit('SET_SPLASH_SCREEN', false)
              swal({
                icon: 'error',
                title: 'Erro ao salvar anamnese',
                text: error.message
              })
            })
          }
				} catch {
					store.commit('SET_SPLASH_SCREEN', false)
				}
			}

			return {
				state,
				salvar
			}
		},
	};
</script>
  