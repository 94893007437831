import Repository from './repository'

const resource = '/anamnese'

export default {
  AnamnesePerguntasGet () {
    return Repository.get(`${resource}-questions`)
  },
  AnamneseGetById (id) {
    return Repository.get(`${resource}-student/${id}`)
  },
  AnamneseGetByAssessmentId (assessmentId) {
    return Repository.get(`${resource}-student/assessment/${assessmentId}`)
  },
  AnamneseGet () {
    return Repository.get(`${resource}-student`)
  },
  AnamnesePost (item) {
    return Repository.post(`${resource}-student`, item)
  },
  AnamnesePut (id, item) {
    return Repository.put(`${resource}-student/${id}`, item)
  },
  AnamneseDelete (planoId) {
    return Repository.delete(`${resource}-student/${planoId}`)
  },
  AnamneseAlunoGet () {
    return Repository.get(`${resource}-student`)
  },
}
