export default class ComposiaoCorporalModel {
    age = 0
    sub_scapular = 0
    triceps = 0
    chest = 0
    mid_axillary = 0
    suprailiac = 0
    abdomen = 0
    thigh = 0
    weight = 0
    height = 0
    fat_percentage = 0
    fat_weight = 0
    skinny_weight = 0
    basal_metabolic_rate = 0
    mm_metabolic_rate = 0
    imc = 0
    student_physical_assessment_id = 0
}