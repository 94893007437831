import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    mcolor: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    apiToken: null,
    coachManager: null,
    studentManager: null,
    trainingPlanManager: null,
    loaderScreen: false,
    userType: null,
    lastTimeLogged: '',
    userLogged: null,
    series: [],
    copyExercises: []
  },
  mutations: {
    SET_SERIES (state, status) {
      state.series = status
    },
    SET_COPY_EXERCISES (state, status) {
      state.copyExercises = status
    },
    SET_SPLASH_SCREEN (state, status) {
      state.loaderScreen = status
    },
    SET_USER_TYPE (state, status) {
      state.userType = status
    },
    SET_API_TOKEN (state, token) {
      state.apiToken = token
    },
    SET_TIME_LOGIN (state) {
      let data = new Date()
      state.lastTimeLogged = data.getTime()
    },
    SET_LOGOUT (state) {
      state.userLogged = null
      state.apiToken = null
      state.userType = null
      state.trainingPlanManager = null
      state.studentManager = null
      state.coachManager = null
      localStorage.removeItem('vuex')
      localStorage.removeItem('pse')
      localStorage.removeItem('psr')
      state.series = null
      state.series = []
      state.copyExercises = null
      state.copyExercises = []
      // state.userPermission = []
    },
    SET_COACH (state, token) {
      state.coachManager = token
    },
    SET_USER_LOGIN (state, obj) {
      state.userLogged = obj
    },
    SET_STUDENT (state, token) {
      state.studentManager = token
    },
    SET_TRAINING_PLAN (state, token) {
      state.trainingPlanManager = token
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show")

      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden")
        sidenav_show.classList.add("g-sidenav-pinned")
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden")
        sidenav_show.classList.remove("g-sidenav-pinned")
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    handleUpdateToken({ commit }, payload) {
      commit("SET_API_TOKEN", payload)
      commit("SET_TIME_LOGIN", payload)
    },
    handleLogout({ commit }, payload) {
      commit("SET_API_TOKEN", payload)
    }
  },
  getters: {
    ApiToken: state => state.apiToken,
    CoachManager: state => state.coachManager,
    StudentManager: state => state.studentManager,
    TrainingPlanManager: state => state.trainingPlanManager,
    splashScreen: state => state.loaderScreen,
    UserType: state => state.userType,
    getLastTimeLogged: state => state.lastTimeLogged,
    LoggedUser: state => state.userLogged,
    getSeries: state => state.series,
    getCopyExercises: state => state.copyExercises
  },
  plugins: [createPersistedState()]
});
