<template>
  <div class="card border-top-start-radius-0 border-top-end-radius-0">
    <div class="card-body">
      <div class="row">
        <div class="col-12 table-responsive">
          <table class="table border table-sm table-striped">
            <thead>
            <tr class="font-weight-bold text-center">
              <td class="text-start">Circunferências</td>
              <td class="text-start">Atual</td>
              <td>Primeira</td>
              <td>Anterior</td>
              <td>Atual - Primeira</td>
              <td>Atual - Anterior</td>
            </tr>
            </thead>
            <tbody class="text-sm">
              <tr class="text-center">
                <td class="text-start"> Ombros </td>
                <td>
                  <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.circ_shoulders">
                </td>
                <td> {{ state.composicaoPrimeira.circ_shoulders }} </td>
                <td> {{ state.composicaoAnterior.circ_shoulders }} </td>
                <td> {{ diferencialAtualPrimeiro(state.composicaoAtual.circ_shoulders, state.composicaoPrimeira.circ_shoulders) }} </td>
                <td> {{ diferencialAtualAnterior(state.composicaoAtual.circ_shoulders, state.composicaoAnterior.circ_shoulders) }} </td>
              </tr>
              <tr class="text-center">
                <td class="text-start"> Peitorais </td>
                <td>
                  <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.circ_chest">
                </td>
                <td> {{ state.composicaoPrimeira.circ_chest }} </td>
                <td> {{ state.composicaoAnterior.circ_chest }} </td>
                <td> {{ diferencialAtualPrimeiro(state.composicaoAtual.circ_chest, state.composicaoPrimeira.circ_chest) }} </td>
                <td> {{ diferencialAtualAnterior(state.composicaoAtual.circ_chest, state.composicaoAnterior.circ_chest) }} </td>
              </tr>
              <tr class="text-center">
                <td class="text-start"> Braço Direito </td>
                <td>
                  <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.circ_right_arm">
                </td>
                <td> {{ state.composicaoPrimeira.circ_right_arm }} </td>
                <td> {{ state.composicaoAnterior.circ_right_arm }} </td>
                <td> {{ diferencialAtualPrimeiro(state.composicaoAtual.circ_right_arm, state.composicaoPrimeira.circ_right_arm) }} </td>
                <td> {{ diferencialAtualAnterior(state.composicaoAtual.circ_right_arm, state.composicaoAnterior.circ_right_arm) }} </td>
              </tr>
              <tr class="text-center">
                <td class="text-start"> Ant. Direito </td>
                <td>
                  <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.circ_right_forearm">
                </td>
                <td> {{ state.composicaoPrimeira.circ_right_forearm }} </td>
                <td> {{ state.composicaoAnterior.circ_right_forearm }} </td>
                <td> {{ diferencialAtualPrimeiro(state.composicaoAtual.circ_right_forearm, state.composicaoPrimeira.circ_right_forearm) }} </td>
                <td> {{ diferencialAtualAnterior(state.composicaoAtual.circ_right_forearm, state.composicaoAnterior.circ_right_forearm) }} </td>
              </tr>
              <tr class="text-center">
                <td class="text-start"> Braço Esquerdo </td>
                <td>
                  <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.circ_left_arm">
                </td>
                <td> {{ state.composicaoPrimeira.circ_left_arm }} </td>
                <td> {{ state.composicaoAnterior.circ_left_arm }} </td>
                <td> {{ diferencialAtualPrimeiro(state.composicaoAtual.circ_left_arm, state.composicaoPrimeira.circ_left_arm) }} </td>
                <td> {{ diferencialAtualAnterior(state.composicaoAtual.circ_left_arm, state.composicaoAnterior.circ_left_arm) }} </td>
              </tr>
              <tr class="text-center">
                <td class="text-start"> Ant. Esquerdo </td>
                <td>
                  <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.circ_left_forearm">
                </td>
                <td> {{ state.composicaoPrimeira.circ_left_forearm }} </td>
                <td> {{ state.composicaoAnterior.circ_left_forearm }} </td>
                <td> {{ diferencialAtualPrimeiro(state.composicaoAtual.circ_left_forearm, state.composicaoPrimeira.circ_left_forearm) }} </td>
                <td> {{ diferencialAtualAnterior(state.composicaoAtual.circ_left_forearm, state.composicaoAnterior.circ_left_forearm) }} </td>
              </tr>
              <tr class="text-center">
                <td class="text-start"> Cintura </td>
                <td>
                  <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.circ_waist">
                </td>
                <td> {{ state.composicaoPrimeira.circ_waist }} </td>
                <td> {{ state.composicaoAnterior.circ_waist }} </td>
                <td> {{ diferencialAtualPrimeiro(state.composicaoAtual.circ_waist, state.composicaoPrimeira.circ_waist) }} </td>
                <td> {{ diferencialAtualAnterior(state.composicaoAtual.circ_waist, state.composicaoAnterior.circ_waist) }} </td>
              </tr>
              <tr class="text-center">
                <td class="text-start"> Abdomen </td>
                <td>
                  <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.circ_abdomen">
                </td>
                <td> {{ state.composicaoPrimeira.circ_abdomen }} </td>
                <td> {{ state.composicaoAnterior.circ_abdomen }} </td>
                <td> {{ diferencialAtualPrimeiro(state.composicaoAtual.circ_abdomen, state.composicaoPrimeira.circ_abdomen) }} </td>
                <td> {{ diferencialAtualAnterior(state.composicaoAtual.circ_abdomen, state.composicaoAnterior.circ_abdomen) }} </td>
              </tr>
              <tr class="text-center">
                <td class="text-start"> Quadril </td>
                <td>
                  <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.circ_hip">
                </td>
                <td> {{ state.composicaoPrimeira.circ_hip }} </td>
                <td> {{ state.composicaoAnterior.circ_hip }} </td>
                <td> {{ diferencialAtualPrimeiro(state.composicaoAtual.circ_hip, state.composicaoPrimeira.circ_hip) }} </td>
                <td> {{ diferencialAtualAnterior(state.composicaoAtual.circ_hip, state.composicaoAnterior.circ_hip) }} </td>
              </tr>
              <tr class="text-center">
                <td class="text-start"> Coxa Direita </td>
                <td>
                  <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.circ_right_thigh">
                </td>
                <td> {{ state.composicaoPrimeira.circ_right_thigh }} </td>
                <td> {{ state.composicaoAnterior.circ_right_thigh }} </td>
                <td> {{ diferencialAtualPrimeiro(state.composicaoAtual.circ_right_thigh, state.composicaoPrimeira.circ_right_thigh) }} </td>
                <td> {{ diferencialAtualAnterior(state.composicaoAtual.circ_right_thigh, state.composicaoAnterior.circ_right_thigh) }} </td>
              </tr>
              <tr class="text-center">
                <td class="text-start"> Panturrilha Direita </td>
                <td>
                  <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.circ_right_calf">
                </td>
                <td> {{ state.composicaoPrimeira.circ_right_calf }} </td>
                <td> {{ state.composicaoAnterior.circ_right_calf }} </td>
                <td> {{ diferencialAtualPrimeiro(state.composicaoAtual.circ_right_calf, state.composicaoPrimeira.circ_right_calf) }} </td>
                <td> {{ diferencialAtualAnterior(state.composicaoAtual.circ_right_calf, state.composicaoAnterior.circ_right_calf) }} </td>
              </tr>
              <tr class="text-center">
                <td class="text-start"> Coxa Esquerda </td>
                <td>
                  <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.circ_left_thigh">
                </td>
                <td> {{ state.composicaoPrimeira.circ_left_thigh }} </td>
                <td> {{ state.composicaoAnterior.circ_left_thigh }} </td>
                <td> {{ diferencialAtualPrimeiro(state.composicaoAtual.circ_left_thigh, state.composicaoPrimeira.circ_left_thigh) }} </td>
                <td> {{ diferencialAtualAnterior(state.composicaoAtual.circ_left_thigh, state.composicaoAnterior.circ_left_thigh) }} </td>
              </tr>
              <tr class="text-center">
                <td class="text-start"> Panturrilha Esquerda </td>
                <td>
                  <input type="text"  class="form-control form-control-sm" style="width: 55px" v-model="state.composicaoAtual.circ_left_calf">
                </td>
                <td> {{ state.composicaoPrimeira.circ_left_calf }} </td>
                <td> {{ state.composicaoAnterior.circ_left_calf }} </td>
                <td> {{ diferencialAtualPrimeiro(state.composicaoAtual.circ_left_calf, state.composicaoPrimeira.circ_left_calf) }} </td>
                <td> {{ diferencialAtualAnterior(state.composicaoAtual.circ_left_calf, state.composicaoAnterior.circ_left_calf) }} </td>
              </tr>

            </tbody>
            <tfoot>
              <tr class="font-weight-bold text-center">
                <td class="text-start">Somatório</td>
                <td class="text-start">{{ somaAtual }}</td>
                <td>{{ somaPrimeiro }}</td>
                <td>{{ somaAnterior }}</td>
                <td>{{ somaAtual - somaPrimeiro }}</td>
                <td>{{ somaAtual - somaAnterior }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="col-12 text-end">
          <router-link to="/avaliacao-fisica">
            <button type="button" class="btn bg-gradient-secondary">
              Voltar
            </button>
          </router-link>
          <button type="button" class="ms-3 btn bg-gradient-primary" @click.prevent="salvar">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { reactive, onMounted, ref, inject, computed } from 'vue'
import { useStore } from 'vuex'
import circunferenciaRepository from "../../../../../services/api/composicaoCorporalCircunferenciaRepository";
import { useRouter, useRoute } from 'vue-router'
/*import CircunferenciaLinhaItemComponent
  from "@/views/pages/treinador/composicaoCorporal/components/CircunferenciaLinhaItemComponent.vue";*/
import CircunferenceModel from "@/models/circunferenciaModel";
export default {
  //components: {CircunferenciaLinhaItemComponent},
    props: {
      avaliacaoIdProp: {
          type: Number,
          default: 0
      }
    },
  setup (props, context) {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()

    onMounted( () => {

        state.avaliacaoId = props.avaliacaoIdProp
        buscarDadosComposicao()
    })

    const state = reactive({
      displayComponent: false,
      composicaoAtual: new CircunferenceModel(),
      composicaoAnterior: new CircunferenceModel(),
      composicaoPrimeira: new CircunferenceModel(),
      dadosSalvos: false,
      avaliacaoId: 0
    })

    async function buscarDadosComposicao() {

      store.commit('SET_SPLASH_SCREEN', true)
      try {
        if (state.avaliacaoId == 0) {
          let responseFirst = await circunferenciaRepository.CircunferenciaGetFirst()
          let responseLast = await circunferenciaRepository.CircunferenciaGetLatest()
          store.commit('SET_SPLASH_SCREEN', false)

          if (responseFirst.data.success && responseFirst.data.data != null) {
            state.composicaoPrimeira = responseFirst.data.data
          }
          if (responseLast.data.success && responseLast.data.data != null) {
            state.composicaoAnterior = responseLast.data.data
          }
        } else {
          let response = await circunferenciaRepository.CircunferenciaGetByAssessmentId(state.avaliacaoId)
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            if(response.data.data.item != null) {
              state.composicaoAtual = Object.assign(new CircunferenceModel(), response.data.data.item)
            }
            if(response.data.data.before != null) {
              state.composicaoAnterior = Object.assign(new CircunferenceModel(), response.data.data.before)
            }
            if(response.data.data.first != null) {
              state.composicaoPrimeira = Object.assign(new CircunferenceModel(), response.data.data.first)
            }
          }
        }
      } catch (error) {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error)
      }
    }


    const somaAtual = computed(() => {
      return parseInt(state.composicaoAtual.circ_shoulders) +
          parseInt(state.composicaoAtual.circ_chest) +
          parseInt(state.composicaoAtual.circ_right_arm) +
          parseInt(state.composicaoAtual.circ_right_forearm) +
          parseInt(state.composicaoAtual.circ_left_arm) +
          parseInt(state.composicaoAtual.circ_left_forearm) +
          parseInt(state.composicaoAtual.circ_waist) +
          parseInt(state.composicaoAtual.circ_abdomen) +
          parseInt(state.composicaoAtual.circ_hip) +
          parseInt(state.composicaoAtual.circ_right_thigh) +
          parseInt(state.composicaoAtual.circ_right_calf) +
          parseInt(state.composicaoAtual.circ_right_calf) +
          parseInt(state.composicaoAtual.circ_left_thigh) +
          parseInt(state.composicaoAtual.circ_left_calf)
    })

    const somaAnterior = computed(() => {
      return parseInt(state.composicaoAnterior.circ_shoulders) +
          parseInt(state.composicaoAnterior.circ_chest) +
          parseInt(state.composicaoAnterior.circ_right_arm) +
          parseInt(state.composicaoAnterior.circ_right_forearm) +
          parseInt(state.composicaoAnterior.circ_left_arm) +
          parseInt(state.composicaoAnterior.circ_left_forearm) +
          parseInt(state.composicaoAnterior.circ_waist) +
          parseInt(state.composicaoAnterior.circ_abdomen) +
          parseInt(state.composicaoAnterior.circ_hip) +
          parseInt(state.composicaoAnterior.circ_right_thigh) +
          parseInt(state.composicaoAnterior.circ_right_calf) +
          parseInt(state.composicaoAnterior.circ_right_calf) +
          parseInt(state.composicaoAnterior.circ_left_thigh) +
          parseInt(state.composicaoAnterior.circ_left_calf)
    })

    const somaPrimeiro = computed(() => {
        return parseInt(state.composicaoPrimeira.circ_shoulders) +
            parseInt(state.composicaoPrimeira.circ_chest) +
            parseInt(state.composicaoPrimeira.circ_right_arm) +
            parseInt(state.composicaoPrimeira.circ_right_forearm) +
            parseInt(state.composicaoPrimeira.circ_left_arm) +
            parseInt(state.composicaoPrimeira.circ_left_forearm) +
            parseInt(state.composicaoPrimeira.circ_waist) +
            parseInt(state.composicaoPrimeira.circ_abdomen) +
            parseInt(state.composicaoPrimeira.circ_hip) +
            parseInt(state.composicaoPrimeira.circ_right_thigh) +
            parseInt(state.composicaoPrimeira.circ_right_calf) +
            parseInt(state.composicaoPrimeira.circ_right_calf) +
            parseInt(state.composicaoPrimeira.circ_left_thigh) +
            parseInt(state.composicaoPrimeira.circ_left_calf)
    })

    function salvar() {
      try {
        if(state.avaliacaoId == 0 && route.params.id && parseInt(route.params.id) > 0) {
          state.avaliacaoId = parseInt(route.params.id)
        }
        store.commit('SET_SPLASH_SCREEN', true)
        if(state.avaliacaoId == 0) {
          context.emit('salvar', state.composicaoAtual, 'circunferencia')
        } else {
          state.composicaoAtual.student_physical_assessment_id = state.avaliacaoId
          if(state.composicaoAtual.id == undefined || state.composicaoAtual.id == 0) {
            delete state.composicaoAtual.id
            circunferenciaRepository.CircunferenciaPost(state.composicaoAtual).then(response => {
              store.commit('SET_SPLASH_SCREEN', false)

              if (response.data.success) {

                context.emit('salvaCircunferencia', response.data.data)
                swal({
                  title: "Medidas",
                  text: "Circunferências salvas com sucesso!",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                  buttonsStyling: false,
                })
              }
            })
          } else {
            circunferenciaRepository.CircunferenciaPut(state.composicaoAtual.id, state.composicaoAtual).then(response => {
              store.commit('SET_SPLASH_SCREEN', false)

              if (response.data.success) {
                context.emit('salvaCircunferencia', response.data.data)
                swal({
                  title: "Medidas",
                  text: "Circunferências salvas com sucesso!",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                  buttonsStyling: false,
                })
              }
            })
          }
        }



      } catch (error) {
          store.commit('SET_SPLASH_SCREEN', false)
          console.error(error)
      } finally {
          store.commit('SET_SPLASH_SCREEN', false)
          state.dadosSalvos = true
      }
    }

    function voltar () {
          if (state.dadosSalvos) {
              router.push("/composicao-corporal")
          } else {
              swal({
                  title: "Dados não salvos",
                  text: "Os dados de cincunferência não foram salvos. Deseja sair sem salvar?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: "Sair sem salvar",
                  cancelButtonText: "Cancelar"
              }).then((result) => {
                  if (result.isConfirmed) {
                      router.push("/composicao-corporal")
                  }
              })
          }
      }

    function diferencialAtualPrimeiro(atual, anterior) {
      return parseFloat(anterior) > 0 ? (parseFloat(atual) - parseFloat(anterior)).toFixed(2) : 0
    }

    function diferencialAtualAnterior(atual, anterior) {
      return parseFloat(anterior) > 0 ? (parseFloat(atual) - parseFloat(anterior)).toFixed(2) : 0
    }

    return {
      state,
      somaAtual,
      somaAnterior,
      somaPrimeiro,
      salvar,
      voltar,
      diferencialAtualAnterior,
      diferencialAtualPrimeiro
    }
  }
}
</script>

<style scoped>

</style>