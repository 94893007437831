<template>
  <div
    class="p-3 bg-white card border-radius-xl mt-3 me-3"
    data-animation="FadeIn"
  >
    <div class="mt-3 row">
      <div class="form-group col-12 col-md-4 mt-sm-0">
        <label class="required">Tipo de plano de treino</label>
        <select
          class="form-control custom-select"
          name="plan_group"
          required
          id="select-plan_group"
          v-model="state.plan_group"
          @change="selecionaTipoPlano"
        >
          <option value="">Selecione uma opção</option>
          <option value="0">Plano de treino individual</option>
          <option value="1">Plano de treino em grupo</option>
        </select>
      </div>
    </div>
    <div class="row" v-if="state.plan_group == '1'">
      <div class="col-12">
        <h5>
          Você deseja criar um novo treino em grupo ou vincular em um já existente?
        </h5>
      </div>
      <div class="col-12 form-group d-flex">
        <div class="form-check me-3" @click="state.novoPlanoGrupo = '1'">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" :checked="state.novoPlanoGrupo == '1'">
          <label class="form-check-label" for="flexRadioDefault1">
            Criar um novo treino em grupo
          </label>
        </div>
        <div class="form-check" @click="state.novoPlanoGrupo = '0'">
          <input class="form-check-input" type="radio" name="flexRadioDefault" :checked="state.novoPlanoGrupo == '0'">
          <label class="form-check-label" for="flexRadioDefault2">
            Vincular aluno em treino em grupo existente
          </label>
        </div>
      </div>
    </div>
    <div class="row"  v-if="(state.plan_group == '1' && state.novoPlanoGrupo == '0')">      
      <div class="form-group col-12 col-md-4 mt-sm-0">
        <label class="required">Selecione o grupo que você deseja vincular o aluno</label>
        <select
          class="form-control custom-select"
          name="include-plan_group"
          required
          id="select-include-plan_group"
          v-model="state.includePlan"
          @change="vinculaAlunoPlano"
        >
          <option value="">Selecione uma opção</option>
          <option v-for="(item, index) in state.planos" :key="index" :value="index">{{ item.name }}</option>
        </select>
      </div>
    </div>

    <!-- INICIO CADASTRO -->
    <div v-if="state.plan_group == '0' || (state.plan_group == '1' && state.novoPlanoGrupo == '1') || (state.plan_group == '1' && state.novoPlanoGrupo == '0' && parseInt(state.plano.id) > 0)">
      <div class="row">
        <div class="form-group col-12 col-md-4">
          <label for="" class="required">Nome do plano de treino</label>
          <input
            class="form-control"
            type="text"
            v-model="state.plano.name"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12 col-md-4">
          <label class="required">Macrociclo</label>
          <select
            class="form-control custom-select"
            name="cycle"
            required
            id="select-cycle"
            v-model="state.plano.training_cycle_id"
          >
            <option value="">Selecione um macrociclo</option>
            <option v-for="(item, index) in state.ciclos" :key="index" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="form-group col-12 col-md-8">
          <label class="required">Objetivo</label>
          <input
            class="form-control"
            type="text"
            placeholder="Objetivo"
            v-model="state.plano.objective"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12 col-md-4">
          <label>Data início</label>
          <input
              :disabled="state.plano.id > 0"
            class="form-control"
            type="text"
              v-maska="'##/##/####'"
            placeholder="00/00/0000"
            v-model="state.plano.initial_date"
          />
        </div>
        <div class="form-group col-12 col-md-4">
          <label>Data fim</label>
          <input
              :disabled="state.plano.id > 0"
            class="form-control"
            type="text"
            v-maska="'##/##/####'"
            placeholder="00/00/0000"
            v-model="state.plano.final_date"
          />
        </div>
        <div class="form-group col-12 col-md-4 align-self-end font-weight-bold">
          {{ dayAndWeek }}
        </div>
      </div>
<!--      <div class="row mb-3">
        <div class="col-12">
          <label for="">Dias de treino</label>
        </div>
        <div class="col-12 text-sm">
          <div class="row ms-auto">
            <div class="col form-check pointer" @click="state.plano.monday = !state.plano.monday">
              <input
                class="mt-1 form-check-input"
                type="checkbox"
                value="1"
                v-model="state.plano.monday"
              />
              <span class="ms-1">Segunda-feira</span> 
            </div>
            <div class="col form-check pointer" @click="state.plano.tuesday = !state.plano.tuesday">
              <input
                class="mt-1 form-check-input"
                type="checkbox"
                
                v-model="state.plano.tuesday"
              />
              <span class="ms-1">Terça-feira</span>
            </div>
            <div class="col form-check pointer" @click="state.plano.wednesday = !state.plano.wednesday">
              <input
                class="mt-1 form-check-input"
                type="checkbox"
                
                v-model="state.plano.wednesday"
              />
              <span class="ms-1">Quarta-feira</span>
            </div>
            <div class="col form-check pointer" @click="state.plano.thursday = !state.plano.thursday">
              <input
                class="mt-1 form-check-input"
                type="checkbox"
                value="1"
                v-model="state.plano.thursday"
              />
              <span class="ms-1">Quinta-feira</span>
            </div>
            <div class="col form-check pointer" @click="state.plano.friday = !state.plano.friday">
              <input
                class="mt-1 form-check-input"
                type="checkbox"
                value="1"
                v-model="state.plano.friday"
              />
              <span class="ms-1">Sexta-feira</span>
            </div>
            <div class="col form-check pointer" @click="state.plano.saturday = !state.plano.saturday">
              <input
                class="mt-1 form-check-input"
                type="checkbox"
                value="1"
                v-model="state.plano.saturday"
              />
              <span class="ms-1">Sábado</span>
            </div>
            <div class="col form-check pointer" @click="state.plano.sunday = !state.plano.sunday">
              <input
                class="mt-1 form-check-input"
                type="checkbox"
                value="1"
                v-model="state.plano.sunday"
              />
              <span class="ms-1">Domingo</span>
            </div>
          </div>
        </div>
      </div>-->
      <div class="row mb-3">
        <div class="col-12 d-flex">
          <label for="">Grupos de treino</label>
          <button type="button" class="ms-3 btn btn-sm font-weight-normal btn-outline-info p-1" style="font-size: .7rem;"  @click="addGrupo()">Novo grupo</button>
        </div>
        <div class="col-12 d-flex">
          <div v-for="(item, index) in state.plano.groups" :key="index" class="form-group me-2">
            <div class="input-group">
              <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Nome do grupo"
                  required
                  v-model="item.name"
              />
              <span class="input-group-text text-body">
                <i class="fas fa-trash" aria-hidden="true" @click="removeGrupo(index)"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12 col-md-4">
          <label for="">Observação</label>
          <textarea
            class="form-control"
            rows="5"
            v-model="state.plano.observation"
          ></textarea>
        </div>
      </div>
      <div class="row" v-if="state.plan_group == '1'">
        <div class="col-12">
          <label class="font-weight-bold text-sm">
            Aproveite para incluir outros alunos no treino em grupo
          </label>
        </div>
        <div class="col-12 col-md-4">
          <select2 
            :ClearAfterSelectedProp="true"
            :ItemsProp="state.alunos"
            :FieldProp="'name'"
            :PlaceholderTextProp="'Pesquisar aluno'"
            @selected="alunoSelected">
          </select2>
          <template v-for="(item, index) in state.alunosPlano" :key="index" class="me-2">
            <span>{{ item.name }}</span>
            <span
              class="pointer"
              data-bs-toggle="tooltip"
              data-bs-original-title="Deletar aluno"
              title="Deletar aluno"
              data-bs-placement="top"
              v-tooltip
              @click="removerAlunoPlano(index)"
            >
              <i class="text-danger fas fa-trash text-secondary mx-1"></i>
            </span>
          </template>
        </div>
      </div>
    </div>
    <!-- FIM NOVO PLANO DE TREINO -->
    <div class="row mt-5">
      <div class="col-12 text-end">
        <router-link to="/planos-de-treino">
          <button type="button" class="btn bg-gradient-secondary">
              Voltar
          </button>
        </router-link>
        <button type="button" class="ms-3 btn bg-gradient-primary" @click.prevent="salvar">Salvar</button>
      </div>
    </div>
    
  </div>
</template>

<script>
import alunoRepository from "../../../../services/api/alunoRepository";
import ciclosRepository from "../../../../services/api/ciclosRepository";
import { reactive, onMounted, ref, inject, watch, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import moment from 'moment'
import Select2 from "../../../../components/Select2.vue";
import planosTreinoRepository from '../../../../services/api/planosTreinoRepository';

export default {
  components: {
    Select2
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()

    onMounted(() => {
      
      store.commit('SET_SPLASH_SCREEN', false)
      // console.log(route.params)
      buscarAlunos()
      buscarMacrociclos()
      if(route.params.id && parseInt(route.params.id) > 0) {
        state.planoTreinoId = parseInt(route.params.id)

        buscarDadosPlano()
      } else {
        let dataHoje = moment().format("DD/MM/yyyy")
        state.plano.initial_date = dataHoje
        state.plano.final_date = dataHoje
      }
    })
    

    const state = reactive({
      cep: '',
      plan_group: '',
      plano: {
        id: 0,
        plan_group: '', training_cycle_id: '', initial_date: '', 
        final_date: '', students: [], groups: []
      },
      novoPlanoGrupo: '1',
      planoTreinoId: 0,
      includePlan: '',
      ciclos: [],
      alunos: [],
      alunosPlano: [],
      planos: [],
      treinoElaborado: []
    })

    const inputNumero = ref(null)

    const dayAndWeek = computed( () => {
      let date1 = moment(state.plano.initial_date.split('/').reverse().join('-'))
      let date2 = moment(state.plano.final_date.split('/').reverse().join('-'))
      let numberWeeks = date2.diff(date1, 'weeks')
      let numberDays = date2.diff(date1, 'days')
      return `${numberWeeks} semanas - ${numberDays} dias`
    })

    const buscarDadosPlano = () => {
      planosTreinoRepository.PlanosTreinoGetById(state.planoTreinoId).then(response => {
        if(response.data.success) {
          state.plano = response.data.data
          state.plan_group = state.plano.plan_group
          state.plano.sunday = state.plano.sunday == '1'
          state.plano.monday = state.plano.monday == '1'
          state.plano.tuesday = state.plano.tuesday == '1'
          state.plano.wednesday = state.plano.wednesday == '1'
          state.plano.thursday = state.plano.thursday == '1'
          state.plano.friday = state.plano.friday == '1'
          state.plano.saturday = state.plano.saturday == '1'
          state.plano.initial_date = state.plano.initial_date.split('-').reverse().join('/')
          state.plano.final_date = state.plano.final_date.split('-').reverse().join('/')
          if(state.plano.students.length > 0) {
            state.alunosPlano =state.plano.students
          }
        }
      }).catch(error => {
        console.log('Erro ao buscar plano para editar!')
        console.log(error)
      })
    }

    const buscarAlunos = () => {
      try {
        store.commit('SET_SPLASH_SCREEN', true)
        alunoRepository.AlunoGet().then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if(response.data.success) {
            state.alunos = response.data.data
          }
        }).catch(error => {
          store.commit('SET_SPLASH_SCREEN', false)
          console.log('Erro ao buscar alunos para plano de treino!')
          console.log(error)
        })
      } catch {
        store.commit('SET_SPLASH_SCREEN', false)
      }
    }

    const buscarMacrociclos = () => {
      ciclosRepository.MacroCiclosGet().then(response => {
        if(response.data.success) {
          state.ciclos = response.data.data
        }
      }).catch(error => {
        console.log('Erro ao buscar alunos para plano de treino!')
        console.log(error)
      })
    }

    const selecionaTipoPlano = () => {
      // buscar dados do plano e carregar os campos
    }

    function alunoSelected(aluno = null) {
      if(aluno != null) {
        let res = state.alunosPlano.find(x => x.id == aluno.id)
        if(!res) {
          state.alunosPlano.push(aluno)
        }
      }
    }

    function removerAlunoPlano(indexLista) {
      let aluno = state.alunosPlano[indexLista]
      state.alunosPlano.splice(indexLista, 1)
      planosTreinoRepository.PlanoTreinoAlunoDelete(state.plano.id, aluno.id)
    }

    const vinculaAlunoPlano = () => {
      if (state.includePlan == '') return

      state.plano = state.planos[state.includePlan]
    }

    const addGrupo = () => {
      state.plano.groups.push({
        name: ''
      })
    }

    const removeGrupo = (index) => {

      state.plano.groups.splice(index, 1)
    }

    function salvar() {
      try {
        if(!validate()) return
        let plano = Object.assign({}, state.plano)
        plano.initial_date = plano.initial_date != '' ? plano.initial_date.split('/').reverse().join('-') : null
        plano.final_date = plano.final_date != '' ? plano.final_date.split('/').reverse().join('-') : null
        plano.students = []
        if (state.plan_group !== '1') {
          plano.students.push(store.getters.StudentManager)
        } else {
          state.alunosPlano.forEach(item => {
            plano.students.push(item.id)
          })
        }
        store.commit('SET_SPLASH_SCREEN', true)
        if(plano.id == 0) {
          planosTreinoRepository.PlanosTreinoPost(plano).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)
            if (response.data.success) {
              swal({
                title: "Plano de treino",
                text: "Plano de treino cadastrado com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                router.back()
              })
            } 
          }).catch((error) => {
            store.commit('SET_SPLASH_SCREEN', false)
            swal({
              icon: 'error',
              title: 'Erro ao salvar Plano de treino',
              text: error.message
            })
          })
        } else {
          planosTreinoRepository.PlanosTreinoPut(plano.id, plano).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)
            if (response.data.success) {
              swal({
                title: "Plano de treino",
                text: "Plano de treino atualizado com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                router.back()
              })
            } 
          }).catch((error) => {
            store.commit('SET_SPLASH_SCREEN', false)
            swal({
              icon: 'error',
              title: 'Erro ao atualizar Plano de treino!',
              text: error.message
            })
          })
        }
      } catch {
        store.commit('SET_SPLASH_SCREEN', false)
      }
    }

    const validate = async () => {
      const result = await v$.value.$validate()

      return result
    }

    const rulesMessages = {
      required: helpers.withMessage('Campo obrigatório', required)
    }
    const rules = {
      plano: {
        training_cycle_id: { required: rulesMessages.required }, 
      }
    }

    const v$ = useVuelidate(rules, state)

    return {
      removeGrupo,
      addGrupo,
      selecionaTipoPlano,
      state,
      inputNumero,
      salvar,
      v$,
      dayAndWeek,
      vinculaAlunoPlano,
      alunoSelected,
      removerAlunoPlano
    }
  }
}
</script>

<style>

</style>