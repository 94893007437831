<template>
  <div class="py-4 container-fluid">
    <GrupoFormulario :modalidadeId="parseInt(state.modalidadeId)" :grupoId="state.grupoId" @selecionaModalidade="displayModal" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <!-- <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Todas grupos</h5>
                <p class="mb-0 text-sm">
                  Listagem de grupos
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <button type="button" class="mb-0 btn bg-gradient-success btn-sm" @click="displayModal(true)">
                    +&nbsp; Nova grupo
                  </button>
                </div>
              </div>
            </div>
          </div> -->
          <div class="card-body">
            <MyTable
              :data="gruposFiltrados"
              :columns="columns"
              :itemsPerPage="12">
              <template v-slot:row="{ row }">
                <td class="text-sm">
                  {{ row.name }} 
                </td>
                <td class="text-sm text-center">
                  {{ row.modality.name }} 
                </td>
                <td class="text-center">
                  {{ $filters.datePtbr(row.created_at) }}
                </td>
                <td  class="text-sm ">
                  <div class="float-end">
                    <div>
                      <span
                        class="pointer"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Cadastrar exercício"
                        @click="cadastrarExercicio(row.modality_id, row.id)"
                      >
                        <i class="fas fa-layer-group me-2"></i>
                        Cadastrar exercicio
                      </span>
                    </div>
                    <div>
                      <span
                        class="pointer"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Editar grupo"
                        @click="editarGrupo(row.id)"
                      >
                        <i class="fas fa-pen text-secondary me-2"></i>
                        Editar
                        
                      </span>
                    </div>
                    <div>
                      <span
                        class="pointer"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Deletar grupo"
                        @click="deletarGrupo(row)"
                      >
                        <i class="fas fa-trash text-secondary me-2"></i>
                        Deletar
                      </span>
                    </div>
                  </div>
                </td>
              </template>
            </MyTable>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    
  </div>
</template>

<script>
  import { reactive, onMounted, ref, inject, computed } from 'vue'
  import { useStore } from 'vuex'
  import grupoRepository from "../../../../../services/api/grupoRepository";
  import MyTable from '../../../../../components/MyTable.vue';
  import { useRouter, useRoute } from 'vue-router'
  import GrupoFormulario from './GrupoFormulario.vue';

  export default {
    components: {
      MyTable,
      GrupoFormulario
    },
    setup () {
      const router = useRouter()
      const route = useRoute()
      const swal = inject('$swal')
      const store = useStore()
      onMounted(() => {
        store.commit('SET_SPLASH_SCREEN', false)
        // console.log(store.getters.ApiToken)
        listarGrupos()
        if(route.params.modalidade) {
          state.modalidadeId = parseInt(route.params.modalidade)
          displayModal(false, state.modalidadeId)
        } 
      })

      //const hidePassword = ref(true)
      // const passwordFieldIcon = computed(() => hidePassword.value ? ['fa', 'eye'] : ['fa', 'eye-slash']);
      // const passwordFieldType = computed(() => hidePassword.value ? "password" : "text");

      let modalVisible= ref(false);
      const columns = ref([
        {
          label: 'Nome',
          field: 'name',
          sortable: 'name'
        },
        {
          label: 'Modalidade',
          field: 'modality_name',
          sortable: 'modality_name'
        },
        {
          label: 'Data de cadastro',
          field: 'dataCadastro'
        },
        {
          label: 'Ações',
          field: 'actions'
        }
      ])

      const state = reactive({
        grupoId: 0,
        grupos: [],
        planoId: 0,
        modalidadeId: 0
      })

      const listarGrupos = () => {
        store.commit('SET_SPLASH_SCREEN', true)
        grupoRepository.GrupoGet().then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          state.grupos = []

          if (response.data.success) {
            state.grupos = response.data.data
          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao listar grupos!',
            text: error.message
          })
        })
      }

      const gruposFiltrados = computed (() => {

        if(state.grupos.length == 0) return []
        if(parseInt(state.modalidadeId) == 0 || state.modalidadeId.toString() == '') return state.grupos
        let modalidadeId = parseInt(state.modalidadeId)
        let dados = state.grupos.filter(obj => {
          if(obj.modality_id == modalidadeId) {
            return obj
          }
        })

        return dados
      })

      function editarGrupo(id) {
        state.grupoId = id
        displayModal(false, id)
        //router.push(`/cadastros/grupo/${id}`);
      }

      function deletarGrupo(item) {
        swal({
          title: "Tem certeza?",
          text: `Deseja deletar ${item.name}`,
          showCancelButton: true,
          confirmButtonText: "Sim, quero deletar!",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            deletarGrupoConfirma(item.id)
          }
        })
      }

      function deletarGrupoConfirma(id) {
        store.commit('SET_SPLASH_SCREEN', true)
        grupoRepository.GrupoDelete(id).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if(response.data.success) {
            swal({
              title: "Removido!",
              text: "Grupo removida com sucesso!",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            }).then(() => {
              listarGrupos()
            })
          }
        }).catch(error => {
          store.commit('SET_SPLASH_SCREEN', false)
          console.error(error.message)
          swal({
            icon: 'error',
            title: 'Erro ao deletar grupo!',
            text: error.message
          })
        })
      }

      function displayModal(refresh = false, item = 0) {
        state.modalidadeId = item
        if(refresh) {
          listarGrupos()
        }
        // listarGrupos()
      }

      function cadastrarExercicio(modalidadeId, id) {
        router.push(`/cadastros/exercicios/${modalidadeId}/${id}`);
      }

      return {
        gruposFiltrados,
        cadastrarExercicio,
        state,
        columns,
        editarGrupo,
        deletarGrupo,
        displayModal,
        modalVisible
      }
    }
  }
  
</script>
