import moment from 'moment'
import store from '../store'

import { createRouter, createWebHistory } from "vue-router";
import TemplateDefault from "../views/template/TemplateDefault.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";

// authentication
import SignIn from '../views/pages/login/Login.vue';

// dashboard
import HomeDefault from "../views/pages/dashboard/HomeDefault.vue";
import Constructor from "../views/template/Constructor.vue";
import Login from "../views/pages/login/Login";

// metodo
import MetodoLista from "../views/pages/treinador/cadastros/metodos/MetodosLista.vue";
// modalidade
import ModalidadeLista from "../views/pages/treinador/cadastros/modalidades/ModalidadeLista.vue";
// grupo
import GrupoLista from "../views/pages/treinador/cadastros/grupos/GrupoLista.vue";
// exercicios
import ExerciciosLista from "../views/pages/treinador/cadastros/exercicios/ExerciciosLista.vue";
// alunos
import AlunosLista from "../views/pages/treinador/alunos/index.vue";
import AlunoFormulario from "../views/pages/treinador/alunos/AlunoCadastro.vue";
// planos de treino
import PlanosDeTreinoLista from "../views/pages/treinador/planoTreino/PlanosDeTreinoLista.vue";
import PlanosDeTreinoFormulario from "../views/pages/treinador/planoTreino/PlanosDeTreinoFormulario.vue";


// elaborar treinos
import SemanasLista from "../views/pages/treinador/elaboradorTreino/SemanasLista.vue";
import ElaboradorTreinoIndividual from "../views/pages/treinador/elaboradorTreino/ElaborarTreinoIndividual.vue";

// administracao
import TreinadorAdministracao from "../views/pages/treinador/administracao/administracao.vue";

// area do aluno
import AreaAluno from "@/views/pages/treinador/areaAluno/index.vue"

// avaliacao fisica
import AvaliacaoFisicaLista from "@/views/pages/treinador/avaliacaoFisica/AvaliacaoLista.vue"
import AvaliacaoFisicaFormulario from "@/views/pages/treinador/avaliacaoFisica/AvaliacaoFisicaFormulario.vue"

const menuCoach = {
  path: "/",
  name: "/",
  component: TemplateDefault,
  redirect: "/dashboard",
  meta: { requiresLogin: true },
  children: [
    {
      path: "dashboard",
      name: "Dashboard",
      component: HomeDefault,
    },
    {
      path: "/desenvolvimento",
      name: "Em Desenvolvimento",
      component: Constructor,
    },
    // METODOS
    {
      path: "/cadastros/metodos",
      name: "Metodo",
      component: MetodoLista,
    },
    // MODALIDADES
    {
      path: "/cadastros/modalidade",
      name: "Modalidade",
      component: ModalidadeLista,
    },
    // GRUPOS
    {
      path: "/cadastros/grupos/:modalidade?",
      name: "Grupos",
      component: GrupoLista,
    },
    // EXERCICIOS
    {
      path: "/cadastros/exercicios/:modalidade?/:grupo?",
      name: "Exercicios",
      component: ExerciciosLista,
    },
    // ALUNOS
    {
      path: "/alunos",
      name: "Alunos",
      component: AlunosLista,
    },
    {
      path: "/alunos/cadastro/:id?",
      name: "Cadastro de aluno",
      component: AlunoFormulario,
    },
    // PLANOS DE TREINO
    {
      path: "/planos-de-treino",
      name: "Plano de treino",
      component: PlanosDeTreinoLista,
    },
    {
      path: "/planos-de-treino/cadastro/:id?",
      name: "Cadastro de plano de treino",
      component: PlanosDeTreinoFormulario,
    },
    // AVALIACAO FISICA
    {
      path: "/avaliacao-fisica/formulario/:id?",
      name: "Avaliacao fisica formulario",
      component: AvaliacaoFisicaFormulario,
    },
    {
      path: "/avaliacao-fisica",
      name: "Avaliacao fisica",
      component: AvaliacaoFisicaLista,
    },
    // ELABORAR TREINO
    {
      path: "/elaborar-treino",
      name: "PlanoTreinoSemanas",
      component: SemanasLista,
    },
    {
      path: "/elaborar-treino/elaborador",
      name: "ElaborarTreino",
      component: ElaboradorTreinoIndividual,
    },
    // AREA DO ALUNO
    {
      path: "/area-aluno",
      name: "Area do aluno",
      component: AreaAluno,
    },
    {
      path: "/administracao",
      name: "Administracao",
      component: TreinadorAdministracao,
    }
  ]
}
const menuStudent = {
  path: "/",
  name: "/",
  component: TemplateDefault,
  redirect: "/area-aluno",
  meta: { requiresLogin: true },
  children: [
    // AREA DO ALUNO
    {
      path: "/area-aluno",
      name: "Area do aluno",
      component: AreaAluno,
    },
  ]
}

const menu = store.getters.UserType !== 's' ? menuCoach : menuStudent

const routes = [
  {
    path: "/login",
    name: "Login",
    component: SignIn,
  },
  menu,
  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404,
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500,
  },
  // and finally the default route, when none of the above matches:
  //{ path: "*", component: Error404 }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  let dateLogin = false
  if (store.getters.ApiToken != null && store.getters.getLastTimeLogged != '') {
    // console.log(store.getters.getLastTimeLogged)
    let date1 = moment(store.getters.getLastTimeLogged).add(24, 'hours').format("yyyy-MM-DD hh:mm:ss")
    let date2 = moment(new Date()).format("yyyy-MM-DD hh:mm:ss")

    // console.log(date1)
    // console.log(date2)
    
    dateLogin = date1 < date2
  }
  
  //  console.log(store.getters.ApiToken)
  //  console.log(dateLogin)

  if (to.matched.some(record => record.meta.requiresLogin) && ( store.getters.ApiToken == null || dateLogin)) {
    // console.log('a')
    store.commit('SET_LOGOUT')
    next('/login')
  } else if (store.getters.ApiToken != null && to.path === '/login') {
    // console.log('b')
    next('/dashboard')
  } else {
    // console.log('c')
    next()
  }
})

export default router;
