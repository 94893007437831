<template>
  <div class="card border-top-start-radius-0 border-top-end-radius-0">
    <div class="card-body">
      <div class="row">
        <div class="col-12 table-responsive">
          <table class="table border table-striped text-center text-sm table-sm">
            <thead>
            <tr class="font-weight-bold">
              <td class="text-start w-25 p-2 align-middle">Medidas de tempo</td>
              <td class="text-start w-15 p-2 align-middle">Tempo atual</td>
              <td class="w-15 p-2 align-middle">Primeiro tempo</td>
              <td class="w-15 p-2 align-middle">Tempo anterior</td>
              <td class="w-15 p-2 align-middle"> Dif. Tempo atual <br>- Tempo anterior</td>
              <td class="w-15 p-2 align-middle">Dif. Tempo atual <br>- Primeiro tempo</td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="text-start">50 metros</td>
              <td class="text-start">
                <input type="text" v-maska="'##:##'"  class="w-50 form-control form-control-sm"  v-model="state.neuroMotorAtual['50m']">
              </td>
              <td> {{ state.neuroMotorPrimeiro['50m'] }} </td>
              <td> {{ state.neuroMotorAnterior['50m'] }} </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['50m'], state.neuroMotorAnterior['50m']) }}
              </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['50m'], state.neuroMotorPrimeiro['50m']) }}
              </td>
<!--              <td> {{ state.neuroMotorAtual['50m']  - state.neuroMotorAnterior['50m'] }} </td>
              <td> {{ state.neuroMotorAtual['50m']  - state.neuroMotorPrimeiro['50m'] }} </td>-->
            </tr>
            <tr>
              <td class="text-start">60 metros</td>
              <td>
                <input type="text"  v-maska="'##:##'" class="w-50 form-control form-control-sm" v-model="state.neuroMotorAtual['60m']">
              </td>
              <td> {{ state.neuroMotorPrimeiro['60m'] }} </td>
              <td> {{ state.neuroMotorAnterior['60m'] }} </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['60m'], state.neuroMotorAnterior['60m']) }}
              </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['60m'], state.neuroMotorPrimeiro['60m']) }}
              </td>
            </tr>
            <tr>
              <td class="text-start">100 metros</td>
              <td>
                <input type="text"  v-maska="'##:##'" class="w-50 form-control form-control-sm"  v-model="state.neuroMotorAtual['100m']">
              </td>
              <td> {{ state.neuroMotorPrimeiro['100m'] }} </td>
              <td> {{ state.neuroMotorAnterior['100m'] }} </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['100m'], state.neuroMotorAnterior['100m']) }}
              </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['100m'], state.neuroMotorPrimeiro['100m']) }}
              </td>
            </tr>
            <tr>
              <td class="text-start">200 metros</td>
              <td>
                <input type="text" v-maska="'##:##'" class="w-50 form-control form-control-sm"  v-model="state.neuroMotorAtual['200m']">
              </td>
              <td> {{ state.neuroMotorPrimeiro['200m'] }} </td>
              <td> {{ state.neuroMotorAnterior['200m'] }} </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['200m'], state.neuroMotorAnterior['200m']) }}
              </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['200m'], state.neuroMotorPrimeiro['200m']) }}
              </td>
            </tr>
            <tr>
              <td class="text-start">300 metros</td>
              <td>
                <input type="text" v-maska="'##:##'" class="w-50 form-control form-control-sm"  v-model="state.neuroMotorAtual['300m']">
              </td>
              <td> {{ state.neuroMotorPrimeiro['300m'] }} </td>
              <td> {{ state.neuroMotorAnterior['300m'] }} </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['300m'], state.neuroMotorAnterior['300m']) }}
              </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['300m'], state.neuroMotorPrimeiro['300m']) }}
              </td>
            </tr>
            <tr>
              <td class="text-start">400 metros</td>
              <td>
                <input type="text" v-maska="'##:##'" class="w-50 form-control form-control-sm"  v-model="state.neuroMotorAtual['400m']">
              </td>
              <td> {{ state.neuroMotorPrimeiro['400m'] }} </td>
              <td> {{ state.neuroMotorAnterior['400m'] }} </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['400m'], state.neuroMotorAnterior['400m']) }}
              </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['400m'], state.neuroMotorPrimeiro['400m']) }}
              </td>
            </tr>
            <tr>
              <td class="text-start">600 metros</td>
              <td>
                <input type="text" v-maska="'##:##'" class="w-50 form-control form-control-sm"  v-model="state.neuroMotorAtual['600m']">
              </td>
              <td> {{ state.neuroMotorPrimeiro['600m'] }} </td>
              <td> {{ state.neuroMotorAnterior['600m'] }} </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['600m'], state.neuroMotorAnterior['600m']) }}
              </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['600m'], state.neuroMotorPrimeiro['600m']) }}
              </td>
            </tr>
            <tr>
              <td class="text-start">800 metros</td>
              <td>
                <input type="text" v-maska="'##:##'" class="w-50 form-control form-control-sm"  v-model="state.neuroMotorAtual['800m']">
              </td>
              <td> {{ state.neuroMotorPrimeiro['800m'] }} </td>
              <td> {{ state.neuroMotorAnterior['800m'] }} </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['800m'], state.neuroMotorAnterior['800m']) }}
              </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['800m'], state.neuroMotorPrimeiro['800m']) }}
              </td>
            </tr>
            <tr>
              <td class="text-start">1000 metros</td>
              <td>
                <input type="text" v-maska="'##:##'" class="w-50 form-control form-control-sm"  v-model="state.neuroMotorAtual['1000m']">
              </td>
              <td> {{ state.neuroMotorPrimeiro['1000m'] }} </td>
              <td> {{ state.neuroMotorAnterior['1000m'] }} </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['1000m'], state.neuroMotorAnterior['1000m']) }}
              </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['1000m'], state.neuroMotorPrimeiro['1000m']) }}
              </td>
            </tr>
            <tr>
              <td class="text-start">1500 metros</td>
              <td>
                <input type="text" v-maska="'##:##'" class="w-50 form-control form-control-sm"  v-model="state.neuroMotorAtual['1500m']">
              </td>
              <td> {{ state.neuroMotorPrimeiro['1500m'] }} </td>
              <td> {{ state.neuroMotorAnterior['1500m'] }} </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['1500m'], state.neuroMotorAnterior['1500m']) }}
              </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['1500m'], state.neuroMotorPrimeiro['1500m']) }}
              </td>
            </tr>
            <tr>
              <td class="text-start">5000 metros</td>
              <td>
                <input type="text" v-maska="'##:##'" class="w-50 form-control form-control-sm"  v-model="state.neuroMotorAtual['5000m']">
              </td>
              <td> {{ state.neuroMotorPrimeiro['5000m'] }} </td>
              <td> {{ state.neuroMotorAnterior['5000m'] }} </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['5000m'], state.neuroMotorAnterior['5000m']) }}
              </td>
              <td>
                {{ $filters.diffTimeMinutesSeconds(state.neuroMotorAtual['5000m'], state.neuroMotorPrimeiro['5000m']) }}
              </td>
            </tr>
            </tbody>
          </table>

          <table class="table border mt-5 table-striped text-center text-sm table-sm">
            <thead>
            <tr>
              <td colspan="6" class="py-2"><span class="font-weight-bold">SALTOS</span> <span class="small">(medidas em metros)</span></td>
            </tr>
              <tr class="font-weight-bold">
                <td class="text-start w-25 p-2 align-middle"></td>
                <td class="text-start w-15 p-2 align-middle">Atual</td>
                <td class="w-15 p-2 align-middle">Primeiro salto</td>
                <td class="w-15 p-2 align-middle">Salto anterior</td>
                <td class="w-15 p-2 align-middle"> Dif. Salto atual <br>- Salto anterior</td>
                <td class="w-15 p-2 align-middle">Dif. Salto atual <br>- Primeiro salto</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-start">Vertical Livre</td>
                <td class="text-start">
                  <input type="text"  class="w-50 form-control form-control-sm" v-maska="'#.##'"  v-model="state.neuroMotorAtual['free_vertical_jump']">
                </td>
                <td> {{ state.neuroMotorPrimeiro['free_vertical_jump'] }} </td>
                <td> {{ state.neuroMotorAnterior['free_vertical_jump'] }} </td>
                <td> {{ state.neuroMotorAtual['free_vertical_jump']  - state.neuroMotorAnterior['free_vertical_jump'] }} </td>
                <td> {{ state.neuroMotorAtual['free_vertical_jump']  - state.neuroMotorPrimeiro['free_vertical_jump'] }} </td>
              </tr>
              <tr>
                <td class="text-start">Vertical Caixote</td>
                <td>
                  <input type="text"  class="w-50 form-control form-control-sm" v-maska="'#.##'" v-model="state.neuroMotorAtual['vertical_box_jump']">
                </td>
                <td> {{ state.neuroMotorPrimeiro['vertical_box_jump'] }} </td>
                <td> {{ state.neuroMotorAnterior['vertical_box_jump'] }} </td>
                <td> {{ state.neuroMotorAtual['vertical_box_jump']  - state.neuroMotorAnterior['vertical_box_jump'] }} </td>
                <td> {{ state.neuroMotorAtual['vertical_box_jump']  - state.neuroMotorPrimeiro['vertical_box_jump'] }} </td>
              </tr>
              <tr>
                <td class="text-start">Horizontal</td>
                <td>
                  <input type="text"  class="w-50 form-control form-control-sm" v-maska="'#.##'" v-model="state.neuroMotorAtual['horizontal_jump']">
                </td>
                <td> {{ state.neuroMotorPrimeiro['horizontal_jump'] }} </td>
                <td> {{ state.neuroMotorAnterior['horizontal_jump'] }} </td>
                <td> {{ state.neuroMotorAtual['horizontal_jump']  - state.neuroMotorAnterior['horizontal_jump'] }} </td>
                <td> {{ state.neuroMotorAtual['horizontal_jump']  - state.neuroMotorPrimeiro['horizontal_jump'] }} </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 text-end">
          <router-link to="/avaliacao-fisica">
            <button type="button" class="btn bg-gradient-secondary">
              Voltar
            </button>
          </router-link>
          <button type="button" class="ms-3 btn bg-gradient-primary" @click.prevent="salvar">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {reactive, onMounted, ref, inject, computed, watch} from 'vue'
import { useStore } from 'vuex'
import neuroMotorRepository from "../../../../../services/api/neuroMotorRepository";
import { useRouter, useRoute } from 'vue-router'
import { Money3Component } from 'v-money3'
import NeuroMotorModel from "@/models/neuroMotorModel";

export default {
  components: {
    money3: Money3Component
  },
  props: {
    avaliacaoIdProp: {
      type: Number,
      default: 0
    },
  },
  setup(props, context) {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()

    onMounted( () => {

      state.avaliacaoId = props.avaliacaoIdProp
      buscarDados()
    })


    const state = reactive({
      avaliacaoId: 0,
      neuroMotorAtual: new NeuroMotorModel(),
      neuroMotorAnterior: new NeuroMotorModel(),
      neuroMotorPrimeiro: new NeuroMotorModel(),

    })

    async function buscarDados() {


      store.commit('SET_SPLASH_SCREEN', true)
      try {
        if (state.avaliacaoId == 0) {
          let responseFirst = await neuroMotorRepository.PrimeiroNeuroMotorGet()
          let responseLast = await neuroMotorRepository.UltimoNeuroMotorGet()
          store.commit('SET_SPLASH_SCREEN', false)

          if (responseFirst.data.success && responseFirst.data.data != null) {
            state.neuroMotorPrimeiro = responseFirst.data.data
          }
          if (responseLast.data.success && responseLast.data.data != null) {
            state.neuroMotorAnterior = responseLast.data.data
          }
        } else {
          let response = await neuroMotorRepository.NeuroMotorGetByAssessmentId(state.avaliacaoId)
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {

            if(response.data.data.item != null) {
              state.neuroMotorAtual = Object.assign(new NeuroMotorModel(), response.data.data.item)
            }
            if(response.data.data.before != null) {
              state.neuroMotorAnterior = Object.assign(new NeuroMotorModel(), response.data.data.before)
            }
            if(response.data.data.first != null) {
              state.neuroMotorPrimeiro = Object.assign(new NeuroMotorModel(), response.data.data.first)
            }
          }
        }
      } catch (error) {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error)
      }
    }

    function salvar() {
      try {
        if(state.avaliacaoId == 0 && route.params.id && parseInt(route.params.id) > 0) {
          state.avaliacaoId = parseInt(route.params.id)
        }
        store.commit('SET_SPLASH_SCREEN', true)
        if(state.avaliacaoId == 0) {
          context.emit('salvar', state.neuroMotorAtual, 'neuromotor')
        } else {
          state.neuroMotorAtual.student_physical_assessment_id = state.avaliacaoId
          if(state.neuroMotorAtual.id == undefined || state.neuroMotorAtual.id == 0) {
            neuroMotorRepository.NeuroMotorPost(state.neuroMotorAtual).then(response => {
              store.commit('SET_SPLASH_SCREEN', false)

              if (response.data.success) {
                swal({
                  title: "Medidas",
                  text: "Circunferências cadastrada com sucesso!",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                  buttonsStyling: false,
                })
              }
            })
          } else {
            neuroMotorRepository.NeuroMotorPut(state.neuroMotorAtual.id, state.neuroMotorAtual).then(response => {
              store.commit('SET_SPLASH_SCREEN', false)

              if (response.data.success) {
                swal({
                  title: "Medidas",
                  text: "Circunferências cadastrada com sucesso!",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                  buttonsStyling: false,
                })
              }
            })
          }

        }

      } catch (error) {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error)
      } finally {
        store.commit('SET_SPLASH_SCREEN', false)
      }
    }

    return {
      state,
      salvar
    }
  }
}
</script>

<style scoped>

</style>