<template>
  <main class="mt-0 main-content">
    <Load />
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Entrar</h4>
                  <p class="mb-0">Digite seu e-mail e senha para entrar</p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="signIn">
                    <div class="mb-3">
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Email"
                        name="email"
                        required
                        v-model="form.email"
                      />
                    </div>
                    <div class="mb-3">
                      <input
                        class="form-control"
                        type="password"
                        placeholder="Password"
                        name="password"
                        required  
                        v-model="form.password"
                      />
                    </div>
                    <!-- <vsud-switch id="rememberMe"> Remember me </vsud-switch> -->

                    <div class="text-center">
                      <vsud-button
                        
                        variant="custom"
                        color="dt-btn-primary"
                        fullWidth
                        size="lg"
                        type="submit"
                        class="mt-4"
                        >
                        ENTRAR
                      </vsud-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Não tem uma conta?
                    <router-link to="/login" class="dt-color-third font-weight-bold">Cadastre-se</router-link>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="m-3 position-relative  h-100 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <!-- <img
                  src="@/assets/img/logos/logo.png"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0"
                /> -->
                <div class="position-relative">
                  <img
                    class="max-width-500 w-100 position-relative z-index-2"
                    src="@/assets/img/logos/logo.png"
                    alt="chat-img"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  <span class="font-weight-bold">
                    DIÁRIO 
                  </span>
                  <span>
                    DE TREINO
                  </span>
                </h4>
                <!-- <p class="text-white">
                  Quanto mais fácil a escrita parece, mais esforço o
                  escritor realmente colocou no processo.
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Load from "./../../template/Load.vue";
import VsudInput from "@/components/VsudInput.vue";
import VsudSwitch from "@/components/VsudSwitch.vue";
import VsudButton from "@/components/VsudButton.vue";
import LoginRepository from "../../../services/api/auth/loginRepository";
import store from "@/store";
const body = document.getElementsByTagName("body")[0];

export default {
  name: "signin-illustration",
  components: {
    VsudInput,
    VsudSwitch,
    VsudButton,
    Load
  }, 
  data() {
    return {
      form: {
        email: "",
        password: ""
      } 
    }
  },
  methods: {
    async signIn() {
      this.$store.commit('SET_SPLASH_SCREEN', true)
      try {
        // const data = { email: this.form.email, password: this.form.password }
        const formDataitems = new FormData()
        formDataitems.append('email', this.form.email)
        formDataitems.append('password', this.form.password)
        const response = await LoginRepository.Authenticate(formDataitems)
        this.$store.commit('SET_SPLASH_SCREEN', false)

        if (response.success) {
          this.$store.dispatch('handleUpdateToken', response.data.token)
          this.$store.state.showSidenav = true
          this.$store.commit('SET_USER_TYPE', response.data.user_type)

          if(response.data.sid) {
            this.$store.commit('SET_STUDENT',  response.data.sid)
            this.$router.push('/area-aluno')
          } else {
            this.$store.commit('SET_COACH',  response.data.cid)
            this.$router.push('/dashboard')
          }

        } else {
          this.$swal({
            icon: 'error',
            title: 'Não foi possível realizar o login',
            text: 'Verifique se seu usuário e/ou estão corretos!'
          })
        }
      } catch (error ) {
        this.$store.commit('SET_SPLASH_SCREEN', false)
        console.error(error)
        this.$swal({
          icon: 'error',
          title: 'Não foi possível realizar o login',
          text: 'Verifique se seu usuário e/ou estão corretos!'
        })
      }
    }
  },
  created() {
    this.$store.commit('SET_LOGOUT')
    this.$store.commit('SET_SPLASH_SCREEN', false)
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
