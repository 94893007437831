<template>
  <div class="card border-top-start-radius-0 border-top-end-radius-0">
    <div class="card-body"  v-if="state.aluno">
      <div class="row mb-4">
        <div class="col-12 col-md-4 col-sm-6">
          <label class="form-label mt-2">Idade</label>
          <input
              disabled
              class="form-control form-control-sm"
              type="text"
              v-model="state.age"
          />
        </div>
        <div class="col-12 col-md-4 col-sm-6">
          <label class="form-label mt-2">Classificação</label>
          <div class="d-flex">
            <div
                class="form-check ps-0"
                @click="state.classificacao = 'A'"
            >
              <input
                  class="form-check-input ms-0"
                  type="radio"
                  name="flexRadioDefault"
                  :checked="state.classificacao == 'A'"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Ativo
              </label>
            </div>
            <div
                class="form-check"
                @click="state.classificacao = 'S'"
            >
              <input
                  class="form-check-input ms-0"
                  type="radio"
                  name="flexRadioDefault"
                  :checked="state.classificacao == 'S'"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Sedentário
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-sm-6">
          <label class="form-label mt-2">Frequência cardíaca</label>
          <input
              disabled
              class="form-control form-control-sm"
              type="text"
              v-model="frequenciaCardiaca"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <label class="form-label mt-2">Selecione o protocolo</label>
          <select class="form-control form-control-sm custom-select" v-model="state.protocolo" >
            <option value="">Selecione aqui</option>
            <option value="cooper">Teste Cooper 12 min</option>
            <option value="corrida">Teste de corrida 2400m</option>
            <option value="caminhada">Teste caminhada 1600m</option>
          </select>
        </div>
      </div>

      <div class="row"  v-if="state.protocolo === 'cooper'">
        <div class="col-12 col-md-6 col-lg-4">
          <label class="form-label mt-2">Distância percorrida (km)</label>
<!--          <input
              class="form-control"
              type="text"
              v-maska="'##.###'"
              placeholder="00.000"
              v-model="state.cooperDistancia"
          />-->
          <money3 v-bind="state.config" v-model="state.cooperDistancia" class="form-control"></money3>
        </div>
      </div>
      <div class="row"  v-if="state.protocolo === 'corrida'">
        <div class="col-12 col-md-6 col-lg-4">
          <label class="form-label mt-2">Tempo de percurso (minuto:segundo)</label>
          <input
              class="form-control"
              type="text"
              v-maska="'##:##'"
              placeholder="00:00"
              v-model="state.corridaTempoPercurso"
          />
        </div>
      </div>
      <div class="row"  v-if="state.protocolo === 'caminhada'">
        <div class="col-12 col-md-6 col-lg-4">
          <label class="form-label mt-2">Peso</label>
          <input
              class="form-control"
              type="text"
              placeholder="Peso"
              v-model="state.caminhada.peso"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <label class="form-label mt-2">Frequência cardiaca final</label>
          <input
              class="form-control"
              type="text"
              placeholder="Frequência cardiaca"
              v-model="state.caminhada.frequenciaCardiaca"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <label class="form-label mt-2">Tempo de percurso (minuto:segundo)</label>
          <input
              class="form-control"
              type="text"
              v-maska="'##:##'"
              placeholder="00:00"
              v-model="state.caminhada.tempoPercurso"
          />
        </div>
      </div>
      <div v-if="state.protocolo != ''" class="row mt-3">
        <div class="font-weight-bold text-center col-12 h3">
          Resultado
        </div>
        <div class="col-12 d-flex">
          <label class="me-2">VO2 Max Obtido (ml/kg/min)</label>
          <span>
            {{ voObtido() }}
          </span>
        </div>
        <div class="col-12 d-flex">
          <label class="me-2">VO2 Max Previsto (ml/kg/min)</label>
          <span>
            {{ voPrevisto() }}
          </span>
        </div>
        <div class="col-12 d-flex">
          <label class="me-2">MEts (vo2 ml/kg/min)/3.5)</label>
          <span>
            {{ mets() }}
          </span>
        </div>
        <div class="col-12 d-flex">
          <label class="me-2">Classificação</label>
          <span>
            {{ classificacao() }}
          </span>
        </div>
        <div class="col-12 d-flex">
          <label class="me-2">FAI %</label>
          <span>
            {{ fai() }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-end mt-3">
          <router-link to="/avaliacao-fisica">
            <button type="button" class="btn bg-gradient-secondary">
              Voltar
            </button>
          </router-link>
          <button type="button" class="ms-3 btn bg-gradient-primary" @click.prevent="salvar">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {reactive, onMounted, ref, inject, computed, watch} from 'vue'
import { useStore } from 'vuex'
import composicaoCorporalRepository from "../../../../../services/api/composicaoCorporalRepository";
import { useRouter, useRoute } from 'vue-router'
import ComposiaoCorporalModel from "@/models/composicaoCorporalModel";
import { Money3Component } from 'v-money3'
import alunoRepository from "@/services/api/alunoRepository";
import {voMaxClassificacao} from "@/services/VoMax.service"
import voMaxRepository from "@/services/api/voMaxRepository";

export default {
  components: {
    money3: Money3Component
  },
  props: {
    avaliacaoIdProp: {
      type: Number,
      default: 0
    },
  },
  setup(props, context) {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()

    onMounted( async () => {
      state.avaliacaoId = props.avaliacaoIdProp
      await buscarDados()
      await buscaDadosAluno()
    })


    const state = reactive({
      id: 0,
      frequenciaCardiaca: 0,
      age: 0,
      classificacao: '',
      protocolo: '',
      avaliacaoId: 0,
      cooperDistancia: '',
      corridaTempoPercurso: '',
      caminhada: {
        peso: '',
        frequenciaCardiaca: '',
        tempoPercurso: ''
      },
      aluno: null,
      config: {
        masked: false,
        prefix: '',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 3,
        disableNegative: false,
        disabled: false,
        min: 0,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      }

    })

    async function buscaDadosAluno() {
      alunoRepository.AlunoGetCurrent().then(response => {
        if(response.data.success) {
          state.aluno = response.data.data

          // quando ja tiver registro de idade nao faz calculo novo de idade
          if(state.age == 0) {
            state.age = state.aluno.age
          }

        }
      }).catch (error => {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error)
      }).finally(() => {
        store.commit('SET_SPLASH_SCREEN', false)
      })
    }

    async function buscarDados() {
      store.commit('SET_SPLASH_SCREEN', true)
      try {
        if (state.avaliacaoId !== 0) {
          let response = await voMaxRepository.VoMaxGetByAssessmentId(state.avaliacaoId)
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {

            if(response.data.data != null) {
              let item = response.data.data

              if(state.age == 0) {
                state.age = item['age']
              }

              state.id = item['id']
              state.classificacao = item['classification']
              state.cooperDistancia = item['cooper']
              state.caminhada.peso = item['walking_weight']
              state.caminhada.frequenciaCardiaca = item['walking_frequency']
              state.caminhada.tempoPercurso = item['walking_time']
              state.corridaTempoPercurso = item['running_time']

              if(state.cooperDistancia) {
                state.protocolo = 'cooper'
              } else if(state.caminhada.peso) {
                state.protocolo = 'caminhada'
              } else {
                state.protocolo = 'corrida '
              }
            }

          }
        }
      } catch (error) {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error)
      } finally {
        store.commit('SET_SPLASH_SCREEN', false)
      }

    }

    function classificacao() {
      return voMaxClassificacao(state.aluno.sex, state.aluno.age, voObtido())
    }

    function cooper () {
      if(!state.cooperDistancia) return 0
      return (parseInt(state.cooperDistancia.replace('.','')) - 504)/45
    }

    function corrida () {

      if(state.corridaTempoPercurso == '' || state.corridaTempoPercurso == null) return 0
      let tempoPercurso = state.corridaTempoPercurso.split(`:`)
      let tempoSegundo = parseInt((tempoPercurso[0] * 60)) + parseInt(tempoPercurso[1])
      return ((2400 * 12) + 3.5) / tempoSegundo
    }

    function caminhada () {

      if(state.caminhada.tempoPercurso == null) return 0
      let sexo = state.aluno.sex == 'F' ? 1 : 0
      let peso = state.caminhada.peso
      let idade = state.age
      let tempo = state.caminhada.tempoPercurso.split(`:`)
      let tempoPercurso = parseInt((tempo[0] * 60)) + parseInt(tempo[1])

      let frequenciaCardiacaFinal = state.caminhada.frequenciaCardiaca

      let response = 6.952 + (0.0091 * peso) - (0.0257 * idade) + (0.5955 * sexo) - (0.2240 * tempoPercurso) - (0.0115 * frequenciaCardiacaFinal)
      return parseFloat(response).toFixed(2)
    }

    const frequenciaCardiaca = computed (() => {
      return 220 - parseInt(state.aluno.age)
    })

    function fai() {
      let response  = (voPrevisto() - voObtido()) / voPrevisto()
      return parseFloat(response).toFixed(2)
    }

    function mets () {
      return parseFloat(voObtido() / 3.5).toFixed(2)
    }

    function voObtido  ()  {
      let obtido = 0

      if(state.protocolo == 'cooper') {
        obtido = cooper()
      }

      if(state.protocolo == 'caminhada') {
        obtido = caminhada()
      }

      if(state.protocolo == 'corrida') {
        obtido = corrida()
      }


      obtido = parseFloat(obtido).toFixed(2)

      if(obtido == 'NaN' || obtido == undefined) return 0

      return obtido
    }

    function voPrevisto() {
      let previsto = 0

      if(state.aluno.sex == 'M') {
        if(state.classificacao == 'A') {
          previsto = 69.7 - (0.612 * state.age)
        } else {
          previsto = 57.8 - (0.445 * state.age)
        }
      } else {
        if(state.classificacao == 'A') {
          previsto = 42.9 - (0.312 * state.age)
        } else {
          previsto = 42.3 - (0.356 * state.age)
        }
      }

      previsto = parseFloat(previsto).toFixed(2)

      if(previsto == 'NaN' || previsto == undefined) return 0

      return previsto
    }

    function salvar() {
      try {

        let item = []
        item['student_physical_assessment_id'] = state.avaliacaoId
        item['age'] = state.age
        item['classification'] =  state.classificacao
        item['cooper'] = state.cooperDistancia
        item['walking_weight'] = state.caminhada.peso
        item['walking_frequency'] = state.caminhada.frequenciaCardiaca
        item['walking_time'] = state.caminhada.tempoPercurso
        item['running_time'] = state.corridaTempoPercurso

        /*console.log(item)
        return*/

        store.commit('SET_SPLASH_SCREEN', true)

        if(state.avaliacaoId == 0 && route.params.id && parseInt(route.params.id) > 0) {
          state.avaliacaoId = parseInt(route.params.id)
        }

        if(state.avaliacaoId == 0) {
          context.emit('salvar', Object.assign({},item), 'vomax')
        } else {
          let jsonItem = JSON.stringify(item)


          if(state.id == undefined || state.id == 0) {
            voMaxRepository.VoMaxPost(Object.assign({},item)).then(response => {
              store.commit('SET_SPLASH_SCREEN', false)

              if (response.data.success) {
                swal({
                  title: "Vo2",
                  text: "Vo2 cadastrado com sucesso!",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                  buttonsStyling: false,
                })
              }
            })
          } else {

            voMaxRepository.VoMaxPut(state.id, Object.assign({},item)).then(response => {
              store.commit('SET_SPLASH_SCREEN', false)

              if (response.data.success) {
                swal({
                  title: "Vo2",
                  text: "Vo2 cadastrado com sucesso!",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                  buttonsStyling: false,
                })
              }
            })
          }

        }

      } catch (error) {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error)
      } finally {
        store.commit('SET_SPLASH_SCREEN', false)
      }
    }

    return {
      state,
      salvar,
      frequenciaCardiaca,
      voObtido,
      voPrevisto,
      fai,
      mets,
      classificacao
    }
  }
}
</script>

<style scoped>

</style>