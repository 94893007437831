<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Avaliação física</h5>
                <p class="mb-0 text-sm">
                  Listagem de avaliações
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <button type="button" class="mb-0 btn bg-gradient-success btn-sm" @click="formularioAvaliacao()">
                    +&nbsp; Nova avaliação
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-if="!studentOn">
                <span class="mt-5 text-center">
                  <h5>Selecione um aluno na parte superior do sistema</h5>
                </span>
            </div>
            <MyTable
                v-else
                :data="state.avaliacoes"
                :columns="columns"
                :itemsPerPage="12">
              <template v-slot:row="{ row }">
                <td colspan="4">
                  <table class="w-100">
                    <tbody>
                    <tr>
                      <td class="text-sm  w-30">
                        {{ row.assessment_location }}
                      </td>
                      <td class="text-sm  text-center w-40">
                        {{ $filters.datePtbr(row.assessment_date) }}
                      </td>
<!--                      <td class="text-sm  text-center w-40">
                        1,78m
                      </td>
                      <td class="text-sm  text-center w-40">
                        78,00kg
                      </td>
                      <td class="text-sm  text-center w-40">
                        26.57%
                      </td>
                      <td class="text-sm  text-center w-40">
                        28,31
                      </td>-->
                      <td class="text-sm ">
                        <div class="float-end d-flex">
                          <div class="me-3">
                            <div class="m-1">
                              <span
                                  class="pointer"
                                  data-bs-toggle="tooltip"
                                  data-bs-original-title="Editar avaliação"
                                  title="Editar avaliação"
                                  data-bs-placement="top"
                                  v-tooltip
                                  @click="formularioAvaliacao(row.id)"
                              >
                                <i class="fas fa-pen text-secondary me-2"></i>
                                Editar avaliação
                              </span>
                            </div>
                          </div>
                          <div>
                            <div class="m-1">
                              <span
                                  class="pointer"
                                  data-bs-toggle="tooltip"
                                  data-bs-original-title="Deletar avaliação"
                                  title="Deletar avaliação"
                                  data-bs-placement="top"
                                  v-tooltip
                                  @click="deletarAvaliacao(row)"
                              >
                                <i class="fas fa-trash text-secondary me-2"></i>
                                Deletar
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="mx-3 d-flex justify-content-between text-sm">
                    <div :class="row.hasAnamnese ? 'text-info' : 'text-danger'">
                      Anamnese
                    </div>
                    <div :class="row.hasCircumference ? 'text-info' : 'text-danger'">
                      Circunferência
                    </div>
                    <div :class="row.hasBodyComposition ? 'text-info' : 'text-danger'">
                      Composição corporal
                    </div>
                    <div :class="row.hasPostural ? 'text-info' : 'text-danger'">
                      Avaliação postural
                    </div>
                    <div :class="row.hasNeuromotor ? 'text-info' : 'text-danger'">
                      Neuro motor
                    </div>
                    <div :class="row.hasLoadTest ? 'text-info' : 'text-danger'">
                      Teste de carga
                    </div>
                    <div :class="row.hasVoMax ? 'text-info' : 'text-danger'">
                      Vo2 Máx
                    </div>
                  </div>
                  <div class="text-sm mt-3">
                    <span class="mx-3" v-if="row['body_composition']"><span class="font-weight-bold">Altura: </span> {{ row['body_composition']['height'] }}m</span>
                    <span class="mx-3" v-if="row['body_composition']"><span class="font-weight-bold">Peso: </span> {{ row['body_composition']['weight'] }}kg</span>
                    <span class="mx-3" v-if="row['body_composition']"><span class="font-weight-bold">Gordura: </span> {{ row['body_composition']['fat_percentage'] }}%</span>
                    <span class="mx-3" v-if="row['body_composition']"><span class="font-weight-bold">Peso magro: </span> {{ row['body_composition']['skinny_weight'] }}%</span>
                    <span class="mx-3" v-if="row['body_composition']"><span class="font-weight-bold">IMC: </span> {{ row['body_composition']['imc'] }}</span>
                    <span class="mx-3"><span class="font-weight-bold">Vo2: </span> {{ parseFloat(row['voMax']).toFixed(2) }}</span>
                  </div>
                </td>
              </template>
            </MyTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, ref, inject } from 'vue'
import { useStore } from 'vuex'
import avaliacaoFisicaRepository from "../../../../services/api/avaliacaoFisicaRepository";
import MyTable from '../../../../components/MyTable.vue';
import { useRouter } from 'vue-router'
import moment from 'moment'

export default {
  components: {
    MyTable
  },
  setup () {
    const router = useRouter()
    const swal = inject('$swal')
    const store = useStore()
    onMounted(() => {
      store.commit('SET_SPLASH_SCREEN', false)
      studentOn.value = store.getters.StudentManager
      if(studentOn.value) {
        listarAvaliacoes()
      }

    })

    //const hidePassword = ref(true)
    // const passwordFieldIcon = computed(() => hidePassword.value ? ['fa', 'eye'] : ['fa', 'eye-slash']);
    // const passwordFieldType = computed(() => hidePassword.value ? "password" : "text");

    let studentOn= ref(false);
    const columns = ref([
      {
        label: 'Local',
        field: 'assessment_location',
        sortable: ''
      },
      {
        label: 'Data de realização',
        field: 'assessment_date',
        sortable: ''
      },
      /*{
        label: 'Altura',
        field: '',
        sortable: ''
      },
      {
        label: 'Peso',
        field: '',
        sortable: ''
      },
      {
        label: 'Gordura',
        field: '',
        sortable: ''
      },
      {
        label: 'IMC',
        field: '',
        sortable: ''
      },*/
      {
        label: 'Ações',
        field: 'actions'
      }
    ])

    const state = reactive({
      avaliacoes: [],
    })

    const listarAvaliacoes = () => {
      store.commit('SET_SPLASH_SCREEN', true)
      avaliacaoFisicaRepository.AvaliacaoGet().then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        console.log(response)
        state.avaliacoes = []
        if (response.data.success) {
          let training_plan_id = store.getters.TrainingPlanManager
          state.avaliacoes = response.data.data.filter(item => {
            if(parseInt(item.training_plan_id) === training_plan_id) return item
          })
        }
      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        swal({
          icon: 'error',
          title: 'Erro ao listar avaliações!',
          text: error.message
        })
      })
    }

    function formularioAvaliacao(id = 0) {
      parseInt(id) !== 0 ? router.push(`/avaliacao-fisica/formulario/${id}`) : router.push('/avaliacao-fisica/formulario')
    }

    function deletarAvaliacao(item) {
      swal({
        title: "Tem certeza?",
        text: `Deseja deletar a avaliação?`,
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        cancelButtonText: "Não, cancelar!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          deletarAvaliacaoConfirma(item.id)
        }
      })
    }

    function deletarAvaliacaoConfirma(id) {
      store.commit('SET_SPLASH_SCREEN', true)
      avaliacaoFisicaRepository.AvaliacaoDelete(id).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if(response.data.success) {
          swal({
            title: "Removido!",
            text: "Avaliação removida com sucesso!",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          }).then(() => {
            listarAvaliacoes()
          })
        }
      }).catch(error => {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error.message)
        swal({
          icon: 'error',
          title: 'Erro ao deletar avaliação!',
          text: error.message
        })
      })
    }


    return {
      studentOn,
      state,
      columns,
      deletarAvaliacao,
      formularioAvaliacao,
    }
  }
}

</script>
