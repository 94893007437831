import Repository from './repository'

const resource = '/student-physical-assessment'

export default {
  AvaliacaoGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  AvaliacaoGet () {
    return Repository.get(`${resource}`)
  },
  AvaliacaoDelete (id) {
    return Repository.delete(`${resource}/${id}`)
  },
  AvaliacaoPost (item) {
    return Repository.post(`${resource}`, item)
  },
  AvaliacaoPostImage (item) {
    return Repository.post(`${resource}`, item, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  AvaliacaoPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  }
}
