<template>
  <div class=" pt-3 px-3 ">
    <div class="col-12">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" >
          <button class="nav-link active" id="dados-tab" data-bs-toggle="tab" data-bs-target="#dados" type="button"
                  role="tab" aria-controls="dados" aria-selected="true">
            Dados do aluno
          </button>
        </li>
        <li class="nav-item" role="presentation" >
          <button class="nav-link" id="contratos-tab" data-bs-toggle="tab" data-bs-target="#contratos" type="button"
                  role="tab" aria-controls="contratos" aria-selected="true">
            Contratos
          </button>
        </li>

      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade active show" id="dados" role="tabpanel" aria-labelledby="dados-tab">
          <aluno-dados />
        </div>
        <div class="tab-pane fade show" id="contratos" role="tabpanel" aria-labelledby="contratos-tab">
          <aluno-termo-adesao-lista />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { reactive, onMounted, ref, inject, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import moment from "moment";
import AlunoDados from "./AlunoDados.vue";
import AlunoTermoAdesaoLista from "./alunoTermoAdesaoLista.vue";


export default {
  components: {
    AlunoTermoAdesaoLista,
    AlunoDados

  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()

    onMounted( () => {
      if(route.params.id && parseInt(route.params.id) > 0) {
        state.avaliacaoId = parseInt(route.params.id)
      }
      state.assessment_date = moment().format("DD/MM/yyyy")
      state.displayComponent = true
    })


    const state = reactive({
      proccess: false,
      composicaoPrimeira: null,
      composicaoAnterior: null,
      composicaoAtual: null,
      circunferenciaPrimeira: null,
      circunferenciaAnterior: null,
      circunferenciaAtual: null,
      avaliacaoId: 0,
      anamnese: null,
      assessment_date: moment().format("DD/MM/yyyy"),
      assessment_location: '',
    })






    return {
      state
    }
  }
}

</script>