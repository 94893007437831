import moment from 'moment'
export default {
    datePtbr (value) {
        if (value === null || value === '') return ''
        return value.slice(0, 10).split('-').reverse().join('/')
    },
    currencyBr (value, cifrao = true) {
        if(cifrao) {
            return value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        }
        return value.toLocaleString('pt-br', {minimumFractionDigits: 2});
    },
    justNumbers(value) {
        return value.replace(/\D/g, "");
    },
    fullAddressFormat(obj = null) {
        if (obj == null) return ''
        return `<span class="font-weight-bold">CEP: </span>${obj.cep}<br>`
                +`<span class="font-weight-bold">Endereço: </span>${obj.street}, ${obj.number} - ${obj.neighborhood}, ${obj.city}/${obj.state}<br>`
    },
    trainingDays(obj = null) {
        if (obj == null) return ''
        let days = ''
        if (parseInt(obj.sunday)) days += ' Domingo,'
        if (parseInt(obj.monday)) days += ' Segunda,'
        if (parseInt(obj.tuesday)) days += ' Terça,'
        if (parseInt(obj.wednesday)) days += ' Quarta,'
        if (parseInt(obj.thursday)) days += ' Quinta,'
        if (parseInt(obj.friday)) days += ' Sexta,'
        if (parseInt(obj.saturday)) days += ' Sábado,'
        return days.slice(0, -1)
    },
    countDayAndWeek(initDate, finDate, type = '') {
        let date1 = moment(initDate.split('/').reverse().join('-'))
        let date2 = moment(finDate.split('/').reverse().join('-'))
        let numberWeeks = date2.diff(date1, 'weeks')
        let numberDays = date2.diff(date1, 'days')
        if (type = 'D') return numberDays
        if (type = 'W') return numberWeeks
        return `${numberWeeks} semanas - ${numberDays} dias`
    },
    nameDayPortuguese(day) {
        day = day.toLowerCase()
        if (day == 'sunday') return 'Domingo'
        if (day == 'monday') return 'Segunda'
        if (day == 'tuesday') return 'Terça'
        if (day == 'wednesday') return 'Quarta'
        if (day == 'thursday') return 'Quinta'
        if (day == 'friday') return 'Sexta'
        if (day == 'saturday') return 'Sábado'
    },
    diffTimeMinutesSeconds(time1, time2) {
        if(time1 == 0 || time2 == 0) return 0
        // Definir os horários de início e fim (apenas minutos e segundos)
        var inicio = moment(time1, "mm:ss");
        var fim = moment(time2, "mm:ss");

        // Calcular a diferença em segundos
        var diferencaEmSegundos = fim.diff(inicio, 'seconds') + 60

        // Calcular os minutos e segundos separadamente
        var minutos = Math.floor(diferencaEmSegundos / 60)
        var segundos = diferencaEmSegundos % 60
    },
    converteCentavosValor(valor) {
        if(valor == null || parseInt(valor) === 0) return ''
        const novoValor = (parseInt(valor) / 100).toFixed(2)
        return `R$ ${novoValor}`
    }
}