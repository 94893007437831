import Repository from './repository'

const resource = '/modalities'

export default {
  ModalidadeGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  ModalidadeGet () {
    return Repository.get(`${resource}`)
  },
  ModalidadePost (item) {
    return Repository.post(`${resource}`, item)
  },
  ModalidadePut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  ModalidadeDelete (planoId) {
    return Repository.delete(`${resource}/${planoId}`)
  }
}
