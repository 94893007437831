<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Planos de treino</h5>
                <p class="mb-0 text-sm">
                  Listagem de planos de treino
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <button type="button" class="mb-0 btn bg-gradient-success btn-sm" @click="formularioPlanoTreino()">
                    +&nbsp; Novo plano de treino
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-if="!studentOn">
              <span class="mt-5 text-center">
                <h5>Selecione um aluno na parte superior do sistema</h5>
              </span>
            </div>
            <MyTable
              v-else
              :data="state.planos"
              :columns="columns"
              :itemsPerPage="12">
              <template v-slot:row="{ row }">
                <td class="text-sm pointer" @click="formularioPlanoTreino(row.id)">
                  {{ row.name }} <br/> {{ row.cycle_name }}
                </td>
                <td class="text-sm  text-center">
                  {{ row.objective }}
                </td>
                <td class="text-sm  text-center">
                  {{ $filters.datePtbr(row.initial_date) }} <br/> à <br/> {{ $filters.datePtbr(row.final_date) }} <br/>
                  {{ dayAndWeek(row.initial_date, row.final_date) }}
                </td>
                <td class="text-sm ">
                  <div class="float-end d-flex">
                    <div class="me-3">
                      <div class="m-1">
                        <span
                          class="pointer"
                          data-bs-toggle="tooltip"
                          data-bs-original-title="Editar plano de treino"
                          title="Editar plano de treino"
                          data-bs-placement="top"
                          v-tooltip
                          @click="formularioPlanoTreino(row.id)"
                        >
                          <i class="fas fa-pen text-secondary me-2"></i>
                          Editar
                        </span>
                      </div>
<!--                      <div class="m-1">
                        <span
                          class="pointer"
                          data-bs-toggle="tooltip"
                          data-bs-original-title="Deletar exercicio"
                          title="Editar exercicio"
                          data-bs-placement="top"
                          v-tooltip
                        >
                          <i class="fas fa-calendar-check text-secondary me-2"></i>
                          Periodização
                        </span>
                      </div>-->
                    </div>
                    <div>
                      <div class="m-1">
                        <span
                          class="pointer"
                          @click="deletarPlanoTreino(row)"
                        >
                          <i class="fas fa-trash text-secondary me-2"></i>
                          Deletar
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </template>
            </MyTable>
          </div>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
  import { reactive, onMounted, ref, inject } from 'vue'
  import { useStore } from 'vuex'
  import planosTreinoRepository from "../../../../services/api/planosTreinoRepository";
  import MyTable from '../../../../components/MyTable.vue';
  import { useRouter } from 'vue-router'
  import moment from 'moment'

  export default {
    components: {
      MyTable
    },
    setup () {
      const router = useRouter()
      const swal = inject('$swal')
      const store = useStore()
      onMounted(() => {
        store.commit('SET_SPLASH_SCREEN', false)
        studentOn.value = store.getters.StudentManager
        if(studentOn.value) {
          listarPlanosTreino()
        }
      })

      //const hidePassword = ref(true)
      // const passwordFieldIcon = computed(() => hidePassword.value ? ['fa', 'eye'] : ['fa', 'eye-slash']);
      // const passwordFieldType = computed(() => hidePassword.value ? "password" : "text");

      let studentOn= ref(false);
      const columns = ref([
        {
          label: 'Nome',
          field: 'name',
          sortable: 'name'
        },
        {
          label: 'Objetivo',
          field: 'objective',
          sortable: ''
        },
        {
          label: 'Período',
          field: 'period',
          sortable: ''
        },
        /*{
          label: 'Dias de treino',
          field: 'created_at'
        },*/
        // {
        //   label: 'Data de cadastro',
        //   field: 'created_at'
        // },
        {
          label: 'Ações',
          field: 'actions'
        }
      ])

      const state = reactive({
        planos: [],
      })

      const listarPlanosTreino = () => {
        store.commit('SET_SPLASH_SCREEN', true)
        planosTreinoRepository.PlanosTreinoGet().then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          state.planos = []
          if (response.data.success) {
            state.planos = response.data.data
          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao listar planos de treino!',
            text: error.message
          })
        })
      }

      function formularioPlanoTreino(id = 0) {
        parseInt(id) !== 0 ? router.push(`/planos-de-treino/cadastro/${id}`) : router.push('/planos-de-treino/cadastro')
      }

      function deletarPlanoTreino(item) {
        swal({
          title: "Tem certeza?",
          text: `Deseja deletar ${item.name}`,
          showCancelButton: true,
          confirmButtonText: "Sim, quero deletar!",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            deletarPlanoTreinoConfirma(item.id)
          }
        })
      }

      function deletarPlanoTreinoConfirma(id) {
        store.commit('SET_SPLASH_SCREEN', true)
        planosTreinoRepository.PlanosTreinoDelete(id).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if(response.data.success) {
            swal({
              title: "Removido!",
              text: "Plano de treino removido com sucesso!",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            }).then(() => {
              listarPlanosTreino()
            })
          }
        }).catch(error => {
          store.commit('SET_SPLASH_SCREEN', false)
          console.error(error.message)
          swal({
            icon: 'error',
            title: 'Erro ao deletar plano de treino!',
            text: error.message
          })
        })
      }

      function dayAndWeek(initial_date, final_date) {
        let date1 = moment(initial_date)
        let date2 = moment(final_date)
        let numberWeeks = date2.diff(date1, 'weeks')
        let numberDays = date2.diff(date1, 'days')
        return `${numberWeeks} semanas - ${numberDays} dias`
      }

      return {
        state,
        columns,
        deletarPlanoTreino,
        formularioPlanoTreino,
        studentOn,
        dayAndWeek
      }
    }
  }
  
</script>
