<template>
  <div class="row">
      <div class="col-12 table-responsive">
          <table class="table border text-center text-sm table-sm">
              <thead>
              <tr class="font-weight-bold">
                  <td colspan="6">Resultado</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                  <td class="text-start w-25">Peso corporal total</td>
                  <td class="text-start w-15">{{ state.composicaoAtual.weight }}</td>
                  <td class="w-15">{{ state.composicaoPrimeira.weight }}</td>
                  <td class="w-15">{{ state.composicaoAnterior.weight }}</td>
                  <td class="w-15">{{ state.composicaoAtual.weight - state.composicaoAnterior.weight }}</td>
                  <td class="w-15">{{ state.composicaoAtual.weight - state.composicaoPrimeira.weight }}</td>
              </tr>
              <tr>
                  <td class="text-start">% de Gord. Corp.</td>
                  <td class="text-start">{{ parseFloat(state.composicaoAtual.fat_percentage) }}</td>
                  <td>{{ parseFloat(state.composicaoPrimeira.fat_percentage) }}</td>
                  <td>{{ parseFloat(state.composicaoAnterior.fat_percentage).toFixed(2) }}
                  </td>
                  <td>
                      {{
                        (parseFloat(state.composicaoAtual.fat_percentage) - parseFloat(state.composicaoAnterior.fat_percentage)).toFixed(2)
                      }}
                  </td>
                  <td>
                      {{
                        (parseFloat(state.composicaoAtual.fat_percentage) - parseFloat(state.composicaoPrimeira.fat_percentage)).toFixed(2)
                      }}
                  </td>
              </tr>
              <tr>
                  <td class="text-start">Peso de Gordura</td>
                  <td class="text-start">
                      {{ state.composicaoAtual.fat_weight }}
                  </td>
                  <td>
                      {{ state.composicaoPrimeira.fat_weight }}
                  </td>
                  <td>
                      {{ state.composicaoAnterior.fat_weight }}
                  </td>
                  <td>
                      {{ (parseFloat(state.composicaoAtual.fat_weight) - parseFloat(state.composicaoAnterior.fat_weight)).toFixed(2) }}
                  </td>
                  <td>
                      {{ (parseFloat(state.composicaoAtual.fat_weight) - parseFloat(state.composicaoPrimeira.fat_weight)).toFixed(2) }}
                  </td>
              </tr>
              <tr>
                  <td class="text-start">Peso Magro</td>
                  <td class="text-start">
                      {{ (state.composicaoAtual.weight - pesoGordura(state.composicaoAtual, somaAtual)).toFixed(2) }}
                  </td>
                  <td>
                      {{ (state.composicaoPrimeira.weight - pesoGordura(state.composicaoPrimeira, somaPrimeiro)).toFixed(2) }}
                  </td>
                  <td>
                      {{ (state.composicaoAnterior.weight - pesoGordura(state.composicaoAnterior, somaAnterior)).toFixed(2) }}
                  </td>
                  <td>
                      {{
                          ((state.composicaoAtual.weight - pesoGordura(state.composicaoAtual, somaAtual)) -
                          (state.composicaoAnterior.weight - pesoGordura(state.composicaoAnterior, somaAnterior) )).toFixed(2)
                      }}
                  </td>
                  <td>
                      {{
                      state.composicaoPrimeira.weight > 0 && pesoGordura(state.composicaoPrimeira, somaAnterior) > 0 ?
                          ((state.composicaoAtual.weight - pesoGordura(state.composicaoAtual, somaAtual)) -
                          (state.composicaoPrimeira.weight - pesoGordura(state.composicaoPrimeira, somaAnterior) )).toFixed(2) : 0
                      }}
                  </td>
              </tr>
              <tr>
                  <td class="text-start">Taxa Met. Basal</td>
                  <td class="text-start">
                      {{ composicaoCorporal.basalMetabolicRate(state.composicaoAtual.weight, state.composicaoAtual.height, state.composicaoAtual.age, 'M') }}
                  </td>
                  <td>
                      {{ composicaoCorporal.basalMetabolicRate(state.composicaoPrimeira.weight, state.composicaoPrimeira.height, state.composicaoPrimeira.age, 'M') }}
                  </td>
                  <td>
                      {{ composicaoCorporal.basalMetabolicRate(state.composicaoAnterior.weight, state.composicaoAnterior.height, state.composicaoAnterior.age, 'M') }}
                  </td>
                  <td>
                      {{
                          (composicaoCorporal.basalMetabolicRate(state.composicaoAtual.weight, state.composicaoAtual.height, state.composicaoAtual.age, 'M') -
                          composicaoCorporal.basalMetabolicRate(state.composicaoAnterior.weight, state.composicaoAnterior.height, state.composicaoAnterior.age, 'M')).toFixed(2)
                      }}
                  </td>
                  <td>
                      {{
                          (composicaoCorporal.basalMetabolicRate(state.composicaoAtual.weight, state.composicaoAtual.height, state.composicaoAtual.age, 'M') -
                          composicaoCorporal.basalMetabolicRate(state.composicaoPrimeira.weight, state.composicaoPrimeira.height, state.composicaoPrimeira.age, 'M')).toFixed(2)
                      }}
                  </td>
              </tr>
              <tr>
                  <td class="text-start">Taxa Met. MM</td>
                  <td class="text-start">
                      {{
                          composicaoCorporal.mmMetabolicRate(
                              state.composicaoAtual.weight - pesoGordura(state.composicaoAtual, somaAtual),
                              state.composicaoAtual.height,
                              state.composicaoAtual.age,
                              'M'
                          )
                      }}
                  </td>
                  <td>
                      {{
                          composicaoCorporal.mmMetabolicRate(
                              state.composicaoPrimeira.weight - pesoGordura(state.composicaoPrimeira, somaPrimeiro),
                              state.composicaoPrimeira.height,
                              state.composicaoPrimeira.age,
                              'M'
                          )
                      }}
                  </td>
                  <td>
                      {{
                          composicaoCorporal.mmMetabolicRate(
                              state.composicaoAnterior.weight - pesoGordura(state.composicaoAnterior, somaAnterior),
                              state.composicaoAnterior.height,
                              state.composicaoAnterior.age,
                              'M'
                          )
                      }}
                  </td>
                  <td>
                      {{
                          (composicaoCorporal.mmMetabolicRate(
                              state.composicaoAtual.weight - pesoGordura(state.composicaoAtual, somaAtual),
                              state.composicaoAtual.height,
                              state.composicaoAtual.age,
                              'M'
                          ) -
                          composicaoCorporal.mmMetabolicRate(
                              state.composicaoAnterior.weight - pesoGordura(state.composicaoAnterior, somaAnterior),
                              state.composicaoAnterior.height,
                              state.composicaoAnterior.age,
                              'M'
                          )).toFixed(2)
                      }}
                  </td>
                  <td>
                      {{
                          (composicaoCorporal.mmMetabolicRate(
                              state.composicaoAtual.weight - pesoGordura(state.composicaoAtual, somaAtual),
                              state.composicaoAtual.height,
                              state.composicaoAtual.age,
                              'M'
                          ) -
                          composicaoCorporal.mmMetabolicRate(
                              state.composicaoPrimeira.weight - pesoGordura(state.composicaoPrimeira, somaPrimeiro),
                              state.composicaoPrimeira.height,
                              state.composicaoPrimeira.age,
                              'M'
                          )).toFixed(2)
                      }}
                  </td>
              </tr>
              <tr>
                  <td class="text-start">IMC</td>
                  <td class="text-start">
                      {{ composicaoCorporal.imc(state.composicaoAtual.weight, state.composicaoAtual.height) }}
                  </td>
                  <td>{{ composicaoCorporal.imc(state.composicaoPrimeira.weight, state.composicaoPrimeira.height) }}</td>
                  <td>{{ composicaoCorporal.imc(state.composicaoAnterior.weight, state.composicaoAnterior.height) }}</td>
                  <td>
                      {{
                          (composicaoCorporal.imc(state.composicaoAtual.weight, state.composicaoAtual.height) -
                          composicaoCorporal.imc(state.composicaoAnterior.weight, state.composicaoAnterior.height)).toFixed(2)
                      }}
                  </td>
                  <td>
                      {{
                          (composicaoCorporal.imc(state.composicaoAtual.weight, state.composicaoAtual.height) -
                          composicaoCorporal.imc(state.composicaoPrimeira.weight, state.composicaoPrimeira.height)).toFixed(2)
                      }}
                  </td>
              </tr>
              <tr>
                  <td class="text-start">Risco Cardíaco</td>
                  <td class="text-start"></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
              </tr>
              </tbody>
          </table>
      </div>
  </div>
</template>

<script>
import {reactive, onMounted, ref, inject, computed, watch} from 'vue'
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import ComposiaoCorporalModel from "@/models/composicaoCorporalModel";

//import {fatWeight, imc, mmMetabolicRate, percentageBodyFatPollock} from "@/services/composicaoCorporal.service";
//import * as composicaoCorporal from "../../../../../services/composicaoCorporal.service";
//import * as composicaoCorporal from "../../../../../services/composicaoCorporal.service";
import alunoRepository from "@/services/api/alunoRepository";
import * as composicaoCorporal from "../../../../../services/composicaoCorporal.service";
export default {
    name: "ResultadosComponent",
  computed: {
    composicaoCorporal() {
      return composicaoCorporal
    }
  },

    props: {
        medidaAtualProp: {
            type: Object,
            default: null
        },
        medidaAnteriorProp: {
            type: Object,
            default: null
        },
        medidaPrimeiraProp: {
            type: Object,
            default: null
        },
      calculaMedidasProp: {
          type: Boolean,
          default: true
      }
    },
    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const swal = inject('$swal')
        const store = useStore()

        onMounted( () => {
          state.composicaoAtual = props.medidaAtualProp ? props.medidaAtualProp : new ComposiaoCorporalModel()
          state.composicaoAnterior = props.medidaAnteriorProp ? props.medidaAnteriorProp : new ComposiaoCorporalModel()
          state.composicaoPrimeira = props.medidaPrimeiraProp ? props.medidaPrimeiraProp : new ComposiaoCorporalModel()
          buscaDadosAluno()

        })

      async function buscaDadosAluno() {
        alunoRepository.AlunoGetCurrent().then(response => {
          if(response.data.success) {
            state.aluno = response.data.data

            // quando ja tiver registro de idade nao faz calculo novo de idade
            if(state.composicaoAtual.age == 0) {
              state.composicaoAtual.age = state.aluno.age
            }

          }
        }).catch (error => {
          store.commit('SET_SPLASH_SCREEN', false)
          console.error(error)
        }).finally(() => {
          store.commit('SET_SPLASH_SCREEN', false)
        })
      }


      const state = reactive({
          composicaoAtual: new ComposiaoCorporalModel(),
          composicaoAnterior: new ComposiaoCorporalModel(),
          composicaoPrimeira: new ComposiaoCorporalModel(),
          dadosSalvos: false,
          aluno: null,
          bodyFat: 0
      })

      const somaAtual = computed (() => {
        const response = parseInt(state.composicaoAtual.sub_scapular) +
              parseInt(state.composicaoAtual.triceps) +
              parseInt(state.composicaoAtual.biceps) +
              parseInt(state.composicaoAtual.chest) +
              parseInt(state.composicaoAtual.mid_axillary) +
              parseInt(state.composicaoAtual.suprailiac) +
              parseInt(state.composicaoAtual.abdomen) +
              parseInt(state.composicaoAtual.thigh) +
              parseInt(state.composicaoAtual.leg)


        return isNaN(response) ? 0 : response
      })

      const somaPrimeiro = computed (() => {
        const response = parseInt(state.composicaoPrimeira.sub_scapular) +
              parseInt(state.composicaoPrimeira.triceps) +
              parseInt(state.composicaoPrimeira.biceps) +
              parseInt(state.composicaoPrimeira.chest) +
              parseInt(state.composicaoPrimeira.mid_axillary) +
              parseInt(state.composicaoPrimeira.suprailiac) +
              parseInt(state.composicaoPrimeira.abdomen) +
              parseInt(state.composicaoPrimeira.thigh) +
              parseInt(state.composicaoPrimeira.leg)
        return isNaN(response) ? 0 : response
      })

      const somaAnterior = computed (() => {
        const response = parseInt(state.composicaoAnterior.sub_scapular) +
              parseInt(state.composicaoAnterior.triceps) +
              parseInt(state.composicaoAnterior.biceps) +
              parseInt(state.composicaoAnterior.chest) +
              parseInt(state.composicaoAnterior.mid_axillary) +
              parseInt(state.composicaoAnterior.suprailiac) +
              parseInt(state.composicaoAnterior.abdomen) +
              parseInt(state.composicaoAnterior.thigh) +
              parseInt(state.composicaoAnterior.leg)
        return isNaN(response) ? 0 : response
      })

      function pesoGordura(item, soma) {
          if(parseFloat(state.composicaoAtual.fat_percentage) > 0) {
            return (item.weight * parseFloat(state.composicaoAtual.fat_percentage) / 100).toFixed(2)
          }
        const bodyFat = composicaoCorporal.percentageBodyFatPollock(item.age, soma)
        return ((item.weight * bodyFat) / 100).toFixed(2)
        //state.composicaoAtual.fat_weight
      }

      function pesoGorduraAtual() {

        //return ((item.weight * composicaoCorporal.percentageBodyFatPollock(item.age, soma)) / 100).toFixed(2)
        const weight = parseFloat(state.composicaoAtual.weight)
        const pollock = parseFloat(state.composicaoAtual.fat_percentage)


        let fatWeight = ((weight * pollock) / 100).toFixed(2)

        state.composicaoAtual.fat_weight = fatWeight

      }

      /*watch(() => props.calculaMedidasProp, (newValue, oldValue) => {
        console.log('watch props.calculaMedidasProp')
        console.log(props.calculaMedidasProp)
        pesoGorduraAtual()
        //fatPercentageAtual()
      })*/

      watch(props, (newValue, oldValue) => {

        pesoGorduraAtual()
        //fatPercentageAtual()
      })

      function fatPercentageAtual() {
        state.composicaoAtual.fat_percentage = composicaoCorporal.percentageBodyFatPollock(state.composicaoAtual.age, somaAtual.value)

      }

        return {
            state,
            somaAtual,
            somaPrimeiro,
            somaAnterior,
            pesoGordura
        }
    }
}
</script>

<style scoped>

</style>