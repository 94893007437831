import Repository from './repository'

const resource = '/student-vo-max'

export default {
  VoMaxGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  VoMaxGetByAssessmentId (id) {
    return Repository.get(`${resource}/assessment/${id}`)
  },
  VoMaxGet () {
    return Repository.get(`${resource}`)
  },
  PrimeiroVoMaxGet () {
    return Repository.get(`${resource}/first`)
  },
  UltimoVoMaxGet () {
    return Repository.get(`${resource}/latest`)
  },
  VoMaxPost (item) {
    return Repository.post(`${resource}`, item)
  },
  VoMaxPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  VoMaxDelete (id) {
    return Repository.delete(`${resource}/${id}`)
  }
}
