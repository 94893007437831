import Repository from './repository'

const resource = '/neuro-motor'

export default {
  NeuroMotorGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  NeuroMotorGetByAssessmentId (id) {
    return Repository.get(`${resource}/assessment/${id}`)
  },
  NeuroMotorGet () {
    return Repository.get(`${resource}`)
  },
  PrimeiroNeuroMotorGet () {
    return Repository.get(`${resource}/first`)
  },
  UltimoNeuroMotorGet () {
    return Repository.get(`${resource}/latest`)
  },
  NeuroMotorPost (item) {
    return Repository.post(`${resource}`, item)
  },
  NeuroMotorPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  NeuroMotorDelete (id) {
    return Repository.delete(`${resource}/${id}`)
  }
}
