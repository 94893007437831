import Repository from './repository'

const resource = '/training-plans'

export default {
  PlanosTreinoGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  PlanosTreinoGet () {
    return Repository.get(`${resource}`)
  },
  PlanosTreinoPost (item) {
    return Repository.post(`${resource}`, item)
  },
  PlanosTreinoPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  PlanosTreinoDelete (planoId) {
    return Repository.delete(`${resource}/${planoId}`)
  },
  PlanoTreinoAlunoDelete(planoId, alunoId) {
    return Repository.delete(`${resource}/${planoId}/${alunoId}`)
  },
  SemanasGetByPlanosTreinoId (id) {
    return Repository.get(`training-plan-week/plan/${id}`)
  },
  SemanasDiasGet () {
    return Repository.get(`training-plan-week/with-days`)
  },
  PlanoTreinoElaboradoPost (item) {
    return Repository.post('training-plan-mount', item)
  },
  PlanoTreinoElaboradoBySemanaIdGet (id) {
    return Repository.get(`training-plan-mount/week/${id}`)
  },
  PlanoTreinoAtualizarExerciciosDiaPost (item) {
    return Repository.post('training-plan-mount/day-mounted-update', item)
  },
  PlanoTreinoExerciciosDiaDelete (id) {
    return Repository.delete(`training-plan-mount/week-exercise/${id}`)
  },
  SemanasPost (item) {
    return Repository.post('training-plan-week', item)
  },
  SemanaDelete (id) {
    return Repository.delete(`training-plan-week/${id}`)
  },
  SemanaDiaDelete (ids) {
    return Repository.post(`training-plan-week/delete-day`, ids)
  },
  DiasDeTreinoGet (dataInicio, dataFim) {
    return Repository.get(`training-plan-days/${dataInicio}/${dataFim}`)
  },
  DiasDeTreinoPlanoAtualGet () {
    return Repository.get(`training-plan-days/current-plan`)
  },
  TreinoPorDiaGet (data) {
    return Repository.get(`training-plan-mount/training-by-date/${data}`)
  },
  SalvarCardioPost (data) {
    return Repository.post(`training-plan-mount/set-cardio`, data)
  },
  PlanoTreinoAtualizarExerciciosPorId (id, data) {
    return Repository.put(`training-plan-mount/exercise/${id}`, data)
  },
  ExerciciosPorGrupoIdGet (id) {
    return Repository.get(`training-plan-mount/by-group/${id}`)
  },
  PlanoTreinoExercicioGrupoDelete (id) {
    return Repository.delete(`training-plan-mount/group/${id}`)
  },
  PlanoTreinoExercicioByGrupoIdDelete (id) {
    return Repository.delete(`training-plan-mount/by-group-id/${id}`)
  },
  AdicionarExerciciosGrupoPost (item) {
    return Repository.post('training-plan-mount/group-exercises', item)
  },
  AdicionarGruposDiasPost (item) {
    return Repository.post('training-plan-mount/set-groups-days', item)
  }
}
