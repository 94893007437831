
export function voMaxClassificacao (sexo, idade, valor) {
  if(idade >= 20 && idade <= 29 ) {
    if(sexo == 'M') {
      if(valor < 24) return 'Muito fraco'
      if(valor >= 24 && valor <= 30) return 'Fraco'
      if(valor >= 31 && valor <= 37) return 'Regular'
      if(valor >= 38 && valor <= 48) return 'Boa'
      if(valor >= 49) return 'Excelente'
    } else {
      if(valor < 25) return 'Muito fraco'
      if(valor >= 25 && valor <= 33) return 'Fraco'
      if(valor >= 34 && valor <= 42) return 'Regular'
      if(valor >= 43 && valor <= 52) return 'Boa'
      if(valor >= 53) return 'Excelente'
    }
  }

  if(idade >= 30 && idade <= 39 ) {
    if(sexo == 'M') {
      if(valor < 39) return 'Muito fraco'
      if(valor >= 20 && valor <= 27) return 'Fraco'
      if(valor >= 28 && valor <= 33) return 'Regular'
      if(valor >= 34 && valor <= 44) return 'Boa'
      if(valor >= 45) return 'Excelente'
    } else {
      if(valor < 23) return 'Muito fraco'
      if(valor >= 23 && valor <= 30) return 'Fraco'
      if(valor >= 31 && valor <= 38) return 'Regular'
      if(valor >= 39 && valor <= 48) return 'Boa'
      if(valor >= 49) return 'Excelente'
    }
  }

  if(idade >= 40 && idade <= 49 ) {
    if(sexo == 'M') {
      if(valor < 17) return 'Muito fraco'
      if(valor >= 17 && valor <= 23) return 'Fraco'
      if(valor >= 24 && valor <= 30) return 'Regular'
      if(valor >= 31 && valor <= 41) return 'Boa'
      if(valor >= 42) return 'Excelente'
    } else {
      if(valor < 20) return 'Muito fraco'
      if(valor >= 20 && valor <= 26) return 'Fraco'
      if(valor >= 27 && valor <= 35) return 'Regular'
      if(valor >= 36 && valor <= 44) return 'Boa'
      if(valor >= 45) return 'Excelente'
    }
  }

  if(idade >= 50 && idade <= 59 ) {
    if(sexo == 'M') {
      if(valor < 15) return 'Muito fraco'
      if(valor >= 15 && valor <= 20) return 'Fraco'
      if(valor >= 21 && valor <= 27) return 'Regular'
      if(valor >= 28 && valor <= 37) return 'Boa'
      if(valor >= 38) return 'Excelente'
    } else {
      if(valor < 18) return 'Muito fraco'
      if(valor >= 18 && valor <= 24) return 'Fraco'
      if(valor >= 25 && valor <= 33) return 'Regular'
      if(valor >= 34 && valor <= 42) return 'Boa'
      if(valor >= 43) return 'Excelente'
    }
  }

  if(idade >= 60 && idade <= 69 ) {
    if(sexo == 'M') {
      if(valor < 13) return 'Muito fraco'
      if(valor >= 13 && valor <= 17) return 'Fraco'
      if(valor >= 18 && valor <= 23) return 'Regular'
      if(valor >= 24 && valor <= 34) return 'Boa'
      if(valor >= 35) return 'Excelente'
    } else {
      if(valor < 16) return 'Muito fraco'
      if(valor >= 16 && valor <= 22) return 'Fraco'
      if(valor >= 23 && valor <= 30) return 'Regular'
      if(valor >= 31 && valor <= 40) return 'Boa'
      if(valor >= 41) return 'Excelente'
    }
  }
}
