import axios from 'axios'
import store from './../../store'

const baseURL = 'https://apidiariodetreino2.agenciaportes.com.br/api/'
// const baseURL = 'http://localhost:9000/api/'

let axiosApi = axios.create({
  baseURL
}, 5000)
axiosApi.interceptors.request.use(
  (config) => {
    let token = store.getters.ApiToken
    //config.headers['Cache-Control'] = 'no-cache'
    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`
    }

      let studentId = store.getters.StudentManager
      if (studentId) {
          config.headers['student-manager'] = btoa(studentId)
      }

      let coachId = store.getters.CoachManager
      if (coachId) {
          config.headers['coach-manager'] = btoa(coachId)
      }

      let trainingPlanId = store.getters.TrainingPlanManager
      if (trainingPlanId) {
          config.headers['training-plan-manager'] = btoa(trainingPlanId)
      }

    return config
  },

  (error) => {
    store.commit('SET_SPLASH_SCREEN', false)
    return Promise.reject(error)
  }
)

export default axiosApi

