<template>
  <div class="card border-top-start-radius-0 border-top-end-radius-0">
    <div class="card-body">
      <div class="row mb-4 text-center" >
        <div class="col-12 col-md-3">
          <h4>
            Lateral Direita
          </h4>
          <div style="height: 300px;" class="border d-flex justify-content-around align-items-center" @click="$refs.fileInputDireita.click()">
            <input type="file" ref="fileInputDireita" @change="uploadImage($event, 'direita')" class="inputfile" />
            <img style="max-height: 300px;" class="img-fluid w-100" v-if="state.images.direita.url" :src="state.images.direita.url" alt="foto do lado direito do aluno">
            <span class="small" v-else>Clique para <br>selecionar a foto</span>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <h4>
            Lateral Esquerda
          </h4>
          <div style="height: 300px;" class="border d-flex justify-content-around align-items-center" @click="$refs.fileInputEsquerda.click()">
            <input type="file" ref="fileInputEsquerda" @change="uploadImage($event, 'esquerda')" class="inputfile" />
            <img style="max-height: 300px;" class="img-fluid w-100" v-if="state.images.esquerda.url" :src="state.images.esquerda.url" alt="foto do lado direito do aluno">
            <span class="small" v-else>Clique para <br>selecionar a foto</span>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <h4>
            Posterior
          </h4>
          <div style="height: 300px;" class="border d-flex justify-content-around align-items-center" @click="$refs.fileInputPosterior.click()">
            <input type="file" ref="fileInputPosterior" @change="uploadImage($event, 'posterior')" class="inputfile" />
            <img style="max-height: 300px;" class="img-fluid w-100" v-if="state.images.posterior.url" :src="state.images.posterior.url" alt="foto do lado direito do aluno">
            <span class="small" v-else>Clique para <br>selecionar a foto</span>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <h4>
            Anterior
          </h4>
          <div style="height: 300px;" class="border d-flex justify-content-around align-items-center" @click="$refs.fileInputAnterior.click()">
            <input type="file" ref="fileInputAnterior" @change="uploadImage($event, 'anterior')" class="inputfile" />
            <img style="max-height: 300px;" class="img-fluid w-100" v-if="state.images.anterior.url" :src="state.images.anterior.url" alt="foto do lado direito do aluno">
            <span class="small" v-else>Clique para <br>selecionar a foto</span>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Visão Lateral-->
        <div class="col-12 col-md-4">
          <div class="row">
            <div class="col-12 font-weight-bold">
              Visão Lateral
            </div>
            <div class="col-12">
              <div
                  class="col form-check pointer"
                  v-for="(option, chave) in state.options['lateral']"
                  :key="chave"
              >
                <input
                    @change="selecionaItem($event, chave)"
                    class="mt-1 form-check-input"
                    type="checkbox"
                    value="1"
                    v-model="state.checkboxValues[chave]"
                />
                <span class="ms-1">
                  {{ option }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Visão Posterior -->
        <div class="col-12 col-md-4">
          <div class="row">
            <div class="col-12 font-weight-bold">
              Visão Posterior
            </div>
            <div class="col-12">
              <div
                  class="col form-check pointer"
                  v-for="(option, chave) in state.options['posterior']"
                  :key="chave"
              >
                <input
                    @change="selecionaItem($event, chave)"
                    class="mt-1 form-check-input"
                    type="checkbox"
                    value="1"
                    v-model="state.checkboxValues[chave]"
                />
                <span class="ms-1">
                  {{ option }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Visão Anterior -->
        <div class="col-12 col-md-4">
          <div class="row">
            <div class="col-12 font-weight-bold">
              Visão Anterior
            </div>
            <div class="col-12">
              <div
                  class="col form-check pointer"
                  v-for="(option, chave) in state.options['anterior']"
                  :key="chave"
              >
                <input
                    @change="selecionaItem($event, chave)"
                    class="mt-1 form-check-input"
                    type="checkbox"
                    value="1"
                    v-model="state.checkboxValues[chave]"
                />
                <span class="ms-1">
                  {{ option }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-end mt-3">
          <router-link to="/avaliacao-fisica">
            <button type="button" class="btn bg-gradient-secondary">
              Voltar
            </button>
          </router-link>
          <button type="button" class="ms-3 btn bg-gradient-primary" @click.prevent="salvar">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {reactive, onMounted, ref, inject, computed, watch} from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { Money3Component } from 'v-money3'
import avaliacaoPosturalRepository from "@/services/api/avaliacaoPosturalRepository";

export default {
  components: {
    money3: Money3Component
  },
  props: {
    avaliacaoIdProp: {
      type: Number,
      default: 0
    },
  },
  setup(props, context) {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()

    onMounted( () => {
      state.avaliacaoId = props.avaliacaoIdProp
      buscarDados()

    })


    const state = reactive({
      id: 0,
      avaliacaoId: 0,
      checkboxValues: {},
      images: {
        esquerda: {
          url: null,
          file: null
        },
        direita: {
          url: null,
          file: null
        },
        anterior: {
          url: null,
          file: null
        },
        posterior: {
          url: null,
          file: null
        }
      },
      options: {
        'lateral': {
          'hip_anthropoversion': 'Antroversão de Quadril',
          'hip_retroversion': 'Retroversão de Quadril',
          'shoulder_retroversion': 'Retroversão Interna dos Ombros',
          'cervical_straightening': 'Retificação Cervical',
          'lumbar_straightening': 'Retificação Lombar',
          'abdominal_protrusion': 'Protusão Abdominal',
          'cervical_hyperlordosis': 'Hiper Lordose Cervical',
          'lumbar_hyperlordosis': 'Hiper Lordose Lombar',
          'thoracic_hyperlordosis': 'Hiper Lordose Torácica',
          'flat_foot': 'Pé - Plano',
          'cavus_foot': 'Pé - Cavo',
          'heel_foot': 'Pé - Calcâneo',
          'equine_foot': 'Pé – Esquino',
          'knee_flexion': 'Genu - Flexo',
          'knee_bent': 'Genu - Recurvado'
        },
        'posterior': {
          'cervical_scoliosis': 'Escoliose Cervical',
          'thoracic_scoliosis': 'Escoliose Torácica',
          'lumbar_scoliosis': 'Escoliose Lombar',
          'scapular_protrusion': 'Protusão Escapular',
          'scapular_retraction': 'Retração Escapular',
          'scapular_depression': 'Depressão Escapular',
          'valgus_foot': 'Pé - Valgo',
          'varus_foot': 'Pé - Varo',
          'trapezius_shortening': 'Encurtamento de Trapézio',
        },
        'anterior': {
          'valgus_knee': 'Genu - Valgo',
          'varus_knee': 'Genu - Varo',
          'adduct_foot': 'Pé - Aduto',
          'abduct_foot': 'Pé - Abduto',
          'shoulder_no_elevation': 'Nenhuma Elevação',
          'shoulder_elevation_right': 'Elevação Direito',
          'shoulder_elevation_left': 'Elevação Esquerdo',
          'hip_no_elevation': 'Nenhuma Elevação',
          'right_pelvis_elevation': 'Elevação da Pelve Direita',
          'left_pelvis_elevation': 'Elevação da Pelve Esquerda'
        }


      }


    })

    function uploadImage(e, lado) {

      const file = e.target.files[0];
      let image = URL.createObjectURL(file);
      if(lado == 'direita') {
        state.images.direita.file = file
        state.images.direita.url = image
      }
      if(lado == 'esquerda') {
        state.images.esquerda.file = file
        state.images.esquerda.url = image
      }
      if(lado == 'posterior') {
        state.images.posterior.file = file
        state.images.posterior.url = image
      }
      if(lado == 'anterior') {
        state.images.anterior.file = file
        state.images.anterior.url = image
      }
    }

    function selecionaItem(event, chave) {

      /*Object.keys(state.checkboxValues).forEach((chave) => {
        let valor = state.checkboxValues[chave];
        console.log(`Chave: ${chave}, Valor: ${valor}`);
      });*/
    }


    async function buscarDados() {
      store.commit('SET_SPLASH_SCREEN', true)
      try {
        if (state.avaliacaoId !== 0) {
          let response = await avaliacaoPosturalRepository.PosturalGetByAssessmentId(state.avaliacaoId)
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            if(response.data.data != null) {
              state.checkboxValues = response.data.data
              state.id = response.data.data.id

              generatePreview(response.data.data.photo_right, response.data.data.photo_left, response.data.data.photo_posterior, response.data.data.photo_ant)
            }

          }
        }
      } catch (error) {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error)
      } finally {
        store.commit('SET_SPLASH_SCREEN', false)
      }
    }

    function generatePreview(direito, esquerdo, posterior, anterior) {
      // Verifica se a URL da imagem não está vazia

      if (direito != null && direito.trim() !== '') {
        direito = `http://localhost:9000/storage/${direito}`
        // Verifica se a URL é válida
        if (isValidImageUrl(direito)) {

          state.images.direita.url = direito;
        }
      }
      if (esquerdo != null && esquerdo.trim() !== '') {
        esquerdo = `http://localhost:9000/storage/${esquerdo}`
        // Verifica se a URL é válida
        if (isValidImageUrl(esquerdo)) {
          state.images.esquerda.url = esquerdo;
        }
      }
      if (posterior != null && posterior.trim() !== '') {
        posterior = `http://localhost:9000/storage/${posterior}`
        // Verifica se a URL é válida
        if (isValidImageUrl(posterior)) {
          state.images.posterior.url = posterior;
        }
      }
      if (anterior != null && anterior.trim() !== '') {
        anterior = `http://localhost:9000/storage/${anterior}`
        // Verifica se a URL é válida
        if (isValidImageUrl(anterior)) {
          state.images.anterior.url = anterior;
        }
      }
    }

    function isValidImageUrl(url) {
      // Regex para verificar a URL da imagem
      // Pode não ser 100% precisa, mas é um bom ponto de partida
      const pattern = /^((http|https):\/\/)/;
      return pattern.test(url);
    }



    function salvar() {
      /*let images = {
        photo_right: ,
        photo_left: state.images.direita.file,
        photo_posterior: state.images.direita.file,
        photo_ant: state.images.direita.file,
      }*/
      const formDataitems = new FormData()
      if(state.images.direita.file) {
        formDataitems.append('photo_right', state.images.direita.file)
      }
      if(state.images.esquerda.file) {
        formDataitems.append('photo_left', state.images.esquerda.file)
      }
      if(state.images.posterior.file) {
        formDataitems.append('photo_posterior', state.images.posterior.file)
      }
      if(state.images.anterior.file) {
        formDataitems.append('photo_ant', state.images.anterior.file)
      }


      formDataitems.append('items', JSON.stringify(state.checkboxValues))

      try {
        if(state.avaliacaoId == 0 && route.params.id && parseInt(route.params.id) > 0) {
          state.avaliacaoId = parseInt(route.params.id)
        }
        store.commit('SET_SPLASH_SCREEN', true)

        if(state.avaliacaoId == 0) {
          context.emit('salvar', formDataitems, 'postural')
        } else {
          formDataitems.append('student_physical_assessment_id', state.avaliacaoId)

          avaliacaoPosturalRepository.PosturalPost(formDataitems).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)

            if (response.data.success) {
              swal({
                title: "Avaliação postural",
                text: "Avaliação postural cadastrado com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              })
            }
          })

          /*if(state.id == undefined || state.id == 0) {
            avaliacaoPosturalRepository.PosturalPost(formDataitems).then(response => {
              store.commit('SET_SPLASH_SCREEN', false)

              if (response.data.success) {
                swal({
                  title: "Avaliação postural",
                  text: "Avaliação postural cadastrado com sucesso!",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                  buttonsStyling: false,
                })
              }
            })
          } else {
            avaliacaoPosturalRepository.PosturalPut(state.id, formDataitems).then(response => {
              store.commit('SET_SPLASH_SCREEN', false)

              if (response.data.success) {
                swal({
                  title: "Avaliação postural",
                  text: "Avaliação postural cadastrado com sucesso!",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                  buttonsStyling: false,
                })
              }
            })
          }*/

        }

      } catch (error) {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error)
      } finally {
        store.commit('SET_SPLASH_SCREEN', false)
      }
    }

    return {
      state,
      salvar,
      selecionaItem,
      uploadImage
    }
  }
}
</script>

<style scoped>
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>