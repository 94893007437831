<template>
  <div class="row" v-if="state.anamnese.length > 0">
    <div class="col-12">
      <div class="card border-radius-top-end-0 border-radius-top-start-0">
        <div class="card-body">
          <div class="row mb-5 text-center">
            <div class="col-12">
              Anamnese realizada em {{ $filters.datePtbr(state.anamnese[0].created_at) }}
            </div>
          </div>
          <template v-for="item in state.anamnese" :key="item.id">
            <div class="row mb-3">
              <div class="col-12 text-bolder">
                <span class="font-weight-bold me-1">P: </span>
                <span class="text-decoration-underline"> {{ item.question.question }}</span>
              </div>
              <div class="col-12">
                <span class="font-weight-bold me-1">R: </span>
                <span> {{ item.answer }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="row me-3">
          <div class="col-12 text-end">
            <router-link to="/avaliacao-fisica">
              <button type="button" class="btn bg-gradient-secondary">
                Voltar
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, ref, inject } from "vue";
import { useStore } from "vuex";
import anamneseRepository from "@/services/api/anamneseRepository";
import MyTable from "../../../../../components/MyTable.vue";
import {useRoute, useRouter} from "vue-router";

export default {
  components: {
    MyTable,
  },
  props: {
    anamneseProp: {
      type: Array,
      required: true,
    }
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute()
    const swal = inject("$swal");
    const store = useStore();
    onMounted(() => {

      store.commit("SET_SPLASH_SCREEN", false);
      listarAnamnese(props.anamneseProp)
      //listarPlanosTreino()
    });

    // const hidePassword = ref(true)
    // const passwordFieldIcon = computed(() => hidePassword.value ? ['fa', 'eye'] : ['fa', 'eye-slash']);
    // const passwordFieldType = computed(() => hidePassword.value ? "password" : "text");

    const state = reactive({
      anamnese: [],
      trainingPlanid: '',
    })

    const listarAnamnese = (anamnese) => {
      state.anamnese = anamnese
      store.commit("SET_SPLASH_SCREEN", true);
      /*anamneseRepository.AnamneseGetByAnamneseNumber(anamneseNumber).then((response) => {
        store.commit("SET_SPLASH_SCREEN", false);
        state.anamnese = []
        if (response.data.success) {
          console.log(response.data.data)
          state.anamnese = response.data.data
          /!*let training_plan_id = store.getters.TrainingPlanManager
          // let studentId = store.getters.StudentManager
          response.data.data.forEach(item => {
            let obj = []
            obj['anamnese_question_id'] = item.id
            obj['anamnese_question_option_id'] = null
            obj['checkbox'] = []
            obj['answer'] = null
            obj['observation'] = null
            obj['training_plan_id'] = training_plan_id

            if(item.type == 'checkbox') {
              item.options.forEach(opt => {
                obj['checkbox'][opt.id] = {name: opt.option, checked: false}
              })
            }

            state.respostas[item.id] = obj
          })
          state.perguntas = response.data.data*!/
        }
      })
      .catch((error) => {
        store.commit("SET_SPLASH_SCREEN", false);
        swal({
          icon: "error",
          title: "Erro ao elaborar anamnese!",
          text: error.message,
        });
      });*/
    }

    function salvar() {
      try {
        let respostas = []
        state.respostas.forEach(item => {
          let data = Object.assign({}, item)
          respostas.push(data)
        })
        // return
        store.commit('SET_SPLASH_SCREEN', true)
        anamneseRepository.AnamnesePost(respostas).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            swal({
              title: "Anamnese",
              text: "Anamnese cadastrada com sucesso!",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            }).then(() => {
              router.back()
            })
          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao salvar anamnese',
            text: error.message
          })
        })
      } catch {
        store.commit('SET_SPLASH_SCREEN', false)
      }
    }

    return {
      state,
      salvar
    }
  },
};
</script>
