import Repository from './repository'

const resource = '/student-contracts'

export default {
  TermosGet () {
    return Repository.get(`${resource}/list`)
  },
  TermoGetById (id) {
    return Repository.get(`${resource}/list/${id}`)
  },
  TermoPost (item) {
    return Repository.post(`${resource}/list`, item)
  },
  TermoPut (id, item) {
    return Repository.put(`${resource}/list/${id}`, item)
  },
  TermoDelete (planoId) {
    return Repository.delete(`${resource}/list/${planoId}`)
  },
  TermoAtivoGet() {
    return Repository.get(`${resource}/active`)
  },
  TermoPendenteAssinarGet() {
    return Repository.get(`${resource}/pendent-accept`)
  },
  TermoAssinar(item) {
    return Repository.post(`${resource}/accept`, item)
  }
}
