<template>
  <div class="card border-top-start-radius-0 border-top-end-radius-0">
    <controle-pagamento-detalhes
        :studentIdProp="state.studentId"
        :studentNameProp="state.studentName"
        v-if="showForm"
        @fecharForm="formularioTermo(false)"
    />
    <div v-else class="card-body">
      <div class="row mb-3">
        <div class="col-12">
          <table class="table-bordered float-end text-sm">

            <tbody>
            <tr class="">
              <td class="text-center px-3">Valor recebido acumulado</td>
              <td class="text-center px-3">{{ $filters.converteCentavosValor(state.totals.total) }}</td>
            </tr>
            <tr class="">
              <td class="text-center px-3">Valor recebido no mês</td>
              <td class="text-center px-3">{{ $filters.converteCentavosValor(state.totals.month) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-end">
          <div class="mx-2">
            <label class="form-label mt-2">Mês referência</label>
            <select class="form-control form-control-sm custom-select" v-model="state.mesSelecionado">
              <option v-for="(item, index) in state.months" :key="index" :value="item.numero" selected>{{ item.mes }}</option>
            </select>
          </div>
          <div class="mx-2">
            <label class="form-label mt-2">Ano referência</label>
            <select class="form-control form-control-sm custom-select" v-model="state.anoSelecionado">
              <option value="2023" selected>2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
            </select>
          </div>
          <div class="mx-2">
            <button type="button" class="mb-0 btn bg-gradient-success btn-sm" @click="buscarDados">
              Filtrar
            </button>
          </div>
        </div>
      </div>
      <MyTable
          :searchable="false"
          :data="state.listaPagamento"
          :columns="colunas"
          :itemsPerPage="12">
        <template v-slot:row="{ row }">
          <td class="text-sm">
            {{ row.name }} <br>

          </td>
          <td class="text-sm text-center">
            {{ row.payment_day }}
          </td>
          <td class="text-sm text-center">
            <span v-if="row.status === 'Pagamento em dia'">
              {{ $filters.datePtbr(row.payment_date) }}
<!--              <br>
              {{ row.payment_reference }}-->
            </span>
          </td>
          <td class="text-sm text-center">
            <template v-if="row.status === 'Pagamento em dia'">
              {{ $filters.converteCentavosValor(row.payment_amount) }}
              <br>
              <span style="font-size: .8rem;">{{ row.payment_form }}</span>
            </template>


          </td>
          <td class="text-sm text-center" :class="row.status === 'Em atraso' ? 'text-danger' : ''">
            {{ row.status }}
            <template v-if="row.status === 'Em atraso' && row.payment_date !== null">
              <br>
              <span style="font-size: .75rem;">
                  Últ. pagamento: {{$filters.datePtbr(row.payment_date)}}
              </span>
            </template>

          </td>
          <td  class="text-sm ">
            <div class="float-end">
              <div>
                <span
                    class="pointer"
                    title="Detalhes"
                    @click="detalhes(row.id, row.name)"
                >
                  <i class="fas fa-bars text-secondary me-2"></i>
                  Detalhes

                </span>
              </div>
            </div>
          </td>
        </template>
      </MyTable>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/MyTable.vue";
import { reactive, onMounted, ref, inject, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import coachAdministrationRepository from "@/services/api/coachAdministrationRepository";
import TermoAdesaoForm from "@/views/pages/treinador/alunos/alunoTermoAdesaoForm.vue";
import ControlePagamentoDetalhes from "@/views/pages/treinador/administracao/controlePagamentoDetalhes.vue";
import moment from "moment";
export default {
  components: {
    ControlePagamentoDetalhes,
    TermoAdesaoForm,
    MyTable
  },
  setup () {
    const store = useStore()
    const swal = inject('$swal')

    onMounted(() => {
      buscarDados()
    })

    const showForm = ref(false)

    const colunas = ref([
      {
        label: 'Nome aluno',
        field: 'name',
        sortable: 'name'
      },
      {
        label: 'Dia de venc.',
        field: 'payment_day',
        sortable: 'payment_day'
      },
      {
        label: 'Data pgto',
        field: 'payment_date',
        sortable: 'payment_date'
      },
      {
        label: 'Valor pago',
        field: 'payment_amount',
        sortable: 'payment_amount'
      },
      {
        label: 'Situação',
        field: 'status',
        sortable: 'status'
      },
      /*{
        label: 'Forma pgto',
        field: 'payment_form',
        sortable: 'payment_form'
      },*/
      {
        label: 'Ações',
        field: 'actions'
      }
    ])

    const state = reactive({
      listaPagamento: [],
      mesSelecionado: moment().format('MM'),
      anoSelecionado: moment().format('YYYY'),
      studentId: 0,
      studentName: '',
      totals: {
        total: '',
        month: ''
      },
      months: [
        { mes: "Janeiro", numero: '01' },
        { mes: "Fevereiro", numero: '02' },
        { mes: "Março", numero: "03" },
        { mes: "Abril", numero: '04' },
        { mes: "Maio", numero: '05' },
        { mes: "Junho", numero: '06' },
        { mes: "Julho", numero: '07' },
        { mes: "Agosto", numero: '08' },
        { mes: "Setembro", numero: '09' },
        { mes: "Outubro", numero: '10' },
        { mes: "Novembro", numero: '11' },
        { mes: "Dezembro", numero: '12' }
      ]
    })

    function statusStudentPayment(payment_day, payment_reference) {
      if(parseInt(payment_day) < 10) {
        payment_day = `0${payment_day}`
      }
      const dataVencimento = `${state.anoSelecionado}-${state.mesSelecionado}-${payment_day}`
      const anoMes = `${state.anoSelecionado}-${state.mesSelecionado}`
      const today = moment().format('YYYY-MM-DD')
      let status = 'Em atraso'
      if(dataVencimento > today || payment_reference === anoMes) {
        status = 'Pagamento em dia'
      }
      //row.payment_day, row.payment_date
      return status
    }

    function buscarDados() {
      store.commit('SET_SPLASH_SCREEN', true)
      const periodo = state.anoSelecionado+'-'+state.mesSelecionado
      coachAdministrationRepository.studentPaymentsGet(periodo).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if(response.data.success) {
          state.listaPagamento = response.data.data.filter(obj => {
            obj.status = statusStudentPayment(obj.payment_day, obj.payment_reference)

            if(obj.payment_reference != null) {
              obj.payment_reference = obj.payment_reference.split('-').reverse().join('/')
            }

            return obj
          })
        }
      }).catch(error => {
        console.error(error)
        store.commit('SET_SPLASH_SCREEN', false)
      })

      coachAdministrationRepository.studentPaymentsTotalsGet(periodo).then(response => {
        //store.commit('SET_SPLASH_SCREEN', false)
        if(response.data.success) {
          state.totals = response.data.data
        }
      }).catch(error => {
        console.error(error)
        //store.commit('SET_SPLASH_SCREEN', false)
      })
    }

    function detalhes(id, name) {
      state.studentId = id
      state.studentName = name

      formularioTermo(true)
    }

    function formularioTermo(visible) {
      showForm.value = visible
      if(!showForm.value) {
        state.studentId = 0
        state.studentName = ''
        buscarDados()
      }
    }

    return {
      showForm,
      formularioTermo,
      colunas,
      state,
      detalhes,
      buscarDados
    }
  }
}
</script>