<template>
  <div class="card card-body mb-2">
    <div class="row text-end">
      <div class="col-12">
        <button type="button" class="mb-0 btn bg-gradient-success btn-sm" @click="novoExercicio()">
          +&nbsp; Novo exercício
        </button>
      </div>
    </div>
    <div class="row">
      <div class="mt-3 col-12 col-md-3 mt-sm-0">
        <label>Modalidade</label>
        <select
          class="form-control custom-select"
          name="modalidades"
          id="select-modalidades"
          @change="buscarGrupoPorModalidade()"
          v-model="modalidadeSelecionadaId"
        >
          <option value="0">Selecione uma modalidade</option>
          <option v-for="(modalidade, index) in state.modalidades" :key="index" :value="modalidade.id">{{ modalidade.name }}</option>
        </select>
      </div>
      <div class="mt-3 col-12 col-md-3 mt-sm-0">
        <label>Grupo</label>
        <select
          class="form-control custom-select"
          name="grupos"

          @change="selecionaGrupo"
          v-model="grupoSelecionadoId"
        >
          <option value="0">Selecione um grupo</option>
          <option v-for="(modalidade, index) in state.grupos" :key="index" :value="modalidade.id">{{ modalidade.name }}</option>
        </select>
      </div>
      <div class="mt-3 col-12 col-md-3 mt-sm-0">
        <label>Nome do exercicio</label>
        <input
          class="form-control"
          type="text"
          placeholder="Informe o nome do exercicio"
          v-model="state.exercicio.name"
        />
      </div>
       <!-- :class="{ 'align-self-center': v$.exercicio.name.$error}" -->
      <div class="align-self-end col-12 col-md-3">
        <button type="button" class="btn bg-gradient-primary mb-0" @click.prevent="salvar">Salvar</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive, onMounted, ref, inject, watch } from 'vue'
  import { useStore } from 'vuex'
  import modalidadeRepository from "../../../../../services/api/modalidadeRepository";
  import grupoRepository from "../../../../../services/api/grupoRepository";
  import exercicioRepository from "../../../../../services/api/exercicioRepository";
  import useVuelidate from '@vuelidate/core'
  import { rulesMessages } from './../../../../../services/validate.messages.service'

  export default {
    name: "exercicio-formulario",
    props: {
      exercicioId: {
        type: Number,
        default: 0
      },
      grupoId: {
        type: Number,
        default: 0
      },
      modalidadeId: {
        type: Number,
        default: 0
      },
      show: {
        type: Boolean,
        default: false,
      }
    },
    setup(props, context) {
      const store = useStore()
      const swal = inject('$swal')
      // const emit = defineEmits(['closeModal'])

      onMounted(() => {
        store.commit('SET_SPLASH_SCREEN', false)
        buscarModalidade()
      })

      watch( () => props.exercicioId, () => {
        if(props.exercicioId > 0) {
          state.exercicio.id = props.exercicioId
          buscarExercicio()
        } else {
          state.exercicio.name = '' 
          state.exercicio.id = 0
          modalidadeSelecionadaId.value = ''
          grupoSelecionadoId.value = ''
        }
      })

      let modalidadeSelecionadaId = ref('0')
      let grupoSelecionadoId = ref('0')

      const state = reactive({
        exercicio: {
          id: 0,
          name: '',
          modality_id: '',
          group_id: ''
        },
        modalidades: [],
        grupos: []
      })

      const selecionaModalidade = () => {
        context.emit('selecionaModalidade', modalidadeSelecionadaId.value)
      }

      const selecionaGrupo = () => {
        context.emit('selecionaGrupo', grupoSelecionadoId.value)
      }

      const novoExercicio = () => {
        state.exercicio.name = '' 
        state.exercicio.id = 0
        // grupoSelecionadoId.value = 0
        // modalidadeSelecionadaId.value = 0
      }

      const buscarExercicio = () => {
        store.commit('SET_SPLASH_SCREEN', true)
        exercicioRepository.ExercicioGetById(props.exercicioId).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            state.exercicio = response.data.data
            buscarGrupoPorModalidade(state.exercicio.group.modality_id)
            grupoSelecionadoId.value = state.exercicio.group_id
            modalidadeSelecionadaId.value = state.exercicio.group.modality_id            
          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao buscar exercicio!',
            text: error.message
          })
        })
      }

      const buscarModalidade = () => {
        store.commit('SET_SPLASH_SCREEN', true)
        modalidadeRepository.ModalidadeGet().then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            state.modalidades = response.data.data
            if(props.modalidadeId > 0) {
              modalidadeSelecionadaId.value = props.modalidadeId
              buscarGrupoPorModalidade()
            }
          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao buscar modalidade!',
            text: error.message
          })
        })
      }

      const buscarGrupo = () => {
        store.commit('SET_SPLASH_SCREEN', true)
        grupoRepository.GrupoGet().then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            state.grupos = response.data.data
          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao buscar grupo!',
            text: error.message
          })
        })
      }

      const buscarGrupoPorModalidade = (id = null) => {
        store.commit('SET_SPLASH_SCREEN', true)
        selecionaModalidade()
        grupoRepository.GrupoGetByModalityId(id ?? modalidadeSelecionadaId.value).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            state.grupos = response.data.data
            if(props.grupoId > 0) {
              grupoSelecionadoId.value = props.grupoId
            }
            //buscarGrupo(state.exercicio.group_id)
          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao buscar modalidade!',
            text: error.message
          })
        })
      }

      function salvar() {
        if(!validate()) return
        let obj = {name: state.exercicio.name, group_id: grupoSelecionadoId.value}
        store.commit('SET_SPLASH_SCREEN', true)
        if(state.exercicio.id == 0) {
          exercicioRepository.ExercicioPost(obj).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)
            if (response.data.success) {
              swal({
                title: "Exercício!",
                text: "Exercício cadastrada com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                novoExercicio()
                context.emit('salvar')
              })
            } 
          }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao cadastrar exercicio!',
            text: error.message
          })
        })
        } else {
          exercicioRepository.ExercicioPut(state.exercicio.id, obj).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)
            if (response.data.success) {
              swal({
                title: "Exercício!",
                text: "Exercício atualizado com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                novoExercicio()
                context.emit('salvar')
              })
            } 
          }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao atualizar exercício!',
            text: error.message
          })
        })
        }
      }

      const validate = async () => {
        const result = await v$.value.$validate()
        return result
      }

      const rules = {
        exercicio: {
          name: { required: rulesMessages().required },
          modality_id: { required: rulesMessages().required },
          group_id: { required: rulesMessages().required },
          // modality_id: { required }
        }
      }

      const v$ = useVuelidate(rules, state)

      return {
        salvar,
        state,
        buscarGrupoPorModalidade,
        modalidadeSelecionadaId,
        grupoSelecionadoId,
        novoExercicio,
        selecionaGrupo
      }
    }
  };
</script>
