import Repository from './repository'

const resource = '/load-tests'

export default {
  TesteCargaGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  TesteCargaGetLatest (id) {
    return Repository.get(`${resource}/latest`)
  },
  TesteCargaGetByAssessmentId (id) {
    return Repository.get(`${resource}/assessment/${id}`)
  },
  TesteCargaGet () {
    return Repository.get(`${resource}`)
  },
  ExerciciosPrimeiroTesteCargaGet () {
    return Repository.get(`${resource}/first/exercises`)
  },
  ExerciciosByTesteCargaIdGet (id) {
    return Repository.get(`${resource}/${id}/exercises`)
  },
  ExerciciosTestadosPorPlanoTreinoId (id) {
    return Repository.get(`load-tests-by-plan/${id}/last-test-exercises`)
  },
  ExerciciosByTesteCargaNumeroGet (id) {
    return Repository.get(`${resource}/test-number/${id}/exercises`)
  },
  TesteCargaPost (item) {
    return Repository.post(`${resource}`, item)
  },
  TesteCargaExerciciosPost (item) {
    return Repository.post(`${resource}/exercises`, item)
  },
  TesteCargaPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  TesteCargaExerciciosPut (item) {
    return Repository.put(`${resource}/exercises`, item)
  },
  TesteCargaDelete (id) {
    return Repository.delete(`${resource}/${id}`)
  },
  TesteCargaExercicioDelete (id) {
    return Repository.delete(`${resource}/exercise/${id}`)
  }
}
