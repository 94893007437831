<template>
  <transition name="fade">
    <div class="modal-mask" v-if="show"  >
      <div class="modal fade " :class="{ show: show, 'd-block': show }" id="assinaTermoAluno" tabindex="-1" role="dialog" aria-labelledby="psrModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" >Contrato</h5>
              <button type="button" class="btn-close text-dark" @click="assinar(false)" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="form-group col-12" v-html="state.termo.term_text">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row">
                <div class="col-12  mb-3 mt-4">
                  <div
                      class="col form-check pointer"

                  >
                    <input
                        class="mt-1 form-check-input"
                        type="checkbox"
                        value="1"
                        v-model="disabledAceito"
                    />
                    <span class="ms-1">
												Li e estou de acordo com os termos
											</span>
                  </div>
                </div>
                <div class="col-12 text-end">
                  <button type="button" class="btn bg-gradient-secondary" @click.prevent="assinar(false)">
                    Rejeitar
                  </button>
                  <button type="button" class="ms-3 btn bg-gradient-primary" :disabled="!disabledAceito" @click.prevent="assinar(true)">Aceitar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import alunoContratosRepository from "../../../../services/api/alunoContratosRepository";
import {inject, onMounted, reactive, ref} from 'vue'
import useVuelidate from '@vuelidate/core'
import {helpers, required} from '@vuelidate/validators'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'

//import { ClassicEditor, Bold, Essentials, Italic, Paragraph, Undo } from 'ckeditor5';
//import CKEditor from '@ckeditor/ckeditor5-vue';
import Editor from '@tinymce/tinymce-vue'
import platform from 'platform';

export default {
  components: {
    Editor
    //ckeditor: CKEditor.component
  },
  props: {
    termoIdProp: {
      type: Number,
      default: 0
    }
  },
  setup (props, context) {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()

    onMounted(() => {
      store.commit('SET_SPLASH_SCREEN', false)
      buscarDados()
    })

    const show = ref(false);



    const state = reactive({
      termo: {
        id: 0,
        term_text: ''
      }
    })


    const buscarDados = () => {
      store.commit('SET_SPLASH_SCREEN', true)
      alunoContratosRepository.TermoPendenteAssinarGet().then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if (response.data.success) {
          if(response.data.data) {
            state.termo = Object.assign({}, response.data.data)
            show.value = true
          }
        }
      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        swal({
          icon: 'error',
          title: 'Erro ao listar dados do contrato!',
          text: error.message
        })
      })
    }

    function assinar(assinar = false)  {
      if(!assinar) {
        show.value = false
        return
      }
      let platforma = platform.parse(navigator.userAgent)
      let deviceInfo = {
        'product': platforma.product,
        'name': platforma.name
      }
      console.log(deviceInfo)
      //show.value = assinar

      //return

      // student.phone  = this.$filters.justNumbers(state.student.phone)
      // student.cpf  = this.$filters.justNumbers(state.student.cpf)
      store.commit('SET_SPLASH_SCREEN', true)


      let termo = {
        device_info: JSON.stringify(deviceInfo),
        term_id: state.termo.id
      }
      alunoContratosRepository.TermoAssinar(termo).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if (response.data.success) {
          swal({
            title: "Contrato",
            text: "Contrato assinado com sucesso!",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          }).then(() => {
            console.log('OK')
            //context.emit('fecharForm')
            show.value = false
            state.termo = {
              id: 0,
              term_text: ''
            }

          })
        }
      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        swal({
          icon: 'error',
          title: 'Erro ao salvar contrato',
          text: error.message
        })
      })
    }

    const disabledAceito = ref(false)


    return {
      state,
      assinar,
      show,
      disabledAceito
    }
  }
}
</script>

<style scoped>
.modal-body {
  max-height: 600px;
  overflow-y: auto;
}
.modal-footer {
  height: 100px;
  padding-top: 0;
  flex-wrap: initial;
  justify-content: flex-end;
}
</style>