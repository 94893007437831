import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import './assets/css/animate.min.css'
import { tooltip } from './assets/js/tooltip'
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import SoftUIDashboard from "./soft-ui-dashboard";
import Maska from 'maska'
import filters from './filters.js'
import "./assets/css/custom.css";
//import vClickOutside from "click-outside-vue3"
//import money from 'v-money3'


const appInstance = createApp(App);
appInstance.config.globalProperties.$filters = filters
appInstance.directive('tooltip', tooltip)
//appInstance.use(vClickOutside)
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(SoftUIDashboard);
appInstance.use(Maska);
/*import CKEditor from '@ckeditor/ckeditor5-vue'
appInstance.use(CKEditor);*/


appInstance.mount("#app");
