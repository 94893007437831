<template>
  <div class="table-responsive">
    <div v-if="searchable" class="row mb-3">
      <div class="col-lg-4 col-md-4 col-sm-12 col-12">
        <div class="input-group">
          <input type="text" class="form-control float-left border-right-0"
          v-on:keyup="filteredListq()"
          v-model="state.searchKey" placeholder="Pesquisar">
          <span class="input-group-text" @click="filteredListq()">
              <div class="bg-transparent"><i class="fa fa-search"></i></div>
          </span>
        </div>
      </div>
    </div>
    <table class="table table-hover border table-striped" v-if="state.listItem">
      <thead>
        <slot name="columns">
          <th v-for="(column, index) in columns" 
            :key="index" 
            :class="[ column.sortable ? 'pointer' : '', column.field == 'actions' ? 'text-end' : 'text-center' ]" 
          >
            {{column.label}} 
            <span v-if="'sortable' in column" class="ml-1" @click="sortBy(column.sortable)">
              <i class="fas fa-sort" style="font-size: 0.7rem;"></i>
            </span>
          </th>
        </slot>
      </thead>
      <tbody>
        <tr v-if="state.listItem.length === 0">
          <td :colspan="columns.length" class="text-center font-weight-bold">
            <span>Nenhum registro a ser exibido</span>
          </td>
        </tr>
        <tr v-else v-for="(item, index) in state.listItem" :key="index" :class="{ 'text-danger': (item.hasOwnProperty('ativo') && item.ativo == 0) ? true : false }">
          <slot name="row" :row="item">
            <td v-for="(column, index) in columns" :key="index" :class="{'text-right': column.field == 'actions'}">
              <slot name="column" :item="item" :column="column">
                {{itemValue(item, column.field)}}
              </slot>
            </td>
          </slot>
        </tr>
      </tbody>
      <tfoot>
        <td class="controls" :colspan="columns.length">
          <div class="d-flex justify-content-end">
            <button class="btn  me-1 shadow-none font-weight-bold" :disabled="!hasPage(-1)" @click="prevPage">
              <i class="fas fa-caret-left me-1"></i>
              <span>
                Anterior
              </span>
            </button> 
            <button class="btn  ms-1 shadow-none font-weight-bold" :disabled="!hasPage(1)"  @click="nextPage">
              <span>
                Próximo
              </span>
              <i class="fas fa-caret-right ms-1"></i>
            </button>
          </div>
        </td>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import { reactive, onMounted, computed, watch } from 'vue'

	export default {
    props: {
      columns: {
        type: Array,
        default: new Array
      },
      data: {
        type: Array,
        default: new Array
      },
      itemsPerPage: {
        type: Number,
        default: 2
      },
      searchable: {
        type: Boolean,
        default: true
      }
    },
		setup(props) {

      const state = reactive({
        dataItems: [],
        listItem: [],
        searchKey: '',
        sortKey: '',
        pageSize: 2,
        page: 0,
        sortOrders: []
      })

      onMounted(() => {
        state.dataItems = props.data
        state.pageSize = props.itemsPerPage
        if(props.columns.length > 0) {
          props.columns.forEach(function (key) {
            if('sortable' in key) {
              state.sortOrders[key.sortable] = 1
            }
          })
        }
        filteredListq()
      })

      watch(() => props.data, () => {
        filteredListq()
      })

      //#region methods

      function filteredListq () {
        let items = filteredDataq()
        let s = state.page * state.pageSize
        let e = (state.page + 1) * state.pageSize
        if (items.length <= s) {
          s = 0
          e = state.pageSize
          pageNumber(0)
        }
        state.listItem = items.slice(s, e)      
      }
      
      function filteredDataq () {
        var sortKey = state.sortKey
        var filterKey = state.searchKey && state.searchKey.toLowerCase()
        var order = state.sortOrders[sortKey] || 1
        var data = props.data
        if (filterKey && props.searchable) {
          filterKey = filterKey.replace(/[^a-zA-Z0-9]/g, '')
          data = data.filter(function (row) {
            return Object.keys(row).some(function (key) {
              // SE A PROPRIEDADE FOR UM OBJETO
              if(row[key] != null && typeof(row[key]) == 'object') {
                return Object.keys(row[key]).some(function (item) {
                  return String(row[key][item]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().indexOf(filterKey) > -1
                  // return String(row[key][item]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(filterKey)
                })
              }
              return String(row[key]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().indexOf(filterKey) > -1
              // return String(row[key]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(filterKey)
            })
          })
        }
        if (sortKey) {
          data = data.slice().sort(function (a, b) {
            a = a[sortKey]
            b = b[sortKey]
            return (a === b ? 0 : a > b ? 1 : -1) * order
          })
        }
        return data
      }

      function pageNumber (page) {
        state.page = page
      }

      function sortBy (key = '') {
        if (key !== '') {
          state.sortKey = key
          state.sortOrders[key] = state.sortOrders[key] * -1
          filteredListq()
        }
      }

      // function itemValue (item, column) {
      //   if (column !== 'actions' && column !== 'custom') {
      //     return item[column]
      //   } else {
      //     return 'CUSTOM'
      //   }
      // }

      function hasPage (dir) {
        //console.log(dataSize.value)
        // console.log(state.page)
        // console.log(state.pageSize)
        //console.log(dataSize)
        if (dir === -1 && (state.page > 0)) return true
        if (dir === 1 && (((state.page + 1) * state.pageSize) < dataSize.value)) return true
        return false
      }

      function prevPage () {
        if (hasPage(-1)) {
          state.page--
          filteredListq()
        }
      }

      function nextPage () {
        if (hasPage(1)) {
          state.page++
          filteredListq()
        }
      }

      // function sendItem (item) {
      //   this.$emit('receiveItem', item)
      // }

      //#endregion methods
    
      //#region computed
      const dataSize = computed(() => {
        return filteredData.value ? filteredData.value.length : 0
      })

      // const filteredList = computed(() => {
      //   let items = this.filteredData
      //   let s = state.page * state.pageSize
      //   let e = (state.page + 1) * state.pageSize
      //   if (items.length <= s) {
      //     s = 0
      //     e = state.page
      //     pageNumber(0)
      //   }
      //   return items.slice(s, e)
      // })

      const filteredData = computed(() => {
        var sortKey = state.sortKey
        var filterKey = state.searchKey && state.searchKey.toLowerCase()
        var order = state.sortOrders[sortKey] || 1
        var data = props.data
        if (filterKey) {
          data = data.filter(function (row) {
            return Object.keys(row).some(function (key) {
              return String(row[key]).toLowerCase().indexOf(filterKey) > -1
            })
          })
        }
        if (sortKey) {
          data = data.slice().sort(function (a, b) {
            a = a[sortKey]
            b = b[sortKey]
            return (a === b ? 0 : a > b ? 1 : -1) * order
          })
        }
        // console.log(data)
        return data
      })

      // const pageSizeModel = computed({
      //   get () {
      //     return state.pageSize
      //   },
      //   set (v) {
      //     state.pageSize = v
      //     pageNumber(0)
      //   }
      // })
      //#endregion

      return {
        filteredListq,
        state,
        nextPage,
        hasPage,
        prevPage,
        sortBy
      }
		},

    

    
	};
</script>

<style scoped>
.controls {
	font-weight: 700;
	font-size: 1rem;
}
table > thead,
table > tfoot {
	background-color: #f3f4f7;
}
table thead th:first-child {
  text-align: left !important;
}

@media only screen and (max-width: 992px) {
	table {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}
</style>