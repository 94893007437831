<template>
  <div class="card card-body mb-2">
    <div class="row text-end">
      <div class="col-12">
        <button type="button" class="mb-0 btn bg-gradient-success btn-sm" @click="novoMetodo()">
          +&nbsp; Novo método
        </button>
      </div>
    </div>
    <div class="row">
      <div class="mt-3 col-12 col-md-6 mt-sm-0">
        <label class="required">Nome do método</label>
        <input
          class="form-control"
          type="text"
          placeholder="Informe o nome do metodo"
          v-model="metodo.name"
        />
        <span class="ms-3 text-error text-danger" v-if="v$.name.$error"> {{ v$.name.$errors[0].$message }} </span>
      </div>
      <div class="align-self-end col-12 col-md-6" :class="{ 'align-self-center': v$.name.$error}">
        <button type="button" class="btn bg-gradient-primary mb-0" @click.prevent="salvar">Salvar</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive, onMounted, inject,watch } from 'vue'
  import { useStore } from 'vuex'
  import metodoRepository from "../../../../../services/api/metodoRepository";
  import useVuelidate from '@vuelidate/core'
  import { rulesMessages } from './../../../../../services/validate.messages.service'

  export default {
    name: "metodo-formulario",
    props: {
      metodoId: {
        type: Number,
        default: 0
      },
      show: {
        type: Boolean,
        default: false,
      }
    },
    setup(props, context) {
      const store = useStore()
      const swal = inject('$swal')
      // const emit = defineEmits(['closeModal'])

      onMounted(() => {
        store.commit('SET_SPLASH_SCREEN', false)
        // if(props.metodoId > 0) {
        //   metodo.id = props.metodoId
        //   console.log(metodo.id)
        //   buscarMetodo()
        // } else {
        //   metodo.name = ''
        //   metodo.id = 0
        // }
        // listarPlanos()
      })

      const metodo = reactive({
        id: 0,
        name: ''
      })

      watch(() => props.metodoId, () => {

        if(props.metodoId > 0) {
          metodo.id = props.grupoId
          buscarMetodo()
        } else {
          metodo.name = ''
          metodo.id = 0
        }
      })

      const buscarMetodo = () => {
        store.commit('SET_SPLASH_SCREEN', true)
        metodoRepository.MetodoGetById(props.metodoId).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            metodo.id = response.data.data.id
            metodo.name = response.data.data.name
          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao buscar método!',
            text: error.message
          })
        })
      }

      const validate = async () => {
        const result = await v$.value.$validate()
        return result
      }

      const novoMetodo = () => {
        metodo.name = ''
        metodo.id = 0
      }

      function salvar() {
        if(!validate()) return
        store.commit('SET_SPLASH_SCREEN', true)
        if(metodo.id == 0) {
          metodoRepository.MetodoPost({name: metodo.name}).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)
            if (response.data.success) {
              swal({
                title: "Metodo!",
                text: "Método cadastrado com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                metodo.name = ''
                metodo.id = 0
                context.emit('closeModal')
              })
            } 
          }).catch((error) => {
            store.commit('SET_SPLASH_SCREEN', false)
            swal({
              icon: 'error',
              title: 'Erro ao salvar método!',
              text: error.message
            })
          })
        } else {
          metodoRepository.MetodoPut(metodo.id, {name: metodo.name}).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)
            if (response.data.success) {
              swal({
                title: "Metodo!",
                text: "Método atualizado com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                metodo.name = ''
                metodo.id = 0
                context.emit('closeModal')
              })
            } 
          }).catch((error) => {
            store.commit('SET_SPLASH_SCREEN', false)
            swal({
              icon: 'error',
              title: 'Erro ao atualizar método!',
              text: error.message
            })
          })
        }
      }

      // const rules = computed(() => {
      //   return {
      //     name: { required }
      //   }
      // })

      const rules = {
        name: { required: rulesMessages().required  }
      }

      const v$ = useVuelidate(rules, metodo)

      return {
        salvar,
        metodo,
        v$,
        novoMetodo
      }
    }
  };
</script>
