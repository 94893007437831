import Repository from './repository'

const resource = '/exercises'

export default {
  ExercicioGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  ExercicioGetByGroupId (id) {
    return Repository.get(`${resource}/group/${id}`)
  },
  ExercicioGet () {
    return Repository.get(`${resource}`)
  },
  ExercicioPost (item) {
    return Repository.post(`${resource}`, item)
  },
  ExercicioPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  ExercicioDelete (exercicioId) {
    return Repository.delete(`${resource}/${exercicioId}`)
  }
}
