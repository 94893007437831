
export function fatWeight (totalWeight, fatPercentage) {
  return ((totalWeight * fatPercentage) / 100).toFixed(2)
}

export function skinnyWeight(totalWeight, fatWeight) {
  return (totalWeight - fatWeight).toFixed(2)
}

export function sumSkinfolds(subScapular, triceps, biceps, chest, midAxillary, supraIliac, abdomen, thigh, leg) {
  leg = 0
  biceps = 0
  let sumSkinfolds = subScapular + triceps + biceps + chest + midAxillary + supraIliac + abdomen + thigh + leg
  return sumSkinfolds.toFixed(2)
}

export function percentageBodyFatPollock(age, sumSkinfolds) {

  if(age == 0 || sumSkinfolds == 0) return 0
  let dc = 1.112 - 0.00043499 * sumSkinfolds + 0.00000055 * sumSkinfolds * 2 - 0.00028826 * age
  let bf = (495/dc) - 450
  return bf.toFixed(2)
}

/*
export function pollockCalc(age, subScapular, triceps, biceps, chest, midAxillary, supraIliac, abdomen, thigh, leg) {
  let sumPollock = subScapular + triceps + biceps + chest + midAxillary + supraIliac + abdomen + thigh + leg
  let dc = 1.112 - 0.00043499 * sumPollock + 0.00000055 * sumPollock * 2 - 0.00028826 * age
  let bf = (495/dc) - 450
  return bf.toFixed(2)
}*/

export function basalMetabolicRate(weight, height, age, gender) {
  if(weight == 0 || height == 0 || age == 0) return 0
  // Para os homens = 66 + (13,8 x peso em kg) + (5 x altura em cm) – (6,8 x idade em anos)
  // Para as mulheres = 655 + (9,6 x peso em kg) + (1,8 x altura em cm) – (4,7 x idade em anos)
  if(gender == 'M') {
    return (66 + (13.8 * weight) + (5 * height) - (6.9 * age)).toFixed(2)
  }
  return (655 + (9.6 * weight) + (1.8 * height) - (4.7 * age)).toFixed(2)

}

export function mmMetabolicRate(skinnyWeight, height, age, gender) {
  if(skinnyWeight == 0 || height == 0 || age == 0) return 0
  if(gender == 'M') {
    return (66 + (13.8 * skinnyWeight) + (5 * height) - (6.9 * age)).toFixed(2)
  }
  return (655 + (9.6 * skinnyWeight) + (1.8 * height) - (4.7 * age)).toFixed(2)
}

export function imc(weight, height) {
  if(weight == 0 || height == 0) return 0
  let result = weight / (height * height)
  if(isNaN(result)) return 0
  return result.toFixed(2)
}

export function cardiacRisk() {

}