import Repository from './repository'

const resource = '/body-composition-circumference'

export default {
  CircunferenciaGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  CircunferenciaGetByAssessmentId (id) {
    return Repository.get(`${resource}/assessment/${id}`)
  },
  CircunferenciaGet () {
    return Repository.get(`${resource}`)
  },
  CircunferenciaGetLatest () {
    return Repository.get(`${resource}/latest`)
  },
  CircunferenciaGetFirst () {
    return Repository.get(`${resource}/first`)
  },
  CircunferenciaGetPenultimate (id = null) {
    return id == null ? Repository.get(`${resource}/penultimate`) : Repository.get(`${resource}/penultimate/${id}`)
  },
  CircunferenciaPost (item) {
    return Repository.post(`${resource}`, item)
  },
  CircunferenciaPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  CircunferenciaDelete (id) {
    return Repository.delete(`${resource}/${id}`)
  }
}
