<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
    style="overflow-x: hidden;"
  >
    <ul class="navbar-nav">


      <li class="nav-item">
        <sidenav-collapse
          navText="Sair"
          collapseRef="/"
          v-bind:collapse="false"
          @click="logout"
          :aria-controls="''"
        >
          <template v-slot:icon>
            <icon name="changelog" />
          </template>
        </sidenav-collapse>
      </li>
      
    </ul>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <!-- <sidenav-card
      :class="cardBg"
      textPrimary="Need Help?"
      textSecondary="Please check our docs"
      href="https://www.creative-tim.com/learning-lab/bootstrap/overview/soft-ui-dashboard"
      linkText="Documentation"
      iconClass="ni ni-diamond"
    /> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import Icon from "@/components/Icon.vue";
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCard from "./SidenavCard.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    Icon,
    SidenavItem,
    SidenavCollapse,
    SidenavCard,
    SidenavCollapseItem,
  },
  computed: {
    ...mapGetters(['SET_API_TOKEN'])
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      //console.log(routeArr[1])
      return routeArr[1];
    },
    logout() {
      this.$store.dispatch('handleLogout', null)
      this.$router.push('/login')
    }
  },
};
</script>
