export function calcularCargaMaxima(repeticoes, carga) {
	switch (repeticoes) {
		case 1:
			return (carga * repeticoes) * 1
		case 2:
			return (carga * repeticoes) * 0.531914893617021
		case 3:
			return (carga * repeticoes) * 0.37037037037037
		case 4:
			return (carga * repeticoes) * 0.28735632183908
		case 5:
			return (carga * repeticoes) * 0.235294117647058
		case 6:
			return (carga * repeticoes) * 0.202020202020202
		case 7:
			return (carga * repeticoes) * 0.178571428571428
		case 8:
			return (carga * repeticoes) * 0.161290322580645
		case 9:
			return (carga * repeticoes) * 0.148148148148148
		case 10:
			return (carga * repeticoes) * 0.137931034482758
		case 11:
			return (carga * repeticoes) * 0.129870129870129
		case 12:
			return (carga * repeticoes) * 0.123456790123456
		case 13:
			return (carga * repeticoes) * 0.118343195266272
		case 14:
			return (carga * repeticoes) * 0.114285714285714
		case 15:
			return (carga * repeticoes) * 0.111111111111111
		default:
			return 0
	}
}