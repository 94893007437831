<template>
	<div class="d-flex align-items-baseline">
    <template  v-if="userType !== 's'">
      <label for="" class="font-weight-bold text-white text-sm text-nowrap me-2">Plano de treino</label>
      <select
          class="form-control custom-select form-control-sm"
          name="training-plan-manager"
          required
          id="select-training-plan"
          v-model="state.trainingPlanId"
          @change="setTrainingPlanManager()"
      >
        <option value="">Selecione um plano</option>
        <option v-for="(item, index) in state.trainingPlans"
                :key="index"
                :value="item.id"
        >
          {{ item.name }} | {{ $filters.datePtbr(item.initial_date) }} à {{ $filters.datePtbr(item.final_date) }}
        </option>
      </select>
    </template>
		<template v-else>

    </template>
	</div>
</template>

<script>
	import { reactive, onMounted, ref, inject, watch } from "vue";
	import { useStore } from "vuex";
	import planosTreinoRepository from "../../services/api/planosTreinoRepository";
	export default {
		setup() {
			const store = useStore()

			const state = reactive({
				trainingPlanId: '',
				trainingPlans: []
			})

			onMounted(() => {
        store.commit('SET_SPLASH_SCREEN', false)
				state.trainingPlanId = store.getters.TrainingPlanManager

        listTrainingPlans()
      })

			const listTrainingPlans = () => {
        planosTreinoRepository.PlanosTreinoGet().then(response => {
          state.trainingPlans = []
          if (response.data.success) {
            state.trainingPlans = response.data.data
          }
        }).catch((error) => {
					console.error(error)
          swal({
            icon: 'error',
            title: 'Erro ao listar planos de treino!',
            text: error.message
          })
        })
      }

			const setTrainingPlanManager = () => {
				let id = parseInt(state.trainingPlanId)
				store.commit('SET_TRAINING_PLAN', id > 0 ? id : null)
				window.location.reload()
			}

      const userType = store.getters.UserType


			return {
				state,
				setTrainingPlanManager,
        userType
			};
		},
	};
</script>

<style>
</style>>