<template>
  <div class="py-4 container-fluid">
    <ExerciciosFormulario :modalidadeId="parseInt(state.modalidadeId)"
                          :grupoId="parseInt(state.grupoId)"
                          :exercicioId="state.exercicioId"
                          @selecionaModalidade="selecionaModalidade"
                          @selecionaGrupo="selecionaGrupo"
                          @salvar="listarExercicios()"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <!-- <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Todos exercícios</h5>
                <p class="mb-0 text-sm">
                  Listagem de exercicios
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <button type="button" class="mb-0 btn bg-gradient-success btn-sm" @click="displayModal(true)">
                    +&nbsp; Nova exercício
                  </button>
                </div>
              </div>
            </div>
          </div> -->
          <div class="card-body">
            <MyTable
              :data="exerciciosFiltrados"
              :columns="columns"
              :itemsPerPage="12">
              <template v-slot:row="{ row }">
                <td class="text-sm">
                  {{ row.name }} 
                </td>
                <td class="text-sm text-center">
                  {{ row.group_name }} 
                </td>
                <td class="text-sm text-center">
                  {{ row.modality_name }} 
                </td>
                <td class="text-sm text-center">
                  {{ $filters.datePtbr(row.created_at) }}
                </td>
                <td  class="text-sm ">
                  <div class="float-end">
                    <div>
                      <span
                        class="pointer"
                       data-bs-toggle="tooltip"
                        data-bs-original-title="Deletar exercicio"
                        title="Editar exercicio"
                        data-bs-placement="top"
                        v-tooltip
                        @click="editarExercicio(row.id)"
                      >
                        <i class="fas fa-pen text-secondary me-2"></i>
                        Editar
                        
                      </span>
                    </div>
                    <div>
                      <span
                        class="pointer"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Deletar exercicio"
                        title="Deletar exercicio"
                        data-bs-placement="top"
                        v-tooltip
                        @click="deletarExercicio(row)"
                      >
                        <i class="fas fa-trash text-secondary me-2"></i>
                        Deletar
                      </span>
                    </div>
                  </div>
                </td>
              </template>
            </MyTable>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    
  </div>
</template>

<script>
  import { reactive, onMounted, ref, inject, computed } from 'vue'
  import { useStore } from 'vuex'
  import exercicioRepository from "../../../../../services/api/exercicioRepository";
  import MyTable from '../../../../../components/MyTable.vue';
  import ExerciciosFormulario from './ExerciciosFormulario.vue';
  import { useRouter, useRoute } from 'vue-router'

  export default {
    components: {
      MyTable,
      ExerciciosFormulario
    },
    setup () {
      const router = useRouter()
      const route = useRoute()
      const swal = inject('$swal')
       const store = useStore()
      onMounted(() => {
        store.commit('SET_SPLASH_SCREEN', false)
        listarExercicios()
        if(route.params.modalidade && route.params.grupo) {
          state.grupoId = parseInt(route.params.grupo)
          state.modalidadeId = parseInt(route.params.modalidade)

          displayModal(true)
        }
      })

      let modalVisible= ref(false);
      const columns = ref([
        {
          label: 'Nome',
          field: 'nome',
          sortable: 'nome'
        },
        {
          label: 'Grupo',
          field: 'group_name',
          sortable: 'group_name'
        },
        {
          label: 'Modalidade',
          field: 'modality_name',
          sortable: 'modality_name'
        },
        {
          label: 'Data de cadastro',
          field: 'created_at'
        },
        {
          label: 'Ações',
          field: 'actions'
        }
      ])

      const state = reactive({
        exercicioId: 0,
        exercicios: [],
        grupoId: 0,
        modalidadeId: 0
      })

      function selecionaGrupo(id = 0) {

        state.grupoId = id
      }

      function selecionaModalidade(id = 0) {
        state.modalidadeId = id
      }

      const listarExercicios = () => {
        store.commit('SET_SPLASH_SCREEN', true)
        exercicioRepository.ExercicioGet().then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          state.exercicios = []

          if (response.data.success) {
            state.exercicios = response.data.data

          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao listar exercicios!',
            text: error.message
          })
        })
      }

      const exerciciosFiltrados = computed (() => {

        if(state.exercicios.length == 0) return []
        
        let dados = [] 

        // se limpa modalidade, limpa todo o resto
        if(parseInt(state.modalidadeId) == 0 || state.modalidadeId.toString() == '') {
          state.grupoId = 0
          return state.exercicios
        }

        let modalidadeId = parseInt(state.modalidadeId)
        if(parseInt(state.grupoId) == 0 || state.grupoId.toString() == '') {
          dados = state.exercicios.filter(obj => {
            if(obj.modality_id == modalidadeId) {
              return obj
            }
          })
          return dados
        }

        let grupoId = parseInt(state.grupoId)
        dados = state.exercicios.filter(obj => {
          if(obj.modality_id == modalidadeId && obj.group_id == grupoId) {
            return obj
          }
        })

        return dados
      })

      function editarExercicio(id) {
        state.exercicioId = id
        displayModal(true)
        //router.push(`/cadastros/exercicio/${id}`);
      }

      function deletarExercicio(item) {

        swal({
          title: "Tem certeza?",
          text: `Deseja deletar ${item.name}`,
          showCancelButton: true,
          confirmButtonText: "Sim, quero deletar!",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            deletarExercicioConfirma(item.id)
          }
        })
      }

      function deletarExercicioConfirma(id) {
        store.commit('SET_SPLASH_SCREEN', true)
        exercicioRepository.ExercicioDelete(id).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if(response.data.success) {
            swal({
              title: "Removido!",
              text: "Exercicio removido com sucesso!",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            }).then(() => {
              listarExercicios()
            })
          }
        }).catch(error => {
          store.commit('SET_SPLASH_SCREEN', false)
          console.error(error.message)
          swal({
            icon: 'error',
            title: 'Erro ao deletar exercicio!',
            text: error.message
          })
        })
      }

      function displayModal(display = true, refresh = false) {
        if(!display) {
          state.exercicioId = 0
          state.modalidadeId = 0
          state.grupoId = 0
        }
        modalVisible.value = display
        if(refresh) {
          
          listarExercicios() 
        }
      }

      return {
        state,
        columns,
        editarExercicio,
        deletarExercicio,
        displayModal,
        modalVisible,
        exerciciosFiltrados,
        selecionaModalidade,
        selecionaGrupo,
        listarExercicios
      }
    }
  }
  
</script>
