<template>
  <div class="py-4 container-fluid">
    <div class="row">
        <div class="col-12 text-center py-10">
            <h1>
                Página em desenvolvimento
            </h1>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>>