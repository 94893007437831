<template>
  <div
    class="card border-top-start-radius-0 border-top-end-radius-0 p-3"
    data-animation="FadeIn"
  >
    <h5>Dados básicos</h5>
    <div class="my-3 row">
      <div class="form-group col-12 col-sm-4">
        <label class="required">Nome do aluno</label>
        <input
          class="form-control"
          type="text"
          placeholder="Nome do aluno"
          v-model="state.student.name"
        />
        <span class="ms-3 text-error text-danger" v-if="v$.student.name.$error"> {{ v$.student.name.$errors[0].$message }} </span>
      </div>
      <div class="form-group col-12 col-sm-4 mt-sm-0">
        <label>CPF</label>
        <input
          class="form-control"
          type="text"
          v-maska="'###.###.###-##'"
          placeholder="999.999.999-99"
          v-model="state.student.cpf"
        />
      </div>
      <div class="form-group col-12 col-sm-4 mt-sm-0">
        <label>Data de nascimento</label>
        <input
          class="form-control"
          type="text"
          v-maska="'##/##/####'"
          placeholder="00/00/0000"
          v-model="state.student.birth_date"
        />
      </div>
      <div class="form-group col-12 col-sm-4 mt-sm-0">
        <label class="required">Sexo biológico</label>
        <select
          class="form-control custom-select"
          name="sexo"
          required
          id="select-sexo"
          v-model="state.student.sex"
        >
          <option value="">Selecione uma opção</option>
          <option value="F">Feminino</option>
          <option value="M">Masculino</option>
        </select>
        <span class="ms-3 text-error text-danger" v-if="v$.student.sex.$error"> {{ v$.student.sex.$errors[0].$message }} </span>
      </div>
      <div class="form-group col-12 col-sm-4 mt-sm-0">
        <label class="required">Altura (m)</label>
        <input
          required
          class="form-control"
          type="text"
          v-maska="'#.##'"
          v-model="state.student.height"
          placeholder="9,99"
        />
        <span class="ms-3 text-error text-danger" v-if="v$.student.height.$error"> {{ v$.student.height.$errors[0].$message }} </span>
      </div>
      <div class="form-group col-12 col-sm-4 mt-sm-0">
        <label class="required">Peso (kg)</label>
        <input
          class="form-control"
          type="text"
          required
          v-model="state.student.weight"
          placeholder="99,99"
        />
        <span class="ms-3 text-error text-danger" v-if="v$.student.weight.$error"> {{ v$.student.weight.$errors[0].$message }} </span>
      </div>
      <div class="form-group col-12 col-sm-4 mt-sm-0">
        <label class="required">Email</label>
        <input
            class="form-control"
            type="email"
            v-model="state.student.email"
            placeholder="email@email.com"
        />
      </div>
      <div class="form-group col-12 col-sm-4 mt-sm-0">
        <label class="required">Senha de acesso</label>
        <input
            class="form-control"
            type="password"
            v-model="state.student.password"
            placeholder="********"
        />
      </div>
      <div class="form-group col-12 col-sm-4 mt-sm-0">
        <label class="required">Celular</label>
        <input
          class="form-control"
          type="text"
          v-maska="'(##) #####-####'"
          v-model="state.student.phone"
          placeholder="(99) 99999-9999"
        />
        <span class="ms-3 text-error text-danger" v-if="v$.student.phone.$error"> {{ v$.student.phone.$errors[0].$message }} </span>
      </div>
      <div class="form-group col-12 col-sm-4 mt-sm-0">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="state.student.active" value="1">
          <label class="form-check-label" for="flexSwitchCheckDefault">{{ state.student.active ? 'Usuário ativo' : 'Usuário inativo' }}</label>
        </div>
      </div>
    </div>
    <!-- ENDERECO -->
    <hr>
    <h5>Endereço</h5>
    <div class="my-3 row">
      <div class="form-group col-12 col-md-4">
        <label>CEP</label>
        <input
          class="form-control"
          type="text"
          v-maska="'#####-####'"
          placeholder="99999-999"
          v-model="state.cep"
        />
      </div>
      <div class="form-group col-12 col-md-4">
        <label>Logradouro</label>
        <input
          class="form-control"
          type="text"
          placeholder="Rua, Avenida"
          disabled
          v-model="state.student.street"
        />
      </div>
      <div class="form-group col-12 col-md-2">
        <label>Número</label>
        <input
          class="form-control"
          type="text"
          ref="inputNumero"
          placeholder="S/N"
          v-model="state.student.number"
        />
      </div>
      <div class="form-group col-12 col-md-2">
        <label>Complemento</label>
        <input
          class="form-control"
          type="text"
          placeholder="S/N"
          v-model="state.student.complement"
        />
      </div>
      <div class="form-group col-12 col-md-4">
        <label>Bairro</label>
        <input
          class="form-control"
          type="text"
          placeholder="Bairro"
          disabled
          v-model="state.student.neighborhood"
        />
      </div>
      <div class="form-group col-12 col-md-4">
        <label>Cidade</label>
        <input
          class="form-control"
          type="text"
          placeholder="Cidade"
          disabled
          v-model="state.student.city"
        />
      </div>
      <div class="form-group col-12 col-md-4">
        <label>Estado</label>
        <input
          class="form-control"
          type="text"
          placeholder="UF"
          disabled
          v-model="state.student.state"
        />
      </div>
    </div>
    <hr>
    <h5>Pagamento</h5>
    <div class="row">
      <div class="form-group col-12 col-md-4">
        <label class="required">Condição de pagamento</label>
        <select
          class="form-control custom-select"
          name="payment_condition"
          required
          id="select-payment_condition"
          v-model="state.student.payment_condition_id"
        >
          <option v-for="(item, index) in state.condicoesPagamento" :key="index" :value="item.id">{{ item.name }}</option>
        </select>
        <span class="ms-3 text-error text-danger" v-if="v$.student.payment_condition_id.$error"> {{ v$.student.payment_condition_id.$errors[0].$message }} </span>
      </div>
      <div class="form-group col-12 col-md-4">
        <label class="required">Forma de pagamento</label>
        <select
          class="form-control custom-select"
          name="payment_form"
          required
          id="select-payment_form"
          v-model="state.student.payment_form_id"
        >
          <option v-for="(item, index) in state.formasPagamento" :key="index" :value="item.id">{{ item.name }}</option>
        </select>
        <span class="ms-3 text-error text-danger" v-if="v$.student.payment_form_id.$error"> {{ v$.student.payment_form_id.$errors[0].$message }} </span>
      </div>
      <div class="form-group col-12 col-md-4">
        <label class="required">Dia de pagamento</label>
        <select
          class="form-control custom-select"
          name="payment_day"
          required
          id="select-payment_day"
          v-model="state.student.payment_day"
        >
          <option v-for="number in 31" :key="number" :value="number">{{ number }}</option>
        </select>
        <span class="ms-3 text-error text-danger" v-if="v$.student.payment_day.$error"> {{ v$.student.payment_day.$errors[0].$message }} </span>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 text-end">
        <router-link to="/alunos">
          <button type="button" class="btn bg-gradient-secondary">
              Fechar
          </button>
        </router-link>
        <button type="button" class="ms-3 btn bg-gradient-primary" @click.prevent="salvar">Salvar</button>
      </div>
    </div>
    
  </div>
</template>

<script>
import { GetAddress } from './../../../../services/cep.service'
import alunoRepository from "../../../../services/api/alunoRepository";
import formaPagamentoRepository from "../../../../services/api/formaPagamentoRepository";
import condicaoPagamentoRepository from "../../../../services/api/condicaoPagamentoRepository";
import { reactive, onMounted, ref, inject, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default {
  setup () {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()

    onMounted(() => {
      store.commit('SET_SPLASH_SCREEN', false)

      buscarCondicaoFormaPagamento()
      if(route.params.id && parseInt(route.params.id) > 0) {
        state.alunoId = parseInt(route.params.id)

        buscarDadosAluno()
      }
    })
    

    const state = reactive({
      cep: '',
      student: {
        name: '', cpf: '', birth_date: '', sex: '', height: '', weight: '', email: '', phone: '',
        cep: '', street: '', complement: '', number: '', neighborhood: '', city: '', state: '',
        payment_condition_id: '', payment_form_id: '', payment_day: '', password: '', active: true
      },
      alunoId: 0,
      formasPagamento: [],
      condicoesPagamento: []
    })

    const inputNumero = ref(null)

    watch(() => state.cep, () => {

      if (state.cep && state.cep !== null && state.cep.length == 9) {

        buscaCep()
      }
    })

    const buscaCep = () => {

      GetAddress(state.cep).then(endereco => {
        state.student.cep = state.cep
        state.student.street = endereco.logradouro
        state.student.neighborhood = endereco.bairro
        state.student.city = endereco.cidade
        state.student.state = endereco.estado
        inputNumero.value.focus()
        // resolve, mas nao deveria precisar
        //this.$forceUpdate()
      })
    }

    const buscarCondicaoFormaPagamento = async () => {
      store.commit('SET_SPLASH_SCREEN', true)
      try {
        store.commit('SET_SPLASH_SCREEN', true)
        // let response = await formaPagamentoRepository.FormaGet().then(response => {
        //   store.commit('SET_SPLASH_SCREEN', false)
        //   console.log(response.data)
        //   if (response.data.success) {
        //     state.formasPagamento = response.data.data
        //   }
        // })
        let response = await formaPagamentoRepository.FormaGet()

        if (response.data.success) {
          state.formasPagamento = response.data.data
        }

        let response1 = await condicaoPagamentoRepository.CondicaoGet()

        if (response1.data.success) {
          state.condicoesPagamento = response1.data.data
        }
        // condicaoPagamentoRepository.CondicaoGet().then(response => {
        //   store.commit('SET_SPLASH_SCREEN', false)
        //   console.log(response.data)
        //   if (response.data.success) {
        //     state.condicoesPagamento = response.data.data
        //   }
        // })
      }catch(error) {
        console.log(error)
      } finally {
        store.commit('SET_SPLASH_SCREEN', false)
      }
      
      
    }

    const buscarDadosAluno = () => {
      store.commit('SET_SPLASH_SCREEN', true)
      alunoRepository.AlunoGetById(state.alunoId).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)

        if (response.data.success) {
          let obj = Object.assign({}, response.data.data)
          obj.birth_date = obj.birth_date != null ? obj.birth_date.split('-').reverse().join('/') : ''
          obj.height = obj.height / 100
          obj.active = obj.deleted_at == null
          state.student = obj
          state.cep = state.student.cep


        }
      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        swal({
          icon: 'error',
          title: 'Erro ao listar dados do aluno!',
          text: error.message
        })
      })
    }

    async function salvar()  {
      let validated = await validate()
      if(!validated) {
        swal({
          text: "Preencha todos os campos obrigatórios!",
          icon: "info",
        })
        return
      }
      let student = Object.assign({}, state.student)
      student.birth_date = student.birth_date != '' ? student.birth_date.split('/').reverse().join('-') : null
      // student.phone  = this.$filters.justNumbers(state.student.phone)
      // student.cpf  = this.$filters.justNumbers(state.student.cpf)
      store.commit('SET_SPLASH_SCREEN', true)
      if(state.alunoId == 0) {
        alunoRepository.AlunoPost(student).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            swal({
              title: "Aluno",
              text: "Aluno cadastrado com sucesso!",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            }).then(() => {
              console.log('OK')
              router.back()
            })
          } 
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao salvar aluno',
            text: error.message
          })
        })
      } else {
        alunoRepository.AlunoPut(state.alunoId, student).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            swal({
              title: "Aluno",
              text: "Aluno atualizado com sucesso!",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            }).then(() => {
              console.log('OK 2')
              router.back()
            })
          } 
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao atualizar aluno!',
            text: error.message
          })
        })
      }
    }

    async function validate ()  {
      const result = await v$.value.$validate()
      console.log(result)
      console.log(v$.value)
      return result
    }

    const formataPeso = () => {
      let v = state.student.weight
      if (/^(?!0{2})\d+(?:,\d+)?$/.exec(v) !== null) {
        if (Number(v.replace(/,/, ".")) <= 800) {
          console.log(v)
        }
      }
      // var numberPattern = '^(0|[1-9]\d*)(,\d+)?$'
      // var res = state.student.weight.match( numberPattern )
      //console.log(res)
      //state.student.weight = parseFloat(res).toFixed(3)
    }

    const rulesMessages = {
      required: helpers.withMessage('Campo obrigatório', required)
    }
    const rules = {
      student: {
        name: { required: rulesMessages.required }, 
        //cpf: { required }, 
        //birth_date: { required }, 
        sex: { required: rulesMessages.required }, 
        height: { required: rulesMessages.required }, 
        weight: { required: rulesMessages.required }, 
        email: { required: rulesMessages.required }, 
        phone: { required: rulesMessages.required },
        //cep: { required }, 
        //street: { required }, 
        //number: { required }, 
        //neighborhood: { required }, 
        //city: { required }, 
        //state: { required },
        payment_condition_id: { required: rulesMessages.required }, 
        payment_form_id: { required: rulesMessages.required }, 
        payment_day: { required: rulesMessages.required }
      }
    }

    const v$ = useVuelidate(rules, state)

    return {
      buscaCep,
      state,
      inputNumero,
      salvar,
      v$,
      formataPeso
    }
  }
}
</script>

<style>

</style>