<template>
  <div class=" pt-3 px-3 ">
    <div class="col-12">
      <div class="row justify-content-around">
        <div class="col-12 col-md-3 col-sm-6">
          <div class="form-group align-items-baseline d-flex">
            <label for="" class="me-3">Data: </label>
            <input
                class="form-control"
                type="text"
                v-maska="'##/##/####'"
                placeholder="00/00/0000"
                @change="updateAssessment()"
                v-model="state.assessment_date"
            />
          </div>
        </div>
        <div class="col-12 col-md-3 col-sm-6">
          <div class="form-group align-items-baseline d-flex">
            <label for="" class="me-3">Local: </label>
            <input
                class="form-control"
                type="text"
                @change="updateAssessment()"
                v-model="state.assessment_location"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" >
          <button class="nav-link active" id="anamnese-tab" data-bs-toggle="tab" data-bs-target="#anamnese" type="button"
                  role="tab" aria-controls="anamnese" aria-selected="true">
            Anamnese
          </button>
        </li>
        <li class="nav-item" role="presentation" >
          <button class="nav-link" id="circunferencia-tab" data-bs-toggle="tab" data-bs-target="#circunferencia" type="button"
                  role="tab" aria-controls="circunferencia" aria-selected="true">
            Circunferências
          </button>
        </li>
        <li class="nav-item" role="presentation" >
          <button class="nav-link" id="medidas-tab" data-bs-toggle="tab" data-bs-target="#medidas" type="button"
                  role="tab" aria-controls="medidas" aria-selected="true">
            Composição Corporal
          </button>
        </li>
        <li class="nav-item" role="presentation" >
          <button class="nav-link" id="postural-tab" data-bs-toggle="tab" data-bs-target="#postural" type="button"
                  role="tab" aria-controls="postural" aria-selected="true">
            Avaliação Postural
          </button>
        </li>
        <li class="nav-item" role="presentation" >
          <button class="nav-link" id="neuro-tab" data-bs-toggle="tab" data-bs-target="#neuro" type="button"
                  role="tab" aria-controls="neuro" aria-selected="true">
            Neuro Motores
          </button>
        </li>
        <li class="nav-item" role="presentation" >
          <button class="nav-link" id="carga-tab" data-bs-toggle="tab" data-bs-target="#carga" type="button"
                  role="tab" aria-controls="carga" aria-selected="true">
            Teste de Carga
          </button>
        </li>
        <li class="nav-item" role="presentation" >
          <button class="nav-link" id="vo-tab" data-bs-toggle="tab" data-bs-target="#vo" type="button"
                  role="tab" aria-controls="vo" aria-selected="true">
            Vo2 Máx
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade active show" id="anamnese" role="tabpanel" aria-labelledby="anamnese-tab">
          <anamnese-formulario
              :avaliacaoIdProp="state.avaliacaoId"
              v-if="state.displayComponent && state.anamnese == null"
              @salvar="salvarAvaliacao"></anamnese-formulario>
          <anamnese-visualizar v-else :anamneseProp="state.anamnese"></anamnese-visualizar>
        </div>
        <div class="tab-pane fade show" id="circunferencia" role="tabpanel" aria-labelledby="circunferencia-tab">
          <circunferencia-component
              v-if="state.displayComponent"
              @salvar="salvarAvaliacao"
              :avaliacaoIdProp="state.avaliacaoId"
          />
        </div>
        <div class="tab-pane fade" id="medidas" role="tabpanel" aria-labelledby="medidas-tab">
          <medidas-component
              v-if="state.displayComponent"
              @salvar="salvarAvaliacao"
              :avaliacaoIdProp="state.avaliacaoId"
          />
        </div>
        <div class="tab-pane fade" id="postural" role="tabpanel" aria-labelledby="postural-tab">
          <postural-formulario
              v-if="state.displayComponent"
              @salvar="salvarAvaliacao"
              :avaliacaoIdProp="state.avaliacaoId"
          />
        </div>
        <div class="tab-pane fade" id="neuro" role="tabpanel" aria-labelledby="neuro-tab">
          <neuro-motor-formulario
              v-if="state.displayComponent"
              @salvar="salvarAvaliacao"
              :avaliacaoIdProp="state.avaliacaoId"
          />
        </div>
        <div class="tab-pane fade" id="carga" role="tabpanel" aria-labelledby="carga-tab">
          <teste-carga-formulario
              v-if="state.displayComponent"
              @salvar="salvarAvaliacao"
              :avaliacaoIdProp="state.avaliacaoId"
              :localProp="state.assessment_location"
              :dataProp="state.assessment_date"
          />
        </div>
        <div class="tab-pane fade" id="vo" role="tabpanel" aria-labelledby="vo-tab">
          <vo-max-formulario
              v-if="state.displayComponent"
              @salvar="salvarAvaliacao"
              :avaliacaoIdProp="state.avaliacaoId"
          />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { reactive, onMounted, ref, inject, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import CircunferenciaComponent from "./circunferencia/CircunferenciaComponent.vue";
import MedidasComponent from "./composicaoCorporal/MedidasComponent.vue";
import AnamneseFormulario from "./anamnese/AnamneseFormulario.vue";
import AnamneseVisualizar from "./anamnese/AnamneseVisualizar.vue";
import avaliacaoFisicaRepository from "@/services/api/avaliacaoFisicaRepository";
import anamneseRepository from "../../../../services/api/anamneseRepository";
import moment from "moment";
import NeuroMotorFormulario from "./testeneuroMotor/NeuroMotorFormulario.vue";
import VoMaxFormulario from "./voMax/VoMaxFormulario.vue";
import TesteCargaFormulario from "./testeCarga/TesteCargaFormulario.vue"
import posturalFormulario from "./avaliacaoPostural/posturalFormulario.vue"


export default {
  components: {
    TesteCargaFormulario,
    NeuroMotorFormulario,
    VoMaxFormulario,
    AnamneseFormulario,
    CircunferenciaComponent,
    MedidasComponent,
    AnamneseVisualizar,
    posturalFormulario

  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()

    onMounted( () => {
      if(route.params.id && parseInt(route.params.id) > 0) {
        state.avaliacaoId = parseInt(route.params.id)
      }
      state.assessment_date = moment().format("DD/MM/yyyy")
      state.displayComponent = true
      buscarDadosAvaliacao()
    })


    const state = reactive({
      proccess: false,
      composicaoPrimeira: null,
      composicaoAnterior: null,
      composicaoAtual: null,
      circunferenciaPrimeira: null,
      circunferenciaAnterior: null,
      circunferenciaAtual: null,
      avaliacaoId: 0,
      anamnese: null,
      assessment_date: moment().format("DD/MM/yyyy"),
      assessment_location: '',
    })

    const salvarAvaliacao = (item, type) =>  {

      if(state.assessment_location == '') {
        swal({
          text: "O local da avaliação deve ser informado!",
          icon: "info",
          customClass: {
            confirmButton: "btn bg-gradient-info",
          },
          buttonsStyling: false,
        })
        return
      }

      try {

        if(type == 'postural') {
          item.append('id', state.avaliacaoId)
          item.append('type', 'postural')
          item.append('assessment_date', state.assessment_date.split('/').reverse().join('-'))
          item.append('assessment_location', state.assessment_location)

          //return

          store.commit('SET_SPLASH_SCREEN', true)
          avaliacaoFisicaRepository.AvaliacaoPostImage(item).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)
            if (response.data.success) {
              state.avaliacaoId = response.data.data['id']
              swal({
                text: "Dados salvos com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                router.push(`/avaliacao-fisica/formulario/${response.data.data.student_physical_assessment_id}`)
              })
            }
          })
        } else {
          let dataItem = {
            'id': state.avaliacaoId,
            'type': type,
            'item': item,
            'assessment_date': state.assessment_date.split('/').reverse().join('-'),
            'assessment_location': state.assessment_location
          }
          store.commit('SET_SPLASH_SCREEN', true)

          avaliacaoFisicaRepository.AvaliacaoPost(dataItem).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)
            if (response.data.success) {
              state.avaliacaoId = response.data.data['id']
              swal({
                text: "Dados salvos com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                router.push(`/avaliacao-fisica/formulario/${response.data.data.student_physical_assessment_id}`)
              })
            }
          })
        }


      } catch (e) {
        console.log(e)
      } finally {
        store.commit('SET_SPLASH_SCREEN', false)
      }
    }

    async function buscarDadosAvaliacao() {
      store.commit('SET_SPLASH_SCREEN', true)
      try {
        if(state.avaliacaoId) {
          avaliacaoFisicaRepository.AvaliacaoGetById(state.avaliacaoId).then(response => {

            if(response.data.success) {
              state.assessment_date = response.data.data.assessment_date.split('-').reverse().join('/')
              state.assessment_location = response.data.data.assessment_location
            }
          })
        }
        await buscarDadosAnamnese()
      } catch (error) {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error)
      } finally {
        state.proccess = false
        store.commit('SET_SPLASH_SCREEN', false)
      }
    }

    function buscarDadosAnamnese() {
      store.commit('SET_SPLASH_SCREEN', true)
      anamneseRepository.AnamneseGetByAssessmentId(state.avaliacaoId).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        state.anamnese = null

        if (response.data.success && response.data.data.length > 0) {
          state.anamnese = response.data.data
        }

      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        console.log(error)
        swal({
          icon: 'error',
          text: 'Erro ao buscar anamnese'
        })
      }).finally(() => {
        store.commit('SET_SPLASH_SCREEN', false)
      })
    }

    function updateAssessment() {
      if(state.avaliacaoId == 0) return


      let item = {
        assessment_date: state.assessment_date.split('/').reverse().join('-'),
        assessment_location: state.assessment_location
      }

      store.commit('SET_SPLASH_SCREEN', true)
      avaliacaoFisicaRepository.AvaliacaoPut(state.avaliacaoId, item).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)

      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        console.log(error)
        swal({
          icon: 'error',
          text: 'Erro ao atualizar data e local'
        })
      }).finally(() => {
        store.commit('SET_SPLASH_SCREEN', false)
      })
    }



    return {
      state,
      salvarAvaliacao,
      updateAssessment
    }
  }
}

</script>