<template>
  <div
      class="p-3 bg-white card border-radius-xl mt-3 me-3"
      data-animation="FadeIn"
  >
    <div class="row">
      <div class="form-group col-12 col-sm-4">
        <label class="required">Nome do Contrato</label>
        <input
            class="form-control"
            type="text"
            placeholder="Nome do Contrato"
            v-model="state.term.name"
            required
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label class="required">Data inicial do Contrato</label>
        <input
            class="form-control"
            type="text"
            placeholder="Data inicial do Contrato"
            v-model="state.term.initial_date"
            v-maska="'##/##/####'"
            required
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label class="required">Data final do Contrato</label>
        <input
            class="form-control"
            type="text"
            placeholder="Data final do Contrato"
            v-model="state.term.final_date"
            v-maska="'##/##/####'"
            required
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label class="required">Situação</label>
        <select class="form-control  text-center custom-select"
                :disabled="state.statusOriginal === 'i'"
                v-model="state.term.status"
                required
        >
          <option value="a">Ativo</option>
          <option value="i">Inativo</option>
          <option value="d">Rascunho</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label class="required">Contrato</label>
        <Editor
            api-key="a8aunc5elyex78xt7diil5fruk72s21r9i63na5028e7qau8"
            v-model="state.term.term_text"
            :init="{
              toolbar_mode: 'sliding',
              plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
              toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
              tinycomments_mode: 'embedded'
            }"
            initial-value=''
        />
      </div>

    </div>

    <div class="row mt-5">
      <div class="col-12 text-end">
        <button type="button" class="btn bg-gradient-secondary" @click.prevent="voltar">
          Voltar
        </button>
        <button type="button" class="ms-3 btn bg-gradient-primary" @click.prevent="salvar">Salvar</button>
      </div>
    </div>

  </div>
</template>

<script>
import alunoContratosRepository from "../../../../services/api/alunoContratosRepository";
import {inject, onMounted, reactive} from 'vue'
import useVuelidate from '@vuelidate/core'
import {helpers, required} from '@vuelidate/validators'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'

//import { ClassicEditor, Bold, Essentials, Italic, Paragraph, Undo } from 'ckeditor5';
//import CKEditor from '@ckeditor/ckeditor5-vue';
import Editor from '@tinymce/tinymce-vue'
export default {
  components: {
    Editor
    //ckeditor: CKEditor.component
  },
  props: {
    termoIdProp: {
      type: Number,
      default: 0
    }
  },
  setup (props, context) {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()

    onMounted(() => {
      store.commit('SET_SPLASH_SCREEN', false)
      if(props.termoIdProp > 0) {
        state.termoId = props.termoIdProp

        buscarDados()
      }
    })



    const state = reactive({
      termoId: 0,
      term: {
        status: 'd', name: '', term_text: ''
      },
      statusOriginal: ''
    })


    const buscarDados = () => {

      store.commit('SET_SPLASH_SCREEN', true)
      alunoContratosRepository.TermoGetById(state.termoId).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)

        if (response.data.success) {
          state.statusOriginal = response.data.data['status']
          state.term = Object.assign({}, response.data.data)
          state.term.initial_date = state.term.initial_date.split('-').reverse().join('/')
          state.term.final_date = state.term.final_date.split('-').reverse().join('/')
        }
      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        swal({
          icon: 'error',
          title: 'Erro ao listar dados do Contrato!',
          text: error.message
        })
      })
    }

    async function salvar()  {
      if(state.statusOriginal == 'i' && state.term.status != 'i') {
        swal({
          text: "Não é possivel alterar o status de um Contrato inativo!",
          icon: "info",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
        return

      }
      //return

      // student.phone  = this.$filters.justNumbers(state.student.phone)
      // student.cpf  = this.$filters.justNumbers(state.student.cpf)
      store.commit('SET_SPLASH_SCREEN', true)
      const term = {
        initial_date: state.term.initial_date.split('/').reverse().join('-'),
        final_date: state.term.final_date.split('/').reverse().join('-'),
        name: state.term.name,
        status: state.term.status,
        term_text: state.term.term_text
      }
      if(state.termoId === 0) {
        alunoContratosRepository.TermoPost(term).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            swal({
              title: "Contrato",
              text: "Contrato cadastrado com sucesso!",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            }).then(() => {
              console.log('OK')
              context.emit('fecharForm')
            })
          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao salvar Contrato',
            text: error.message
          })
        })
      } else {
        alunoContratosRepository.TermoPut(state.termoId, term).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            swal({
              title: "Contrato",
              text: "Contrato atualizado com sucesso!",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            }).then(() => {
              console.log('OK 2')
              context.emit('fecharForm')
            })
          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao atualizar aluno!',
            text: error.message
          })
        })
      }
    }

    function voltar () {
      context.emit('fecharForm')
    }

    return {
      state,
      salvar,
      voltar

    }
  }
}
</script>

<style scoped>

</style>