<template>
  <div class="card card-body mb-2">
    <div class="row text-end">
      <div class="col-12">
        <button type="button" class="mb-0 btn bg-gradient-success btn-sm" @click="novoGrupo()">
          +&nbsp; Novo grupo
        </button>
      </div>
    </div>
    <div class="row">
      <div class="mt-3  col-12 mt-sm-0 col-md-4">
        <label class="required">Modalidade</label>
        <select
          class="form-control custom-select"
          name="modalidades"
          required
          id="select-modalidades"
          v-model="state.modalidadeSelecionadaId"
          @change="selecionaModalidade"
        >
          <option value="">Selecione uma modalidade</option>
          <option v-for="(modalidade, index) in state.modalidades" :key="index" :value="modalidade.id">{{ modalidade.name }}</option>
        </select>
        <!-- <span class="ms-3 text-error text-danger" v-if="v$.modalidadeSelecionadaId.$error"> {{ v$.modalidadeSelecionadaId.$errors[0].$message }} </span> -->
      </div>
      <div class="mt-3 col-12 mt-sm-0  col-md-4">
        <label class="required">Nome do grupo</label>
        <input
          class="form-control"
          type="text"
          required
          placeholder="Informe o nome da grupo"
          v-model="state.grupo.name"
        />
        <span class="ms-3 text-error text-danger" v-if="v$.grupo.name.$error"> {{ v$.grupo.name.$errors[0].$message }} </span>
      </div>
      <div class="align-self-end col-12 col-md-4" :class="{ 'align-self-center': v$.grupo.name.$error}">
        <button type="button" class="btn bg-gradient-primary mb-0" @click.prevent="salvar">Salvar</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive, onMounted, ref, inject, watch } from 'vue'
  import { useStore } from 'vuex'
  import grupoRepository from "../../../../../services/api/grupoRepository";
  import modalidadeRepository from "../../../../../services/api/modalidadeRepository";
  import useVuelidate from '@vuelidate/core'
  import { rulesMessages } from './../../../../../services/validate.messages.service'

  export default {
    name: "grupo-formulario",
    props: {
      grupoId: {
        type: Number,
        default: 0
      },
      modalidadeId: {
        type: Number,
        default: 0
      },
      show: {
        type: Boolean,
        default: false,
      }
    },
    setup(props, context) {
      const swal = inject('$swal')
      // const emit = defineEmits(['closeModal'])
      const store = useStore()
      onMounted(() => {
        store.commit('SET_SPLASH_SCREEN', false)
        buscarModalidades()
        
        
      })

      watch(() => props.grupoId, () => {
        if(props.grupoId > 0) {
          state.grupo.id = props.grupoId

          buscarGrupo()
        } else {
          state.grupo.name = ''
          state.grupo.id = 0
        }
      })

      // let modalidadeSelecionadaId = ref('0')

      const state = reactive({
        modalidades: [],
        grupo: {
          id: 0,
          name: '',
          modality_id: 0
        },
        modalidadeSelecionadaId: ''
      })

      const buscarModalidades = () => {
        store.commit('SET_SPLASH_SCREEN', true)
        modalidadeRepository.ModalidadeGet().then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            state.modalidades = response.data.data

            if(props.modalidadeId > 0) {
              state.modalidadeSelecionadaId = props.modalidadeId
            }
            // var element = document.getElementById('select-modalidades');
            // const selectModalidades = new Choices(element, {
            //   searchEnabled: false,
            // })
            // selectModalidades.setChoices(state.modalidades, 'id', 'name')
          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao buscar modalidades!',
            text: error.message
          })
        })
      }

      const buscarGrupo = () => {
        store.commit('SET_SPLASH_SCREEN', true)
        grupoRepository.GrupoGetById(props.grupoId).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            state.grupo.id = response.data.data.id
            state.grupo.name = response.data.data.name
            state.grupo.modality_id = response.data.data.modality_id
            state.modalidadeSelecionadaId = state.grupo.modality_id
            // console.log(modalidadeSelecionadaId.value)
            // selectModalidades.setChoiceByValue(modalidadeSelecionadaId.value)
          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao buscar grupo!',
            text: error.message
          })
        })
      }

      const novoGrupo = () => {
        state.grupo.id = 0
        state.grupo.name = ''
        state.grupo.modality_id = ''
        state.modalidadeSelecionadaId = ''
      }

      function salvar() {
        validate()
        if(state.modalidadeSelecionadaId == '') return
        store.commit('SET_SPLASH_SCREEN', true)
        if(state.grupo.id == 0) {
          grupoRepository.GrupoPost({name: state.grupo.name, modality_id: state.modalidadeSelecionadaId}).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)
            if (response.data.success) {
              swal({
                title: "Grupo!",
                text: "Grupo cadastrada com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                state.grupo.name = ''
                state.grupo.id = 0
                context.emit('selecionaModalidade', true, state.modalidadeSelecionadaId)
              })
            } 
          }).catch((error) => {
            store.commit('SET_SPLASH_SCREEN', false)
            swal({
              icon: 'error',
              title: 'Erro ao salvar grupo',
              text: error.message
            })
          })
        } else {
          grupoRepository.GrupoPut(state.grupo.id, {name: state.grupo.name, modality_id: state.modalidadeSelecionadaId}).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)
            if (response.data.success) {
              swal({
                title: "Grupo!",
                text: "Grupo atualizado com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                state.grupo.name = ''
                state.grupo.id = 0
                context.emit('selecionaModalidade', true, state.modalidadeSelecionadaId)
              })
            } 
          }).catch((error) => {
            store.commit('SET_SPLASH_SCREEN', false)
            swal({
              icon: 'error',
              title: 'Erro ao atualizar grupo!',
              text: error.message
            })
          })
        }
      }

      const selecionaModalidade = () => {
        context.emit('selecionaModalidade', false, state.modalidadeSelecionadaId)
      }

      const validate = async () => {
        const result = await v$.value.$validate()
        return result
      }

      const rules = {
        grupo: {
          name: { required: rulesMessages().required },
          // modality_id: { required }
        }
      }

      const v$ = useVuelidate(rules, state)

      return {
        salvar,
        state,
        novoGrupo,
        v$,
        selecionaModalidade
      }
    }
  };
</script>
