import Repository from './repository'

const resource = '/students'

export default {
  AlunoGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  AlunoGetCurrent (id) {
    return Repository.get(`${resource}/current`)
  },
  AlunoBloqueadoGet (id) {
    return Repository.get(`${resource}/block`)
  },
  AlunoGet () {
    return Repository.get(`${resource}`)
  },
  AlunoPost (item) {
    return Repository.post(`${resource}`, item)
  },
  AlunoPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  AlunoDelete (planoId) {
    return Repository.delete(`${resource}/${planoId}`)
  }
}
