<template>
  <div class="card border-top-start-radius-0 border-top-end-radius-0">
    <termo-adesao-form
        :termoIdProp="state.termoId"
        v-if="showForm"
        @fecharForm="formularioTermo(false)"
    />
    <div class="card-body" v-else>
      <div class="d-lg-flex">
        <div class="my-auto mt-4 ms-auto mt-lg-0">
          <div class="my-auto ms-auto">
            <button type="button" class="mb-0 btn bg-gradient-success btn-sm" @click="formularioTermo(true)">
              +&nbsp; Novo Contrato
            </button>
          </div>
        </div>
      </div>
      <MyTable
          :data="state.termosLista"
          :columns="colunas"
          :itemsPerPage="12">
        <template v-slot:row="{ row }">
          <td class="text-sm">
            {{ row.name }}
          </td>
          <td class="text-sm text-center">
            {{ row.status == 'd' ? 'Rascunho' : row.status == 'a' ? 'Ativo' : 'Inativo' }}
          </td>
          <td class="text-sm text-center">
            {{ $filters.datePtbr(row.initial_date) }} - {{ $filters.datePtbr(row.final_date) }}
          </td>
          <td  class="text-sm ">
            <div class="float-end">
              <div>
                <span
                    class="pointer"
                    data-bs-toggle="tooltip"
                    data-bs-original-title="Editar Contrato"
                    title="Editar Contrato"
                    data-bs-placement="top"
                    v-tooltip
                    @click="editarTermo(row.id)"
                >
                  <i class="fas fa-pen text-secondary me-2"></i>
                  Editar

                </span>
              </div>
              <div>
                <span
                    class="pointer"
                    data-bs-toggle="tooltip"
                    data-bs-original-title="Deletar Contrato"
                    title="Deletar Contrato"
                    data-bs-placement="top"
                    v-tooltip
                    @click="deletarTermo(row)"
                >
                  <i class="fas fa-trash text-secondary me-2"></i>
                  Deletar
                </span>
              </div>
            </div>
          </td>
        </template>
      </MyTable>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted, ref, inject, computed } from 'vue'
import { useStore } from 'vuex'
import alunoContratosRepository from "../../../../services/api/alunoContratosRepository";
import MyTable from '../../../../components/MyTable.vue';
import { useRouter, useRoute } from 'vue-router'
import TermoAdesaoForm from "./alunoTermoAdesaoForm.vue";

export default {
  components: {
    TermoAdesaoForm,
    MyTable
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()
    onMounted(() => {
      store.commit('SET_SPLASH_SCREEN', false)
      listarTermos()
    })

    let showForm= ref(false);
    const colunas = ref([
      {
        label: 'Nome',
        field: 'name',
        sortable: 'name'
      },
      {
        label: 'Status',
        field: 'status',
        sortable: 'status'
      },
      {
        label: 'Período',
        field: 'initial_date',
        sortable: ''
      },
      {
        label: 'Ações',
        field: 'actions'
      }
    ])

    const state = reactive({
      termosLista: [],
      termoId: 0
    })

    const listarTermos = () => {
      store.commit('SET_SPLASH_SCREEN', true)
      alunoContratosRepository.TermosGet().then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        state.termosLista = []

        if (response.data.success) {
          state.termosLista = response.data.data

        }
      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        swal({
          icon: 'error',
          title: 'Erro ao listar Contratos!',
          text: error.message
        })
      })
    }


    function editarTermo(id) {
      state.termoId = id
      formularioTermo(true)
    }

    function deletarTermo(item) {

      swal({
        title: "Tem certeza?",
        text: `Deseja deletar ${item.name}`,
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        cancelButtonText: "Não, cancelar!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          deletarTermoConfirma(item.id)
        }
      })
    }

    function deletarTermoConfirma(id) {
      store.commit('SET_SPLASH_SCREEN', true)
      alunoContratosRepository.TermoDelete(id).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if(response.data.success) {
          swal({
            title: "Removido!",
            text: "Contrato removido com sucesso!",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          }).then(() => {
            listarTermos()
          })
        }
      }).catch(error => {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error.message)
        swal({
          icon: 'error',
          title: 'Erro ao deletar Contrato!',
          text: error.message
        })
      })
    }

    function formularioTermo(show = true) {
      showForm.value = show
      if(!show) {
        state.termoId = 0
        listarTermos()
      }
    }



    return {
      state,
      colunas,
      editarTermo,
      deletarTermo,
      formularioTermo,
      showForm
    }
  }
}
</script>