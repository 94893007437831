<template>
  <div class="card card-body mb-2">
    <div class="row text-end">
      <div class="col-12">
        <button type="button" class="mb-0 btn bg-gradient-success btn-sm" @click="novaModalidade()">
          +&nbsp; Nova modalidade
        </button>
      </div>
    </div>
    <div class="row">
      <div class="mt-3 col-12 col-md-6 mt-sm-0">
        <label class="required">Nome da modalidade</label>
        <input
          class="form-control"
          type="text"
          placeholder="Informe o nome da modalidade"
          v-model="modalidade.name"
        />
        <span class="ms-3 text-error text-danger" v-if="v$.name.$error"> {{ v$.name.$errors[0].$message }} </span>
      </div>
      <div class="align-self-end col-12 col-md-6" :class="{ 'align-self-center': v$.name.$error}">
        <button type="button" class="btn bg-gradient-primary mb-0" @click.prevent="salvar">Salvar</button>
      </div>
    </div>
    
  </div>
</template>

<script>
  import { reactive, onMounted, inject, watch } from 'vue'
  import { useStore } from 'vuex'
  import modalidadeRepository from "../../../../../services/api/modalidadeRepository";
  import useVuelidate from '@vuelidate/core'
  import { rulesMessages } from './../../../../../services/validate.messages.service'

  export default {
    name: "modalidade-formulario",
    props: {
      modalidadeId: {
        type: Number,
        default: 0
      },
      show: {
        type: Boolean,
        default: false,
      }
    },
    setup(props, context) {
      const store = useStore()
      const swal = inject('$swal')
      // const emit = defineEmits(['closeModal'])

      onMounted(() => {
        store.commit('SET_SPLASH_SCREEN', false)
      })

      watch(() => props.modalidadeId, () => {
        if(props.modalidadeId > 0) {
          modalidade.id = props.modalidadeId

          buscarModalidade()
        } else {
          modalidade.name = ''
          modalidade.id = 0
        }
      })

      const modalidade = reactive({
        id: 0,
        name: ''
      })

      const buscarModalidade = () => {
        store.commit('SET_SPLASH_SCREEN', true)
        modalidadeRepository.ModalidadeGetById(props.modalidadeId).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if (response.data.success) {
            modalidade.id = response.data.data.id
            modalidade.name = response.data.data.name
          }
        }).catch((error) => {
          store.commit('SET_SPLASH_SCREEN', false)
          swal({
            icon: 'error',
            title: 'Erro ao buscar modalidade!',
            text: error.message
          })
        })
      }

      const validate = async () => {
        const result = await v$.value.$validate()
        return result
      }

      function salvar() {
        if(!validate()) return
        store.commit('SET_SPLASH_SCREEN', true)
        if(modalidade.id == 0) {
          modalidadeRepository.ModalidadePost({name: modalidade.name}).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)
            if (response.data.success) {
              swal({
                title: "Modalidade!",
                text: "Modalidade cadastrada com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                modalidade.name = ''
                modalidade.id = 0
                context.emit('closeModal')
              })
            } 
          }).catch((error) => {
            store.commit('SET_SPLASH_SCREEN', false)
            swal({
              icon: 'error',
              title: 'Erro ao salvar modalidade!',
              text: error.message
            })
          })
        } else {
          modalidadeRepository.ModalidadePut(modalidade.id, {name: modalidade.name}).then(response => {
            store.commit('SET_SPLASH_SCREEN', false)
            if (response.data.success) {
              swal({
                title: "Modalidade!",
                text: "Modalidade atualizada com sucesso!",
                icon: "success",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              }).then(() => {
                modalidade.name = ''
                modalidade.id = 0
                context.emit('closeModal')
              })
            } 
          }).catch((error) => {
            store.commit('SET_SPLASH_SCREEN', false)
            swal({
              icon: 'error',
              title: 'Erro ao atualizar modalidade!',
              text: error.message
            })
          })
        }
      }

      const novaModalidade = () => {
        metodo.name = ''
        metodo.id = 0
      }

      const rules = {
        name: { required: rulesMessages().required }
      }

      const v$ = useVuelidate(rules, modalidade)

      return {
        salvar,
        modalidade,
        v$,
        novaModalidade
      }
    }
  };
</script>
