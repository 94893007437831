import Repository from './repository'

const resource = '/payment-conditions'

export default {
  // AlunoGetById (id) {
  //   return Repository.get(`${resource}/${id}`)
  // },
  CondicaoGet () {
    return Repository.get(`${resource}`)
  },
  // AlunoPost (item) {
  //   return Repository.post(`${resource}`, item)
  // },
  // AlunoPut (id, item) {
  //   return Repository.put(`${resource}/${id}`, item)
  // },
  // AlunoDelete (planoId) {
  //   return Repository.delete(`${resource}/${planoId}`)
  // }
}
