export default class NeuroMotorModel {
    '50m' = 0
    '60m' = 0
    '100m' = 0
    '200m' = 0
    '300m' = 0
    '400m' = 0
    '600m' = 0
    '800m' = 0
    '1000m' = 0
    '1500m' = 0
    '5000m' = 0
    'free_vertical_jump' = ''
    'vertical_box_jump' = ''
    'horizontal_jump' = ''
    'student_physical_assessment_id' = 0
}
