<template>
<!--  <div class="calendar" data-bs-toggle="calendar" id="calendar"></div>-->
  <div>

    <FullCalendar :options="state.calendarOptions"  ref="calendarRef"/>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import { reactive, onMounted, ref, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import planosTreinoRepository from "../../../../services/api/planosTreinoRepository"


export default {
  name: "calendar-card",
  components: {
    FullCalendar
  },
  setup (props, {emit}) {
    const router = useRouter()
    const swal = inject('$swal')
    const store = useStore()
    onMounted( () => {
      buscaDiasTreino()

    })

    // Ref para o calendário
    const calendarRef = ref(null);


    const state = reactive({
      date: new Date(),
      dataSelecionada: '',
      calendarOptions: {
        locale: ptBrLocale, // Definindo o idioma para pt-br
        contentHeight: "auto",
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        weekends: true,
        headerToolbar: {
          start: "title", // will normally be on the left. if RTL, will be on the right
          center: "",
          end: "today prev,next", // will normally be on the right. if RTL, will be on the left
        },
        views: {
          month: {
            titleFormat: {
              month: "long",
              year: "numeric",
            },
          },
          /*agendaWeek: {
            titleFormat: {
              month: "long",
              year: "numeric",
              day: "numeric",
            },
          },
          agendaDay: {
            titleFormat: {
              month: "short",
              year: "numeric",
              day: "numeric",
            },
          },*/
        },
        events: [],
        eventBackgroundColor: '#344767',
        dateClick: function(event, jsEvent, view) {

          const currentView = calendarRef.value.getApi();

          // Limpa a cor de fundo da data anterior
          if (state.dataSelecionada) {
            currentView.el.querySelectorAll('.fc-day').forEach((day) => {
              const dateStr = day.getAttribute('data-date');
              if (dateStr === state.dataSelecionada) {
                day.style.backgroundColor = ''
              }
            });
          }
          event.dayEl.style.backgroundColor = '#344767';
          // Aqui você pode executar qualquer ação desejada quando o usuário clicar em uma data.
          // Neste exemplo, exibimos um alerta com a data clicada.
          state.dataSelecionada = event.dateStr

          if(state.calendarOptions.events.find(item => item.date === state.dataSelecionada)) {
            handleDateClick(event.dateStr);
          }
        },
      }
    })

    const handleDateClick = (date) => {
      emit('selecionaDiaTreino', date)
    }

    function buscaDiasTreino() {
      store.commit('SET_SPLASH_SCREEN', true)
      //planosTreinoRepository.DiasDeTreinoGet('2020-01-01', '2023-12-31').then(response => {
      planosTreinoRepository.DiasDeTreinoPlanoAtualGet().then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if(response.data.success) {
          response.data.data.forEach(obj => {
            let item = { date: obj.training_date, display: 'background'}
            state.calendarOptions.events.push(item)
          })
        }
      }).catch(error => {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error.message)
        swal({
          icon: 'error',
          title: 'Erro ao buscar dias de treino!',
          text: error.message
        })
      })
    }

    return {
      state,
      calendarRef
    }
  }

};
</script>