import Repository from './repository'

const resource = '/period'

export default {
  PeriodoGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  PeriodoGet () {
    return Repository.get(`${resource}`)
  },
  PeriodoPost (item) {
    return Repository.post(`${resource}`, item)
  },
  PeriodoPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  PeriodoDelete (planoId) {
    return Repository.delete(`${resource}/${planoId}`)
  }
}
