<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-3 col-sm-6 mt-sm-0">
        <card
          :title="stats.money.title"
          :value="stats.money.value"
          :percentage="stats.money.percentage"
          :iconClass="stats.money.iconClass"
          :iconBackground="stats.iconBackground"
          directionReverse
        >
        </card>
      </div>
      <div class="col-lg-3 col-sm-6 mt-sm-0">
        <card
          :title="stats.users.title"
          :value="stats.users.value"
          :percentage="stats.users.percentage"
          :iconClass="stats.users.iconClass"
          :iconBackground="stats.iconBackground"
          directionReverse
        >
        </card>
      </div>
      <div class="col-lg-3 col-sm-6 mt-sm-0">
        <card
          :title="stats.sales.title"
          :value="stats.sales.value"
          :percentage="stats.sales.percentage"
          :iconClass="stats.sales.iconClass"
          :iconBackground="stats.iconBackground"
          directionReverse
        >
        </card>
      </div>
      <div class="col-lg-3 col-sm-6 mt-sm-0">
        <card
          :title="stats.clients.title"
          :value="stats.clients.value"
          :percentage="stats.clients.percentage"
          :iconClass="stats.clients.iconClass"
          :iconBackground="stats.iconBackground"
          :percentageColor="stats.clients.percentageColor"
          directionReverse
        >
        </card>
      </div>
      <div class="col-6 mt-sm-0">
        <card
          :title="stats.sales1.title"
          :value="stats.sales1.value"
          :percentage="stats.sales1.percentage"
          :iconClass="stats.clients.iconClass"
          :iconBackground="stats.sales1.iconBackground"
          :percentageColor="stats.sales1.percentageColor"
          directionReverse
        >
        </card>
      </div>
      <div class="col-6 mt-sm-0">
        <card
          :title="stats.sales2.title"
          :value="stats.sales2.value"
          :percentage="stats.sales2.percentage"
          :iconClass="stats.clients.iconClass"
          :iconBackground="stats.sales2.iconBackground"
          :percentageColor="stats.sales2.percentageColor"
          directionReverse
        >
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card pb-5">
          <div class="p-3 pb-0">
            <div class="row justify-content-center">
              <div class="col-12 text-center">
                Quadro comparativo de evolução
              </div>
              <div class="col-6">
                <label class="form-label mt-2">Selecione o aluno</label>
                <select class="form-control" name="dashboard-alunos" id="dashboard-alunos">
                  <option value="">Selecione aqui</option>
                  <option value="">Maria</option>
                  <option value="">André</option>
                  <option value="">Marcelo</option>
                  <option value="">Fernanda</option>
                </select>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 table-responsive">
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr class="text-center">
                      <th></th>
                      <th colspan="3" class="font-weight-bold">Previsto</th>
                      <th colspan="3" class="font-weight-bold">Realizado</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th>Mês</th>
                      <th>Ano 2022</th>
                      <th>Acumulado</th>
                      <th>Mês</th>
                      <th>Ano 2022</th>
                      <th>Acumulado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Tempo Total de Treino</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>                      
                    </tr>
                    <tr>
                      <td class="font-weight-bold">N. de Treino Realizados</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">N. de Treinos Não Realiz.</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">N. Total de Repetições</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">N. Total de Séries</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">N. Total de Tonelagem</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">N. Total de Saltos</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">PSE Média</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">PSR Média</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Média de Autoridade Arb.</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12 col-md-10">
        <div class="card">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-2">Sales by Country</h6>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table align-items-center">
              <tbody>
                <tr v-for="(sale, index) in sales" :key="index">
                  <td class="w-30">
                    <div class="px-2 py-1 d-flex align-items-center">
                      <div>
                        <img :src="sale.flag" alt="Country flag" />
                      </div>
                      <div class="ms-4">
                        <p class="mb-0 text-xs font-weight-bold">
                          Country:
                        </p>
                        <h6 class="mb-0 text-sm">{{ sale.country }}</h6>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                      <p class="mb-0 text-xs font-weight-bold">Sales:</p>
                      <h6 class="mb-0 text-sm">{{ sale.sales }}</h6>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                      <p class="mb-0 text-xs font-weight-bold">Value:</p>
                      <h6 class="mb-0 text-sm">{{ sale.value }}</h6>
                    </div>
                  </td>
                  <td class="text-sm align-middle">
                    <div class="text-center col">
                      <p class="mb-0 text-xs font-weight-bold">Bounce:</p>
                      <h6 class="mb-0 text-sm">{{ sale.bounce }}</h6>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="mt-4 row">
      <div class="mb-4 col-lg-5 mb-lg-0">
        <div class="card z-index-2">
          <div class="p-3 card-body">
            
            <active-users-chart />
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="card z-index-2">
          <gradient-line-chart />
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import Card from "../../../components/Cards/Card.vue";
import ActiveUsersChart from "../../../components/Charts/ActiveUsersChart.vue";
import GradientLineChart from "../../../components/Charts/GradientLineChart.vue";

import US from "../../../assets/img/icons/flags/US.png";
import DE from "../../../assets/img/icons/flags/DE.png";
import GB from "../../../assets/img/icons/flags/GB.png";
import BR from "../../../assets/img/icons/flags/BR.png";

export default {
  name: "dashboard-default",
  data() {
    return {
      stats: {
        iconBackground: "bg-gradient-success",
        money: {
          title: "Total de alunos",
          value: "20",
          percentage: "",
          iconClass: "ni ni-single-02",
        },
        users: {
          title: "Alunos sexo masculino",
          value: "2,300",
          percentage: "+3%",
          iconClass: "ni ni-single-02",
        },
        clients: {
          title: "Total geral de treinos",
          value: "5",
          percentage: "+33%",
          iconClass: "ni ni-single-02",
          
        },
        sales: {
          title: "Alunos sexo feminino",
          value: "0",
          percentage: "0%",
          iconClass: "ni ni-single-02",
        },
        sales1: {
          title: "Total de alunos em dia",
          value: "15",
          percentage: "75%",
          iconClass: "ni ni-single-02",
        },
        sales2: {
          title: "Total de alunos em atraso",
          value: "5",
          percentage: "-20%",
          iconClass: "ni ni-single-02",
          percentageColor: "text-danger",
        },
      },
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  components: {
    Card,
    ActiveUsersChart,
    GradientLineChart,
  },
};
</script>
