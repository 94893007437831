<template>
	<div class="d-flex align-items-baseline">
		<label for="" class="font-weight-bold text-white text-sm me-3 custom-select">Aluno</label>
		<select
			class="form-control  form-control-sm"
			name="student-manager"
			required
			id="select-student-manager"
			v-model="state.studentId"
			@change="setStudentManager()"
		>
			<option value="">Selecione um aluno</option>
			<option v-for="(item, index) in state.alunos" :key="index" :value="item.id">{{ item.name }}</option>
		</select>
	</div>
</template>

<script>
	import { reactive, onMounted, ref, inject, watch } from "vue";
	import { useStore } from "vuex";
	import alunoRepository from "../../services/api/alunoRepository";
	export default {
		setup() {
			const store = useStore()

			const state = reactive({
				studentId: '',
				alunos: []
			})

			onMounted(() => {
        store.commit('SET_SPLASH_SCREEN', false)
        if(store.getters.StudentManager != null) {
          state.studentId = store.getters.StudentManager
        }

        listarAlunos()
      })

			const listarAlunos = () => {
        alunoRepository.AlunoGet().then(response => {
          state.alunos = []
          if (response.data.success) {
            state.alunos = response.data.data
          }
        }).catch((error) => {
					console.error(error)
          swal({
            icon: 'error',
            title: 'Erro ao listar alunos!',
            text: error.message
          })
        })
      }

			const setStudentManager = () => {
				let id = parseInt(state.studentId)
				store.commit('SET_STUDENT',  id > 0 ? id : null)
				store.commit('SET_TRAINING_PLAN', null)
				window.location.reload()
			}
			return {
				state,
				setStudentManager
			};
		},
	};
</script>

<style>
</style>>