import Repository from './repository'

const resource = '/coach-administration'

export default {
  studentPaymentsTotalsGet (periodo) {
    return Repository.get(`${resource}/students-payments/totals/${periodo}`)
  },
  studentPaymentsGet (periodo) {
    return Repository.get(`${resource}/students-payments/${periodo}`)
  },
  studentPaymentsStudentIdGet (id) {
    return Repository.get(`${resource}/students-payments/details/${id}`)
  },
  studentPaymentPost (item) {
    return Repository.post(`${resource}/students-payments`, item)
  },
  studentPaymentPut (id, item) {
    return Repository.put(`${resource}/students-payments/${id}`, item)
  },
  studentPaymentDelete (id) {
    return Repository.delete(`${resource}/students-payments/${id}`)
  },
  coachPagePost(item) {
    return Repository.post(`${resource}/my-page`, item, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  coachPageGet() {
    return Repository.get(`${resource}/my-page`)
  },
  coachPageVideoDelete() {
    return Repository.delete(`${resource}/my-page/video`)
  },
  coachPageImageDelete() {
    return Repository.delete(`${resource}/my-page/image`)
  },
  coachPageGalleryDelete(id) {
    return Repository.delete(`${resource}/my-page/gallery/${id}`)
  },
}
