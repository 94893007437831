<template>
  <transition name="fade">
    <div class="modal-mask" v-if="show"  >
      <div class="modal fade " :class="{ show: show, 'd-block': show }" id="pseModal" tabindex="-1" role="dialog" aria-labelledby="pseModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" >PSE</h5>
              <button type="button" class="btn-close text-dark" @click="salvar" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <span>
                    Qual foi seu nível de cansaço nesta sessão de treino?
                  </span>
                  <div class="text-sm my-3">
                    10 - Esforço Máximo <br>
                    9 -<br>
                    8 -<br>
                    7 - Muito Forte<br>
                    6 -<br>
                    5 - Forte<br>
                    4 - Um Pouco Forte<br>
                    3 - Moderado<br>
                    2 - Fraco<br>
                    1 - Muito Fraco
                  </div>
                </div>
                <div class="col-12">
                  <input
                      class="form-control"
                      type="text"
                      required
                      v-maska="'##'"
                      placeholder="Informe sua nota"
                      v-model="state.nota"
                  />
                  <span class="ms-3 text-error text-danger" v-if="v$.nota.$error"> Obrigatório informar uma nota </span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-gradient-primary" @click.prevent="salvar">Salvar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { reactive, onMounted, ref, inject } from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: "pse-modal",
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, {emit}) {
    const swal = inject('$swal')
    // const emit = defineEmits(['closeModal'])
    const store = useStore()
    onMounted(() => {
      store.commit('SET_SPLASH_SCREEN', false)
    })

    // let modalidadeSelecionadaId = ref('0')

    const state = reactive({
      nota: ''
    })

    const fechaPseModal = () => {
      window.localStorage.setItem('pse', state.nota);
      emit('fechaPseModal')
    }


    function salvar() {
      validate()
      let nota = parseInt(state.nota)
      if(nota > 0 && nota < 11) {
        window.localStorage.setItem('pse', state.nota);
        emit('fechaPseModal')
      }
    }

    const validate = async () => {
      const result = await v$.value.$validate()
      return result
    }

    const rules = {
      nota: { required },
    }

    const v$ = useVuelidate(rules, state)

    return {
      state,
      salvar,
      fechaPseModal,
      v$
    }
  }
};
</script>
