import Repository from './repository'

const resource = '/kpi'

export default {
  TrainingAverageTotalsGet (planId, weekId, type = null) {
    if(type == null) {
      return Repository.get(`${resource}/training-average-totals/${planId}/${weekId}`)
    }
    return Repository.get(`${resource}/training-average-totals/${planId}/${weekId}/${type}`)

  },
  TrainingResumeGet () {
    return Repository.get(`${resource}/total-trainings-resume`)
  },
  CaloricExpenditureGet (trainingDate) {
    return Repository.get(`${resource}/caloricExpenditure/${trainingDate}`)
  }
}
