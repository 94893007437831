import Repository from './repository'

const resource = '/plan-cycles'

export default {
  PlanoCiclosGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  PlanoCiclosGet () {
    return Repository.get(`${resource}`)
  },
  MacroCiclosGet () {
    return Repository.get(`${resource}/type/3`)
  },
  MesoCiclosGet () {
    return Repository.get(`${resource}/type/2`)
  },
  MicroCiclosGet () {
    return Repository.get(`${resource}/type/1`)
  },
  PlanoCiclosPost (item) {
    return Repository.post(`${resource}`, item)
  },
  PlanoCiclosPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  PlanoCiclosDelete (planoId) {
    return Repository.delete(`${resource}/${planoId}`)
  }
}
