import Repository from './repository'

const resource = '/step'

export default {
  EtapaGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  EtapaGet () {
    return Repository.get(`${resource}`)
  },
  EtapaPost (item) {
    return Repository.post(`${resource}`, item)
  },
  EtapaPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  EtapaDelete (planoId) {
    return Repository.delete(`${resource}/${planoId}`)
  }
}
