<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
    style="overflow-x: hidden;"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
          navText="Dashboard"
          v-bind:collapse="false"
          url="#"
          :aria-controls="''"
          collapseRef="/dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
        >
          <template v-slot:icon>
            <icon name="dashboard" />
          </template>
        </sidenav-collapse>
      </li>
      <!-- CADASTROS -->
      <li class="nav-item">
        <sidenav-collapse
          collapseRef="cadastros"
          navText="Cadastros"
          :class="getRoute() === 'cadastros' ? 'active' : ''"
        >
          <template v-slot:icon>
            <icon name="applications" />
          </template>
          <template v-slot:list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->

              <sidenav-item
                :to="{ name: 'Metodo' }"
                miniIcon="M"
                text="Métodos"
              />
              <sidenav-item
                :to="{ name: 'Modalidade' }"
                miniIcon="M"
                text="Modalidade"
              />
              <sidenav-item
                :to="{ name: 'Grupos' }"
                miniIcon="G"
                text="Grupos"
              />
              <sidenav-item
                :to="{ name: 'Exercicios' }"
                miniIcon="E"
                text="Exercícios"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <!-- ATLETAS -->
      <li class="nav-item">
        <sidenav-collapse
        class=""
          navText="Atletas/Alunos"
          v-bind:collapse="false"
          url="/alunos"
          :aria-controls="''"
          collapseRef="/alunos"
          :class="getRoute() === 'alunos' ? 'active' : ''"
        >
          <template v-slot:icon>
            <icon name="changelog" />
          </template>
        </sidenav-collapse>
      </li>
      <!-- PLANO DE TREINO -->
      <li class="nav-item">
        <sidenav-collapse
          navText="Planos de treino"
          v-bind:collapse="false"
          url="/planos-de-treino"
          :aria-controls="''"
          collapseRef="/planos-de-treino"
          :class="getRoute() === 'planos-de-treino' ? 'active' : ''"
        >
          <template v-slot:icon>
            <icon name="changelog" />
          </template>
        </sidenav-collapse>
      </li>

      <!-- AVALIACAO FISICA-->
      <li class="nav-item">
        <sidenav-collapse
            navText="Avaliação Física"
            v-bind:collapse="false"
            url="/avaliacao-fisica"
            :aria-controls="''"
            collapseRef="/avaliacao-fisica"
            :class="getRoute() === 'avaliacao-fisica' ? 'active' : ''"
        >
          <template v-slot:icon>
            <icon name="changelog" />
          </template>
        </sidenav-collapse>
      </li>
<!--      <li class="nav-item">
        <sidenav-collapse
          collapseRef="avaliacaofisica"
          navText="Avaliação Física"
          :class="getRoute() === 'avaliacao' ? 'active' : ''"
        >
          <template v-slot:icon>
            <icon name="applications" />
          </template>
          <template v-slot:list>
            <ul class="nav ms-4 ps-3">
              &lt;!&ndash; nav links &ndash;&gt;

              <sidenav-item
                :to="{ name: 'Anamnese' }"
                miniIcon="A"
                text="Anamnese"
              />
              &lt;!&ndash; <sidenav-item
                :to="{ name: 'Em Desenvolvimento' }"
                miniIcon="C"
                text="Circunferência"
              />
              <sidenav-item
                :to="{ name: 'Em Desenvolvimento' }"
                miniIcon="CC"
                text="Composição corporal"
              /> &ndash;&gt;
              <sidenav-item
                  to="/teste-carga"
                  miniIcon="T"
                  text="Teste de carga"
              />
              <sidenav-item
                  to="/composicao-corporal"
                  miniIcon="B"
                  text="Composição corporal"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>-->

      <!-- elaborador de treino -->
      <li class="nav-item">
        <sidenav-collapse
            navText="Elaborador de treino"
            v-bind:collapse="false"
            url="/elaborar-treino/elaborador"
            :aria-controls="''"
            collapseRef="/elaborar-treino/elaborador"
            :class="getRoute() === 'elaborar-treino/elaborador' ? 'active' : ''"
        >
          <template v-slot:icon>
            <icon name="changelog" />
          </template>
        </sidenav-collapse>
      </li>
<!--      <li class="nav-item">
        <sidenav-collapse
          collapseRef="elaborador"
          navText="Elaborador de treino"
          :class="getRoute() === 'elaborador' ? 'active' : ''"
        >
          <template v-slot:icon>
            <icon name="applications" />
          </template>
          <template v-slot:list>
            <ul class="nav ms-4 ps-3">
              &lt;!&ndash; nav links &ndash;&gt;

              <sidenav-item
                :to="{ path: '/elaborar-treino' }"
                miniIcon="I"
                text="Individual"
              />
              <sidenav-item
                :to="{ name: 'Em Desenvolvimento' }"
                miniIcon="G"
                text="Grupo"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>-->

      <!-- AREA DO ALUNO -->
      <li class="nav-item">
        <sidenav-collapse
          navText="Área do aluno"
          v-bind:collapse="false"
          url="/area-aluno"
          :aria-controls="''"
          collapseRef="/area-aluno"
          :class="getRoute() === 'area-aluno' ? 'active' : ''"
        >
          <template v-slot:icon>
            <icon name="changelog" />
          </template>
        </sidenav-collapse>
      </li>

      <!-- ADMINISTRACAO -->
      <li class="nav-item">
        <sidenav-collapse
            navText="Administração"
            v-bind:collapse="false"
            url="/administracao"
            :aria-controls="''"
            collapseRef="/administracao"
            :class="getRoute() === 'administracao' ? 'active' : ''"
        >
          <template v-slot:icon>
            <icon name="changelog" />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          navText="Sair"
          collapseRef="/"
          v-bind:collapse="false"
          @click="logout"
          :aria-controls="''"
        >
          <template v-slot:icon>
            <icon name="changelog" />
          </template>
        </sidenav-collapse>
      </li>
      
    </ul>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <!-- <sidenav-card
      :class="cardBg"
      textPrimary="Need Help?"
      textSecondary="Please check our docs"
      href="https://www.creative-tim.com/learning-lab/bootstrap/overview/soft-ui-dashboard"
      linkText="Documentation"
      iconClass="ni ni-diamond"
    /> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import Icon from "@/components/Icon.vue";
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCard from "./SidenavCard.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    Icon,
    SidenavItem,
    SidenavCollapse,
    SidenavCard,
    SidenavCollapseItem,
  },
  computed: {
    ...mapGetters(['SET_API_TOKEN'])
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      //console.log(routeArr[1])
      return routeArr[1];
    },
    logout() {
      this.$store.dispatch('handleLogout', null)
      this.$router.push('/login')
    }
  },
};
</script>
