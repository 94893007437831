<template>
  <div class="card border-top-start-radius-0 border-top-end-radius-0">
    <div class="card-body">
      <div class="row">
        <div class="col-md-2 col-12">
          <div class="">
            <template v-if="state.preview">
              <div>
                <img :src="state.preview" class="img-fluid mb-2" style="" />
                <div v-if="state.image || (state.preview && state.preview != 'avatar.jpg')" class="d-flex justify-content-center text-center">
<!--                <span
                    class="pointer"
                    data-bs-toggle="tooltip"
                    data-bs-original-title="Deletar"
                    @click="removeAttachment"
                >
                  <i class="fas fa-trash text-danger me-2"></i>
                </span>-->
                  <span class="w-100 btn btn-danger btn-sm px-2 py-1 text-nowrap" style="font-size: .7rem"
                        @click="removeAttachment">
                    Remover image
                  </span>
                </div>
              </div>
            </template>
          </div>
          <label class="align-items-center custom-file d-flex justify-content-around">
          <span class="w-100">
            <input type="file"  style="display:none;" id="attachments" @change="previewImage" name="anexararquivo" placeholder="Anexar arquivos" class="w-100 custom-file-input">
            <span class="btn btn-info btn-sm px-2 py-1 text-nowrap w-100" style="font-size: .7rem">{{!state.image ? 'Selecionar imagem' : 'Alterar imagem' }}</span>
          </span>
          </label>
        </div>
        <div class="col-12 col-md-10">
          <div class="row">
            <div class="col-12">
              <h3>Redes sociais</h3>
            </div>
            <div class="form-group col-12 col-md-6">
              <label >Instagram</label>
              <div class="input-group input-group-sm mb-3">
                <span class="input-group-text" id="basic-addon1">@</span>
                <input type="text" class="form-control form-control-sm" aria-describedby="basic-addon1"
                v-model="state.instagram">
              </div>

            </div>
            <div class="form-group col-12 col-md-6">
              <label >Facebook</label>
              <div class="input-group input-group-sm mb-3">
                <span class="input-group-text" id="basic-addon1">fb.com/</span>
                <input type="text" class="form-control form-control-sm" aria-describedby="basic-addon1"
                       v-model="state.facebook">
              </div>

            </div>
            <div class="form-group col-12 col-md-6">
              <label >Youtube</label>
              <input class="form-control form-control-sm"
                     type="text"
                     v-model="state.youtube"
              >

            </div>
            <div class="form-group col-12 col-md-6">
              <label >Linkedin</label>
              <input class="form-control form-control-sm"
                     type="text"
                     v-model="state.linkedin"
              >

            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-12">
              <h3>Contato</h3>
            </div>
            <div class="form-group col-12 col-md-6">
              <label >Telefone</label>
              <input class="form-control form-control-sm"
                     type="text"
                     v-maska="'(##) #####-####'"
                     v-model="state.telefone"
              >

            </div>
            <div class="form-group col-12 col-md-6">
              <label >Whatsapp</label>
              <input class="form-control form-control-sm"
                     type="text"
                     v-maska="'(##) #####-####'"
                     v-model="state.whatsapp"
              >

            </div>
            <div class="form-group col-12 col-md-6">
              <label >Email</label>
              <input class="form-control form-control-sm"
                     type="text"
                     v-model="state.email"
              >

            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="form-group">
            <label >Biografia</label>
            <Editor
                api-key="a8aunc5elyex78xt7diil5fruk72s21r9i63na5028e7qau8"
                v-model="state.biography"
                :init="{
              toolbar_mode: 'sliding',
              plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
              toolbar: 'blocks fontfamily fontsize | bold italic underline strikethrough | spellcheckdialog a11ycheck typography | align lineheight | numlist bullist indent outdent | removeformat',
              tinycomments_mode: 'embedded'
            }"
                initial-value=''
            />
          </div>
        </div>
        <div class="col-md-12 col-12">
          <div class="">
            <div>
              <div class="d-flex mt-3">
                <label class="me-2">Vídeo de apresentação</label>
                <label class="">
                  <span>
                    <input type="file"  style="display:none;" id="attachments" @change="previewVideo" name="anexararquivo" placeholder="Anexar arquivos" class="custom-file-input">
                    <span class="btn btn-info btn-sm px-2 py-1 text-nowrap" style="font-size: .7rem">{{!state.video ? 'Selecionar video' : 'Alterar video' }}</span>
                  </span>
                </label>
              </div>
              <video id="video-preview" class="w-md-50 w-100" controls>
                <source v-if="state.videopreview != null" :src="state.videopreview">
              </video>
              {{state.videopreview}}
              <div class="" v-if="state.videopreview != null || state.video != null">
                <span class="btn btn-danger btn-sm px-2 py-1 text-nowrap" style="font-size: .7rem"
                      @click="removeAttachmentVideo">
                    Remover vídeo
                  </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-12">
          <h3>Galeria de imagens (antes/depois dos alunos)</h3>
        </div>
        <div class="col-12 col-md-3 text-center" v-for="(item, index) in state.galeria" :key="index" >
          <div>
            <img :src="item.preview" class="img-fluid mb-2" style="height:300px;" />
            <div v-if="item.image || item.preview" class="d-flex justify-content-center text-center">
              <span class="btn btn-danger btn-sm px-2 py-1 text-nowrap" style="font-size: .7rem"
                    @click="removeAttachmentGaleria(index)">
                    Remover imagem
                  </span>
<!--                <span
                    class="pointer"
                    data-bs-toggle="tooltip"
                    data-bs-original-title="Deletar"
                    @click="removeAttachmentGaleria(index)"
                >
                  <i class="fas fa-trash text-danger me-2"></i>
                </span>-->
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3" >
          <input type="file"  style="display:none;" id="attachgaleria" @change="addGaleriaImage"
                 name="anexararquivo2" placeholder="Anexar arquivos" class="btn btn-info btn-sm px-2 py-1 text-nowrap">
          <label for="attachgaleria" class="btn btn-info btn-sm px-2 py-1 text-nowrap" style="font-size: .7rem">Adicionar imagem</label>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-end">
          <button type="button" class="ms-3 btn bg-gradient-primary" @click.prevent="salvar">Salvar</button>
        </div>
      </div>
    </div>


  </div>
</template>
<script>

import {reactive, onMounted, ref, inject, computed, onUnmounted} from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import Editor from "@tinymce/tinymce-vue";
import coachAdministrationRepository from "@/services/api/coachAdministrationRepository";
  export default {
    components: {
      Editor

    },
    setup () {
      const swal = inject('$swal')

      onMounted(() => {
        buscaDadosPagina()
      })
      onUnmounted(() => {
        if (state.videopreview) {
          URL.revokeObjectURL(state.videopreview);
        }
      })
      const state = reactive({
        baseUrl: 'https://apidiariodetreino2.agenciaportes.com.br/storage/',
        // baseUrl: 'http://localhost:9000/storage/',
        id: null,
        videopreview: null,
        video: null,
        image: null,
        preview: 'avatar.jpg',
        biography: '',
        galeria: [],
        instagram: '',
        facebook: '',
        youtube: '',
        linkedin: '',
        telefone: '',
        whatsapp: '',
        email: '',

      })

      function buscaDadosPagina() {
        coachAdministrationRepository.coachPageGet().then(response => {
          console.log(response)
          if(response.data.success && response.data.data) {
            const item = response.data.data
            state.id = item.id
            if(item.video_apresentation != null) {
              state.videopreview = state.baseUrl+item.video_apresentation
            }
            if(item.profile_image_url != null) {
              state.preview = state.baseUrl+item.profile_image_url
            }


            state.biography = item.biography
            state.instagram = item.instagram
            state.facebook = item.facebook
            state.youtube = item.youtube
            state.linkedin = item.linkedin
            state.telefone = item.phone
            state.whatsapp = item.whatsapp
            state.email = item.email

            if(item.gallery.length > 0) {
              item.gallery.forEach(obj => {
                const image = {
                  id: obj.id,
                  image: null,
                  preview: state.baseUrl+obj.image_url
                }
                state.galeria.push(image)
              })
            }
          }


        })
      }

      function deletarArquivo(tipo, index = null) {
        let mensagem = 'essa imagem'
        if(tipo === 'video') {
          mensagem = 'este vídeo'
        }


        swal({
          text: `Deseja deletar ${mensagem}?`,
          showCancelButton: true,
          confirmButtonText: "Sim, quero deletar!",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            deletarArquivoConfirma(tipo, index)
          }
        })
      }

      function deletarArquivoConfirma(tipo, index) {
        if(tipo === 'video') {
          coachAdministrationRepository.coachPageVideoDelete().then(response => {
            deletarArquivoRetornoAPI(response.data.success)
            if(response.data.success) {
              state.video = null
              state.videopreview = null
              let video = document.getElementById('video-preview');
              video.src = null
            }
          })
        }
        if(tipo === 'perfil') {
          coachAdministrationRepository.coachPageImageDelete().then(response => {
            deletarArquivoRetornoAPI(response.data.success)
            if(response.data.success) {
              state.image = null
              state.preview = 'avatar.jpg'
            }
          })
        }
        if(tipo === 'galeria') {
          const image = state.galeria[index]
          coachAdministrationRepository.coachPageGalleryDelete(image.id).then(response => {
            deletarArquivoRetornoAPI(response.data.success)
            if(response.data.success) {
              state.galeria.splice(index, 1)
            }
          })
        }
      }

      function deletarArquivoRetornoAPI(status) {
        let mensagem = 'Erro ao processar solicitação de exclusão!'
        if(status) {
          mensagem = 'Mídia removida com sucesso!'
        }
        swal({
          icon: 'info',
          text: mensagem,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        })
      }

      function previewImage(event) {
        var input = event.target;
        if (input.files) {
          var reader = new FileReader();
          reader.onload = (e) => {
            state.preview = e.target.result;
          }
          state.image=input.files[0];
          reader.readAsDataURL(input.files[0]);
        }
      }

      function removeAttachment () {
        // se so existe o preview é que vem da base de dados e remove via API
        if(state.image == null && state.preview != null) {
          deletarArquivo('perfil')
        } else {
          state.image = null
          state.preview = 'avatar.jpg'
        }

      }

      function previewVideo(event) {
        const videoFile = event.target.files[0];
        state.video = videoFile

        let video = document.getElementById('video-preview');
        let reader = new FileReader();

        reader.readAsDataURL( videoFile );
        reader.onload = (e) => {
          video.src = e.target.result;
        }

      }

      function removeAttachmentVideo () {
        if(state.image == null && state.preview != null) {
          deletarArquivo('video')
        } else {
          state.video = null
          state.videopreview = null
          let video = document.getElementById('video-preview');
          video.src = null
        }

      }

      function removeAttachmentGaleria(index) {
        let item = state.galeria[index]
        // se so existe o preview é que vem da base de dados e remove via API
        if(item.image == null && item.preview != null) {
          deletarArquivo('galeria', index)
        } else {
          state.galeria.splice(index, 1)
        }

      }

      function addGaleriaImage(e) {
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) {
          return
        }

        for (var i = files.length - 1; i >= 0; i--) {
          let file = files[i];

          var reader = new FileReader();
          reader.onload = (e) => {
            let item = {
              id: null,
              image: file,
              preview: e.target.result,
            }

            // Adiciona o item ao array apenas após a leitura do preview
            state.galeria.push(item);
          }

          // Inicia a leitura da imagem
          reader.readAsDataURL(file);
        }

        document.getElementById('attachgaleria').value = []
      }

      function salvar() {
        try {
          // Cria um objeto FormData
          let formData = new FormData();

          if(state.image != null) {
            formData.append(`profile_image`, state.image);
          }
          if(state.video != null) {
            formData.append(`video`, state.video);
          }

          formData.append(`biography`, state.biography);
          formData.append(`instagram`, state.instagram);
          formData.append(`facebook`, state.facebook);
          formData.append(`youtube`, state.youtube);
          formData.append(`linkedin`, state.linkedin);
          formData.append(`phone`, state.telefone);
          formData.append(`whatsapp`, state.whatsapp);
          formData.append(`email`, state.email);

          // Adiciona cada imagem ao FormData
          if(state.galeria.length > 0) {
            state.galeria.forEach((file, index) => {
              if(file.image != null) {
                formData.append(`gallery[${index}]`, file.image);
              }
            });
          }

          coachAdministrationRepository.coachPagePost(formData).then(response => {
            if(response.data.success) {
              swal({
                icon: 'success',
                text: 'Dados da página salvo com sucesso!',
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                  cancelButton: "btn bg-gradient-danger",
                },
                buttonsStyling: false,
              })
            }
          })
          /*if(state.id == null) {
            coachAdministrationRepository.coachPagePost(formData).then(response => {
              console.log(response)
            })
          } else {
            coachAdministrationRepository.coachPagePut(state.id, formData).then(response => {
              console.log(response)
            })
          }*/


        } catch (error) {
          console.error('Erro: ', error);
        }
      }

      return {
        removeAttachmentGaleria,
        removeAttachmentVideo,
        previewVideo,
        removeAttachment,
        previewImage,
        state,
        salvar,
        addGaleriaImage
      }
    }
  }
</script>