import Repository from './repository'

const resource = '/groups'

export default {
  GrupoGetById (id) {
    return Repository.get(`${resource}/${id}`)
  },
  GrupoGetByModalityId (id) {
    return Repository.get(`${resource}/modality/${id}`)
  },
  GrupoGet () {
    return Repository.get(`${resource}`)
  },
  GrupoPost (item) {
    return Repository.post(`${resource}`, item)
  },
  GrupoPut (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  GrupoDelete (planoId) {
    return Repository.delete(`${resource}/${planoId}`)
  }
}
