<template>
	<div class="py-4 container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<!-- Card header -->
					<div class="pb-0 card-header">
						<div class="d-lg-flex">
							<div>
								<h5 class="mb-0">Elaborador de treino</h5>
								<p class="mb-0 text-sm">Listagem de semanas de treinamento</p>
							</div>
							<div class="my-auto mt-4 ms-auto mt-lg-0">
								<div class="my-auto ms-auto">
<!--									<button
										type="button"
										class="mb-0 btn bg-gradient-success btn-sm"
										@click="elaborarTreino()"
									>
										+&nbsp; Elaborar treino
									</button>-->
								</div>
							</div>
						</div>
					</div>
					<div class="card-body">
						<!-- <div class="row">
							<div class="form-group col-12 col-md-6">
								<label class="required">Plano de treino</label>
								<select
									class="form-control custom-select"
									name="training_plan"
									required
									id="select-payment_condition"
									v-model="state.trainingPlanid"
									@change="buscarPlanoDetreinoSemanas"
								>
									<option value="">Selecione um plano de treino</option>
									<option v-for="(item, index) in state.planosDeTreino" 
										:key="index" 
										:value="item.id">
											{{ item.name }} | {{ $filters.datePtbr(item.initial_date) }} à {{ $filters.datePtbr(item.final_date) }} | {{ $filters.countDayAndWeek(item.initial_date, item.final_date) }}
									</option>
								</select>
							</div>
						</div> -->
						<template v-for="(item, index) in state.semanas" :key="index">
							<div class="row mt-3">
								<div class="col-12">
									Semana {{ index + 1 }}
									<span
										class="pointer ms-2"
										data-bs-toggle="tooltip"
										data-bs-original-title="Deletar"
									>
										<i class="fas fa-trash text-secondary" @click="removerSemana(item, index)"></i>
									</span>
								</div>
								<div class="col-12 col-md-12">
									<div class="row">
										<div class="col">
											<div class="form-group ">
												<label for="">Data início</label>
												<span class="form-control form-control-sm">
													{{ $filters.datePtbr(item.initial_date) }}
												</span>
											</div>
										</div>
										<div class="col">
											<div class="form-group">
												<label for="">Data fim</label>
												<span class="form-control  form-control-sm">
													{{ $filters.datePtbr(item.final_date) }}
												</span>
											</div>
										</div>
										<div class="col">
											<div class="form-group">
												<label class="required">Período</label>
												<select
													v-model="item.period_id"
													class="form-control  form-control-sm custom-select"
												>
													<option value="">Selecione</option>
													<option v-for="(select, key) in state.periods " :value="select.id" :key="key">{{select.name}}</option>
												</select>
											</div>
										</div>
										<div class="col">
											<div class="form-group">
												<label class="required">Etapa</label>
												<select
													v-model="item.step_id"
													class="form-control  form-control-sm custom-select"
												>
													<option value="">Selecione</option>
													<option v-for="(select, key) in state.steps " :value="select.id" :key="key">{{select.name}}</option>
												</select>
											</div>
										</div>
										<div class="col">
											<div class="form-group">
												<label class="required">Mesociclo</label>
												<select
													v-model="item.mesocycle_id"
													class="form-control  form-control-sm custom-select"
												>
													<option value="">Selecione</option>
													<option v-for="(select, key) in state.mesocycles " :value="select.id" :key="key">{{select.name}}</option>
												</select>
											</div>
										</div>
										<div class="col">
											<div class="form-group">
												<label class="required">Microciclo</label>
												<select
													v-model="item.microcycle_id"
													class="form-control  form-control-sm custom-select"
												>
													<option value="">Selecione</option>
													<option v-for="(select, key) in state.microcycles " :value="select.id" :key="key">{{select.name}}</option>
												</select>
											</div>
										</div>
										<div class="col">
											<div class="form-group">
												<label class="required">Método</label>
												<select
													v-model="item.method_id"
													class="form-control  form-control-sm custom-select"
												>
													<option value="">Selecione</option>
													<option v-for="(select, key) in state.methods " :value="select.id" :key="key">{{select.name}}</option>
												</select>
											</div>
										</div>
										<div class="col">
											<div class="form-group">
												<label for="">% técnica</label>
												<input
													class="form-control  form-control-sm"
													type="text"
													v-maska="'###'"
													v-model="item.percent_technique"
													@keyup="calcularTecnicaForca('T', index)"
												/>
											</div>
										</div>
										<div class="col">
											<div class="form-group">
												<label for="">% força</label>
												<input
													class="form-control  form-control-sm"
													type="text"
													v-maska="'###'"
													v-model="item.percent_force"
													@keyup="calcularTecnicaForca('F', index)"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
						<div class="row">
							<div class="col-12 text-end">
								<button type="button" class="btn bg-gradient-success btn-sm" @click="salvarSemanas">
									Salvar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
  
  <script>
	import { reactive, onMounted, ref, inject } from "vue"
	import { useStore } from "vuex"
	import planosTreinoRepository from "../../../../services/api/planosTreinoRepository"
	import metodoRepository from "../../../../services/api/metodoRepository"
	import ciclosRepository from "../../../../services/api/ciclosRepository"
	import periodosRepository from "../../../../services/api/periodosRepository"
	import etapasRepository from "../../../../services/api/etapasRepository"
	import MyTable from "../../../../components/MyTable.vue"
	import { useRouter } from "vue-router"
	import moment from "moment"

	export default {
		components: {
			MyTable,
		},
		setup() {
			const router = useRouter();
			const swal = inject("$swal");
			const store = useStore();
			onMounted(() => {
				store.commit("SET_SPLASH_SCREEN", false);

				studentOn.value = store.getters.StudentManager
				if (studentOn.value) {
					listarPlanosTreino()
					fetch()
				}
				state.trainingPlanid = store.getters.TrainingPlanManager
				if (state.trainingPlanid) {
					buscarPlanoDetreinoSemanas()
				}
			});

			//const hidePassword = ref(true)
			// const passwordFieldIcon = computed(() => hidePassword.value ? ['fa', 'eye'] : ['fa', 'eye-slash']);
			// const passwordFieldType = computed(() => hidePassword.value ? "password" : "text");

			let studentOn = ref(false)

			const state = reactive({
				planosDeTreino: [],
				trainingPlanid: '',
				semanas: [],
				methods: [],
				periods: [],
				mesocycles: [],
				microcycles: [],
				steps: [],
			})

			const listarPlanosTreino = () => {
				store.commit("SET_SPLASH_SCREEN", true);
				planosTreinoRepository
					.PlanosTreinoGet()
					.then((response) => {
						store.commit("SET_SPLASH_SCREEN", false);
						state.planosDeTreino = [];
						if (response.data.success) {
							state.planosDeTreino = response.data.data;

						}
					})
					.catch((error) => {
						store.commit("SET_SPLASH_SCREEN", false);
						swal({
							icon: "error",
							title: "Erro ao listar planos de treino!",
							text: error.message,
						});
					});
			}

			const fetch = async () => {
				// methods
				let responseMetodo = await metodoRepository.MetodoGet()
				if(responseMetodo.data.success) {
					state.methods = responseMetodo.data.data
				}

				// cycles
				let responseMesocycle = await ciclosRepository.MesoCiclosGet()
				if(responseMesocycle.data.success) {
					state.mesocycles = responseMesocycle.data.data
				}
				let responseMicrocycle = await ciclosRepository.MicroCiclosGet()
				if(responseMicrocycle.data.success) {
					state.microcycles = responseMicrocycle.data.data
				}

				// periods
				let responsePeriodo = await periodosRepository.PeriodoGet()
				if(responsePeriodo.data.success) {
					state.periods = responsePeriodo.data.data
				}

				// steps
				let responseEtapa = await etapasRepository.EtapaGet()
				if(responseEtapa.data.success) {
					state.steps = responseEtapa.data.data
				}
			}

			const buscarPlanoDetreinoSemanas = async () => {
				try {
					store.commit("SET_SPLASH_SCREEN", true)
					let response = await planosTreinoRepository.SemanasGetByPlanosTreinoId(state.trainingPlanid)
					state.semanas = [];
					if (response.data.success) {
						state.semanas = response.data.data;

					}

					store.commit("SET_SPLASH_SCREEN", false)
				} catch (error) {
					store.commit("SET_SPLASH_SCREEN", false)
					swal({
						icon: "error",
						title: "Erro ao listar planos de treino!",
						text: error.message,
					})
				} finally {
					store.commit("SET_SPLASH_SCREEN", false)
				}
			}

			function elaborarTreino() {
				if (state.trainingPlanid == '' || parseInt(state.trainingPlanid) == 0) {
					swal({
						icon: "info",
						title: "Plano de treino não informado!",
						text: 'Selecione um plano de treino para elaborar um treino',
					})
					return
				}
				router.push(`/elaborar-treino/elaborador`)
			}

			function calcularTecnicaForca(tipo, posicao) {
				if (tipo == 'F') {
					state.semanas[posicao].percent_technique = 100 - parseInt(state.semanas[posicao].percent_force)
				} else {
					state.semanas[posicao].percent_force = 100 - parseInt(state.semanas[posicao].percent_technique)
				}
			}

			const salvarSemanas = async () => {
				try {
					store.commit("SET_SPLASH_SCREEN", true)
					let response = await planosTreinoRepository.SemanasPost(state.semanas)
					store.commit("SET_SPLASH_SCREEN", false)
					if (response.data.success) {
						swal({
							icon: "success",
							title: "Periodização",
							text: 'Periodização salva com sucesso!',
						})
					}
				} catch (error) {
					store.commit("SET_SPLASH_SCREEN", false)
					swal({
						icon: "error",
						title: "Erro ao listar dados do plano de treino!",
						text: error.message,
					})
				} finally {
					store.commit("SET_SPLASH_SCREEN", false)
				}
			}

			function removerSemana(item, index) {

        swal({
          title: `Deseja deletar a semana ${index + 1}`,
          text: 'Ao confirmar todo o treino da semana também será removido.',
          showCancelButton: true,
          confirmButtonText: "Sim, quero deletar!",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            removerSemanaConfirma(item.id)
          }
        })
      }

      function removerSemanaConfirma(id) {
        store.commit('SET_SPLASH_SCREEN', true)
        planosTreinoRepository.SemanaDelete(id).then(response => {
          store.commit('SET_SPLASH_SCREEN', false)
          if(response.data.success) {
            swal({
              title: "Removido!",
              text: "Semana removida com sucesso!",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            }).then(() => {
              location.reload()
            })
          }
        }).catch(error => {
          store.commit('SET_SPLASH_SCREEN', false)
          console.error(error.message)
          swal({
            icon: 'error',
            title: 'Erro ao deletar semana!',
            text: error.message
          })
        })
      }

			

			return {
				state,
				elaborarTreino,
				buscarPlanoDetreinoSemanas,
				studentOn,
				removerSemana,
				calcularTecnicaForca,
				salvarSemanas
			};
		},
	};
</script>
  