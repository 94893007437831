<template>
  <div class="loading" v-if="store.getters.splashScreen">
    <div>
      <img src="@/assets/img/logos/logo.png" class="img-fluid rotate" alt="Logo loading">
    </div>
  </div>
</template>

<script>
	import { reactive, onMounted, ref, inject } from 'vue'
	import { useStore } from 'vuex'

	export default {
		name: 'Load',		
		setup () {
			// SET_SPLASH_SCREEN
			const store = useStore()

			return {
				store
			}
		}
	}
</script>

<style scoped>
.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}

.loading {
  position: fixed;
  z-index: 1150;
  height: 200px;
  width: 200px;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; 
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}


</style>
